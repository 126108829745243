<template>
  <svg
    width="126px"
    height="46px"
    viewBox="0 0 126 46"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="84.1940789%"
        y1="49.5504834%"
        x2="16.5296053%"
        y2="50.4621912%"
        id="linearGradient-1"
      >
        <stop stop-color="#00CBD1" offset="0%"></stop>
        <stop stop-color="#02539B" offset="100%"></stop>
      </linearGradient>
      <text
        id="text-2"
        font-family="Helvetica"
        font-size="22"
        font-weight="normal"
        fill="#FFFFFF"
      >
        <tspan x="13.6823242" y="27">
          {{ from }}
        </tspan>
      </text>
      <!-- box shadow - left box -->
      <filter
        x="-84.6%"
        y="-43.2%"
        width="269.1%"
        height="204.5%"
        filterUnits="objectBoundingBox"
        id="filter-3"
      >
        <feOffset
          dx="0"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="3.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.289826766 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
      <text
        id="text-4"
        font-family="Helvetica"
        font-size="22"
        font-weight="normal"
        fill="#FFFFFF"
      >
        <tspan x="13.6823242" y="27">
          {{ to }}
        </tspan>
      </text>
      <!-- box shadow - right box -->
      <filter
        x="-84.6%"
        y="-43.2%"
        width="269.1%"
        height="204.5%"
        filterUnits="objectBoundingBox"
        id="filter-5"
      >
        <feOffset
          dx="0"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="3.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.289826766 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="components/other/progress/medium"
        transform="translate(3.000000, 3.000000)"
      >
        <path
          d="M42,16 L104,16 C106.209139,16 108,17.790861 108,20 C108,22.209139 106.209139,24 104,24 L42,24 C39.790861,24 38,22.209139 38,20 C38,17.790861 39.790861,16 42,16 Z"
          id="Rectangle"
          fill="url(#linearGradient-1)"
          fill-rule="nonzero"
        ></path>
        <path
          d="M14,15 L60.9204968,15 L66.4985501,20.0386904 L60.9158512,25 L14,25 L14,15 Z"
          id="Rectangle"
          stroke="#EEF2F6"
          stroke-width="2"
          fill-rule="nonzero"
        ></path>
        <g id="Group-3-Copy-4">
          <path
            d="M20,-1.5 C27.8786843,-1.5 34.7672234,0.119150701 37.2974251,2.68792205 C39.8810556,5.23297615 41.5,12.1219239 41.5,20 C41.5,27.8786843 39.8808493,34.7672234 37.312078,37.2974251 C34.7670239,39.8810556 27.8780761,41.5 20,41.5 C12.1213157,41.5 5.23277662,39.8808493 2.70257486,37.312078 C0.118944441,34.7670239 -1.5,27.8780761 -1.5,20 C-1.5,12.1213157 0.119150701,5.23277662 2.68792205,2.70257486 C5.23297615,0.118944441 12.1219239,-1.5 20,-1.5 Z"
            id="Combined-Shape"
            stroke="#EEF2F6"
            stroke-width="3"
            fill="#A0B4C9"
            fill-rule="nonzero"
          ></path>
          <g id="2" fill="#FFFFFF" fill-opacity="1">
            <use filter="url(#filter-3)" xlink:href="#text-2"></use>
            <use xlink:href="#text-2"></use>
          </g>
        </g>
        <g id="Group-3-Copy-2" transform="translate(83.000000, 0.000000)">
          <path
            d="M20,-2.04636308e-12 C27.6391655,-2.04636308e-12 34.1014791,1.58076937 36.2447727,3.75652993 C38.4192306,5.89852093 40,12.3608345 40,20 C40,27.6391655 38.4192306,34.1014791 36.2434701,36.2447727 C34.1014791,38.4192306 27.6391655,40 20,40 C12.3608345,40 5.89852093,38.4192306 3.75522731,36.2434701 C1.58076937,34.1014791 0,27.6391655 0,20 C0,12.3608345 1.58076937,5.89852093 3.75652993,3.75522731 C5.89852093,1.58076937 12.3608345,-2.0478084e-12 20,-2.04636308e-12 Z"
            id="Combined-Shape"
            fill="#0383B4"
            fill-rule="nonzero"
          ></path>
          <g id="3" fill="#FFFFFF" fill-opacity="1">
            <use filter="url(#filter-5)" xlink:href="#text-4"></use>
            <use xlink:href="#text-4"></use>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "op-diagnostic-timeline-small",

  props: {
    from: {
      type: Number,
      default: 0,
      validator: value => value >= 0 && value <= 100,
    },
    to: {
      type: Number,
      default: 0,
      validator: value => value >= 0 && value <= 100,
    },
  },
}
</script>

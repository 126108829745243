<template>
  <v-card elevation="0">
    <op-alert ref="opAlert" color="red" />
    <v-data-table
      :items="phishingSimulations"
      @dblclick:row="gotoSimulation"
      :headers="headers"
      dense
      :search="search"
      fixed-header
      :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
      :items-per-page="itemsPerPage"
      :loading="isLoading"
      class="px-4 table-with-clickable-rows"
    >
      <template v-slot:top>
        <div>
          <v-container>
            <v-row justify="end"
              ><v-col cols="12" sm="4" class="d-flex justify-end"
                ><div
                  class="text-h4"
                  v-html="
                    $tc(
                      'phishingSimulations.totalNumber',
                      phishingSimulations.length
                    )
                  "
                ></div
              ></v-col>
            </v-row>
          </v-container>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              :label="$t('phishingSimulations.searchLabel')"
              single-line
              hide-details
              solo
              flat
              clearable
              dense
              class="text-body-1"
          /></v-toolbar>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <img
          :src="getSimulationStatusImage(item)"
          :title="getSimulationStatusText(item)"
        />
      </template>
      <template v-slot:[`item.launch_date`]="{ item }">
        {{ getLocalDate(item?.launch_date) }}
      </template>
      <template v-slot:[`item.summary.sent`]="{ item }">
        {{
          Math.round((100 * item.summary.sent) / item.summary.total)
            | formatedRate
        }}
      </template>
      <template v-slot:[`item.summary.clicked`]="{ item }">
        {{
          Math.round((100 * item.summary.clicked) / item.summary.total)
            | formatedRate
        }}
      </template>
      <template v-slot:[`item.summary.submitted_data`]="{ item }">
        {{
          Math.round((100 * item.summary.submitted_data) / item.summary.total)
            | formatedRate
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          ><template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on"
              ><v-icon class="very-light-grey--text"
                >mdi-dots-horizontal</v-icon
              ></v-btn
            >
          </template>
          <v-list>
            <v-list-item
              :disabled="!item.phishing_exercise?.documentation_url"
              :href="item.phishing_exercise?.documentation_url"
              target="_blank"
              ><v-list-item-title>{{
                $t("phishingSimulations.communicationKit")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item @click="showPreview(item)"
              ><v-list-item-title>{{
                $t("phishingSimulations.previewLabel")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item
              @click="editPreview(item)"
              :disabled="!isSimulationToCome(item)"
              ><v-list-item-title>{{
                $t("phishingSimulations.editLabel")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item
              :disabled="item.status !== 'created'"
              @click="showTestDialog(item)"
              ><v-list-item-title>{{
                $t("phishingSimulations.tests.startLabel")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item @click="showAllLogs(item)"
              ><v-list-item-title>{{
                $t("phishingSimulations.tests.logsLabel")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item @click="gotoSimulation(null, { item })"
              ><v-list-item-title>{{
                $t("phishingSimulations.viewLabel")
              }}</v-list-item-title></v-list-item
            >
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`header.summary.sent`]>
        <div class="d-inline-flex justify-center align-center">
          <img class="header-icon" :src="getHeaderImage('SentEmails')" />
          <div>{{ $t("phishingSimulations.sentEmails") }}</div>
        </div>
      </template>
      <template v-slot:[`header.summary.clicked`]>
        <div class="d-inline-flex justify-center align-center">
          <img class="header-icon" :src="getHeaderImage('ClickedLinks')" />
          <div>{{ $t("phishingSimulations.clickedLinks") }}</div>
        </div>
      </template>
      <template v-slot:[`header.summary.submitted_data`]>
        <div class="d-inline-flex justify-center align-center">
          <img class="header-icon" :src="getHeaderImage('EnteredData')" />
          <div>{{ $t("phishingSimulations.enteredData") }}</div>
        </div>
      </template>
      <template v-slot:[`footer.prepend`]>
        {{ $t("phishingSimulations.footerText") }}
      </template>
    </v-data-table>
    <v-dialog v-model="logsDialogIsOpened" max-width="720px">
      <v-card class="pa-12">
        <v-card-title>{{
          $t("phishingSimulations.testLogsDialog.title", {
            name: itemName(currentItem),
          })
        }}</v-card-title>
        <v-card-text v-if="isProcessing" class="d-flex">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mx-auto mt-2"
          ></v-progress-circular
        ></v-card-text>
        <ul v-else-if="testLogs.length > 0" class="test-log-list">
          <li v-for="logs in testLogs" :key="logs.id">
            <v-subheader>{{
              $t("phishingSimulations.testLogsDialog.subheader", {
                date: getLocalDateTime(logs.created_at),
              })
            }}</v-subheader>
            <op-phishing-test-results
              :testResults="logs.timeline"
              :isLoading="isProcessing"
            />
          </li>
        </ul>
        <v-card-text v-else>{{
          $t("phishingSimulations.testLogsDialog.noData")
        }}</v-card-text>
        <v-card-actions class="mx-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-none rounded-lg"
            outlined
            icon
            elevation="0"
            @click="showAllLogs(currentItem)"
            :disabled="isProcessing"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            class="text-none rounded-lg"
            outlined
            elevation="0"
            @click="closeLogsDialog"
            :disabled="isProcessing"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="startTestDialogIsOpened" max-width="720px">
      <v-card class="pa-12">
        <v-window v-model="testStep">
          <v-window-item>
            <v-card-title>{{
              $t("phishingSimulations.testConfirmationDialog.title", {
                name: itemName(currentItem),
              })
            }}</v-card-title>
            <v-card-text>
              <ul class="test-help-links">
                <li>
                  <a
                    target="_blank"
                    referrerpolicy="no-referrer"
                    href="https://csg-storage-oppens.s3.eu-west-3.amazonaws.com/_app/faq/ASTUCE-3-Microsoft365-Whitelisting.pdf"
                    ><v-badge color="primary" icon="mdi-help"></v-badge
                    ><span class="ml-6">{{
                      $t(
                        "phishingSimulations.testConfirmationDialog.whiteListMicrosoft365"
                      )
                    }}</span></a
                  >
                </li>
                <li>
                  <a
                    target="_blank"
                    referrerpolicy="no-referrer"
                    href="https://csg-storage-oppens.s3.eu-west-3.amazonaws.com/_app/faq/ASTUCE-11-GoogleWorkspace-Whitelisting.pdf"
                    ><v-badge color="primary" icon="mdi-help"></v-badge
                    ><span class="ml-6">{{
                      $t(
                        "phishingSimulations.testConfirmationDialog.whiteListGoogleWorkspace"
                      )
                    }}</span></a
                  >
                </li>
              </ul>
            </v-card-text>
            <v-card-actions class="mx-4">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none rounded-lg"
                outlined
                elevation="0"
                @click="closeTestDialog"
                :disabled="isProcessing"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                class="text-none rounded-lg"
                dark
                elevation="0"
                @click="startTest(currentItem)"
                :loading="isProcessing"
              >
                {{ $t("phishingSimulations.testConfirmationDialog.start") }}
              </v-btn>
            </v-card-actions>
          </v-window-item>
          <v-window-item>
            <v-card-title>{{
              $t("phishingSimulations.testConfirmationDialog.emailSentTitle")
            }}</v-card-title>
            <v-card-text
              ><span
                v-html="
                  $t(
                    'phishingSimulations.testConfirmationDialog.emailSentDescription'
                  )
                "
              ></span
            ></v-card-text>
            <v-card-actions class="mx-4">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none rounded-lg"
                outlined
                elevation="0"
                @click="closeTestDialog"
                :disabled="isProcessing"
              >
                {{ $t("close") }}
              </v-btn>
              <v-btn
                color="primary"
                class="text-none rounded-lg"
                dark
                elevation="0"
                @click="showLogs(currentItem, 2)"
                :loading="isProcessing"
              >
                {{ $t("phishingSimulations.testConfirmationDialog.next") }}
              </v-btn>
            </v-card-actions>
          </v-window-item>
          <v-window-item>
            <v-card-title>{{
              $t("phishingSimulations.testConfirmationDialog.showLogsTitle")
            }}</v-card-title>
            <op-phishing-test-results
              :testResults="testResults"
              :isLoading="isProcessing"
            />
            <v-card-text
              ><span
                v-html="
                  $t(
                    'phishingSimulations.testConfirmationDialog.showLogsDescription'
                  )
                "
              ></span
            ></v-card-text>
            <v-card-actions class="mx-4">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none rounded-lg"
                outlined
                icon
                elevation="0"
                @click="showLogs(currentItem, 2)"
                :disabled="isProcessing"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                class="text-none rounded-lg"
                outlined
                elevation="0"
                @click="closeTestDialog"
                :disabled="isProcessing"
              >
                {{ $t("close") }}
              </v-btn>
              <v-btn
                color="primary"
                class="text-none rounded-lg"
                dark
                elevation="0"
                @click="showLogs(currentItem, 3)"
                :loading="isProcessing"
              >
                {{ $t("phishingSimulations.testConfirmationDialog.next") }}
              </v-btn>
            </v-card-actions>
          </v-window-item>
          <v-window-item>
            <v-card-title>{{
              $t("phishingSimulations.testConfirmationDialog.linkClickedTitle")
            }}</v-card-title>
            <op-phishing-test-results
              :testResults="testResults"
              :isLoading="isProcessing"
            />
            <v-card-text
              ><span
                v-html="
                  $t(
                    'phishingSimulations.testConfirmationDialog.linkClickedDescription'
                  )
                "
              ></span
            ></v-card-text>
            <v-card-actions class="mx-4">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none rounded-lg"
                outlined
                icon
                elevation="0"
                @click="showLogs(currentItem, 3)"
                :disabled="isProcessing"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                class="text-none rounded-lg"
                dark
                elevation="0"
                @click="closeTestDialog"
                :disabled="isProcessing"
              >
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </v-window-item>
        </v-window>
        <v-snackbar v-model="errorSnackbar" color="error">{{
          errorMessage
        }}</v-snackbar>
      </v-card>
    </v-dialog>
    <op-phishing-exercice-preview-dialog
      :companyUuid="companyUuid"
      :simulation="selectedSimulation"
      :mode="exerciceMode"
      @close="closePreview"
      @refresh="getPhishingSimulations"
    />
  </v-card>
</template>
<script>
import SentEmails from "@/assets/phishing/sent-emails.svg"
import ClickedLinks from "@/assets/phishing/clicked-links.svg"
import EnteredData from "@/assets/phishing/entered-data.svg"
import PhishingSimulationLogic from "@/logics/PhishingSimulationLogic"
import PhishingMixins from "@/mixins/Phishing.mixins"
import TableMixins from "@/mixins/Table.mixins.js"
import DateTimeMixins from "@/mixins/DateTime.mixins.js"
import AlertMixin from "@/mixins/Alert.mixins.js"
import Active from "@/assets/active.svg"
import Ended from "@/assets/ended.svg"
import Warning from "@/assets/warning.svg"

export default {
  name: "op-phishing-simulation-list",
  mixins: [PhishingMixins, TableMixins, DateTimeMixins, AlertMixin],
  props: {
    companyUuid: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    this.getPhishingSimulations()
  },
  watch: {
    companyUuid(newCompanyUuid, oldCompanyUuid) {
      this.getPhishingSimulations()
    },
  },
  data() {
    return {
      phishingSimulations: [],
      search: "",
      isLoading: false,
      startTestDialogIsOpened: false,
      logsDialogIsOpened: false,
      testResults: [],
      testLogs: [],
      test: null,
      testStep: 0,
      testSteps: ["notStarted", "emailSent", "showLogs", "linkClicked"],
      errorMessage: "",
      isProcessing: false,
      currentItem: null,
      headers: [
        {
          text: this.$t("phishingSimulations.status"),
          value: "status",
          sortable: false,
          cellClass: "text-body-1",
          class: this.headerClass,
          align: "center",
        },
        {
          text: "Date de lancement",
          value: "launch_date",
          sortable: false,
          cellClass: "text-body-1",
          class: this.headerClass,
        },
        {
          text: this.$t("phishingSimulations.campaign"),
          value: "campaign.name",
          sortable: true,
          cellClass: "text-body-1",
          class: this.headerClass,
        },
        {
          text: this.$t("phishingSimulations.simulation"),
          value: "name",
          sortable: true,
          cellClass: "text-body-1",
          class: this.headerClass,
        },
        {
          text: this.$t("phishingSimulations.sentEmails"),
          value: "summary.sent",
          sortable: false,
          cellClass: "text-body-1",
          class: this.headerClass,
          align: "center",
        },
        {
          text: this.$t("phishingSimulations.clickedLinks"),
          value: "summary.clicked",
          sortable: false,
          cellClass: "text-body-1",
          class: this.headerClass,
          align: "center",
        },
        {
          text: this.$t("phishingSimulations.enteredData"),
          value: "summary.submitted_data",
          sortable: false,
          cellClass: "text-body-1",
          class: this.headerClass,
          align: "center",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          cellClass: "text-body-1",
          class: this.headerClass,
          align: "center",
        },
      ],
    }
  },
  computed: {
    errorSnackbar: {
      get: function () {
        return this.errorMessage !== ""
      },
      set: function (newValue) {
        return newValue
      },
    },
  },
  methods: {
    itemName(item) {
      return item?.name
    },
    showTestDialog(item) {
      this.currentItem = item
      this.startTestDialogIsOpened = true
    },
    getHeaderImage(header) {
      switch (header) {
        case "EnteredData":
          return EnteredData
        case "ClickedLinks":
          return ClickedLinks
        case "SentEmails":
          return SentEmails
        default:
          return null
      }
    },
    async getPhishingSimulations() {
      if (this.companyUuid) {
        try {
          this.isLoading = true
          const phishingSimulationResults =
            await PhishingSimulationLogic.getAll(this.companyUuid, {
              with_employees: 0,
              with_results: 0,
              with_summary: 1,
              with_phishing_exercise_documentation: 1,
              with_admin_infos: 1,
              "sorts[launch_date]": "desc",
            })
          this.phishingSimulations = phishingSimulationResults.simulations
        } catch (error) {
          this.displayAlert(error?.response?.data?.error?.message)
        } finally {
          this.isLoading = false
        }
      }
    },
    getSimulationStatusImage(simulation) {
      if (simulation) {
        if (simulation.status === "created") {
          if (simulation.is_active === false) {
            return Warning
          } else {
            return Active
          }
        } else {
          return Ended
        }
      } else {
        return Warning
      }
    },
    gotoSimulation(event, { item }) {
      this.$router.push({
        name: "member-phishing-simulation",
        params: { simulationId: item.id },
      })
    },
    async startTest(item) {
      this.errorMessage = ""
      if (this.companyUuid) {
        try {
          this.isProcessing = true
          const test = await PhishingSimulationLogic.createTest(
            this.companyUuid,
            item.id
          )
          this.test = test.response.data.data
          this.testStep += 1
        } catch (error) {
          this.displayAlert(error?.response?.data?.error?.message)
        } finally {
          this.isProcessing = false
        }
      }
    },
    closeTestDialog() {
      this.startTestDialogIsOpened = false
      this.testStep = 0
      this.testResults = []
      this.errorMessage = ""
    },
    closeLogsDialog() {
      this.logsDialogIsOpened = false
      this.testLogs = []
    },
    async showLogs(item, step) {
      this.errorMessage = ""
      this.isProcessing = true
      const testResults = await this.getTestResults(item.id)
      this.testResults = testResults.find(
        (test) => test.id === this.test.id
      ).timeline
      this.testStep = step
      this.isProcessing = false
    },
    async showAllLogs(item) {
      this.currentItem = item
      this.logsDialogIsOpened = true
      this.testLogs = await this.getTestResults(item.id)
    },
    async getTestResults(simulationId) {
      this.errorMessage = ""
      if (this.companyUuid) {
        try {
          this.isProcessing = true
          const testResults = await PhishingSimulationLogic.getTestResults(
            this.companyUuid,
            simulationId
          )
          return testResults.response.data.data
        } catch (error) {
          if (error?.response?.data?.error) {
            this.errorMessage = error.response.data.error
          } else {
            this.errorMessage = error
          }
        } finally {
          this.isProcessing = false
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.header-icon {
  width: 1.2rem;
  margin-right: 0.6rem;
}

.test-log-list {
  list-style: none;
}
.test-help-links {
  list-style: none;
}
</style>

import AuthLogic from "@/logics/AuthLogic"
import Vue from "vue"

class AuthStore {
  constructor() {
    this.me = null
    this.token = null
    this.state = Vue.observable({
      me: null,
      token: null,
    })
    this.saveAnonymous = false
  }

  setAnonymousData(val) {
    this.saveAnonymous = val
  }

  setToken(token) {
    this.token = token
    this.state.token = token
  }

  setMe(user) {
    this.state.me = user
    this.me = user
  }

  reset() {
    this.me = null
    this.token = null
    this.state.me = null
    this.state.token = null
  }

  isSignedIn() {
    return this.state.me && !this.state.me.is_anonymous
  }

  isAnonymous() {
    return this.state.me && this.state.me.is_anonymous
  }

  /** @description called by App.vue if we have a token */
  async sync() {
    if (!AuthLogic.me()) {
      await AuthLogic.loadMe()
    }

    // get values from localstorage ?
    this.me = AuthLogic.me()
    this.token = AuthLogic.getTokens()
    this.state.me = AuthLogic.me()
    this.state.token = AuthLogic.getTokens()
  }

  isCompanyUser() {
    return (
      this.state.me?.company_roles &&
      this.state.me.company_roles.includes("company-user")
    )
  }

  canManageEmployees(){
    let val = (this.state.me?.company_roles &&
    (this.state.me.company_roles.includes("company-user") ||
    this.state.me.company_roles.includes("company-human-resource-manager")))
    return val
  }

  isManager() {
    return (
      this.state.me?.company_roles &&
      this.state.me?.is_manager === true &&
      this.state.me.company_roles.includes("company-user")
    )
  }
}

export default new AuthStore()

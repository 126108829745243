<template>
  <svg
    height="100%"
    width="125px"
    viewBox="0 0 146 125"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="84.1940789%"
        y1="15.5838816%"
        x2="16.5296053%"
        y2="85.3865132%"
        id="linearGradient-1"
      >
        <stop v-bind:stop-color="theme.color1" offset="0%"></stop>
        <stop v-bind:stop-color="theme.color2" offset="100%"></stop>
      </linearGradient>
      <path
        d="M43,0 C60.3128045,0 74.7977848,3.22862802 78.4497988,7.55117956 C82.771372,11.2022152 86,25.6871955 86,43 C86,60.3128045 82.771372,74.7977848 78.4488204,78.4497988 C74.7977848,82.771372 60.3128045,86 43,86 C25.6871955,86 11.2022152,82.771372 7.55020118,78.4488204 C3.22862802,74.7977848 3.55271368e-15,60.3128045 3.55271368e-15,43 C4.87906012e-16,25.6871955 3.22862802,11.2022152 7.55117956,7.55020118 C11.2022152,3.22862802 25.6871955,0 43,0 Z"
        id="path-2"
      ></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1019.000000, -131.000000)">
        <g transform="translate(328.000000, 131.000000)">
          <g transform="translate(332.000000, 0.000000)">
            <g transform="translate(371.000000, 0.000000)">
              <g transform="translate(18.000000, 19.000000)">
                <mask id="mask-3" fill="white">
                  <use xlink:href="#path-2"></use>
                </mask>
                <g id="Combined-Shape">
                  <use
                    fill="black"
                    fill-opacity="1"
                    filter="url(#filter-4)"
                    xlink:href="#path-2"
                  ></use>
                  <use
                    fill="url(#linearGradient-1)"
                    fill-rule="evenodd"
                    xlink:href="#path-2"
                  ></use>
                </g>
                <text
                  id="3.9"
                  mask="url(#mask-3)"
                  font-family="Helvetica"
                  font-size="36.3380282"
                  font-weight="normal"
                  fill="#FFFFFF"
                >
                  <tspan x="24.3894009" y="42">
                    {{ score.split(".")[0] ? score.split(".")[0] : 0 }}
                  </tspan>
                  <tspan x="44.5988804" y="42" font-size="18">
                    .{{ score.split(".")[1] ? score.split(".")[1] : 0 }}
                  </tspan>
                </text>
                <text
                  id="4"
                  opacity="0.4"
                  mask="url(#mask-3)"
                  font-family="Helvetica"
                  font-size="14"
                  font-weight="normal"
                  line-spacing="22"
                  fill="#FFFFFF"
                >
                  <tspan x="38" y="70">4</tspan>
                </text>
                <line
                  x1="37"
                  y1="54.5"
                  x2="49"
                  y2="54.5"
                  id="Line"
                  stroke="#FFFFFF"
                  stroke-width="1.5"
                  opacity="0.399321057"
                  stroke-linecap="round"
                  mask="url(#mask-3)"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  name: "op-diagnostic-svg-score",

  props: {
    score: {
      type: String,
    },
    theme: {
      default: () => ({
        color1: "#00CBD1",
        color2: "#02539B",
      }),
    },
  },
}
</script>

<template>
  <div v-if="$route.query.payment_intent_client_secret">
    <p>{{ $t("checkout.payment-in-progress") }}</p>
    <p>{{ $t("checkout.payment-redirection") }}</p>
  </div>
  <div v-else>
    <p>{{ $t("checkout.payment-failed") }}</p>
    <p>{{ $t("checkout.payment-redirection") }}</p>
  </div>
</template>

<script>
export default {
  name: "view-store-payment-result",

  created() {
    window.top.postMessage("3DS-authentication-complete")
  },
}
</script>

<style lang="scss"></style>

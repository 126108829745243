import axios from "axios"

export default class BlogRepository {
  constructor() {}

  static async getPosts() {
    let client = axios.create({
      baseURL: "https://www.oppens.fr/wp-json/wp/v2/posts",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    return client.get("", {})
  }

  static async getMedia(id) {
    let client = axios.create({
      baseURL: "https://www.oppens.fr/wp-json/wp/v2/media/" + id,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    let response = await client.get("", {})
    return response.data
  }
}

<template>
  <v-menu v-model="menuVisible"  :close-on-content-click="false" :nudge-width="300" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div class="menu-button-container" v-on="on" v-bind="attrs">
        <v-avatar color="primary">
          <v-icon color="white">mdi-bell</v-icon>
        </v-avatar>
        <div class="nb-notif" v-if="unreadNotificationsCount > 0">
          {{ unreadNotificationsCount }}
        </div>
      </div>
    </template>

    <v-card class="notif-car">
      <v-card-title>{{ $t("notifications.title") }}</v-card-title>

      <v-divider></v-divider>

      <v-list class="notif-list">
        <template v-for="notification of notifications">

          <div class="ml-2 mr-2 d-flex" v-bind:key="notification._id">

              <!-- Notification Icon -->
              <v-list-item-icon>
                <v-avatar :class="getNotificationClass(notification?.data?.type)" size="36">
                  <v-icon v-if="notification?.data?.icon">{{ notification?.data?.icon }}</v-icon>
                  <v-icon v-else>mdi-information-variant</v-icon>
                </v-avatar>
              </v-list-item-icon>

              <!-- Notification Content -->
              <v-list-item-content>
                <v-list-item-title>{{ notification?.data?.company }}</v-list-item-title>
                <v-list-item-subtitle
                  class="multi-line-message"
                  v-if="notification.data.payload"
                >{{ notification?.data?.payload.message }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <!-- Action Button to mark as read -->
              <v-list-item-action>
                <v-btn icon @click="markNotificationAsRead(notification)">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
          </div>
        </template>
        <v-list-item v-if="unreadNotificationsCount > 0">
          <v-btn @click="markAllAsRead" class="no-uppercase" block>
            Tout lire
          </v-btn>
        </v-list-item>
        <v-list-item v-if="unreadNotificationsCount === 0">
          <span class="no-data">{{ $t("notifications.noData") }}</span>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import ZapAPILogic from "@/logics/ZapAPILogic"
import UserLogic from "@/logics/UserLogic"

export default {
  name: "op-notification-menu",

  props: {},

  data: () => ({
    notifications: [], // Liste des notifications
    unreadNotificationsCount: 0, // Nombre de notifications non lues
    menuVisible: false,
  }),
  async mounted() {
    await this.fetchNotifications()
    setInterval(this.fetchNotifications, 60000) // Toutes les 60 secondes
  },
  computed: {},

  methods: {

    /*async requestNotifications() {
      await this.$notifStore.sync()
    },

    deleteNotif(notif) {
      ZapAPILogic.deleteNotification(notif)
        .then((success) => {
          this.$notifStore.sync()
        })
        .catch((e) => {
          //console.log("error: ", e)
        })
    },*/
    closeMenu(){
      this.menuVisible = false
    },
    async markNotificationAsRead(notification) {
      await UserLogic.markNotificationAsRead(notification.id)
      await this.fetchNotifications()
    },
    async fetchNotifications() {
      const response = await UserLogic.getNotifications(true)
      if (response && response.length >= 0) {
        this.notifications = response
        this.unreadNotificationsCount = response.length
      }
      return null
    },
    async markAllAsRead() {
      await UserLogic.markAllAsRead()
      await this.fetchNotifications()
      this.closeMenu();
    },
    getNotificationClass(type) {
      switch (type) {
        case "error":
          return "notification-error"
        case "success":
          return "notification-success"
        default:
          return "notification-default"
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-button-container {
  position: relative;
}

$nb-notif-size: 20px;
.nb-notif {
  position: absolute;
  background-color: red;
  color: white;
  font-weight: bold;
  width: $nb-notif-size;
  height: $nb-notif-size;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  line-height: $nb-notif-size;
  bottom: 0;
  right: 0;
  font-size: $nb-notif-size / 2 + $nb-notif-size / 5;
}

.notif-card {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
}

.notif-list {
  flex-grow: 1;
  overflow-y: scroll;
}

.list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10px;
}

.date {
  color: silver;
  font-size: 12px;
  margin-left: 10px;
}

.notification {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scan-notif {
  display: flex;
  flex-direction: column;
}

.notif-link {
  cursor: pointer;
}

.close-notif {
  color: red;
  margin: 0 5px;
}

.no-data {
  color: silver;
  font-style: italic;
}


.notification {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.notification-error {
  background-color: #f8d7da;
  color: #721c24;
}

.notification-success {
  background-color: #d4edda;
  color: #155724;
}

.notification-default {
  background-color: #f8f9fa;
  color: #6c757d;
}
</style>

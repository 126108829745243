import Request from "../services/Request"

export default class UserRepository {
  /* All */

  /**
   * @returns {Promise}
   */
  static getAll(params) {
    return Request.make("GET", "/users", { params })
  }

  /**
   * @returns {Promise}
   */
  static getOne(uuid, params = {}) {
    return Request.make("GET", `/users/${uuid}`, { params })
  }

  /**
   * @returns {Promise}
   */
  static createOne(body = {}) {
    return Request.make("POST", `/users`, body)
  }

  /**
   * @returns {Promise}
   */
  static patchOne(userUuid, body = {}) {
    return Request.make("PATCH", `/users/${userUuid}`, body)
  }

  /* Me */

  /**
   * @returns {Promise}
   */
  static updateMe(body = {}) {
    return Request.make("PUT", `/me`, body)
  }

  /**
   * @returns {Promise}
   */
  static registerToNewsletter(email) {
    return Request.make("POST", `/misc/register-newsletter`, {
      email: email,
    })
  }

  /**
   * @returns {Promise}
   */
  static getPermissions() {
    return Request.make("GET", "/user-roles")
  }

  static getNotifications(unread) {
    const url = unread ? "/v1/notifications?unread=true" : "/v1/notifications"
    return Request.make("GET", url)
  }

  static markNotificationAsRead(notificationId) {
    return Request.make("POST", `/v1/notifications/${notificationId}/read`)
  }

  static markAllAsRead() {
    return Request.make("POST", `/v1/notifications/read-all`)
  }

}

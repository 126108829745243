import CampaignRepository from "../repositories/CampaignRepository"

export default class CampaignLogic {
  /**
   *
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static async getAll(companyUuid, params = {}) {
    const response = await CampaignRepository.getAll(companyUuid, params)
    return response.dataObject()
  }
  /**
   *
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static async getEmployees(companyUuid, params = {}) {
    const res = await CampaignRepository.getEmployees(companyUuid, params)
    return res?.response?.data
  }
  /**
   *
   * @param companyUuid
   * @returns {Promise}
   */
  static async getStats(companyUuid) {
    const response = await CampaignRepository.getStats(companyUuid)
    return response.dataObject()
  }
}

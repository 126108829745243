<template>
  <div class="level-group">
    <op-level-item
      v-for="currentLevel in [1, 2, 3, 4]"
      :key="currentLevel"
      :size="
        level == currentLevel
          ? 58
          : 58 - 3 * (4 + 3 * Math.abs(currentLevel - level))
      "
      :gradient="
        level == currentLevel ? ['#00cbd1', '#02539b'] : ['#000', '#000']
      "
      :backgroundColor="'#a0b4c9aa'"
      :enableShadow="level == currentLevel"
      :textContent="currentLevel.toString()"
      :strokeWidth="level == currentLevel ? 0 : 4"
      :style="{
        margin: '0 0 0 ' + ([2, 3, 4].includes(currentLevel) ? '-9px' : '0'),
        'z-index': 4 - Math.abs(currentLevel - level),
      }"
      :enableTextShadow="true"
    >
    </op-level-item>
  </div>
</template>

<script>
export default {
  name: "op-level-group",
  props: {
    level: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.level-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>

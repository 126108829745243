var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":_vm.firstRowCols}},[(_vm.title)?_c('h3',{staticClass:"text-h3 font-weight-light"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('op-toggle-buttons',{attrs:{"buttons":[
        {
          value: 'clicked',
          label: _vm.$t('phishingSimulations.charts.clicked'),
        },
        {
          value: 'submitted',
          label: _vm.$t('phishingSimulations.charts.submitted'),
        },
      ]},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":_vm.firstRowCols}},[_c('op-toggle-buttons',{attrs:{"buttons":_vm.mappingButtons},model:{value:(_vm.selectedMapping),callback:function ($$v) {_vm.selectedMapping=$$v},expression:"selectedMapping"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.hasData)?_c('Doughnut',{attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartData[_vm.selectedMapping][_vm.selectedAction],"height":_vm.height}}):_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t(`phishingSimulations.charts.noDataFor_${_vm.selectedMapping}`))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
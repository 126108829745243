import Vue from "vue"

class DashboardStore {
  constructor() {
    this.state = Vue.observable({
      obj: null,
    })
  }

  setObj(obj) {
    this.state.obj = obj
  }

  reset() {
    this.state.obj = null
  }
}

export default new DashboardStore()

<template>
  <section class="store-breadcrumb align-baseline d-flex my-4">
    <router-link
      class="path path-link text-body-2 charcoal-grey--text text-no-decoration"
      to="/store"
      >{{ $t(homePath) }}</router-link
    >

    <op-store-breadcrumb-arrow
      v-if="productName"
      class="svg-arrow mx-2"
    ></op-store-breadcrumb-arrow>

    <router-link
      class="path path-link text-body-2 steel--text text-no-decoration"
      v-if="productName"
      :to="{ name: 'store_category', params: { categoryId: categoryId } }"
      >{{ categoryName }}
    </router-link>

    <div v-else class="path text-body-2 steel--text text-no-decoration">
      <op-store-breadcrumb-arrow
        class="svg-arrow mx-2"
      ></op-store-breadcrumb-arrow
      >{{ categoryName }}
    </div>

    <div
      v-if="productName"
      class="path text-body-2 steel--text text-no-decoration"
    >
      <op-store-breadcrumb-arrow
        v-if="productName"
        class="svg-arrow mx-2"
      ></op-store-breadcrumb-arrow
      >{{ productName }}
    </div>
  </section>
</template>

<script>
import PageStore from "../../../stores/PageStore"
import CategoryLogic from "../../../logics/CategoryLogic"
export default {
  name: "op-store-breadcrumbs",
  data() {
    return {
      categoryName: "",
    }
  },
  props: {
    categoryId: {
      type: [Number, String],
      required: true,
      default() {
        return 0
      },
    },
  },
  created() {
    this.setProductCategoryName()
  },
  methods: {
    setProductCategoryName: async function () {
      this.categoryName = ""
      if (this.categoryId == 0) {
        this.categoryName = "A la une"
      } else if (this.categoryId) {
        let category = await CategoryLogic.getOne(this.categoryId)
        if (category) {
          this.categoryName = category.attributes.name
        }
      }
    },
  },
  watch: {
    categoryId: function () {
      this.setProductCategoryName()
    },
  },
  computed: {
    homePath() {
      return PageStore.storeBreadcrumbs.root
    },
    productName() {
      return PageStore.storeBreadcrumbs.productName
    },
  },
}
</script>

<style lang="scss">
.store-breadcrumb {
  .svg-arrow {
    width: 8px;
    height: 8px;
  }

  .path {
    &.path-link {
      color: $charcoal-grey;
      transition: color 0.3s;

      &:hover {
        color: $steel;
      }
    }
  }
}
</style>

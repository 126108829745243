<template>
  <v-card width="100%" elevation="0">
    <v-data-table
      :multi-sort="true"
      :items="employees"
      :server-items-length="totalEmployees"
      @update:options="changeListOptions"
      :page.sync="currentPage"
      :headers="headers"
      dense
      fixed-header
      :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
      :items-per-page="itemsPerPage"
      :loading="employeeTableIsLoading"
      class="px-4"
    >
      <template v-slot:top>
        <div>
          <op-employee-table-filters
            :employeeTableIsLoading="employeeTableIsLoading"
            :filteringOptions="filteringOptions"
            :totalEmployees="totalEmployees"
            :filterType="1"
            @updateFilters="updateFilters"
            :hideableColumns="hideableColumns"
            @updateColumnSelection="updateColumnSelection"
          ></op-employee-table-filters>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              @input="debouncedSearchUpdate"
              prepend-icon="mdi-magnify"
              :label="$t('employees.searchLabel')"
              single-line
              hide-details
              solo
              flat
              clearable
              dense
              class="text-body-1"
              :disabled="employeeTableIsLoading"
          /></v-toolbar>
        </div>
      </template>
      <template v-slot:[`item.informations.last_name`]="{ item }">
        {{ item.informations.last_name | uppercase }}
      </template>
      <template v-slot:[`item.informations.first_name`]="{ item }">{{
        item.informations.first_name | titlecase
      }}</template>
      <template v-slot:[`item.informations.cyberscore`]="{ item }"
        ><v-btn text @click="selectedEmployeeId = item.informations.id">
          {{
            item.informations.cyberscore ? item.informations.cyberscore : "--"
          }}
        </v-btn></template
      >
      <template v-slot:[`item.informations.active`]="{ item }">
        <img :src="getActiveIcon(item.informations.active)" />
      </template>
      <template
        v-for="cell in additionalCells"
        v-slot:[`item.${bestScoreField(cell)}`]="{ item }"
        ><div
          :key="cell"
          class="d-inline-flex justify-center align-center"
          v-if="getCurrentCourse(item.company_courses, cell)"
        >
          <img
            class="mr-2"
            :src="
              getEmployeeBestScoreImage(
                getCurrentCourse(item.company_courses, cell).results
              )
            "
          />
          <div>
            {{
              getCurrentCourse(item.company_courses, cell).results
                .number_of_sessions > 0
                ? `${
                    getCurrentCourse(item.company_courses, cell).results
                      .best_score
                  }%`
                : ""
            }}
          </div>
        </div></template
      >
      <template
        v-for="cell in additionalCells"
        v-slot:[`header.${bestScoreField(cell)}`]
      >
        <router-link
          :key="cell"
          :to="{
            name: 'member-course',
            params: { courseId: cell },
          }"
          class="color-inherit"
          >{{ courseHeader(cell) }}</router-link
        >
      </template>
    </v-data-table>
    <op-employee-cyberscore-dialog
      :employeeId="selectedEmployeeId"
      @close="() => (selectedEmployeeId = null)"
    />
  </v-card>
</template>
<script>
import lodash from "lodash"
import EmployeeTableMixin from "@/mixins/EmployeeTable.mixins.js"
import TableMixins from "@/mixins/Table.mixins.js"
import CourseMixins from "@/mixins/Course.mixins"
import CourseLogic from "../../logics/CourseLogic"

export default {
  name: "op-course-employees",
  mixins: [EmployeeTableMixin, CourseMixins, TableMixins],
  props: {
    companyUuid: {
      type: String,
      required: true,
    },
    courses: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      additionalCells: [],
    }
  },
  watch: {
    /* companyUuid(newCompanyUuid, oldCompanyUuid) {
      if (!lodash.isEmpty(this.additionalCells)) {
        this.refreshEmployees()
      }
    }, */
    courses: {
      handler() {
        this.prepTable()
      },
      immediate: true,
    },
  },
  created() {
    this.defineHeaders()
  },
  methods: {
    courseHeader(courseId) {
      return this.hideableColumns.find((col) => col.courseId === courseId)?.text
    },
    defineHeaders() {
      if (!this.STATIC_HEADERS) {
        // Here we define headers that are always displayed
        this.STATIC_HEADERS = [
          {
            text: this.$t("employees.active"),
            value: "informations.active",
            cellClass: "text-body-1",
            class: this.headerClass,
            sortable: false,
            align: "center",
          },
          {
            text: this.$t("employees.emailAddress"),
            value: "informations.email",
            sortable: true,
            cellClass: "text-body-1",
            class: this.headerClass,
          },
        ]
        // Here we define headers that can be hidden
        this["informations.last_name"] = {
          text: this.$t("employees.lastName"),
          value: "informations.last_name",
          cellClass: "text-body-1",
          class: this.headerClass,
        }
        this["informations.first_name"] = {
          text: this.$t("employees.firstName"),
          value: "informations.first_name",
          cellClass: "text-body-1",
          class: this.headerClass,
        }
        this["informations.cyberscore"] = {
          text: this.$t("employees.cyberscore"),
          value: "informations.cyberscore",
          cellClass: "text-body-1",
          align: "right",
          class: this.headerClass,
          sortable: false,
        }
        // No action headers for this view
        this.ACTION_HEADERS = []
        this.hideableColumns = [
          { ...this["informations.cyberscore"], default: true },
          { ...this["informations.last_name"], default: false },
          { ...this["informations.first_name"], default: false },
        ]
      }
    },
    prepTable() {
      this.additionalCells = []
      if (!lodash.isEmpty(this.courses)) {
        this.defineHeaders()
        this.headers = lodash.clone(this.STATIC_HEADERS)
        for (
          let courseIndex = 0;
          courseIndex < this.courses.length;
          courseIndex++
        ) {
          const course = this.courses[courseIndex]
          const courseId = course.id.toString()
          const columnValueName = this.bestScoreField(course.id)
          this[columnValueName] = {
            text: course?.name,
            value: columnValueName,
            sortable: false,
            cellClass: "text-body-1",
            class: this.headerClass,
            align: "center",
            courseId: courseId,
            default: true,
          }
          this.hideableColumns.push(this[columnValueName])
          this.additionalCells.push(courseId)
        }
        this.headers = this.headers.concat(
          this.hideableColumns.filter((col) => col.default === true)
        )
        return true
      } else {
        return false
      }
    },
    bestScoreField(courseId) {
      return `company_courses.${courseId}.results.best_score`
    },
    async refreshEmployees(pageNumber) {
      if (this.companyUuid && !this.employeeTableIsLoading) {
        try {
          this.employeeTableIsLoading = true
          if (pageNumber) {
            this.currentPage = pageNumber
          }
          let params = this.getParams(pageNumber)
          params = {
            ...params,
            ...this.sortOptions,
            with_employees: 0,
            with_results: 1,
            with_summary: 1,
          }
          const result = await CourseLogic.getEmployees(
            this.companyUuid,
            params
          )
          this.employees = result.data?.employees
          this.totalEmployees = result.meta?.total || 0
          this.filteringOptions = result.meta?.filtering_options
        } catch (error) {
          this.$emit("onError", error?.response?.data?.error?.message)
        } finally {
          this.employeeTableIsLoading = false
        }
      }
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',_vm._b({staticStyle:{"position":"relative"}},'v-card',_vm.$attrs,false),[(_vm.$vuetify.breakpoint[_vm.mobileBreakpoint])?_c('div',{style:({ width: '100vw', height: '20vh' })},[_c('v-img',{attrs:{"src":_vm.article.img,"lazy-src":_vm.article.img_lazy}})],1):_vm._e(),_c('v-container',{staticClass:"py-0"},[(!_vm.$vuetify.breakpoint[_vm.mobileBreakpoint])?_c('div',{style:({
        width: '50vw',
        height: 'calc(70vh - ' + _vm.toolbarHeight + 'px)',
      }),attrs:{"id":"floating-image"}},[_c('v-img',{attrs:{"height":"100%","src":_vm.article.img,"lazy-src":_vm.article.img_lazy}})],1):_vm._e(),_c('v-row',{style:({
        height: !_vm.$vuetify.breakpoint[_vm.mobileBreakpoint]
          ? 'calc(70vh - ' + _vm.toolbarHeight + 'px)'
          : null,
      }),attrs:{"align":!_vm.$vuetify.breakpoint[_vm.mobileBreakpoint] ? 'center' : 'start'}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-card',{attrs:{"flat":"","tile":"","color":"transparent"}},[_c('v-card-actions',_vm._l((_vm.tags),function(tag){return _c('v-chip',{key:tag.id,attrs:{"filter":""}},[_vm._v(" "+_vm._s(tag.text)+" ")])}),1),_c('v-card-actions',{staticClass:"headline font-weight-bold"},[_vm._v(" "+_vm._s(_vm.article.title)+" ")]),_c('v-card-actions',{staticClass:"headline font-weight-bold"},[_c('v-chip',{attrs:{"filter":"","x-large":!_vm.$vuetify.breakpoint[_vm.mobileBreakpoint]}},[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-action',{staticClass:"py-0"},[_c('v-avatar',[_c('v-img',{attrs:{"src":"https://cdn.vuetifyjs.com/images/john.jpg"}})],1)],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.author.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.article.created_at)+" ")])],1)],1)],1)],1)],1)],1)],1),_c('br'),_c('br'),_c('v-row',[_c('v-col',{staticClass:"article-p",attrs:{"md":"7"}},[(_vm.article.content)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.article.content)}}):_vm._t("content")],2),(!_vm.$vuetify.breakpoint[_vm.mobileBreakpoint])?_c('v-col',{attrs:{"cols":"12","md":"5","justify":"center","order":_vm.$vuetify.breakpoint[_vm.mobileBreakpoint] ? 'first' : 'last'}},[_c('op-diagnostic-card',{attrs:{"to":_vm.routeCta,"submitBtnClass":"white--text","title":"Évaluez le niveau de cyber-sécurité de votre entreprise en 1 minute","description":"Obtenez des recommandations gratuites immédiatement, sans inscription.","submitBtnText":"Commencer mon diagnoctic","background-image":"rocket"}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
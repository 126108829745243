var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['layout-col', 'small-product-card', { 'bigger-card': _vm.biggerCard }],on:{"click":function($event){$event.stopPropagation();return _vm.showTheDialog()}}},[_c('div',{staticClass:"product-header"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.product.attributes.logo}})]),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.product.attributes.name)+" ")]),(_vm.displayPrice)?_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.priceText)+" ")]):_vm._e()]),(_vm.openInModal)?_c('v-dialog',{attrs:{"max-width":"1180","scrollable":"","fullscreen":_vm.modalIsFullScreen,"hide-overlay":_vm.modalIsFullScreen},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({class:[
            'text-none',
            'font-weight-bold',
            'body-1',
            'ml-auto',
            { 'icon-btn': _vm.accessLabel == '' },
            { 'd-none': _vm.showButtonAtBottom },
          ],attrs:{"color":"primary","dark":"","small":true,"min-width":_vm.buttonMinWidth,"min-height":40,"elevation":"8"}},on),[_vm._v(" "+_vm._s(_vm.accessLabel)+" "),_c('v-icon',{attrs:{"right":"","size":"26"}},[_vm._v("mdi-chevron-right")])],1)]}}],null,false,2654630583),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('div',{staticClass:"d-flex flex-column white"},[_c('v-btn',{staticClass:"align-self-end mt-2 mr-2",attrs:{"text":"","icon":"","color":"steel","x-small":""},on:{"click":function($event){_vm.showDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('view-store-product',{attrs:{"productId":_vm.product.id,"isInModal":true}})],1)]):_c('v-btn',{class:[
        'text-none',
        'font-weight-bold',
        'body-1',
        'ml-auto',
        { 'icon-btn': _vm.accessLabel == '' },
        { 'd-none': _vm.showButtonAtBottom },
      ],attrs:{"color":"primary","dark":"","small":true,"min-width":_vm.buttonMinWidth,"min-height":40,"elevation":"8"},on:{"click":_vm.selectProduct}},[_vm._v(" "+_vm._s(_vm.accessLabel)+" "),_c('v-icon',{attrs:{"right":"","size":"26"}},[_vm._v("mdi-close")])],1)],1),_c('v-btn',{class:[
      'text-none',
      'font-weight-bold',
      'body-1',
      'mt-6',
      { 'icon-btn': _vm.accessLabel == '' },
      { 'd-none': !_vm.showButtonAtBottom },
    ],attrs:{"color":"primary","dark":"","small":true,"block":"","min-width":_vm.buttonMinWidth,"min-height":40,"to":{ name: 'store_product', params: { productId: _vm.product.id } },"elevation":"8"}},[_vm._v(" "+_vm._s(_vm.accessLabel)+" "),_c('v-icon',{attrs:{"right":"","size":"26"}},[_vm._v("mdi-close")])],1),(_vm.mainImage && _vm.displayImage)?_c('div',{staticClass:"preview"},[_c('img',{attrs:{"src":_vm.mainImage}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
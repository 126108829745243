<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <h4 v-if="doubleAuthCodes.length > 0" class="mb-1">
        {{ $t("profile.doubleAuth.recoveryCodes") }}
      </h4>
      <ul @click="copyCodes" v-bind="attrs" v-on="on">
        <li class="code-list" v-for="code in doubleAuthCodes" :key="code">
          {{ code }}
        </li>
      </ul></template
    >{{
      codesCopied
        ? $t("profile.doubleAuth.copiedTooltip")
        : $t("profile.doubleAuth.copyTooltip")
    }}
  </v-tooltip>
</template>
<script>
export default {
  name: "op-recovery-codes",
  props: {
    doubleAuthCodes: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      codesCopied: false,
    }
  },
  methods: {
    copyCodes() {
      navigator.clipboard.writeText(this.doubleAuthCodes.join("\n"))
      this.codesCopied = true
    },
  },
}
</script>
<style lang="scss" scoped>
.code-list {
  list-style: none;
  cursor: pointer;
}
</style>

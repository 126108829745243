<template>
  <op-text-field
    v-model="model"
    type="text"
    :name="name"
    :label="label"
    rounded
    outlined
    hide-details="auto"
    color="primary"
  >
    <template v-slot:append>
      <v-menu
        v-model="menu"
        top
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" class="swatch" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="model" flat mode="hexa" hide-mode-switch />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none rounded-lg"
              outlined
              elevation="0"
              @click="() => (menu = false)"
              >{{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
  </op-text-field>
</template>

<script>
export default {
  name: "op-color-input",

  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    swatchStyle() {
      const { value, menu } = this
      return {
        backgroundColor: value,
        borderRadius: menu ? "50%" : "4px",
      }
    },
  },
}
</script>
<style scoped>
.swatch {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: border-radius 200ms ease-in-out;
}
</style>

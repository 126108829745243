<template>
  <div :class="['layout-col', 'product-card', { 'bigger-card': biggerCard }]">
    <div class="product-header">
      <div class="icon">
        <img :src="product.attributes.logo" />
      </div>

      <div class="infos">
        <div class="text-h3 font-weight-bold marine-blue--text">
          {{ product.attributes.name }}
        </div>

        <div
          class="text-uppercase text-h4 font-weight-medium steel--text"
          v-if="displayEditor"
        >
          {{ product.attributes.developer }}
        </div>

        <div class="text-h4 font-weight-bold teal--text" v-if="displayPrice">
          {{ priceText }}
        </div>

        <div class="text-h4 font-weight-bold teal--text" v-if="displayBuyInfo">
          {{ product.attributes.pricing.description }}
        </div>
      </div>

      <v-dialog
        v-if="openInModal"
        v-model="showDialog"
        max-width="1180"
        scrollable
        :fullscreen="modalIsFullScreen"
        :hide-overlay="modalIsFullScreen"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            dark
            :small="!biggerAddButton"
            :large="biggerAddButton"
            :min-width="buttonMinWidth"
            :min-height="40"
            :class="[
              'text-none',
              'font-weight-bold',
              'text-body-1',
              'ml-auto',
              { 'icon-btn': accessLabel == '' },
              { 'd-none': showButtonAtBottom },
            ]"
            v-on="on"
            elevation="8"
          >
            {{ accessLabel }}
            <v-icon right size="26">mdi-plus</v-icon>
          </v-btn>
        </template>
        <div class="d-flex flex-column white">
          <v-btn
            text
            icon
            color="steel"
            x-small
            class="align-self-end mt-2 mr-2"
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <view-store-product
            :productId="product.id"
            :isInModal="true"
          ></view-store-product>
        </div>
      </v-dialog>
      <v-btn
        v-else
        color="primary"
        dark
        :small="!biggerAddButton"
        :large="biggerAddButton"
        :min-width="buttonMinWidth"
        :min-height="40"
        :class="[
          'text-none',
          'font-weight-bold',
          'text-body-1',
          'ml-auto',
          { 'icon-btn': accessLabel == '' },
          { 'd-none': showButtonAtBottom },
        ]"
        @click="selectProduct"
        elevation="8"
      >
        {{ accessLabel }}
        <v-icon right size="26">mdi-plus</v-icon>
      </v-btn>
    </div>

    <div
      class="description steel--text text-body-1"
      v-html="product.attributes.description"
    ></div>

    <op-product-tags
      v-if="displayTags && product.attributes.tags"
      class="mt-3"
      :tags="product.attributes.tags"
      small
    ></op-product-tags>

    <op-slider-layout
      class="product-slider"
      v-if="displayImages"
      :items="filteredMedias"
      :itemPaddingLeft="26"
      :itemPaddingRight="26"
      :itemPaddingTop="26"
      :numOfItems="1"
      :gutter="10"
      :previewWidth="40"
    >
      <op-product-media-slide slot-scope="{ item }" :item="item">
      </op-product-media-slide>
    </op-slider-layout>
    <v-btn
      color="primary"
      dark
      :small="!biggerAddButton"
      :large="biggerAddButton"
      block
      :min-width="buttonMinWidth"
      :min-height="40"
      :class="[
        'text-none',
        'font-weight-bold',
        'body-1',
        'mt-6',
        { 'icon-btn': accessLabel == '' },
        { 'd-none': !showButtonAtBottom },
      ]"
      :to="{ name: 'store_product', params: { productId: product.id } }"
      elevation="8"
    >
      {{ accessLabel }}
      <v-icon right size="26">mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import ViewStoreProduct from "../../views/member/store/ViewStoreProduct.vue"
import mixin from "@/mixins/Products.mixins.js"

export default {
  name: "op-product-card",
  components: {
    ViewStoreProduct,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    displayPrice: {
      type: Boolean,
      default() {
        return false
      },
    },
    displayBuyInfo: {
      type: Boolean,
      default() {
        return false
      },
    },
    displayEditor: {
      type: Boolean,
      default() {
        return false
      },
    },
    displayTags: {
      type: Boolean,
      default() {
        return false
      },
    },
    displayImages: {
      type: Boolean,
      default() {
        return false
      },
    },
    biggerAddButton: {
      type: Boolean,
      default() {
        return false
      },
    },
    biggerCard: {
      type: Boolean,
      default() {
        return false
      },
    },
    openInModal: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  mixins: [mixin],
  data() {
    return {
      showDialog: false,
    }
  },
  computed: {
    accessLabel() {
      if (this.biggerAddButton) {
        return this.$i18n.t("store.product.obtain")
      } else {
        return ""
      }
    },
    priceText() {
      if (this.product.attributes.buy_button_label) {
        return this.product.attributes.buy_button_label.split("-")[0].trim()
      } else {
        return (
          this.$options.filters.monetize(
            this.product.attributes.pricing.amount
          ) + " HT"
        )
      }
    },
    buttonMinWidth() {
      return this.biggerAddButton ? 50 : 40
    },
    showButtonAtBottom() {
      return (
        (this.$vuetify.breakpoint.name == "xs" ||
          this.$vuetify.breakpoint.name == "sm") &&
        this.accessLabel != ""
      )
    },
    filteredMedias() {
      if (this.product.attributes.media) {
        return this.product.attributes.media.filter(
          (media) => media.type == "screenshot"
        )
      } else {
        return []
      }
    },
    modalIsFullScreen() {
      return (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      )
    },
  },
  methods: {
    selectProduct() {
      this.$gtmLogic.clickProductRecommendation(this.product, null)
      this.$router.push({
        name: "store_product",
        params: { productId: this.getProductId(this.product) },
      })
    },
  },
}
</script>

<style lang="scss">
.product-card {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 4, 10, 0.09);
  margin: 0 !important;
  padding: 25px 18px 18px;
  box-sizing: border-box;

  &.bigger-card {
    .product-header .infos .title {
      font-size: 1.429em;
    }
  }

  .product-slider {
    margin-top: 22px;
    height: auto;

    .slider-element {
      background-color: $ice-blue;
    }

    .product-slider-description {
      font-size: 0.857em;
      line-height: 1.67;
      text-align: center;
      color: $steel;
      margin-bottom: 20px;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .product-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .icon {
      width: 70px;
      margin-right: 14px;

      img {
        width: inherit;
        height: auto;
      }
    }

    .infos {
      display: flex;
      flex-basis: auto;
      flex-direction: column;
      margin-right: 20px;
    }

    .icon-btn i {
      margin: 0;
    }

    .more {
      display: flex;
      cursor: pointer;
      background-color: $topaz;
      height: 40px;
      margin-top: 5px;
      margin-left: auto;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      transition: all 0.4s ease;
      color: white;
      position: relative;

      &:hover {
        background-color: $marine-blue;

        &:after {
          transform: translate(0px, 3px) rotate(-45deg);
          width: 10px !important;
        }
        &:before {
          transform: translate(0px, -3px) rotate(52deg);
          width: 10px !important;
        }
      }

      &:not(.bigger) {
        flex: 0 0 40px;
      }

      &.bigger {
        padding: 11px 40px 10px 12px;

        &:before,
        &:after {
          width: 16px;
          height: 2px;
          position: absolute;
          right: 14px;
        }
      }

      &:before,
      &:after {
        content: "";
        width: 14px;
        height: 2px;
        display: flex;
        background: white;
        position: absolute;
        border-radius: 2px;
        transition: all 0.3s ease-in;
      }

      &:before {
        transform: rotate(90deg);
      }
    }

    .description {
      margin-top: 20px;
      flex-basis: 100%;
    }
  }
}
</style>

<template>
  <v-card
    v-bind="$attrs"
    :elevation="$attrs.elevation ? $attrs.elevation : '1'"
    :background-image="backgroundImage"
    :background-align="backgroundAlign"
    class="pa-6"
  >
    <slot name="default">
      <v-row class="pa-0 ma-0 fill-height">
        <v-col cols="12" class="pa-0 align-self-start">
          <slot name="title">
            <div class="card-title" :style="{ color: titleColor }">
              {{ title | titlecase }}
            </div>
          </slot>
        </v-col>
        <v-col cols="7" class="pa-0 align-self-start">
          <slot name="score">
            <div class="scoreBall" v-if="showScore">
              <div class="score">
                {{ score }}
              </div>
            </div>
          </slot>

          <slot name="description">
            <v-card-actions :class="descriptionClass" class="max3lines">
              {{ description }}
            </v-card-actions>
          </slot>
        </v-col>
        <v-col cols="12" class="pa-0 align-self-end">
          <slot name="buttons">
            <v-card-actions>
              <v-btn
                v-bind="submitButtonAttrs"
                :dark="submitBtnDark"
                :color="submitBtnColor"
                :class="submitBtnClass"
                class="text-none"
                @click="submit()"
              >
                {{ submitBtnText }}
              </v-btn>
              <v-btn
                dark
                text
                v-if="showCancel"
                color="primary"
                class="text-none text-underline"
                @click="notConcerned()"
              >
                Je ne suis pas concerné
              </v-btn>
            </v-card-actions>
          </slot>
        </v-col>
      </v-row>
    </slot>
  </v-card>
</template>

<style lang="scss" scoped>
.max3lines {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 5.4em;
  height: 5.4em;
  line-height: 1.8em;
  width: 130%;
}

.scoreBall {
  background-color: white;
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -22px;
  right: -12px;

  .score {
    font-weight: bold;
    font-size: 32px;
    color: #009ba4;
    line-height: 39px;
  }
}

.card-title {
  font-size: 1.25rem !important;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-weight: 700;
  padding: 8px;
  padding-top: 4px;
  color: $marine-blue;
}

.v-card {
  &.flexcard {
    display: flex;
    flex-direction: column;
  }
  &[background-image="collaborators-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/collaborators_blue.svg");
  }
  &[background-image="collaborators-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/collaborators_white.svg");
  }

  &[background-image="data-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/data_blue.svg");
  }
  &[background-image="data-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/data_white.svg");
  }

  &[background-image="hand-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/hand_blue.svg");
  }
  &[background-image="hand-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/hand_white.svg");
  }

  &[background-image="home-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/home_blue.svg");
  }
  &[background-image="home-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/home_white.svg");
  }

  &[background-image="mobile-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/mobile_blue.svg");
  }
  &[background-image="mobile-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/mobile_white.svg");
  }

  &[background-image="office-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/office_blue.svg");
  }

  &[background-image="payment-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/payment_blue.svg");
  }
  &[background-image="payment-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/payment_white.svg");
  }

  &[background-image="screen-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/screen_blue.svg");
  }
  &[background-image="screen-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/screen_white.svg");
  }

  &[background-image="security-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/security_blue.svg");
  }
  &[background-image="security-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/security_white.svg");
  }

  &[background-image="update-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/update_blue.svg");
  }
  &[background-image="update-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/update_white.svg");
  }

  &[background-image="user-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/user_blue.svg");
  }
  &[background-image="user-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/user_white.svg");
  }

  &[background-image="world-blue"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/world_blue.svg");
  }
  &[background-image="world-white"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/world_white.svg");
  }

  &[background-image="conversation"] {
    background-image: url("../../assets/svg/grey-shape-1.svg"),
      url("../../assets/card/conversation.svg");
    background-position: right -7px bottom, right 0px center !important;
  }

  &[background-align="left"] {
    background-position: right -7px bottom, left 0 center;
  }

  &[background-align="right"] {
    background-position: right -7px bottom, right 20px center;
  }

  &[background-align="center"] {
    background-position: right -7px bottom, center;
  }
}
</style>

<script>
import Helpers from "../../services/Helpers"

export default {
  name: "op-diagnostic-card",

  data() {
    return {
      scoreStyle: {
        color: this.scoreColor,
      },
    }
  },
  props: {
    backgroundImage: {
      type: String,
    },
    showCancel: Boolean,
    submitBtnDark: Boolean,
    showScore: Boolean,
    title: String,
    slug: String,
    description: String,
    score: Number,
    scoreColor: {
      type: String,
      default: "purple",
    },
    backgroundAlign: {
      type: String,
      default: "right",
    },
    routeCta: {
      type: Object,
      validator: (object) => Helpers.propertiesRequired(object, ["name"]),
    },
    titleClass: {
      type: String,
      default: "primary--text",
    },
    titleColor: {
      type: String,
      default: "#00315a",
    },
    descriptionClass: {
      type: String,
      default: "",
    },
    submitBtnColor: {
      type: String,
      default: "primary",
    },
    submitBtnText: String,
    submitBtnClass: String,
    submitButtonAttrs: Object,
  },

  methods: {
    submit() {
      this.$emit("submit")
      this.$router.push(this.routeCta)
    },
    notConcerned() {
      this.$emit("notConcerned")
    },
  },
}
</script>

<template>
  <v-alert
    v-bind="$attrs"
    v-on="$listeners"
    v-model="alert.display"
    class="mt-0 text-center"
    transition="scale-transition"
    absolute
    :type="alert.type"
  >
    {{ alert.msg }}
  </v-alert>
</template>

<script>
export default {
  name: "op-alert",

  computed: {},

  data: vm => ({
    alert: {
      display: false,
      type: "error",
      msg: vm.$i18n.t("error.default"),
    },
  }),

  methods: {
    displayAlert(
      message = this.$i18n.t("error.default"),
      type = "error",
      timeout = 5000
    ) {
      this.alert.display = true
      this.alert.type = type
      this.alert.msg = message

      setTimeout(() => {
        this.closeAlert()
      }, timeout)
    },

    closeAlert() {
      this.alert.display = false
    },
  },
}
</script>

<template>
  <v-card
    v-bind="$attrs"
    v-on="$listeners"
    :style="{ height: 'calc(100% - ' + toolbarHeight + 'px)' }"
    :loading="loading ? 'primary' : false"
    color="transparent"
  >
    <v-row justify="center" align="stretch" class="fill-height pa-0">
      <v-col
        cols="12"
        md="7"
        lg="6"
        :align-self="$vuetify.breakpoint.mdAndUp ? 'center' : 'start'"
        class="pa-0 pb-10"
      >
        <v-row justify="center">
          <v-col cols="10" offset-md="1">
            <v-window
              :value="questionIndex"
              @input="updateQuestionIndex($event)"
            >
              <v-window-item
                v-for="(question, key) in questions"
                :key="question.id"
              >
                <op-diagnostic-question-item
                  :answer="answers[question.id]"
                  @update:answer="setAnswer(question.id, $event)"
                  :surveyLength="questions.length"
                  :questionKey="key + 1"
                  @next="updateQuestionIndex(questionIndex + 1)"
                  @previous="updateQuestionIndex(questionIndex - 1)"
                  @submit="submit"
                  :value="question"
                >
                </op-diagnostic-question-item>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="5"
        lg="6"
        v-if="$vuetify.breakpoint.mdAndUp"
        class="pa-0"
      >
        <v-img
          v-if="questions && questions.length"
          height="100%"
          :src="require('../../assets/diagnostic-survey-1.jpg')"
          :lazy-src="require('../../assets/diagnostic-survey-1-light.jpg')"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "op-diagnostic-question-list",
  data() {
    return {
      started: false,
      formerTopic: null,
    }
  },
  props: {
    answers: Object,
    questions: Array,
    questionIndex: Number,
    toolbarHeight: {
      type: Number,
      default: 0,
    },
    loading: Boolean,
    topicId: Number,
  },
  methods: {
    updateQuestionIndex(index) {
      // call GTM if its the first question of a new theme
      if (1 == index && (!this.started || this.topicId != this.formerTopic)) {
        this.started = true
        this.formerTopic = this.topicId
        this.$gtmLogic.startDiagnostic(this.topicId)
      }
      this.$emit("update:questionIndex", index)
    },

    setAnswer(key, $event) {
      this.$emit(
        "update:answers",
        Object.assign({}, this.answers, { [key]: $event ? $event : null })
      )
    },

    submit() {
      this.$gtmLogic.completedDiagnostic(this.topicId)
      this.$emit("submit", this.answers)
    },
  },
}
</script>

const titleOptions = {
  font: {
    size: 18,
    family: "Montserrat, sans-serif",
    weight: "normal",
    lineHeight: "30px",
  },
}

export { titleOptions }

<template>
  <div id="divider" :style="diagonalBlocStyle" :class="diagonalBlocClass"></div>
</template>

<style lang="scss" scoped>
#divider {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
</style>

<script>
export default {
  name: "op-divider-basic",

  props: {
    bgColor: {
      type: String,
      default: "whitesmoke",
    },
    height: {
      type: String,
      default: "70px",
    },
    diagonal: {
      type: String,
      default: "primary--text text--darken-1",
    },
    contain: {
      type: Boolean,
    },
    reverse: {
      type: Boolean,
    },
  },

  computed: {
    diagonalBlocClass() {
      return `${this.bgColor} ${this.height}`
    },
    diagonalBlocStyle() {
      return {
        height: this.height,
        "margin-top": this.contain ? this.height : null,
      }
    },
  },
}
</script>

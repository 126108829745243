// mixin.js file
import { i18n } from "../plugins/i18n"
export default {
  methods: {
    instructionalVideoMedia(product) {
      let instructionalVideoMedia = {}
      if (product.attributes.media) {
        for (var i = product.attributes.media.length - 1; i >= 0; i--) {
          if (product.attributes.media[i].type == "instructional_video") {
            instructionalVideoMedia = product.attributes.media[i]
            break
          }
        }
      }
      instructionalVideoMedia.exist = true
      if (
        !instructionalVideoMedia.description ||
        instructionalVideoMedia.description == ""
      ) {
        instructionalVideoMedia.description = i18n.t(
          "store.product.instructionalVideoMediaDescription"
        )
      }
      if (
        !instructionalVideoMedia.title ||
        instructionalVideoMedia.title == ""
      ) {
        instructionalVideoMedia.exist = false
        instructionalVideoMedia.title = i18n.t(
          "store.product.instructionalVideoMediaTitle"
        )
      }
      if (
        !instructionalVideoMedia.url_thumbnail ||
        instructionalVideoMedia.url_thumbnail == ""
      ) {
        instructionalVideoMedia.url_thumbnail = undefined
      }
      if (
        !instructionalVideoMedia.url_large ||
        instructionalVideoMedia.url_large == ""
      ) {
        instructionalVideoMedia.url_large = ""
      }
      if (
        !instructionalVideoMedia.channel ||
        typeof instructionalVideoMedia.channel != "string"
      ) {
        instructionalVideoMedia.channel = ""
      }
      return instructionalVideoMedia
    },
    quantityIsFixed(product) {
      return (
        product.attributes.checkout_config &&
        product.attributes.checkout_config.quantity_min &&
        product.attributes.checkout_config.quantity_max &&
        product.attributes.checkout_config.quantity_max ==
          product.attributes.checkout_config.quantity_min
      )
    },
    productQuantity(product) {
      return this.quantityIsFixed(product)
        ? product.attributes.checkout_config.quantity_min
        : product.attributes.checkout_quantity
    },
    getProductId(product) {
      return product.attributes.slug ? product.attributes.slug : product.id
    },
  },
}

<template>
  <v-avatar
    :color="avatarColor"
    :height="diameter"
    :width="diameter"
    class="white--text"
  >
    <v-img v-if="user.avatar" :src="user.avatar.url_thumbnail"></v-img>
    <div v-else-if="!user.avatar" :class="textClass">
      {{ user.initials ? user.initials.toUpperCase() : "" }}
    </div>
  </v-avatar>
</template>
<script>
export default {
  name: "op-user-avatar",
  props: {
    size: { type: String, default: "small" },
    user: { type: Object },
  },
  computed: {
    avatarColor() {
      return this.user.avatar ? "none" : "primary"
    },
    diameter() {
      return this.size === "large" ? "72px" : undefined
    },
    textClass() {
      return this.size === "large" ? "text-h1 font-weight-regular" : "body-2"
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['layout-col', 'product-card', { 'bigger-card': _vm.biggerCard }]},[_c('div',{staticClass:"product-header"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.product.attributes.logo}})]),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"text-h3 font-weight-bold marine-blue--text"},[_vm._v(" "+_vm._s(_vm.product.attributes.name)+" ")]),(_vm.displayEditor)?_c('div',{staticClass:"text-uppercase text-h4 font-weight-medium steel--text"},[_vm._v(" "+_vm._s(_vm.product.attributes.developer)+" ")]):_vm._e(),(_vm.displayPrice)?_c('div',{staticClass:"text-h4 font-weight-bold teal--text"},[_vm._v(" "+_vm._s(_vm.priceText)+" ")]):_vm._e(),(_vm.displayBuyInfo)?_c('div',{staticClass:"text-h4 font-weight-bold teal--text"},[_vm._v(" "+_vm._s(_vm.product.attributes.pricing.description)+" ")]):_vm._e()]),(_vm.openInModal)?_c('v-dialog',{attrs:{"max-width":"1180","scrollable":"","fullscreen":_vm.modalIsFullScreen,"hide-overlay":_vm.modalIsFullScreen},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({class:[
            'text-none',
            'font-weight-bold',
            'text-body-1',
            'ml-auto',
            { 'icon-btn': _vm.accessLabel == '' },
            { 'd-none': _vm.showButtonAtBottom },
          ],attrs:{"color":"primary","dark":"","small":!_vm.biggerAddButton,"large":_vm.biggerAddButton,"min-width":_vm.buttonMinWidth,"min-height":40,"elevation":"8"}},on),[_vm._v(" "+_vm._s(_vm.accessLabel)+" "),_c('v-icon',{attrs:{"right":"","size":"26"}},[_vm._v("mdi-plus")])],1)]}}],null,false,429612903),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('div',{staticClass:"d-flex flex-column white"},[_c('v-btn',{staticClass:"align-self-end mt-2 mr-2",attrs:{"text":"","icon":"","color":"steel","x-small":""},on:{"click":function($event){_vm.showDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('view-store-product',{attrs:{"productId":_vm.product.id,"isInModal":true}})],1)]):_c('v-btn',{class:[
        'text-none',
        'font-weight-bold',
        'text-body-1',
        'ml-auto',
        { 'icon-btn': _vm.accessLabel == '' },
        { 'd-none': _vm.showButtonAtBottom },
      ],attrs:{"color":"primary","dark":"","small":!_vm.biggerAddButton,"large":_vm.biggerAddButton,"min-width":_vm.buttonMinWidth,"min-height":40,"elevation":"8"},on:{"click":_vm.selectProduct}},[_vm._v(" "+_vm._s(_vm.accessLabel)+" "),_c('v-icon',{attrs:{"right":"","size":"26"}},[_vm._v("mdi-plus")])],1)],1),_c('div',{staticClass:"description steel--text text-body-1",domProps:{"innerHTML":_vm._s(_vm.product.attributes.description)}}),(_vm.displayTags && _vm.product.attributes.tags)?_c('op-product-tags',{staticClass:"mt-3",attrs:{"tags":_vm.product.attributes.tags,"small":""}}):_vm._e(),(_vm.displayImages)?_c('op-slider-layout',{staticClass:"product-slider",attrs:{"items":_vm.filteredMedias,"itemPaddingLeft":26,"itemPaddingRight":26,"itemPaddingTop":26,"numOfItems":1,"gutter":10,"previewWidth":40},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return _c('op-product-media-slide',{attrs:{"item":item}})}}],null,false,3620325308)}):_vm._e(),_c('v-btn',{class:[
      'text-none',
      'font-weight-bold',
      'body-1',
      'mt-6',
      { 'icon-btn': _vm.accessLabel == '' },
      { 'd-none': !_vm.showButtonAtBottom },
    ],attrs:{"color":"primary","dark":"","small":!_vm.biggerAddButton,"large":_vm.biggerAddButton,"block":"","min-width":_vm.buttonMinWidth,"min-height":40,"to":{ name: 'store_product', params: { productId: _vm.product.id } },"elevation":"8"}},[_vm._v(" "+_vm._s(_vm.accessLabel)+" "),_c('v-icon',{attrs:{"right":"","size":"26"}},[_vm._v("mdi-plus")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class CampaignRepository {
  /**
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static getAll(companyUuid, params = {}) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/campaigns`,
      { params }
    )
  }
  /**
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static getEmployees(companyUuid, params = {}) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/campaigns/employees`,
      { params }
    )
  }
  /**
   * @param companyUuid
   * @returns {Promise}
   */
  static getStats(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/campaigns/stats`
    )
  }
}

<template>
  <v-col cols="12" sm="2">
    <v-row no-gutters justify="center">
      <v-col cols="12" class="d-flex justify-center">
        <img
          :src="currentBadge.image100Path"
          :srcset="`${currentBadge.image100Path}, ${currentBadge.image300Path} 3x`"
          class="theme-icon"
          :class="{ 'no-score': score === 0 }"
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between stars">
        <img
          :srcset="starSet"
          :src="star1x"
          alt="star"
          class="star"
          :class="{ grayscale: disabled || score === 0 }"
        />
        <img
          :srcset="starSet"
          :src="star1x"
          alt="star"
          class="star align-self-end"
          :class="{ grayscale: disabled || score < 2 }"
        />
        <img
          :srcset="starSet"
          :src="star1x"
          alt="star"
          class="star"
          :class="{ grayscale: disabled || score !== 3 }"
        />
      </v-col>
      <v-col
        cols="12"
        class="text-caption font-weight-light text-center label"
        >{{ currentBadge.label }}</v-col
      >
    </v-row>
  </v-col>
</template>
<style scoped lang="scss">
.theme-icon {
  width: 58px;
  height: 60px;

  &.no-score {
    filter: grayscale(1);
  }
}
.score {
  width: 2rem;
  text-align: end;
}
.label {
  line-height: 14px;
}
.stars {
  width: 75px;
  max-width: 75px;
  height: 36px;
  margin-top: -21px;
  & .star {
    width: 25px;
    height: 25px;

    &.grayscale {
      filter: grayscale(1);
    }
  }
}
</style>
<script>
import Star025x from "@/assets/learning/star-set/star-0.25x.png"
import Star05x from "@/assets/learning/star-set/star-0.5x.png"
import Star1x from "@/assets/learning/star-set/star-1x.png"
import Star2x from "@/assets/learning/star-set/star-2x.png"
import Star3x from "@/assets/learning/star-set/star-3x.png"
import Star4x from "@/assets/learning/star-set/star-4x.png"
import Star5x from "@/assets/learning/star-set/star-5x.png"
import PhishingIcon300 from "@/assets/learning/badges/badge-phishing-300px.png"
import PhishingIcon100 from "@/assets/learning/badges/badge-phishing-100px.png"
import DataProtectionIcon300 from "@/assets/learning/badges/badge-data-protection-300px.png"
import DataProtectionIcon100 from "@/assets/learning/badges/badge-data-protection-100px.png"
import WorkstationIcon300 from "@/assets/learning/badges/badge-workstation-300px.png"
import WorkstationIcon100 from "@/assets/learning/badges/badge-workstation-100px.png"
import OutOfOfficeIcon300 from "@/assets/learning/badges/badge-out-of-office-300px.png"
import OutOfOfficeIcon100 from "@/assets/learning/badges/badge-out-of-office-100px.png"
import CommunicationToolsIcon300 from "@/assets/learning/badges/badge-communication-tools-300px.png"
import CommunicationToolsIcon100 from "@/assets/learning/badges/badge-communication-tools-100px.png"
import SocialEngineeringIcon300 from "@/assets/learning/badges/badge-social-engineering-300px.png"
import SocialEngineeringIcon100 from "@/assets/learning/badges/badge-social-engineering-100px.png"
export default {
  name: "op-badge",
  props: {
    type: {
      type: String,
    },
    score: {
      type: Number,
      default() {
        return 0
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  computed: {
    star1x() {
      return Star1x
    },
    starSet() {
      return `${Star1x}, ${Star2x} 2x, ${Star3x} 3x, ${Star4x} 4x, ${Star5x} 5x, ${Star05x} 0.5x, ${Star025x} 0.25x`
    },
    currentBadge() {
      return this.badges[this.type]
    },
    badges() {
      return {
        phishing: {
          image100Path: PhishingIcon100,
          image300Path: PhishingIcon300,
          label: this.$t("badges.phishing"),
        },
        "data-protection": {
          image100Path: DataProtectionIcon100,
          image300Path: DataProtectionIcon300,
          label: this.$t("badges.dataProtection"),
        },
        workstation: {
          image100Path: WorkstationIcon100,
          image300Path: WorkstationIcon300,
          label: this.$t("badges.workstation"),
        },
        "out-of-office": {
          image100Path: OutOfOfficeIcon100,
          image300Path: OutOfOfficeIcon300,
          label: this.$t("badges.outOfOffice"),
        },
        "communication-tools": {
          image100Path: CommunicationToolsIcon100,
          image300Path: CommunicationToolsIcon300,
          label: this.$t("badges.communicationTools"),
        },
        "social-engineering": {
          image100Path: SocialEngineeringIcon100,
          image300Path: SocialEngineeringIcon300,
          label: this.$t("badges.socialEngineering"),
        },
      }
    },
  },
}
</script>

<template>
  <div class="ice-blue py-4 px-5">
    <div
      class="password-strength-section d-flex justify-space-between align-center mb-3"
    >
      <div
        class="password-strength-value body-1 font-weight-bold oxford-blue--text mr-12"
      >
        {{ $t("register.passwordStrength." + meterValue) }}
      </div>

      <meter
        max="4"
        low="1"
        high="3"
        optimum="4"
        id="password-strength-meter"
        :value="meterValue"
      ></meter>
    </div>

    <ul class="body-2 oxford-blue--text dashed">
      <li>{{ $t("register.password_recommendation.reuse") }}<br /><br /></li>

      <li>
        {{ $t("register.password_recommendation.character") }}<br /><br />
      </li>

      <li>{{ $t("register.password_recommendation.give") }}<br /><br /></li>

      <li>{{ $t("register.password_recommendation.phishing") }}</li>
    </ul>
  </div>
</template>

<script>
import zxcvbn from "zxcvbn"

export default {
  name: "op-password-tips",
  props: {
    password: {
      type: String,
      required: true,
      default() {
        return ""
      },
    },
  },
  data() {
    return {
      checkoutList: [
        { title: "1. Paramétrage", progression: 100, key: 0 },
        { title: "2. Compte", progression: 0, key: 1 },
        { title: "3. Paiement", progression: 0, key: 2 },
      ],
      currentStep: 0,
      quantities: [],
      errorMessage: "",
      newUser: null,
    }
  },
  computed: {
    meterValue() {
      // return 0
      return zxcvbn(this.password).score
    },
  },
}
</script>

<style lang="scss">
ul.dashed {
  list-style: none;
  padding-left: 0;
}

ul.dashed > li {
  margin-left: 15px;
}

/* Prevent nested li's from getting messed up */
ul.dashed > li::before {
  content: "- ";
  margin-left: -15px;
}
.password-strength-section {
  .password-strength-value {
    white-space: nowrap;
  }

  #password-strength-meter {
    max-width: 210px;
    width: 100%;
    height: 10px;

    &::-webkit-meter-bar {
      background: white;
      border-radius: 10px;
    }

    &::-webkit-meter-suboptimum-value {
      background: linear-gradient(265deg, #e57700 3%, #e57901 3%, #dca813);
      box-shadow: 0 6px 10px 0 rgba(0, 4, 10, 0.12);
      border-radius: 10px;
      transition: all 0.5s;
    }

    &::-webkit-meter-optimum-value {
      background: linear-gradient(265deg, #1ac8aa 3%, #1ac8aa 3%, #1ac8aa);
      box-shadow: 0 6px 10px 0 rgba(0, 4, 10, 0.12);
      border-radius: 10px;
      transition: all 0.5s;
    }
  }

  #password-strength-meter[value="1"]::-moz-meter-bar,
  #password-strength-meter[value="2"]::-moz-meter-bar {
    background: linear-gradient(265deg, #e57700 3%, #e57901 3%, #dca813);
    box-shadow: 0 6px 10px 0 rgba(0, 4, 10, 0.12);
    border-radius: 10px;
  }
  #password-strength-meter[value="3"]::-moz-meter-bar,
  #password-strength-meter[value="4"]::-moz-meter-bar {
    background: linear-gradient(265deg, #1ac8aa 3%, #1ac8aa 3%, #1ac8aa);
    box-shadow: 0 6px 10px 0 rgba(0, 4, 10, 0.12);
    border-radius: 10px;
  }
}
</style>

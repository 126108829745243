import Vue from "vue"

import {
  OpAlert,
  OpBrowserAlert,
  OpArticle,
  OpBuyButton,
  OpCategoryLevelCard,
  OpDiagnosticBtn,
  OpDiagnosticCard,
  OpDiagnosticReco,
  OpDiagnosticResult,
  OpDiagnosticQuestionList,
  OpDiagnosticQuestionItem,
  OpDiagnosticSvgScore,
  OpDiagnosticTabs,
  OpDiagnosticTimeline,
  OpDiagnosticTimelineCard,
  OpDiagnosticTimelineSmall,
  OpDiagnosticToolbar,
  OpDivider,
  OpDividerBasic,
  OpForm,
  OpLargeProductCard,
  OpLoginCard,
  OpPageDivider,
  OpProductCard,
  OpProductTags,
  OpRegisterCard,
  OpSelect,
  OpStoreTabs,
  OpTextField,
  OpToolbar,
  OpUserTable,
  OpImproveWithProductCard,
  OpLevelItem,
  OpOperatingSystemList,
  OpImprovementLevels,
  OpReasonCards,
  OpTipCard,
  OpVideoThumbnail,
  OpRequirementsCard,
  OpSliderLayout,
  OpProductMediaSlide,
  OpNavigationArrow,
  OpPackOverviewCard,
  OpPackProductCard,
  OpLevelGroup,
  OpStoreBreadcrumbs,
  OpPasswordTips,
  OpStoreBreadcrumbArrow,
  OpStoreCheckoutItem,
  OpFooter,
  OpCountrySelect,
  OpSmallProductCard,
  OpAsyncImg,
  OpStoreMobileMenu,
  OpNotificationMenu,
  OpPhishingSummary,
  OpEmployeeList,
  OpPhishingSimulationList,
  OpDashboardCard,
  OpToggleButtons,
  OpCourseList,
  OpCourseEmployees,
  OpCourseSummary,
  OpRecoveryCodes,
  OpEmployeeTableFilters,
  OpPhishingEventSummaryBar,
  OpPhishingEventSummaryLine,
  OpTopChart,
  OpPhishingEvolutionLine,
  OpCourseSuccessRate,
  OpCourseSessionEvolutionLine,
  OpPhishingEmployees,
  OpCourseSuccessKeyNumbers,
  OpCourseSessionTime,
  OpComparisonDonut,
  OpCourseSessionByDateLine,
  OpCourseSessionByDateBar,
  OpCampaignLinkedActivityCard,
  OpPhishingTestResults,
  OpFilesTable,
  OpUserAvatar,
  OpPhishingExercicePreviewDialog,
  OpColorInput,
  OpConfirmationDialog,
  OpCyberscoreSummary,
  OpCyberscoreByTheme,
  OpCyberscoreGauge,
  OpCSVExportButton,
  OpEmployeeCyberscoreDialog,
  OpBadge,
} from "./components"

Vue.component(OpLoginCard.name, OpLoginCard)
Vue.component(OpAlert.name, OpAlert)
Vue.component(OpBrowserAlert.name, OpBrowserAlert)
Vue.component(OpBuyButton.name, OpBuyButton)
Vue.component(OpArticle.name, OpArticle)
Vue.component(OpCategoryLevelCard.name, OpCategoryLevelCard)
Vue.component(OpDiagnosticBtn.name, OpDiagnosticBtn)
Vue.component(OpDiagnosticCard.name, OpDiagnosticCard)
Vue.component(OpDiagnosticReco.name, OpDiagnosticReco)
Vue.component(OpDiagnosticResult.name, OpDiagnosticResult)
Vue.component(OpDiagnosticQuestionList.name, OpDiagnosticQuestionList)
Vue.component(OpDiagnosticQuestionItem.name, OpDiagnosticQuestionItem)
Vue.component(OpDiagnosticSvgScore.name, OpDiagnosticSvgScore)
Vue.component(OpDiagnosticTabs.name, OpDiagnosticTabs)
Vue.component(OpDiagnosticTimeline.name, OpDiagnosticTimeline)
Vue.component(OpDiagnosticTimelineCard.name, OpDiagnosticTimelineCard)
Vue.component(OpDiagnosticTimelineSmall.name, OpDiagnosticTimelineSmall)
Vue.component(OpDiagnosticToolbar.name, OpDiagnosticToolbar)
Vue.component(OpDivider.name, OpDivider)
Vue.component(OpDividerBasic.name, OpDividerBasic)
Vue.component(OpForm.name, OpForm)
Vue.component(OpLargeProductCard.name, OpLargeProductCard)
Vue.component("op-page-divider", OpPageDivider)
Vue.component(OpProductCard.name, OpProductCard)
Vue.component(OpProductTags.name, OpProductTags)
Vue.component(OpRegisterCard.name, OpRegisterCard)
Vue.component(OpSelect.name, OpSelect)
Vue.component(OpStoreTabs.name, OpStoreTabs)
Vue.component(OpTextField.name, OpTextField)
Vue.component(OpToolbar.name, OpToolbar)
Vue.component(OpUserTable.name, OpUserTable)
Vue.component(OpImproveWithProductCard.name, OpImproveWithProductCard)
Vue.component(OpLevelItem.name, OpLevelItem)
Vue.component(OpOperatingSystemList.name, OpOperatingSystemList)
Vue.component(OpImprovementLevels.name, OpImprovementLevels)
Vue.component(OpReasonCards.name, OpReasonCards)
Vue.component(OpTipCard.name, OpTipCard)
Vue.component(OpVideoThumbnail.name, OpVideoThumbnail)
Vue.component(OpRequirementsCard.name, OpRequirementsCard)
Vue.component(OpSliderLayout.name, OpSliderLayout)
Vue.component(OpProductMediaSlide.name, OpProductMediaSlide)
Vue.component(OpNavigationArrow.name, OpNavigationArrow)
Vue.component(OpPackOverviewCard.name, OpPackOverviewCard)
Vue.component(OpPackProductCard.name, OpPackProductCard)
Vue.component(OpLevelGroup.name, OpLevelGroup)
Vue.component(OpStoreBreadcrumbs.name, OpStoreBreadcrumbs)
Vue.component(OpPasswordTips.name, OpPasswordTips)
Vue.component(OpStoreBreadcrumbArrow.name, OpStoreBreadcrumbArrow)
Vue.component(OpStoreCheckoutItem.name, OpStoreCheckoutItem)
Vue.component(OpFooter.name, OpFooter)
Vue.component(OpCountrySelect.name, OpCountrySelect)
Vue.component(OpSmallProductCard.name, OpSmallProductCard)
Vue.component(OpAsyncImg.name, OpAsyncImg)
Vue.component(OpStoreMobileMenu.name, OpStoreMobileMenu)
Vue.component(OpNotificationMenu.name, OpNotificationMenu)
Vue.component(OpPhishingSummary.name, OpPhishingSummary)
Vue.component(OpEmployeeList.name, OpEmployeeList)
Vue.component(OpPhishingEmployees.name, OpPhishingEmployees)
Vue.component(OpPhishingSimulationList.name, OpPhishingSimulationList)
Vue.component(OpDashboardCard.name, OpDashboardCard)
Vue.component(OpToggleButtons.name, OpToggleButtons)
Vue.component(OpCourseList.name, OpCourseList)
Vue.component(OpCourseEmployees.name, OpCourseEmployees)
Vue.component(OpCourseSummary.name, OpCourseSummary)
Vue.component(OpRecoveryCodes.name, OpRecoveryCodes)
Vue.component(OpEmployeeTableFilters.name, OpEmployeeTableFilters)
Vue.component(OpPhishingEventSummaryBar.name, OpPhishingEventSummaryBar)
Vue.component(OpPhishingEventSummaryLine.name, OpPhishingEventSummaryLine)
Vue.component(OpPhishingEvolutionLine.name, OpPhishingEvolutionLine)
Vue.component(OpTopChart.name, OpTopChart)
Vue.component(OpCourseSuccessRate.name, OpCourseSuccessRate)
Vue.component(OpCourseSessionEvolutionLine.name, OpCourseSessionEvolutionLine)
Vue.component(OpCourseSuccessKeyNumbers.name, OpCourseSuccessKeyNumbers)
Vue.component(OpCourseSessionTime.name, OpCourseSessionTime)
Vue.component(OpComparisonDonut.name, OpComparisonDonut)
Vue.component(OpCourseSessionByDateLine.name, OpCourseSessionByDateLine)
Vue.component(OpCourseSessionByDateBar.name, OpCourseSessionByDateBar)
Vue.component(OpCampaignLinkedActivityCard.name, OpCampaignLinkedActivityCard)
Vue.component(OpPhishingTestResults.name, OpPhishingTestResults)
Vue.component(OpFilesTable.name, OpFilesTable)
Vue.component(OpUserAvatar.name, OpUserAvatar)
Vue.component(
  OpPhishingExercicePreviewDialog.name,
  OpPhishingExercicePreviewDialog
)
Vue.component(OpColorInput.name, OpColorInput)
Vue.component(OpConfirmationDialog.name, OpConfirmationDialog)
Vue.component(OpCyberscoreSummary.name, OpCyberscoreSummary)
Vue.component(OpCyberscoreByTheme.name, OpCyberscoreByTheme)
Vue.component(OpCyberscoreGauge.name, OpCyberscoreGauge)
Vue.component(OpCSVExportButton.name, OpCSVExportButton)
Vue.component(OpEmployeeCyberscoreDialog.name, OpEmployeeCyberscoreDialog)
Vue.component(OpBadge.name, OpBadge)

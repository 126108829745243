<template>
  <v-card
    width="100%"
    class="py-8"
    color="light-grey"
    rounded="lg"
    elevation="0"
    :disabled="simulations && simulations.length === 0 && !summary"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      class="ma-auto d-flex"
      v-if="isLoading"
    ></v-progress-circular>
    <v-container v-else class="px-0">
      <v-row>
        <v-col v-for="summary in summaries" :key="summary.id" class="px-10">
          <div class="d-flex align-center">
            <img :src="summary.icon" />
            <div class="text-h2 font-weight-bold ml-4">
              {{ `${summary.rate} %` }}
            </div>
          </div>
          <div class="text-h3 mt-4 mb-2">
            {{ summary.label }}
          </div>
          <v-progress-linear
            rounded
            height="14"
            background-color="#dbdbdb"
            :value="
              simulations && simulations.length === 0 && !summary
                ? 100
                : summary.rate
            "
            :color="$colors[summary.color[0]][summary.color[1]]"
          />
          <div
            :style="{ color: $colors[summary.color[0]][summary.color[1]] }"
            class="body-1 font-weight-medium my-2"
          >
            {{
              `${summary.value.toLocaleString()} / ${summary.total.toLocaleString()}`
            }}
          </div>
        </v-col>
      </v-row>
      <v-row
        class="px-5"
        v-if="displayDetails && simulations && simulations.length > 0"
      >
        <v-expansion-panels flat tile :value="panelOpened">
          <v-expansion-panel>
            <v-expansion-panel-header :color="$colors['light-grey']">
              <template v-slot:default
                ><v-row
                  ><v-col class="d-flex justify-end mr-1"
                    ><div class="mr-1 text-body-1 steel-blue--text">
                      {{ detailsTitle }}
                    </div>
                  </v-col></v-row
                ></template
              ></v-expansion-panel-header
            >
            <v-expansion-panel-content :color="$colors['light-grey']">
              <ul class="campaign-list w-100 mr-6">
                <li
                  class="mb-4"
                  v-for="simulation in simulations"
                  :key="simulation.id"
                >
                  <v-row dense>
                    <v-col>
                      <div class="text-h3 h3-reset-line-height">
                        {{ simulation.launch_date | date }}
                      </div>
                      <div class="text-body-1">
                        {{
                          $t("phishingSimulations.exerciseLabel", {
                            name: simulation.name,
                          })
                        }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <v-col
                          v-for="summary in simulation.summaries"
                          :key="summary.id"
                        >
                          <div
                            class="d-flex align-center justify-space-between"
                          >
                            <img
                              class="summary-small-icon"
                              :src="summary.icon"
                            />
                            <div class="text-h3 font-weight-bold ml-2">
                              {{ summary.rate | formatedRate }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end"
                      ><v-btn
                        class="primary--text"
                        fab
                        elevation="0"
                        small
                        :to="{
                          name: 'member-phishing-simulation',
                          params: { simulationId: simulation.id },
                        }"
                        ><v-icon>mdi-magnify</v-icon>
                      </v-btn></v-col
                    >
                  </v-row>
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel></v-expansion-panels
        >
      </v-row>
    </v-container>
    <v-overlay absolute :value="displayOverlay" opacity="0.4">
      <span class="font-weight-bold text-h2">{{
        $t("phishingSimulations.noActiveSimulation")
      }}</span>
    </v-overlay>
  </v-card>
</template>
<script>
import { isEmpty, clone, isUndefined } from "lodash"
import PhishingMixin from "@/mixins/Phishing.mixins.js"

export default {
  name: "op-phishing-summary",
  mixins: [PhishingMixin],
  props: {
    simulations: {
      type: Array,
    },
    summary: {
      type: Object,
    },
    displayDetails: {
      type: Boolean,
      default() {
        return true
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      panelOpened: -1,
      summaries: [],
    }
  },
  mounted() {
    if (this.summary) {
      this.createSummary(this.summary)
    }
  },
  watch: {
    summary(newSummary, oldSummary) {
      if (!isEmpty(this.summary)) {
        this.createSummary(this.summary)
      }
    },
  },
  computed: {
    displayOverlay() {
      const display =
        !this.isLoading &&
        !isUndefined(this.simulations) &&
        this.simulations.length === 0
      return display
    },
    detailsTitle() {
      return this.$tc(`phishingSimulations.count`, this.simulations.length)
    },
  },
  methods: {
    createSummary(simulationSummary) {
      this.summaries = clone(this.stats, true)
      for (
        let summaryIndex = 0;
        summaryIndex < this.summaries.length;
        summaryIndex++
      ) {
        let summary = this.summaries[summaryIndex]
        if (
          ((!this.simulations || this.simulations.length === 0) &&
            !this.summary) ||
          isNaN(simulationSummary[summary.attribute])
        ) {
          summary.value = "-"
          summary.total = "-"
          summary.rate = "-"
        } else {
          summary.value = simulationSummary[summary.attribute]
          const total = simulationSummary.total
          summary.total = total
          summary.rate =
            total === 0 ? 0 : Math.floor((100 * summary.value) / total)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.campaign-list {
  list-style: none;
}
.summary-small-icon {
  width: 2.2rem;
}
.h3-reset-line-height {
  line-height: $h3-font-size;
}
</style>

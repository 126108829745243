export default class LazyLoad {
  // route level code-splitting
  // this generates a separate chunk (localView.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  static localView(view) {
    return () =>
      import(
        /* webpackChunkName: "localView-[request]" */ `@/views/${view}.vue`
      )
  }

  // route level code-splitting
  // this generates a separate chunk (view.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  static component(name) {
    return () =>
      import(
        /* webpackChunkName: "localComponent-[request]" */ `@/components/${name}.vue`
      )
  }
}

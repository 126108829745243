<template>
  <div class="mobilemenu">
    <v-select
      id="mySelect"
      :items="tabs"
      :item-text="'name'"
      :item-value="'slug'"
      v-model="selected"
      v-on:change="onUpdateTab(selected)"
      color="green-blue"
      background-color="transparent"
      solo
    >
    </v-select>
  </div>
</template>

<style lang="scss" scoped>
:deep(.v-select__selection) {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  text-align: center;

  color: #009ca4 !important;
  font-weight: bold !important;
}

:deep(.v-list-item__title) {
  text-align: center;
  color: black;
  font-weight: 500;
}

:deep(.v-list-item--active) .v-list-item__title {
  color: #009ca4 !important;
  font-weight: bold;
}

:deep(.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat))
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}
</style>
<script>
import _ from "lodash"
import PageStore from "../../../stores/PageStore"
export default {
  name: "op-store-mobile-menu",

  props: {
    tabs: {
      type: Array,
    },
  },

  data: () => ({
    selected: null,
  }),

  created: function () {
    const current = _.find(this.tabs, { id: this.currentCategoryId })
    if (current) {
      this.selected = current
    }
  },
  computed: {
    currentCategoryId() {
      return PageStore.storeBreadcrumbs.categoryId
    },
  },
  watch: {
    currentCategoryId() {
      let currentCategoryTab = this.tabs.find(
        (tab) => tab.id == PageStore.storeBreadcrumbs.categoryId
      )
      if (currentCategoryTab && currentCategoryTab.route) {
        this.currentTab = this.getRouteHref(currentCategoryTab.route)
      }
    },
  },

  methods: {
    getRouteHref(route) {
      const resolvedRoute = this.$router.resolve(route)
      return resolvedRoute ? resolvedRoute.href : null
    },
    onUpdateTab(value) {
      const selected = _.find(this.tabs, { slug: value })
      if (selected && selected.id != this.currentCategoryId) {
        this.$router.push(selected.route)
        PageStore.setStoreBreadcrumbsCategoryId(selected.id)
      }
    },
  },
}
</script>

<template>
  <div id="diagonal-bloc" :style="diagonalBlocStyle" :class="diagonalBlocClass">
    <div id="diagonal-bloc-translate">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#diagonal-bloc {
  margin-right: -10px;

  #diagonal-bloc-translate {
    background-color: inherit;
    width: 100%;
    height: 100%;
    transform: rotate(-1.7deg) translate(0, -26px);
    /* TODO reverse */
    /*transform: rotate(-1.7deg) translate(0, -28px);*/
  }
}
</style>

<script>
export default {
  name: "op-divider",

  props: {
    bgColor: {
      type: String,
      default: "white",
    },
    height: {
      type: String,
      default: "70px",
    },
    diagonal: {
      type: String,
      default: "primary--text text--darken-1",
    },
    contain: {
      type: Boolean,
    },
    reverse: {
      type: Boolean,
    },
  },

  computed: {
    diagonalBlocClass() {
      return `${this.bgColor} ${this.height}`
    },
    diagonalBlocStyle() {
      return {
        height: this.height,
        "margin-top": this.contain ? this.height : null,
      }
    },
  },
}
</script>

<template>
  <v-row
    store-v-tabs
    :justify="justify"
    :class="$vuetify.theme.dark ? 'secondary' : 'white'"
  >
    <!--  class="my-4"  hide-slider -->
    <v-col cols="12" class="my-0 pa-0 max-width-1050">
      <v-tabs
        height="67"
        fixed-tabs
        v-model="currentTab"
        show-arrows
        slider-size="5"
        color="green-blue"
      >
        <!--  hide-slider -->

        <template v-slot:default>
          <v-tab
            v-for="tab in tabs"
            :key="tab.slug"
            class="text-none"
            :to="tab.route"
            :ripple="false"
            :exact="true"
            active-class="selected-tab"
            @click="onUpdateTab(tab)"
          >
            <div class="text-wrap">
              {{ tab.name }}
            </div>

            <!--v-progress-linear
              v-if="getRouteHref(tab.route) === currentTab"
              color="green-blue"
              class="store-v-tabs-progress-linear"
              background-color="green-blue"
              height="5"
            >
            </v-progress-linear-->
          </v-tab>
        </template>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.row[store-v-tabs] {
  .selected-tab {
    color: $teal-blue;
  }
  .store-v-tabs-progress-linear {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &.v-progress-linear {
      max-width: 81px;

      .v-progress-linear__background {
        opacity: 0.5 !important;
      }

      position: absolute;
      top: 0;
    }
  }
}
</style>

<script>
import PageStore from "../../../stores/PageStore"
export default {
  name: "op-store-tabs",

  props: {
    value: String,
    justify: String,
    active: Boolean,
    tabs: {
      type: Array,
    },
  },

  data: () => ({}),

  computed: {
    currentTab: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    currentCategoryId() {
      return PageStore.storeBreadcrumbs.categoryId
    },
  },
  watch: {
    currentCategoryId() {
      let currentCategoryTab = this.tabs.find(
        (tab) => tab.id == PageStore.storeBreadcrumbs.categoryId
      )
      if (currentCategoryTab && currentCategoryTab.route) {
        this.currentTab = this.getRouteHref(currentCategoryTab.route)
      }
    },
  },
  methods: {
    getRouteHref(route) {
      const resolvedRoute = this.$router.resolve(route)
      return resolvedRoute ? resolvedRoute.href : null
    },
    onUpdateTab(value) {
      PageStore.setStoreBreadcrumbsCategoryId(value.id)
    },
  },
}
</script>

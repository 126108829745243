<template>
  <v-card
    v-bind="$attrs"
    v-on="$listeners"
    :loading="loading ? loadingColor : false"
    class="card"
  >
    <div style="width: 200px; margin: 0 auto; margin-bottom: 8%; padding: 5px">
      <a :href="$links.oppens_site">
        <v-img :alt="`${companyName} logo`" :src="logo" width="99%"> </v-img>
      </a>
    </div>

    <op-form ref="form" v-if="mustSend2faCode">
      <v-card-text class="px-3 pa-0">
        <op-text-field
          name="code"
          type="text"
          inputmode="numeric"
          :autocomplete="useRecoveryCode ? 'off' : 'one-time-code'"
          background-color="white"
          rules="required"
          ref="codeInput"
          v-model="code"
          @keyup.enter.native="submitCode"
          :label="
            $t(
              useRecoveryCode
                ? 'profile.doubleAuth.recoveryCodeLabel'
                : 'profile.doubleAuth.codeLabel'
            )
          "
        >
        </op-text-field>
      </v-card-text>
      <v-card-text class="px-3 pa-0">
        <v-checkbox
          name="useRecoveryCode"
          v-model="useRecoveryCode"
          :label="$t('profile.doubleAuth.useRecoveryCodeLabel')"
        >
        </v-checkbox>
      </v-card-text>
      <v-card-text class="px-3 pa-0 text-center">
        <v-btn
          block
          large
          dark
          :color="submitBtnColor"
          :loading="loading"
          class="text-none"
          @click="submitCode"
        >
          {{ $t("label.validate") }}
        </v-btn>
      </v-card-text>
    </op-form>

    <op-form ref="form" v-else-if="hasForgottenPassword">
      <v-card-title>Modifier mon mot de passe</v-card-title>
      <v-card-text class="px-3 pa-0">
        <op-text-field
          name="email"
          type="email"
          background-color="white"
          rules="required|email"
          v-model="email"
          :label="$t('label.email')"
        >
        </op-text-field>
      </v-card-text>
      <v-card-text class="px-3 pa-0 text-center">
        <v-btn
          block
          large
          dark
          :color="submitBtnColor"
          :loading="loading"
          class="text-none"
          @click="forgotPassword"
        >
          {{ $t("label.send") }}
        </v-btn>
      </v-card-text>
      <v-card-text class="px-3 pa-0 mt-2 text-center">
        <v-btn
          text
          :color="submitBtnColor"
          @click="hasForgottenPassword = false"
          class="text-none"
        >
          {{ $t("label.connexionScreen") }}
        </v-btn>
      </v-card-text>
    </op-form>

    <op-form ref="form" v-else-if="ssoConnection">
      <v-card-text class="px-3 pa-0">
        <op-text-field
          name="email"
          type="email"
          background-color="white"
          rules="required|email"
          v-model="email"
          :label="$t('label.email')"
        >
        </op-text-field>
      </v-card-text>

      <v-card-text class="px-3 pa-0 text-center">
        <v-btn
          block
          large
          dark
          :color="submitBtnColor"
          :loading="loading"
          class="text-none"
          @click="ssoLogin"
        >
          {{ $t("label.microsoftConnexion") }}
        </v-btn>
      </v-card-text>
      <div class="py-5 d-flex">
        <div class="text-decoration-line-through flex-grow-1 lines"></div>
        <div class="flex-grow-0 mx-4 text-uppercase">{{ $t("login.or") }}</div>
        <div class="text-decoration-line-through flex-grow-1 lines"></div>
      </div>
      <v-btn
        block
        large
        outlined
        :disabled="loading"
        class="text-none"
        @click="ssoConnection = false"
      >
        {{ $t("label.connexionScreen") }}
      </v-btn>
    </op-form>

    <op-form ref="form" v-else>
      <v-card-text class="px-3 pa-0">
        <op-text-field
          name="email"
          type="email"
          background-color="white"
          rules="required|email"
          v-model="email"
          :label="$t('label.email')"
        >
        </op-text-field>
      </v-card-text>

      <v-card-text class="px-3 pa-0">
        <op-text-field
          name="password"
          rules="required"
          background-color="white"
          v-model="password"
          :label="$t('label.password')"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @keyup.enter.native="submit()"
        >
        </op-text-field>
      </v-card-text>

      <v-card-text class="px-3 pa-0 text-center">
        <v-btn
          block
          large
          dark
          :color="submitBtnColor"
          :loading="loading"
          class="text-none"
          @click="submit"
        >
          {{ $t("label.connexion") }}
        </v-btn>
      </v-card-text>

      <v-card-text class="px-3 pa-0 mt-2 text-center">
        <v-btn
          text
          :color="submitBtnColor"
          :to="{ name: 'register' }"
          class="text-none"
        >
          {{ $t("label.create_account") }}
        </v-btn>

        <v-btn
          text
          :color="submitBtnColor"
          @click="hasForgottenPassword = true"
          class="text-none"
        >
          {{ $t("label.forgot_password") }}
        </v-btn>
      </v-card-text>
      <div class="py-5 d-flex">
        <div class="text-decoration-line-through flex-grow-1 lines"></div>
        <div class="flex-grow-0 mx-4 text-uppercase">{{ $t("login.or") }}</div>
        <div class="text-decoration-line-through flex-grow-1 lines"></div>
      </div>
      <v-btn
        block
        large
        outlined
        :disabled="loading"
        class="text-none"
        @click="ssoConnection = true"
      >
        {{ $t("label.microsoftConnexion") }}
      </v-btn>
    </op-form>
  </v-card>
</template>

<script>
import AuthLogic from "@/logics/AuthLogic"
import DoubleAuthLogic from "@/logics/DoubleAuthLogic"

export default {
  name: "op-login-card",

  components: {},

  data: () => ({
    email: "",
    password: "",
    code: "",
    useRecoveryCode: false,
    showPassword: false,
    loading: false,
    mustSend2faCode: false,
    hasForgottenPassword: false,
    ssoConnection: false,
  }),

  props: {
    loadingColor: {
      type: String,
      default: "primary",
    },
    submitBtnColor: {
      type: String,
      default: "primary",
    },
  },

  mounted() {},

  computed: {
    form() {
      return this.$refs.form
    },
    logo() {
      return this.$customizationStore.getLogos()?.default
    },
    companyName() {
      return this.$customizationStore.getCompany()?.name
    },
  },

  methods: {
    redirectAfterLogin() {
      if (this.$route.query.redirect) {
        this.$router.push({ path: this.$route.query.redirect })
      } else {
        // should be perhaps placed in the authentication logic component because one can be logged from several places
        // issue --> get the $gtm reference on the authLogic
        this.$gtmLogic.login()
        this.$router.push({ name: "member-dashboard" })
      }
    },
    async ssoLogin() {
      const isValid = await this.form.validate()
      if (isValid) {
        this.loading = true
        AuthLogic.ssoLogin({ email: this.email })
          .then((res) => {
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              this.displayError(error.response)
            } else if (error.response && 429 === error.response.status) {
              error.response = {
                data: {
                  message: this.$i18n.t("login.errors.429-too-many-attempts"),
                },
              }
            } else {
              error.response = {
                data: {
                  message: this.$i18n.t("login.errors.401-unauthorized"),
                },
              }
            }
            this.displayError(error.response)
          })
      }
    },
    async login(body) {
      this.loading = true

      let user = this.$authStore.me

      if (this.$authStore.saveAnonymous && user && user.is_anonymous) {
        //console.log("About to save anonymous data")
        body.anonymous_user_uuid = user.uuid
      } else {
        //console.log("NOT saving anonymous data")
      }

      AuthLogic.login(body)
        .then((res) => {
          this.loading = false
          if (res.mustSend2faCode === true) {
            this.mustSend2faCode = true
            this.$nextTick(() => this.$refs.codeInput.focus())
          } else {
            this.redirectAfterLogin()
          }
        })
        .catch((error) => {
          this.loading = false
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.displayError(error.response)
          } else if (error.response && 429 === error.response.status) {
            error.response = {
              data: {
                message: this.$i18n.t("login.errors.429-too-many-attempts"),
              },
            }
          } else {
            error.response = {
              data: {
                message: this.$i18n.t("login.errors.401-unauthorized"),
              },
            }
          }
          this.displayError(error.response)
        })
    },
    async submit() {
      const isValid = await this.form.validate()

      if (isValid) {
        await this.login({
          email: this.email,
          username: this.email,
          password: this.password,
        })
      }
    },
    async submitCode() {
      this.loading = true
      try {
        if (this.useRecoveryCode) {
          await DoubleAuthLogic.postRecoveryCode(this.code)
        } else {
          await DoubleAuthLogic.postChallenge(this.code)
        }
        this.redirectAfterLogin()
      } catch (error) {
        this.displayError(error.response)
      } finally {
        this.loading = false
      }
    },
    async forgotPassword() {
      if (!this.email) {
        const errorMessage = {
          data: {
            error: "no email",
            message: this.$i18n.t("forgot-password.no-email"),
          },
        }
        this.displayError(errorMessage)
        return
      }

      AuthLogic.forgotPassword(this.email)
        .then((result) => {
          this.displaySuccess(result.message)
        })
        .catch((error) => {
          if (error.response && 429 === error.response.status) {
            error.response = {
              data: {
                message: this.$i18n.t(
                  "forgot-password.errors.429-too-many-attempts"
                ),
              },
            }
          } else {
            error.response = {
              data: {
                message: this.$i18n.t(
                  "forgot-password.errors.401-unauthorized"
                ),
              },
            }
          }

          this.displayError(error.response)
        })
    },
    displayError(response) {
      this.form.displayError(response)
    },
    displaySuccess(str) {
      this.form.displaySuccess(str)
    },
  },
}
</script>
<style scoped>
.lines {
  border-bottom: solid 1px var(--v-primary-base);
  margin-bottom: 0.7rem;
}
</style>

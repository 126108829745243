import Vue from "vue"
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate"
import {
  required,
  required_if,
  email,
  min,
  max,
  min_value,
  max_value,
} from "vee-validate/dist/rules"
import { i18n } from "./i18n"

/// see https://vee-validate.logaretm.com/v3/guide/localization.html#using-the-default-i18n

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`)

    return i18n.t(`validation.${values._rule_}`, values)
  },
})

// Install required rule and message.
extend("required", required)
extend("required_if", required_if)

// Install email rule and message.
extend("email", email)

// Install min rule and message.
extend("min", min)
extend("max", max)

// Install min_value rule and message.
extend("min_value", min_value)
extend("max_value", max_value)

extend("strong_password", {
  validate: (value) => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    )
    return strongRegex.test(value)
    // return !!value;
  },
})

// regex from https://stackoverflow.com/a/5717133
const urlRegex = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
) // fragment locator

extend("url", {
  validate: (value) => {
    if (value) {
      return urlRegex.test(value)
    }
    return false
  },
})
const phoneRegex = new RegExp("^(\\+?\\d{6,20})$", "m")
//const phoneRegex = /[+]?\d{6,20}/

extend("phone", {
  validate: (value) => {
    if (value) {
      return phoneRegex.test(value)
    }
    return false
  },
})

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

<template>
  <v-card v-bind="$attrs" class="flex">
    <v-container class="pa-0 max-width-1050">
      <!--  resolution > 400px       -->
      <v-list-item
        v-if="$vuetify.breakpoint.smAndUp"
        :class="{ 'pl-0': !title }"
      >
        <v-list-item-content v-if="title">
          <v-list-item-title class="text-uppercase caption">
            {{ title }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action
          v-if="!redoTopicLink"
          :class="{ 'mr-0': !title }"
          class="align-self-center"
        >
          <v-btn @click="$emit('coach')" large outlined class="text-none">
            {{ $t("diagnostic.consult-coach") }}
          </v-btn>
        </v-list-item-action>

        <v-list-item-action
          v-if="redoTopicLink"
          :class="{ 'mr-0': !title }"
          class="align-self-center"
        >
          <v-btn :to="redoTopicLink" large outlined class="text-none">
            {{ $t("diagnostic.redo-title") }}
          </v-btn>
        </v-list-item-action>

        <v-list-item-action v-if="nextTopicLink" class="align-self-center">
          <v-btn :to="nextTopicLink" large color="primary" class="text-none">
            {{ $t("diagnostic.btn-next") }}
            <v-icon class="ml-1">mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <!--  resolution < 400px       -->
      <v-card-title v-else class="py-0">
        <div v-if="title" class="pb-2 text-uppercase caption">
          {{ title }}
        </div>
        <v-row>
          <v-col cols="6" align="center" v-if="!redoTopicLink">
            <v-btn large outlined class="text-none">
              {{ $t("diagnostic.consult-coach") }}
            </v-btn>
          </v-col>

          <v-col cols="6" align="center" v-if="redoTopicLink">
            <v-btn :to="redoTopicLink" large outlined class="text-none">
              {{ $t("diagnostic.redo-title") }}
            </v-btn>
          </v-col>

          <v-col cols="6" align="end" v-if="nextTopicLink">
            <v-btn :to="nextTopicLink" large color="primary" class="text-none">
              {{ $t("diagnostic.btn-next") }}
              <v-icon class="ml-1">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "op-diagnostic-btn",

  props: {
    title: {
      type: String,
    },
    nextTopicLink: Object,
    redoTopicLink: Object,
  },
}
</script>

<template>
  <div class="pack-overview-container">
    <div class="pack-background"></div>
    <div class="pack-details">
      <h3 class="text-h3 font-weight-medium marine-blue--text">
        {{ pack.attributes.name }}
      </h3>
      <div class="pack-price steel--text">
        {{ pack.attributes.pricing.description }}
      </div>
      <div class="pack-tags">
        <div class="pack-tag text-caption marine-blue--text tag-title">
          PACK
        </div>
        <div
          class="pack-tag text-caption charcoal-grey--text"
          v-for="tag in pack.attributes.tags"
          :key="tag"
        >
          {{ tag }}
        </div>
      </div>
    </div>
    <div class="pack-actions">
      <v-btn
        color="primary"
        dark
        :small="true"
        :min-width="40"
        :min-height="40"
        :class="[
          'text-none',
          'font-weight-bold',
          'text-body-1',
          'ml-auto',
          'icon-btn',
        ]"
        :to="{ name: 'store_pack', params: { packId: getProductId(pack) } }"
        elevation="8"
      >
        <v-icon right size="26">mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/Products.mixins.js"

export default {
  name: "op-pack-overview-card",
  props: {
    pack: {
      type: Object,
      required: true,
    },
  },
  mixins: [mixin],
  data() {
    return {
      packLoaded: false,
    }
  },
  created() {
    this.packLoaded = true
  },
  computed: {
    auth() {
      return this.$store.state.auth
    },
  },
}
</script>

<style lang="scss">
$text-black: #081124;
$text-grey: #949baa;

.pack-overview-container {
  position: relative;
  padding: 38px 24px 42px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 3px 8px 0 rgba(0, 4, 10, 0.09),
    0 0 19px 0 rgba(64, 69, 78, 0.19);
  width: 100%;

  .pack-background {
    clip-path: polygon(78% 0, 100% 0, 100% 100%, 63% 100%);
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    filter: blur(1px);
    display: block;
    position: absolute;
    background-color: black;

    &:after {
      content: "";
      background-repeat: no-repeat;
      background-size: cover;
      background-clip: border-box;
      background-image: url(https://www.ecos-office.com/fileadmin/_processed_/1/c/csm_Teaser-Mainz-Infos-Fotolia_7469a91029.jpg);
      opacity: 0.4;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }

  .pack-details {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    z-index: 0;
    width: 70%;

    h3 {
      margin-top: 0;
    }
    .pack-price {
      line-height: 1.43;
      margin-top: 0.857em;
      margin-bottom: 1.643em;
    }
    .pack-tags {
      display: flex;
      flex-flow: row wrap;

      .pack-tag {
        border-radius: 18px;
        background-color: #f5f5f5;
        text-align: center;
        text-transform: uppercase;
        margin-right: 0.714em;
        margin-bottom: 0.5em;
        padding: 0.24rem 0.8rem;
        white-space: nowrap;
      }
      .pack-tag.tag-title {
        background-color: #dfe5ec;
      }
    }
  }

  .pack-actions {
    display: flex;
    flex-direction: column;
    z-index: 0;

    .icon-btn i {
      margin: 0;
    }
  }
}
</style>

import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class AuthRepository {
  /**
   *
   * @returns {Promise}
   */
  static me(params = {}) {
    return Request.make("GET", `/${API_VERSION}/user`, { params })
  }

  static login(body) {
    return Request.make("POST", "/login", {
      ...body,
    })
  }

  static ssoLogin(body) {
    return Request.make(
      "GET",
      `/login-sso?email=${body.email}&type=azure-active-directory`
    )
  }

  /**
   *
   * @param body
   * @returns {Promise}
   */
  static register(body) {
    return Request.make("POST", "/users", body)
  }

  /**
   * @returns {Promise}
   */
  static logout() {
    return Request.make("POST", "/signout")
  }

  /**
   * @returns {Promise}
   */
  static resetPassword(params) {
    return Request.make("GET", `/password/reset`, { params })
  }

  /**
   * @returns {Promise}
   */
  static forgotPassword(params) {
    return Request.make("GET", `/password/forgot`, { params })
  }

  /**
   *
   * @param refreshToken
   * @returns {Promise}
   */
  static refreshToken(refreshToken) {
    return Request.make("POST", "/oauth/token", {
      refresh_token: refreshToken,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      grant_type: "refresh_token",
    })
  }
}

<template>
  <v-card width="100%" elevation="0">
    <v-data-table
      :multi-sort="true"
      :items="employees"
      :server-items-length="totalEmployees"
      @update:options="changeListOptions"
      :page.sync="currentPage"
      :headers="headers"
      dense
      fixed-header
      :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
      :items-per-page="itemsPerPage"
      :loading="employeeTableIsLoading"
      class="px-4"
    >
      <template v-slot:top>
        <div>
          <op-employee-table-filters
            :employeeTableIsLoading="employeeTableIsLoading"
            :filteringOptions="filteringOptions"
            :totalEmployees="totalEmployees"
            :filterType="0"
            @updateFilters="updateFilters"
            :hideableColumns="hideableColumns"
            @updateColumnSelection="updateColumnSelection"
          ></op-employee-table-filters>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              @input="debouncedSearchUpdate"
              prepend-icon="mdi-magnify"
              :label="$t('employees.searchLabel')"
              single-line
              hide-details
              solo
              flat
              clearable
              dense
              class="text-body-1"
              :disabled="employeeTableIsLoading"
          /></v-toolbar>
        </div>
      </template>
      <template v-slot:[`item.informations.last_name`]="{ item }">
        {{ item.informations.last_name | uppercase }}
      </template>
      <template v-slot:[`item.informations.first_name`]="{ item }">{{
        item.informations.first_name | titlecase
      }}</template>
      <template v-slot:[`item.informations.cyberscore`]="{ item }"
        ><v-btn text @click="selectedEmployeeId = item.informations.id">
          {{
            item.informations.cyberscore ? item.informations.cyberscore : "--"
          }}
        </v-btn></template
      >
      <template
        v-for="cell in additionalCells"
        v-slot:[`item.campaigns.${cell}.summary.simulations.total`]="{ item }"
        ><div :key="cell">
          <v-tooltip
            v-if="getSummaryImage(item.campaigns[cell]?.summary?.simulations)"
            right
            color="#ffffff"
            content-class="black--text"
            ><template v-slot:activator="{ on, attrs }"
              ><img
                v-bind="attrs"
                v-on="on"
                class="summary-icon"
                :src="
                  getSummaryImage(item.campaigns[cell]?.summary?.simulations)
                " /></template
            ><span class="subtitle-1">{{
              getSummaryCount(item.campaigns[cell]?.summary?.simulations)
            }}</span></v-tooltip
          >
          <v-tooltip
            v-if="getTrainedImage(item.campaigns[cell]?.summary?.simulations)"
            right
            color="#ffffff"
            content-class="black--text"
            ><template v-slot:activator="{ on, attrs }">
              <img
                v-bind="attrs"
                v-on="on"
                class="summary-icon"
                :src="
                  getTrainedImage(item.campaigns[cell]?.summary?.simulations)
                " /></template
            ><span class="subtitle-1">{{
              $tc(
                "phishingSimulations.trainedCount",
                item.campaigns[cell]?.summary?.simulations.trained
              )
            }}</span></v-tooltip
          >

          <v-tooltip
            v-if="getEmailReportedImage(item.campaigns[cell]?.summary?.simulations)"
            right
            color="#ffffff"
            content-class="black--text"
            ><template v-slot:activator="{ on, attrs }">
              <img
                v-bind="attrs"
                v-on="on"
                class="summary-icon"
                :src="
                  getEmailReportedImage(item.campaigns[cell]?.summary?.simulations)
                " /></template
            ><span class="subtitle-1">{{
              $tc(
                "phishingSimulations.email_reported",
                item.campaigns[cell]?.summary?.simulations.email_reported
              )
            }}</span></v-tooltip
          >
        </div>
      </template>
      <template
        v-for="cell in additionalCells"
        v-slot:[`header.campaigns.${cell}.summary?.simulations.total`]
      >
        <router-link
          :key="cell"
          :to="{
            name: 'member-phishing-simulation',
            params: { simulationId: cell },
          }"
          class="color-inherit"
          >{{ simulationHeader(cell) }}</router-link
        >
      </template>
    </v-data-table>
    <op-employee-cyberscore-dialog
      :employeeId="selectedEmployeeId"
      @close="() => (selectedEmployeeId = null)"
    />
  </v-card>
</template>
<script>
import lodash from "lodash"
import CampaignLogic from "@/logics/CampaignLogic.js"
import EmployeeTableMixin from "@/mixins/EmployeeTable.mixins.js"
import TableMixins from "@/mixins/Table.mixins.js"

export default {
  name: "op-phishing-employees",
  mixins: [EmployeeTableMixin, TableMixins],
  props: {
    companyUuid: {
      type: String,
      required: true,
    },
    phishingSimulations: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      additionalCells: [],
    }
  },
  created() {
    this.defineHeaders()
  },
  watch: {
    companyUuid(newCompanyUuid, oldCompanyUuid) {
      if (!lodash.isEmpty(this.additionalCells)) {
        this.refreshEmployees()
      }
    },
    phishingSimulations(oldSimulations, newSimulations) {
      if (this.prepTable()) {
        this.refreshEmployees()
      }
    },
  },
  methods: {
    simulationHeader(campaignId) {
      return this.hideableColumns.find((col) => col.campaignId === campaignId)
        ?.text
    },
    defineHeaders() {
      if (!this.STATIC_HEADERS) {
        // Here we define headers that are always displayed
        this.STATIC_HEADERS = [
          {
            text: this.$t("employees.emailAddress"),
            value: "informations.email",
            sortable: true,
            cellClass: "text-body-1",
            class: this.headerClass,
          },
        ]
        // Here we define headers that can be hidden
        this["informations.last_name"] = {
          text: this.$t("employees.lastName"),
          value: "informations.last_name",
          cellClass: "text-body-1",
          class: this.headerClass,
        }
        this["informations.first_name"] = {
          text: this.$t("employees.firstName"),
          value: "informations.first_name",
          cellClass: "text-body-1",
          class: this.headerClass,
        }
        this["informations.cyberscore"] = {
          text: this.$t("employees.cyberscore"),
          value: "informations.cyberscore",
          cellClass: "text-body-1",
          align: "right",
          class: this.headerClass,
          sortable: false,
        }
        // No action headers for this view
        this.ACTION_HEADERS = []
        this.hideableColumns = [
          { ...this["informations.cyberscore"], default: true },
          { ...this["informations.last_name"], default: false },
          { ...this["informations.first_name"], default: false },
        ]
      }
    },
    getSummaryCount(summary) {
      if (!summary) {
        return ""
      } else if (summary.submitted_data > 0) {
        return this.$tc(
          "phishingSimulations.submittedDataCount",
          summary.submitted_data
        )
      } else if (summary.clicked > 0) {
        return this.$tc("phishingSimulations.clickedCount", summary.clicked)
      } else if (summary.sent > 0) {
        return this.$tc("phishingSimulations.emailsSentCount", summary.sent)
      } else {
        return ""
      }
    },
    prepTable() {
      if (!lodash.isEmpty(this.phishingSimulations)) {
        this.additionalCells = []
        this.defineHeaders()
        this.headers = lodash.clone(this.STATIC_HEADERS)
        for (
          let simulationIndex = 0;
          simulationIndex < this.phishingSimulations.length;
          simulationIndex++
        ) {
          const campaign = this.phishingSimulations[simulationIndex]
          const campaignId = campaign.id.toString()
          const columnValueName = `campaigns.${campaign.id}.summary.simulations.total`
          this[columnValueName] = {
            text: campaign.name,
            value: columnValueName,
            sortable: false,
            cellClass: "text-body-1",
            class: this.headerClass,
            align: "center",
            default: true,
            campaignId,
          }
          this.hideableColumns.push(this[columnValueName])
          this.additionalCells.push(campaignId)
        }
        this.headers = this.headers.concat(
          this.hideableColumns.filter((col) => col.default === true)
        )
        return true
      } else {
        return false
      }
    },
    async refreshEmployees(pageNumber) {
      if (this.companyUuid && !this.employeeTableIsLoading) {
        try {
          this.employeeTableIsLoading = true
          if (pageNumber) {
            this.currentPage = pageNumber
          }
          let params = this.getParams(pageNumber)
          params.last_number_campaigns = this.phishingSimulations.length
          params = {
            ...params,
            ...this.sortOptions,
            with_employees: 0,
            with_results: 1,
            with_summary: 1,
            with_simulations: 1,
            with_company_courses: 1,
            "sorts[actual_start_date]": "asc",
          }
          const result = await CampaignLogic.getEmployees(
            this.companyUuid,
            params
          )
          this.employees = lodash.map(result.data?.employees)
          this.totalEmployees = result.meta?.total || 0
          this.filteringOptions = result.meta?.filtering_options
        } catch (error) {
          this.$emit("onError", error?.response?.data?.error?.message)
        } finally {
          this.employeeTableIsLoading = false
        }
      }
    },
  },
}
</script>
<style scoped>
.summary-icon {
  width: 28px;
  height: 28px;
}
</style>

<template>
  <v-card flat tile color="transparent">
    <v-card-title
      :class="{ 'justify-center text-center': $vuetify.breakpoint.smAndDown }"
      class="pb-0 grey-dark--text caption font-weight-bold"
    >
      Question {{ questionKey }} / {{ surveyLength }}
    </v-card-title>
    <v-card-title
      :class="{ 'justify-center text-center': $vuetify.breakpoint.smAndDown }"
      class="pt-1 text-break no-letter-spacing font-weight-bold"
    >
      {{ question.text }}
    </v-card-title>
    <v-card-text>
      <v-list-item-group
        :value="answer"
        @change="updateAnswer"
        :multiple="question.multiple"
        :ripple="false"
        active-class="primary--border"
        color="indigo"
      >
        <v-card
          v-for="choice in question.choices"
          class="my-2"
          :key="choice.id"
        >
          <v-list-item class="py-2" :value="choice.id" :ripple="false">
            <template v-slot:default="{ active }">
              <v-list-item-action class="mr-4">
                <v-checkbox
                  color="primary"
                  :input-value="active"
                  :true-value="choice.id"
                  hide-details
                >
                </v-checkbox>
              </v-list-item-action>

              <v-list-item-action>
                <v-avatar border-radius color="primary" size="30">
                  <div class="white--text">{{ choice.key }}</div>
                </v-avatar>
              </v-list-item-action>

              <v-list-item-content class="pl-4">
                <v-list-item-title class="text-wrap">
                  {{ choice.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-card>
      </v-list-item-group>

      <div
        class="pt-5"
        :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
      >
        <v-btn
          v-if="questionKey > 1"
          large
          color="primary"
          outlined
          :block="$vuetify.breakpoint.smAndDown"
          @click="$emit('previous', answer)"
          class="my-2 text-none white--text"
        >
          {{ $t("diagnostic.survey.previous") }}
        </v-btn>

        <v-btn
          v-if="surveyLength !== questionKey"
          large
          color="primary"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="!answer"
          @click="$emit('next', answer)"
          class="my-2 ml-2 text-none white--text"
        >
          {{ $t("diagnostic.survey.next") }}
        </v-btn>

        <v-btn
          v-else
          large
          color="primary"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="!answer"
          @click="$emit('submit')"
          class="my-2 ml-2 text-none white--text"
        >
          {{ $t("diagnostic.survey.result") }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "op-diagnostic-question-item",

  data: () => ({
    //
    // answer : {}
  }),

  props: {
    value: {
      type: Object,
    },

    surveyLength: {
      type: Number,
    },

    questionKey: {
      type: Number,
    },
    answer: {},

    isLastQuestion: {
      type: Boolean,
    },
  },

  computed: {
    question: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },

  methods: {
    updateAnswer(value) {
      this.$emit("update:answer", value)
    },
  },
}
</script>

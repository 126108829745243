<template>
  <div>
    <v-toolbar
      v-bind="$attrs"
      v-on="$listeners"
      :height="$attrs.height ? $attrs.height : 85"
      :elevation="$attrs.elevation ? $attrs.elevation : 1"
      style="
        z-index: 200;
        box-shadow: none !important;
        border-bottom: #dfe5ec solid 1px;
      "
    >
      <template v-if="isFunnel">
        <v-toolbar-title style="position: absolute">
          <v-btn
            color="white"
            tile
            text
            class="pr-8"
            :href="previousExternalUrl"
            @click="goBack()"
          >
            <v-icon color="black" size="20">mdi-chevron-left</v-icon>
            <span
              v-if="$vuetify.breakpoint.mdAndUp"
              class="text-none no-letter-spacing black--text"
              >Retour
            </span>
          </v-btn>
        </v-toolbar-title>
        <div id="center-logo">
          <a :href="$links.oppens_site">
            <v-img :alt="`${companyName} logo`" :src="logo"> </v-img>
          </a>
        </div>
      </template>

      <template v-else>
        <v-toolbar-title style="">
          <v-btn
            color="white"
            tile
            text
            class="pr-8"
            :href="previousExternalUrl"
            @click="goBack()"
          >
            <v-icon color="black" size="20">mdi-chevron-left</v-icon>
            <span
              v-if="$vuetify.breakpoint.mdAndUp"
              class="no-letter-spacing text-none black--text"
              >Retour</span
            >
          </v-btn>
        </v-toolbar-title>

        <v-toolbar-title>
          <a :href="$links.oppens_site">
            <v-img
              :alt="`${companyName} logo`"
              contain
              :src="logo"
              width="179px"
              height="33px"
            >
            </v-img>
          </a>
        </v-toolbar-title>

        <div id="divider"></div>

        <v-toolbar-title
          style="padding-left: 20px; margin-top: 4px"
          class="subtitle-1"
        >
          {{ topic ? topic.getAttribute("title") : "" }}
        </v-toolbar-title>
      </template>
    </v-toolbar>

    <div v-if="!isFunnel || showProgressBar" class="" id="">
      <v-progress-linear
        color="primary"
        class="diagnostic-v-tabs-progress-linear"
        :class="{ mobile: $vuetify.breakpoint.smAndDown }"
        background-color="#DFE5EC"
        height="5"
        width="50%"
        :value="progression"
      >
      </v-progress-linear>
    </div>
  </div>
</template>

<style scoped lang="scss">
#center-logo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  width: 179px;
  right: 0;
}

#divider {
  border-right: 2px solid #c2d1d9;
  height: 25px;
  margin-top: 4px;
  padding-left: 15px;
  padding-right: 15px;
}

.diagnostic-v-tabs-progress-linear {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  position: absolute;
  width: calc(100% - 250px);
  left: 125px;

  &.mobile {
    width: calc(100% - 40px);
    left: 20px;
  }

  &.v-progress-linear {
    .v-progress-linear__background {
      opacity: 0.5 !important;
    }

    z-index: 100;
  }
}
</style>

<script>
import PageStore from "../../stores/PageStore"

export default {
  name: "op-diagnostic-toolbar",

  props: {
    previousUrl: Object,
    previousExternalUrl: String,
    topic: Object,
    isFunnel: Boolean,
    showProgressBar: Boolean,
    progression: Number,
  },

  data: () => ({
    PageStore,
  }),

  computed: {
    user() {
      return this.$authStore.state.me
    },
    logo() {
      return this.$customizationStore.getLogos()?.default
    },
    companyName() {
      return this.$customizationStore.getCompany()?.name
    },
  },
  methods: {
    goBack() {
      //const routeName = this.$router.currentRoute.name
      const routePath = this.$router.currentRoute.path
      if (this.previousExternalUrl) {
        return null
      } else if (this.previousUrl && routePath != this.previousUrl.path) {
        this.$router.push(this.previousUrl)
      } else {
        this.$router.push({
          path: "/dashboard",
        })
      }
    },
  },
}
</script>

import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class FileRepository {
  static getUserFiles(uuid) {
    return Request.make("GET", `file/${uuid}`)
  }

  static downloadUserFile(uuid, body) {
    return Request.blob("POST", `/file/${uuid}/download`, body)
  }

  static getInvoices() {
    return Request.make("GET", "/invoice")
  }

  static downloadInvoice(body) {
    return Request.blob("POST", "/invoice", body)
  }

  static getCompanyFiles(companyUuid) {
    return Request.make("GET", `/${API_VERSION}/companies/${companyUuid}/files`)
  }

  static downloadCompanyFile(companyUuid, body) {
    return Request.blob(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/files/download`,
      body
    )
  }
}

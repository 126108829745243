<template>
  <v-app-bar
    v-bind="$attrs"
    v-on="$listeners"
    :height="$attrs.height ? $attrs.height : 85"
    :elevation="$attrs.elevation ? $attrs.elevation : 1"
    style="z-index: 200; box-shadow: none !important"
    color="white"
  >
    <v-app-bar-nav-icon
      v-if="
        isMobile ||
        (!(true == isAnonymous || null == user) && !PageStore.drawer)
      "
      color="primary"
      class="mr-5"
      @click.stop="PageStore.drawer = !PageStore.drawer"
    >
    </v-app-bar-nav-icon>

    <v-container
      :fluid="!fluid"
      class="d-flex"
      :class="{ 'pr-5': !hideActions }"
    >
      <op-alert ref="opAlert" color="red" />
      <v-row :justify="justify" align="center">
        <div
          style="height: 40px; padding: 5px; cursor: pointer"
          v-if="isAnonymous || !user || isMobile"
        >
          <a :href="$links.oppens_site">
            <v-img
              :alt="`${companyName} logo`"
              :src="logo"
              contain
              max-height="40"
            >
            </v-img>
          </a>
        </div>

        <div
          v-if="(isAnonymous || !user) && !isMobile"
          class="nav-links"
          style="margin-left: 30px; color: black; font-weight: bold"
        >
          <v-btn
            text
            class="text-none"
            :href="$links.oppens_blog"
            active-class="active-green-btn"
          >
            {{ $t("label.news") }}
          </v-btn>
          <v-btn
            text
            active-class="active-green-btn"
            class="text-none"
            to="/store"
          >
            {{ $t("label.solutions") }}
          </v-btn>
          <v-btn text class="text-none" :href="$links.oppens_contact">
            {{ $t("label.contact") }}
          </v-btn>
        </div>

        <v-spacer v-if="!hideActions"></v-spacer>

        <div v-if="user && !isAnonymous" class="mr-5 d-flex">
          <v-speed-dial
            v-model="managerFab"
            direction="bottom"
            top
            left
            transition="slide-y-transition"
            class="mr-5 manager-dial"
            v-if="isManager"
          >
            <template v-slot:activator>
              <v-btn
                v-model="managerFab"
                :color="isManagerMode ? 'deep-orange' : 'primary'"
                dark
                fab
                depressed
                :loading="isLoading"
                max-width="48"
                max-height="48"
              >
                <v-icon v-if="managerFab"> mdi-close </v-icon>
                <v-icon v-else-if="isManagerMode">
                  mdi-account-multiple
                </v-icon>
                <v-icon v-else> mdi-domain </v-icon>
              </v-btn>
            </template>
            <div class="d-flex align-center">
              <v-btn
                fab
                dark
                small
                color="deep-orange"
                :title="$t('label.managerView')"
                :loading="isLoading"
                @click="setManagerMode(true)"
              >
                <v-icon>mdi-account-multiple</v-icon>
              </v-btn>
              <div
                class="text-body-2 elevation-6 deep-orange white--text rounded-pill width-max-content px-2 py-1"
              >
                {{ $t("label.managerView") }}
              </div>
            </div>

            <div class="d-flex align-center">
              <v-btn
                fab
                dark
                small
                color="primary"
                :title="$t('label.companyView')"
                :loading="isLoading"
                @click="setManagerMode(false)"
              >
                <v-icon>mdi-domain</v-icon>
              </v-btn>
              <div
                class="text-body-2 elevation-6 primary white--text rounded-pill width-max-content px-2 py-1"
              >
                {{ $t("label.companyView") }}
              </div>
            </div>
          </v-speed-dial>
          <op-notification-menu></op-notification-menu>
        </div>

        <div class="d-flex align-center" v-if="!hideActions && !isMobile">
          <v-btn
            text
            :to="{ name: 'register' }"
            class="text-none"
            v-if="!user || isAnonymous"
          >
            {{ $t("label.create_account") }}
          </v-btn>

          <v-btn
            v-if="!user || isAnonymous"
            dark
            :to="{ name: 'login' }"
            color="primary"
            class="text-none"
          >
            {{ $t("label.connexion") }}
          </v-btn>

          <v-menu
            v-else
            offset-y
            left
            min-width="300"
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on, value }">
              <div v-on="on" style="cursor: pointer">
                <!-- LIST ITEMS -->
                <div v-if="$vuetify.breakpoint.lgAndUp" class="testclass">
                  <op-user-avatar :user="user" />

                  <div
                    :class="
                      !$vuetify.theme.dark
                        ? 'blue-dark--text font-weight-regular'
                        : 'white--text'
                    "
                  >
                    {{ getName() }}
                  </div>
                  <v-icon>{{ value ? "mdi-menu-up" : "mdi-menu-down" }}</v-icon>
                </div>

                <!-- AVATAR WITH INITIALS -->
                <v-avatar
                  color="primary"
                  class="white--text"
                  v-else-if="$vuetify.breakpoint.mdAndUp"
                >
                  <div v-if="user" class="body-2">
                    {{ user.initials ? user.initials.toUpperCase() : "" }}
                  </div>
                </v-avatar>
              </div>
            </template>

            <v-list class="py-0">
              <v-list-item :to="{ name: 'member-profile' }">
                <v-list-item-content
                  :class="
                    !$vuetify.theme.dark
                      ? 'blue-dark--text font-weight-regular'
                      : 'white--text'
                  "
                >
                  <v-list-item-title>
                    {{ $t("navigation.member-profile") }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    :class="
                      !$vuetify.theme.dark ? 'blue-dark--text' : 'white--text'
                    "
                  >
                    mdi-account
                  </v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item
                :to="{ name: 'company-profile' }"
                :disabled="!isCompanyUser"
              >
                <v-list-item-content
                  :class="{
                    'white--text': $vuetify.theme.dark,
                    'blue-dark--text font-weight-regular':
                      !$vuetify.theme.dark && isCompanyUser,
                  }"
                >
                  <v-list-item-title>
                    {{ $t("navigation.company-profile") }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    :class="{
                      'white--text': $vuetify.theme.dark,
                      'blue-dark--text': !$vuetify.theme.dark && isCompanyUser,
                      'grey--text': !isCompanyUser,
                    }"
                  >
                    mdi-domain
                  </v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item
                :to="{ name: 'member-collaborators' }"
                :disabled="!canManageEmployees"
              >
                <v-list-item-content
                  :class="{
                    'white--text': $vuetify.theme.dark,
                    'blue-dark--text font-weight-regular':
                      !$vuetify.theme.dark && canManageEmployees,
                  }"
                >
                  <v-list-item-title>
                    {{ $t("navigation.collaborators") }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    :class="{
                      'white--text': $vuetify.theme.dark,
                      'blue-dark--text': !$vuetify.theme.dark && canManageEmployees,
                      'grey--text': !canManageEmployees,
                    }"
                  >
                    mdi-account-multiple
                  </v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item
                :to="{ name: 'member-access-rights' }"
                :disabled="!isCompanyUser"
              >
                <v-list-item-content
                  :class="{
                    'white--text': $vuetify.theme.dark,
                    'blue-dark--text font-weight-regular':
                      !$vuetify.theme.dark && isCompanyUser,
                  }"
                >
                  <v-list-item-title>
                    {{ $t("navigation.accessRights") }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    :class="{
                      'white--text': $vuetify.theme.dark,
                      'blue-dark--text': !$vuetify.theme.dark && isCompanyUser,
                      'grey--text': !isCompanyUser,
                    }"
                  >
                    mdi-account-key
                  </v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item :to="{ name: 'my_documents' }">
                <v-list-item-content
                  :class="
                    !$vuetify.theme.dark
                      ? 'blue-dark--text font-weight-regular'
                      : 'white--text'
                  "
                >
                  <v-list-item-title>
                    {{ $t("navigation.my-documents") }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    :class="
                      !$vuetify.theme.dark ? 'blue-dark--text' : 'white--text'
                    "
                  >
                    mdi-file-document-outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <!--v-list-item @click="logout"-->
              <v-list-item :to="{ name: 'logout' }">
                <v-list-item-content
                  :class="
                    !$vuetify.theme.dark
                      ? 'blue-dark--text font-weight-regular'
                      : 'white--text'
                  "
                >
                  <v-list-item-title>
                    {{ $t("navigation.disconnect") }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    :class="
                      !$vuetify.theme.dark ? 'blue-dark--text' : 'white--text'
                    "
                  >
                    mdi-power
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import PageStore from "../../stores/PageStore"
import AuthLogic from "../../logics/AuthLogic"
import CompanyLogic from "../../logics/CompanyLogic"
import AlertMixin from "@/mixins/Alert.mixins.js"

export default {
  name: "op-toolbar",
  mixins: [AlertMixin],

  props: {
    justify: String,
    value: Boolean,
    fluid: Boolean,
    showBurger: Boolean,
    hideActions: Boolean,
    showLogo: Boolean,
    isAdmin: Boolean,
  },

  data: () => ({
    PageStore,
    storeRoutes: [
      "store_main_home",
      "store_product",
      "store_category",
      "store_pack",
      "store_checkout",
    ],
    small: null,
    managerFab: false,
    isLoading: false,
  }),
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize)
    })
    this.onResize()
  },

  computed: {
    isCompanyUser() {
      return this.$authStore.isCompanyUser()
    },
    canManageEmployees(){
      return this.$authStore.canManageEmployees()
    },
    isManager() {
      return this.$authStore.isManager()
    },
    isManagerMode() {
      return this.$authStore.state.me?.is_manager_mode_active === true
    },
    isMobile() {
      return this.small ? true : false
    },
    user() {
      return this.$authStore.state.me
    },
    isAnonymous() {
      if (this.$authStore.state.me && this.$authStore.state.me.is_anonymous) {
        return true
      }
      return false
    },
    logo() {
      return this.$customizationStore.getLogos()?.default
    },
    companyName() {
      return this.$customizationStore.getCompany()?.name
    },
  },

  methods: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    getName() {
      let me = this.$authStore.state.me
      if (me.first_name) {
        return this.capitalize(me.first_name.toLowerCase())
      } else if (me.last_name) {
        return this.capitalize(me.last_name.toLowerCase())
      } else if (me.email) {
        let temp = me.email.split("@")[0]
        return this.capitalize(temp.toLowerCase())
      }
      return " "
    },
    goToProfile() {
      this.$router.push({ name: "/member/profile" })
    },
    logout() {
      AuthLogic.logout().then(() => {
        const routeName = this.$router.currentRoute.name
        if (routeName && this.storeRoutes.includes(routeName)) {
          this.$router.push({ name: "store_main_home" })
        } else {
          window.location.href = this.$links.oppens_site
        }
      })
    },
    setDarkTheme(value) {
      AuthLogic.setDarkTheme(value)
    },
    onResize() {
      //950
      this.small = window.innerWidth <= 500 ? true : false
    },
    computeLogo() {},
    async setManagerMode(active) {
      this.isLoading = true
      try {
        const response = await CompanyLogic.setManagerMode(active)
      } catch (error) {
        this.displayAlert(error?.response?.data?.error?.message)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.testclass {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.marginLeft {
  margin-left: 4%;
}
.v-avatar {
  margin-right: 10px;
}

.nav-links {
  @media (max-width: 720px) {
    display: none;
    margin-left: 1%;
  }

  .v-btn {
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }

  .v-btn:before,
  .v-btn:hover:before,
  .v-btn:focus:before {
    background-color: white !important;
  }
}
.manager-dial {
  left: 0;
  top: 0;
}
:deep(.v-speed-dial__list) {
  align-items: flex-start;
}
</style>

import { clone } from "lodash"
import SentEmails from "@/assets/phishing/sent-emails.svg"
import ClickedLinks from "@/assets/phishing/clicked-links.svg"
import EnteredData from "@/assets/phishing/entered-data.svg"
import ReportedEmails from "@/assets/phishing/reported-emails.svg"
import PhishingSimulationLogic from "@/logics/PhishingSimulationLogic"
import DateTimeMixins from "@/mixins/DateTime.mixins.js"
import ChartsMixins from "@/mixins/Charts.mixins.js"
export default {
  mixins: [DateTimeMixins, ChartsMixins],
  data() {
    return {
      stats: [
        {
          label: this.$t("phishingSimulations.sentEmails"),
          icon: SentEmails,
          color: ["teal", "base"],
          attribute: "sent",
        },
        {
          label: this.$t("phishingSimulations.clickedLinks"),
          icon: ClickedLinks,
          color: ["yellow", "base"],
          attribute: "clicked",
        },
        {
          label: this.$t("phishingSimulations.enteredData"),
          icon: EnteredData,
          color: ["red", "base"],
          attribute: "submitted_data",
        },
        {
          label: this.$t("phishingSimulations.emailReported"),
          icon: ReportedEmails,
          color: ["green", "base"],
          attribute: "email_reported",
        },
      ],
      phishingSimulations: [],
      lastPhishingSimulations: [],
      phishingSummary: null,
      simulationsStatus: "in_progress",
      allSimulationsMaps: null,
      selectedSimulation: null,
      exerciceMode: "preview",
      phishingSimulationSummary: null,
    }
  },
  computed: {
    clickedLinks() {
      return ClickedLinks
    },
    enteredData() {
      return EnteredData
    },
  },
  methods: {
    showPreview(simulation) {
      this.exerciceMode = "preview"
      this.selectedSimulation = simulation
    },
    editPreview(simulation) {
      this.exerciceMode = "edit"
      this.selectedSimulation = simulation
    },
    closePreview() {
      this.selectedSimulation = null
    },
    isSimulationToCome(simulation) {
      return simulation.status === "created" && simulation.is_active === false
    },
    getSimulationStatusText(simulation) {
      if (simulation) {
        if (simulation.status === "created") {
          if (simulation.is_active === false) {
            return this.$t("phishingSimulations.toCome")
          } else {
            return this.$t("phishingSimulations.inProgress")
          }
        } else {
          return this.$t("phishingSimulations.ended")
        }
      } else {
        return ""
      }
    },
    async getLastPhishingSimulations(
      count,
      with_summary = 0,
      returnStats = false
    ) {
      this.lastPhishingSimulations = []
      if (this.companyUuid) {
        this.isLoading = true
        let simulationResult = await PhishingSimulationLogic.getAll(
          this.companyUuid,
          {
            last_number_simulations: count,
            with_employees: 0,
            with_results: 0,
            with_summary,
            "sorts[launch_date]": "asc",
          }
        )
        this.lastPhishingSimulations = simulationResult.simulations
        if (returnStats) {
          let chartData = {
            labels: [],
            datasets: [],
          }
          chartData.datasets.push({
            label: this.$t(`phishingSimulations.charts.clickedLinkPercentage`),
            data: [],
            xAxisID: "xMainAxis",
            borderColor: this.chartColors[this.CLICKED_LINK_MESSAGE],
            backgroundColor: this.chartColors[this.CLICKED_LINK_MESSAGE],
            tension: 0.1,
            fill: "origin",
          })
          chartData.datasets.push({
            label: this.$t(
              `phishingSimulations.charts.submittedDataPercentage`
            ),
            data: [],
            xAxisID: "xMainAxis",
            borderColor: this.chartColors[this.SUBMITTED_DATA_MESSAGE],
            backgroundColor: this.chartColors[this.SUBMITTED_DATA_MESSAGE],
            tension: 0.1,
            fill: "origin",
          })
          for (
            let simulationIndex = 0;
            simulationIndex < this.lastPhishingSimulations.length;
            simulationIndex++
          ) {
            const simulation = this.lastPhishingSimulations[simulationIndex]
            chartData.labels.push(
              `${simulation.name}|${this.getLocalDate(simulation.launch_date)}`
            )
            chartData.datasets[0].data.push(
              (simulation.summary.clicked / simulation.summary.total) * 100
            )
            chartData.datasets[1].data.push(
              (simulation.summary.submitted_data / simulation.summary.total) *
                100
            )
          }
          return chartData
        }
      }
    },
    async getPhishingSimulations() {
      if (this.companyUuid) {
        this.isLoading = true
        let simulationResult = await PhishingSimulationLogic.getAll(
          this.companyUuid,
          {
            status_simulation: "active",
            with_employees: 0,
            with_results: 0,
            with_summary: 1,
          }
        )
        let simulations = simulationResult.simulations
        if (simulations.length === 0) {
          simulationResult = await PhishingSimulationLogic.getAll(
            this.companyUuid,
            {
              status_simulation: "completed",
              last_number_campaigns: 1,
              with_employees: 0,
              with_results: 0,
              with_summary: 1,
            }
          )
          simulations = simulationResult.simulations
          this.simulationsStatus = "completed"
        }
        for (
          let simulationIndex = 0;
          simulationIndex < simulations.length;
          simulationIndex++
        ) {
          const simulation = simulations[simulationIndex]
          simulation.summaries = []
          for (let statIndex = 0; statIndex < this.stats.length; statIndex++) {
            const stat = clone(this.stats[statIndex])
            stat.rate = Math.floor(
              (100 * simulation?.summary[stat.attribute]) /
                simulation?.summary.total
            )
            simulation.summaries.push(stat)
          }
        }
        this.phishingSimulations = simulations
        this.phishingSummary = simulationResult.summary
      }
    },
    async getPhishingSimulationStats() {
      if (this.companyUuid) {
        const simulationResults = await PhishingSimulationLogic.getStats(
          this.companyUuid
        )
        this.allSimulationsMaps = simulationResults?.maps
      }
    },
    async getActiveSimulations() {
      this.isLoading = true
      let simulationResult = await PhishingSimulationLogic.getAll(
        this.companyUuid,
        {
          status_simulation: "active",
          with_employees: 0,
          with_results: 0,
          with_summary: 1,
        }
      )
      this.phishingSimulations = simulationResult.simulations
      for (
        let simulationIndex = 0;
        simulationIndex < this.phishingSimulations.length;
        simulationIndex++
      ) {
        const simulation = this.phishingSimulations[simulationIndex]
        simulation.summaries = []
        for (let statIndex = 0; statIndex < this.stats.length; statIndex++) {
          const stat = clone(this.stats[statIndex])
          stat.rate = Math.floor(
            (100 * simulation?.summary[stat.attribute]) /
              simulation?.summary.total
          )
          simulation.summaries.push(stat)
        }
      }
      this.phishingSimulationSummary = simulationResult.summary
    },
  },
}

// import Config from 'config/index';
import Model from "./Model"

class User extends Model {
  constructor(uri) {
    const headers = "OAuth"

    const attributes = {
      // client_id: {
      //   data: Config.API_CLIENT_ID,
      //   rules: 'required'
      // },
      // client_secret: {
      //   data: Config.API_CLIENT_SECRET,
      //   rules: 'required'
      // },
      first_name: {
        data: "",
      },
      last_name: {
        data: "",
      },
      email: {
        data: "",
        rules: "required|email",
      },
      full_name: {
        data: "",
        rules: "required",
      },
      password: {
        data: "",
        rules: "required",
      },
      password_confirmation: {
        data: "",
        rules: "required",
      },
    }

    super(attributes, uri, headers)
  }
}

export default User

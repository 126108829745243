import SessionStorage from "../services/SessionStorage"
import AuthRepository from "../repositories/AuthRepository"
import PageStore from "../stores/PageStore"
import AuthStore from "../stores/AuthStore"
import UserLogic from "./UserLogic"
import Model from "../models/Model"
import DashboardLogic from "./DashboardLogic"
import Fingerprint2 from "fingerprintjs2"
import { DateTime } from "luxon"
import LocaleLogic from "./LocaleLogic"
import { i18n } from "../plugins/i18n"
/* import AuthorizationStore from "../stores/AuthorizationStore" */

export default class AuthLogic {
  /**
   * @param body
   * @returns {Promise}
   */
  static register(body) {
    const lang = LocaleLogic.getBrowserLanguage()
    return AuthRepository.register({
      ...body,
      locale: lang,
      lang,
    })
  }

  /**
   *
   * @param body
   * @returns {Promise<Model>}
   */
  static async login(body) {
    AuthLogic.set2FAIsNeeded(false)
    const response = await AuthRepository.login({
      ...body,
      locale: LocaleLogic.getBrowserLanguage(),
    })
    if (response.status_code === 200) {
      const mustSend2faCode = response.responseObject().two_factor
      AuthLogic.set2FAIsNeeded(mustSend2faCode)
      AuthLogic.setTokens(response.responseObject())
      if (!mustSend2faCode) {
        DashboardLogic.updateDashboardObject()
        return await this.loadMe()
      }
      return { mustSend2faCode }
    }
  }

  /**
   *
   * @param body
   * @returns {Promise<Model>}
   */
  static async ssoLogin(body) {
    const response = await AuthRepository.ssoLogin({
      ...body,
    })
    const data = response.responseObject()
    window.location = data.url
  }

  static async loadMe() {
    const response = await AuthRepository.me({
      with_company_roles: true,
      with_statuses: true,
    })
    if (response.status_code === 200) {
      const user = response.dataObject()
      AuthLogic.setMe(user)
      /* const permissions = await UserLogic.getPermissions()
      AuthorizationStore.setPermissions(permissions) */
      return user
    } else {
      return null
    }
  }

  /**
   * @description store user in class memory & local storage
   */
  static setMe(user) {
    i18n.locale = user.lang
    AuthLogic.computeName(user)
    AuthStore.setMe(user)
    SessionStorage.set("me", user)
  }

  /**
   * @description compute full name & initials
   */
  static computeName(user) {
    const firstName = user.first_name
    const lastName = user.last_name
    const email = user.email

    if (firstName && lastName) {
      user.full_name = firstName + " " + lastName
      user.initials = firstName.substr(0, 1) + lastName.substr(0, 1)
    } else if (lastName) {
      user.full_name = lastName
      user.initials = lastName.substr(0, 2)
    } else if (firstName) {
      user.full_name = firstName
      user.initials = firstName.substr(0, 2)
    } else if (email) {
      let emailSplit = email.split("@")[0]
      user.full_name = emailSplit
      user.initials = emailSplit.substr(0, 2)
    }
  }

  /**
   */
  static async refreshToken(refreshToken) {
    this.deleteRefreshToken()
    const response = await AuthRepository.refreshToken(refreshToken)
    AuthLogic.setTokens(response.responseObject())
    return response.responseObject()
  }

  static deleteRefreshToken() {
    let token = SessionStorage.get("token")
    delete token.refresh_token
    AuthLogic.setTokens(token)
  }

  /**
   * @returns {Promise<boolean>}
   */
  static async logout() {
    if (this.getTokens()) {
      await AuthRepository.logout()
      PageStore.showDrawer = false
    }

    this.removeMe()
    this.removeTokens()
    this.removeDarkTheme()

    return true
  }

  /**
   * @returns  {Promise<string>}
   */
  static async forgotPassword(email) {
    const response = await AuthRepository.forgotPassword({
      email: email,
      locale: LocaleLogic.getBrowserLanguage(),
    })
    return response.responseObject()
  }

  static async resetPassword(email) {
    const response = await AuthRepository.resetPassword({
      email: email,
      locale: LocaleLogic.getBrowserLanguage(),
    })
    return new Model(response.dataObject())
  }

  /**
   * @returns {Model}
   */
  static me() {
    return new Model(SessionStorage.get("me"))
  }

  /**
   * @returns void
   */
  static removeMe() {
    SessionStorage.remove("me")
    AuthStore.reset()
  }

  /**
   *
   * @returns {boolean}
   */
  static hasAccessToken() {
    return !!this.getTokens()
  }

  /**
   * @param token
   */
  static setTokens(token) {
    token.expireDate = DateTime.now()
      .plus({ seconds: token.expires_in })
      .toJSDate()
    SessionStorage.set("token", token)
    AuthStore.setToken(token)
  }

  /**
   * @returns {string}
   */
  static getTokens() {
    return SessionStorage.get("token")
  }

  /**
   * @returns {string}
   */
  static removeTokens() {
    SessionStorage.remove("token")
    AuthStore.state.token = null
  }

  /**
   * @param needs2FA
   */
  static set2FAIsNeeded(needs2FA) {
    SessionStorage.set("needs2FA", needs2FA)
  }

  /**
   * @returns {boolean}
   */
  static get2FAIsNeeded() {
    return SessionStorage.get("needs2FA")
  }

  /**
   * @returns {string}
   */
  static isDarkTheme() {
    return SessionStorage.get("dark-theme")
  }

  /**
   * @returns {string}
   */
  static setDarkTheme(value) {
    PageStore.dark = value
    SessionStorage.set("dark-theme", value)
  }

  /**
   * @returns {string}
   */
  static removeDarkTheme() {
    PageStore.dark = false
    SessionStorage.remove("dark-theme")
  }

  /* @note:  password rules
                                                                                      Which means  the following
                                                                                      Should have At least one Uppercase letter.
                                                                                      At least one Lower case letter.
                                                                                      Also,At least one numeric value.
                                                                                      And, At least one special character.
                                                                                      Must be more than 10 characters long. 
                                                                                      */
  static async getBodyWithFingerPrint() {
    let fingerprint = await this.getFingerprint()
    let password = "Oppens@" + fingerprint
    let body = {
      email: `visitor_${fingerprint}@unknown.net`,
      password: password,
      password_confirmation: password,
      is_anonymous: true,
      fingerprint,
    }

    return body
  }

  static async getFingerprint() {
    // eslint-disable-next-line no-undef
    let components = await Fingerprint2.getPromise(this.fpOptions)
    const values = components.map((component) => component.value)
    // eslint-disable-next-line no-undef
    return Fingerprint2.x64hash128(values.join(""), 31)
  }

  /** @description build an anonymous user and try to create it  - if denied try to login
   * @returns  true if success false if failure */
  static async createAnonymousUserAndLogin() {
    let body = await this.getBodyWithFingerPrint()
    body.lang = LocaleLogic.getBrowserLanguage()
    try {
      await UserLogic.createOne(body)
    } catch (error) {
      // console.error("could not create anonymous user ", error.response.status)
    }

    try {
      await AuthLogic.login({
        username: body.email,
        password: body.password,
        email: body.email,
      })
    } catch (error) {
      if (
        error &&
        Object.prototype.hasOwnProperty.call(error, "response") &&
        error.response &&
        error.response.status !== 422
      ) {
        return false
      } else if (
        error &&
        Object.prototype.hasOwnProperty.call(error, "response") &&
        error.response &&
        error.response.status == 422
      ) {
        return false
      }
    }

    return true
  }
}

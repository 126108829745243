<template>
  <v-card elevation="0">
    <op-alert ref="opAlert" color="red" />
    <v-data-table
      :items="courses"
      @click:row="gotoCourse"
      :headers="headers"
      dense
      :search="search"
      fixed-header
      :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
      :items-per-page="itemsPerPage"
      :loading="isLoading"
      class="px-4 table-with-clickable-rows"
    >
      <template v-slot:top>
        <div>
          <v-container>
            <v-row justify="end"
              ><v-col cols="12" sm="4" class="d-flex justify-end"
                ><div
                  class="text-h4"
                  v-html="$tc('courses.totalNumber', courses.length)"
                ></div
              ></v-col>
            </v-row>
          </v-container>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              :label="$t('courses.searchLabel')"
              single-line
              hide-details
              solo
              flat
              clearable
              dense
              class="text-body-1"
          /></v-toolbar>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <img :src="getCourseStatusImage(item)" :title="getCourseStatus(item)" />
      </template>
      <template v-slot:[`item.start_date`]="{ item }">{{
        getLocalDate(item?.start_date)
      }}</template>
      <template v-slot:[`item.summary.success_percentage`]="{ item }">
        <div class="d-inline-flex justify-center align-center">
          <img
            class="summary-icon mr-2"
            :src="getCourseSuccessImage(item.summary)"
          />
          <div>{{ `${item.summary.success_percentage}%` }}</div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CourseMixins from "@/mixins/Course.mixins"
import CourseLogic from "@/logics/CourseLogic"
import TableMixins from "@/mixins/Table.mixins.js"
import DateTimeMixins from "@/mixins/DateTime.mixins.js"
import AlertMixin from "@/mixins/Alert.mixins.js"
import Active from "@/assets/active.svg"
import Ended from "@/assets/ended.svg"
import Warning from "@/assets/warning.svg"
export default {
  name: "op-course-list",
  mixins: [CourseMixins, TableMixins, DateTimeMixins, AlertMixin],
  props: {
    companyUuid: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    this.getCourses()
  },
  watch: {
    companyUuid(newCompanyUuid, oldCompanyUuid) {
      this.getCourses()
    },
  },
  data() {
    return {
      courses: [],
      isLoading: false,
      headers: [
        {
          text: this.$t("courses.status"),
          value: "status",
          sortable: true,
          cellClass: "text-body-1",
          class: this.headerClass,
        },
        {
          text: this.$t("courses.start_date"),
          value: "start_date",
          sortable: true,
          cellClass: "text-body-1",
          class: this.headerClass,
        },
        {
          text: this.$t("courses.type"),
          value: "name",
          sortable: true,
          cellClass: "text-body-1",
          class: this.headerClass,
        },
        {
          text: this.$t("courses.employees_count"),
          value: "summary.employees_count",
          sortable: true,
          cellClass: "text-body-1",
          class: this.headerClass,
          align: "right",
        },
        {
          text: this.$t("courses.success"),
          value: "summary.success_percentage",
          sortable: true,
          cellClass: "text-body-1",
          class: this.headerClass,
          align: "center",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          class: this.headerClass,
        },
      ],
    }
  },
  methods: {
    async getCourses() {
      if (this.companyUuid) {
        try {
          this.isLoading = true
          const courseResults = await CourseLogic.getAll(this.companyUuid, {
            with_employees: 0,
            with_results: 0,
            with_summary: 1,
          })
          this.courses = courseResults.company_courses
        } catch (error) {
          this.displayAlert(error?.response?.data?.error?.message)
        } finally {
          this.isLoading = false
        }
      }
    },
    gotoCourse(item) {
      this.$router.push({
        name: "member-course",
        params: { courseId: item.id },
      })
    },
    getCourseStatusImage(course) {
      if (course) {
        if (course.is_active === true) {
          return Active
        } else {
          if (course.is_stopped === true) {
            return Ended
          } else {
            return Warning
          }
        }
      } else {
        return Ended
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.summary-icon {
  width: 21px;
}
</style>

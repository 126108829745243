<template>
  <svg
    width="100%"
    viewBox="0 -10 200 200"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M70,0 C98.1831908,0 121.763092,5.2557403 127.708694,12.292285 C134.74426,18.2369081 140,41.8168092 140,70 C140,98.1831908 134.74426,121.763092 127.707715,127.708694 C121.763092,134.74426 98.1831908,140 70,140 C41.8168092,140 18.2369081,134.74426 12.2913065,127.707715 C5.2557403,121.763092 -5.37786595e-14,98.1831908 -5.68434189e-14,70 C-5.99081782e-14,41.8168092 5.2557403,18.2369081 12.292285,12.2913065 C18.2369081,5.2557403 41.8168092,0 70,0 Z"
        id="path-1"
      ></path>
    </defs>

    <g transform="translate(-300.000000, -457.000000)">
      <g transform="translate(0.000000, -235.000000)">
        <g transform="translate(330.000000, 666.000000)">
          <g id="Group-3" transform="translate(0.000000, 46.000000)">
            <use fill="#0383B4" xlink:href="#path-1"></use>
            <text id="2" font-size="70" font-weight="normal" fill="#FFFFFF">
              <tspan v-if="level < 10" x="51.534668" y="102">
                {{ level }}
              </tspan>
              <tspan
                v-else-if="level >= 10 && level < 100"
                x="31.534668"
                y="102"
              >
                {{ level }}
              </tspan>
              <tspan v-else-if="level >= 100" x="13.534668" y="102">
                {{ v }}
              </tspan>
            </text>
            <text
              id="niveau"
              mask="url(#mask-2)"
              font-size="12"
              font-weight="normal"
              letter-spacing="0.692307692"
              fill="#FFFFFF"
            >
              <tspan x="47.4303636" y="42">
                {{ $t("level").toUpperCase() }}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "op-category-level-card",

  props: {
    level: {
      type: Number,
      validator: value => value > 0 && value < 1000,
    },
  },
}
</script>

<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 62 62"
      :width="size"
      :height="size"
      :filter="shadow"
    >
      <defs>
        <filter id="shadow">
          <feDropShadow
            dx="0"
            dy="0"
            stdDeviation="10"
            flood-color="#00040a"
            flood-opacity="0.19"
          />
        </filter>
        <linearGradient
          :id="'level-gradient-' + _uid"
          x1="1"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="0%" :stop-color="gradient[0]" />
          <stop offset="100%" :stop-color="gradient[1]" />
        </linearGradient>
      </defs>
      <path
        :fill="backgroundValue"
        fill-rule="evenodd"
        :stroke="strokeColor"
        :stroke-width="strokeWidth"
        d="M31 3c11.273 0 20.704 2.102 23.083 4.917C56.898 10.296 59 19.727 59 31c0 11.273-2.102 20.704-4.917 23.083C51.704 56.898 42.273 59 31 59c-11.273 0-20.704-2.102-23.083-4.917C5.102 51.704 3 42.273 3 31 3 19.727 5.102 10.296 7.917 7.917 10.296 5.102 19.727 3 31 3z"
      />
      <text
        :class="{ 'level-text': true, 'level-text-shadow': enableTextShadow }"
        x="31"
        y="31"
        text-anchor="middle"
      >
        {{ textContent }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "op-level-item",
  props: {
    backgroundColor: {
      type: String,
      default() {
        return "#eef2f6"
      },
    },
    size: {
      type: Number,
      required: false,
      default() {
        return 40
      },
    },
    enableShadow: {
      type: Boolean,
      required: false,
      default() {
        return true
      },
    },
    enableTextShadow: {
      type: Boolean,
      required: false,
      default() {
        return true
      },
    },
    textContent: {
      type: String,
      required: true,
      default() {
        return "0"
      },
    },
    strokeWidth: {
      type: Number,
      required: false,
      default() {
        return 5
      },
    },
    strokeColor: {
      type: String,
      required: false,
      default() {
        return "white"
      },
    },
    gradient: {
      type: Array,
      required: false,
      default() {
        return ["#000", "#000"]
      },
    },
  },
  computed: {
    shadow() {
      if (this.enableShadow) {
        return "url(#shadow)"
      } else {
        return ""
      }
    },
    backgroundValue() {
      if (this.gradient[0] != "#000") {
        return "url(#level-gradient-" + this._uid + ")"
      } else {
        return this.backgroundColor
      }
    },
  },
}
</script>

<style lang="scss">
.level-text {
  font-size: 1.548em;
  font-weight: bold;
  fill: white;
  dominant-baseline: middle;

  &.level-text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.29);
  }
}
</style>

<template>
  <v-app>
    <v-navigation-drawer
      :mini-variant="$vuetify.breakpoint.lgAndUp && !PageStore.drawer"
      :permanent="$vuetify.breakpoint.lgAndUp"
      v-model="PageStore.drawer"
      width="300"
      class="elevation-1"
      app
    >
      <template v-slot:prepend>
        <v-list-item dense class="my-3" :class="{ 'ml-3': PageStore.drawer }">
          <v-list-item-content v-if="PageStore.drawer">
            <v-img
              contain
              alt="Oppens logo"
              :src="require('../assets/oppens-logo-white.png')"
            />
          </v-list-item-content>

          <v-list-item-action>
            <v-app-bar-nav-icon
              color="primary"
              class="ml-0"
              @click.stop="PageStore.drawer = !PageStore.drawer"
            >
            </v-app-bar-nav-icon>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-list dense id="drawer-list" class="pt-0">
        <v-list-item-group v-model="model">
          <v-list-item
            class="px-7"
            v-for="(item, key) in drawerLink"
            :key="key"
            link
            active-class="primary font-weight-bold"
            :to="item.route"
          >
            <template v-slot:default="{}">
              <v-list-item-action>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title
                  :class="
                    !$vuetify.theme.dark ? 'blue-dark--text' : 'white--text'
                  "
                >
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>


    <op-toolbar
      isAdmin
      :showBurger="!$vuetify.breakpoint.lgAndUp"
      :showLogo="!$vuetify.breakpoint.lgAndUp"
      app
    >
    </op-toolbar>

    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<style lang="scss" scoped>
#drawer-list {
  .v-list-item--active {
    color: white !important;
    caret-color: white !important;

    .primary--text {
      color: inherit !important;
      caret-color: inherit !important;
    }

    .v-list-item__title {
      caret-color: white !important;
      color: white !important;
      font-weight: bold !important;
    }
  }
}
</style>

<script>
import PageStore from "@/stores/PageStore"

export default {
  name: "admin-layout",

  components: {},

  data: () => ({
    drawerLink: [
      {
        text: "Tableau de bord",
        icon: "mdi-home-outline",
        route: {
          name: "admin-dashboard",
        },
      },
      {
        text: "Gestion des utilisateurs",
        icon: "mdi-account-group-outline",
        route: {
          name: "admin-users",
        },
      },
      {
        text: "Gestion des categories",
        icon: "mdi-tag-outline",
        route: {
          name: "admin-categories-list",
        },
      },
    ],
    PageStore,
    model: null,
  }),
}
</script>

import EmployeeRepository from "../repositories/EmployeeRepository"
//import EmployeeStore from "../stores/EmployeeStore"

let isLoadingAll = false

export default class EmployeeLogic {
  /**
   *
   * @param companyUuid
   * @returns {Promise}
   */
  static async getAll(companyUuid, params = {}) {
    if (!isLoadingAll) {
      isLoadingAll = true
      try {
        const res = await EmployeeRepository.getAll(companyUuid, params)
        let employeeList = res?.response?.data
        //EmployeeStore.setList(employeeList || [])
        return employeeList
      } catch (e) {
        //EmployeeStore.setList([])
        return e.message
      } finally {
        isLoadingAll = false
      }
    }
  }

  /**
   *
   * @param companyUuid
   * @param employeeId
   * @returns {Promise}
   */
  static async getOne(companyUuid, employeeId) {
    const response = await EmployeeRepository.getOne(companyUuid, employeeId)
    return response.dataObject()
  }

  /**
   * @param companyUuid
   * @param body
   * @returns {Promise}
   */
  static async createOne(companyUuid, body = {}) {
    const response = await EmployeeRepository.createOne(companyUuid, body)
    return response.dataObject()
  }

  /**
   * @param companyUuid
   * @param list
   * @param mode
   * @returns {Promise}
   */
  static async createList(companyUuid, list = [], mode) {
    const response = await EmployeeRepository.createList(
      companyUuid,
      list,
      mode
    )
    return response.dataObject()
  }

  /**
   * @param companyUuid
   * @param list
   * @returns {Promise}
   */
  static async activateList(companyUuid, list = []) {
    const response = await EmployeeRepository.activateList(companyUuid, list)
    return response
  }

  /**
   * @param companyUuid
   * @param list
   * @returns {Promise}
   */
  static async deactivateList(companyUuid, list = []) {
    const response = await EmployeeRepository.deactivateList(companyUuid, list)
    return response
  }

  /**
   * @param companyUuid
   * @param body
   * @returns {Promise}
   */
  static async updateOne(companyUuid, body = {}) {
    const response = await EmployeeRepository.updateOne(companyUuid, body)
    return response.dataObject()
  }

  /**
   * @param companyUuid
   * @param body
   * @returns {Promise}
   */
  static async deleteOne(companyUuid, userId) {
    const response = await EmployeeRepository.deleteOne(companyUuid, userId)
    return response
  }
}

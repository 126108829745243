<template>
  <v-row diagnostic-v-tabs justify="center" :class="color">
    <v-col md="10" class="ma-0 pa-0 max-width-1050">
      <v-tabs
        fixed-tabs
        height="67"
        v-model="currentTab"
        show-arrows
        hide-slider
        color="black"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.id"
          :to="tab.route"
          active-class="font-weight-bold"
          class="text-none no-letter-spacing mx-5 transparent"
          :ripple="false"
        >
          <v-progress-linear
            color="primary"
            class="diagnostic-v-tabs-progress-linear"
            background-color="#DFE5EC"
            height="5"
            width="80%"
            :value="tab.score"
          >
          </v-progress-linear>
          {{ tab.text }}
        </v-tab>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.row[diagnostic-v-tabs] {
  .v-tabs-bar {
    background-color: transparent !important;
  }

  .v-tab {
    &:hover {
      &::before {
        opacity: 0 !important;
      }
      color: #009ea6 !important;
      background-color: transparent !important;
    }
  }

  .diagnostic-v-tabs-progress-linear {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &.v-progress-linear {
      .v-progress-linear__background {
        opacity: 0.5 !important;
      }

      position: absolute;
      top: 0;
    }
  }
}
</style>

<script>
export default {
  name: "op-diagnostic-tabs",

  props: {
    value: String,
    color: {
      type: String,
      default() {
        return "transparent"
      },
    },
    tabs: {
      type: Array,
    },
  },

  computed: {
    currentTab: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
</script>

<template>
  <div
    v-on:click.stop="showTheDialog()"
    :class="['layout-col', 'small-product-card', { 'bigger-card': biggerCard }]"
  >
    <div class="product-header">
      <div class="icon">
        <img :src="product.attributes.logo" />
      </div>

      <div class="infos">
        <div class="title">
          {{ product.attributes.name }}
        </div>

        <div class="price" v-if="displayPrice">
          {{ priceText }}
        </div>
      </div>

      <!--- DIALOG -->

      <v-dialog
        v-if="openInModal"
        v-model="showDialog"
        max-width="1180"
        scrollable
        :fullscreen="modalIsFullScreen"
        :hide-overlay="modalIsFullScreen"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            dark
            :small="true"
            :min-width="buttonMinWidth"
            :min-height="40"
            :class="[
              'text-none',
              'font-weight-bold',
              'body-1',
              'ml-auto',
              { 'icon-btn': accessLabel == '' },
              { 'd-none': showButtonAtBottom },
            ]"
            v-on="on"
            elevation="8"
          >
            {{ accessLabel }}
            <v-icon right size="26">mdi-chevron-right</v-icon>
          </v-btn>
        </template>

        <div class="d-flex flex-column white">
          <v-btn
            text
            icon
            color="steel"
            x-small
            class="align-self-end mt-2 mr-2"
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <view-store-product
            :productId="product.id"
            :isInModal="true"
          ></view-store-product>
        </div>
      </v-dialog>
      <!--- / DIALOG -->

      <v-btn
        v-else
        color="primary"
        dark
        :small="true"
        :min-width="buttonMinWidth"
        :min-height="40"
        :class="[
          'text-none',
          'font-weight-bold',
          'body-1',
          'ml-auto',
          { 'icon-btn': accessLabel == '' },
          { 'd-none': showButtonAtBottom },
        ]"
        @click="selectProduct"
        elevation="8"
      >
        {{ accessLabel }}
        <v-icon right size="26">mdi-close</v-icon>
      </v-btn>
    </div>

    <v-btn
      color="primary"
      dark
      :small="true"
      block
      :min-width="buttonMinWidth"
      :min-height="40"
      :class="[
        'text-none',
        'font-weight-bold',
        'body-1',
        'mt-6',
        { 'icon-btn': accessLabel == '' },
        { 'd-none': !showButtonAtBottom },
      ]"
      :to="{ name: 'store_product', params: { productId: product.id } }"
      elevation="8"
    >
      {{ accessLabel }}
      <v-icon right size="26">mdi-close</v-icon>
    </v-btn>

    <div class="preview" v-if="mainImage && displayImage">
      <img :src="mainImage" />
    </div>
  </div>
</template>

<style lang="scss">
.small-product-card {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 4, 10, 0.09);
  margin: 0 !important;
  padding: 16px 22px 16px;
  box-sizing: border-box;
  max-width: 455px;
  min-height: 96px;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 4, 10, 0.09);
  background-color: white;

  .preview {
    width: 100%;
    margin-top: 10px;

    img {
      width: 100%;
    }
  }

  .product-slider {
    margin-top: 22px;
    height: auto;

    .slider-element {
      background-color: $ice-blue;
    }

    .product-slider-description {
      font-size: 0.857em;
      line-height: 1.67;
      text-align: center;
      color: $steel;
      margin-bottom: 20px;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .product-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      border-radius: 6px;
      width: 64px;
      height: 64px;
      margin-right: 21px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .infos {
      display: flex;
      flex-basis: auto;
      flex-direction: column;
      margin-right: 20px;

      .title {
        font-family: Montserrat;
        font-size: 18px !important;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal !important;
        color: $marine-blue;
      }

      .editor {
        color: $steel;
        line-height: 1.43;
        text-transform: uppercase;
      }

      .price,
      .product-buy-info {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $teal-blue;
      }
    }

    .icon-btn i {
      margin: 0;
    }

    .more {
      display: flex;
      cursor: pointer;
      background-color: $topaz;
      height: 40px;
      margin-top: 5px;
      margin-left: auto;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      transition: all 0.4s ease;
      color: white;
      position: relative;

      &:hover {
        background-color: $marine-blue;

        &:after {
          transform: translate(0px, 3px) rotate(-45deg);
          width: 10px !important;
        }
        &:before {
          transform: translate(0px, -3px) rotate(52deg);
          width: 10px !important;
        }
      }

      &:not(.bigger) {
        flex: 0 0 40px;
      }

      &.bigger {
        padding: 11px 40px 10px 12px;

        &:before,
        &:after {
          width: 16px;
          height: 2px;
          position: absolute;
          right: 14px;
        }
      }

      &:before,
      &:after {
        content: "";
        width: 14px;
        height: 2px;
        display: flex;
        background: white;
        position: absolute;
        border-radius: 2px;
        transition: all 0.3s ease-in;
      }

      &:before {
        transform: rotate(90deg);
      }
    }

    .description {
      margin-top: 20px;
      flex-basis: 100%;
      color: $steel;
      line-height: 1.43;
    }
  }
}
</style>

<script>
import ViewStoreProduct from "../../views/member/store/ViewStoreProduct.vue"
import mixin from "@/mixins/Products.mixins.js"

export default {
  name: "op-small-product-card",
  components: {
    ViewStoreProduct,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    displayPrice: {
      type: Boolean,
      default() {
        return true
      },
    },
    displayBuyInfo: {
      type: Boolean,
      default() {
        return false
      },
    },
    displayImage: {
      type: Boolean,
      default() {
        return false
      },
    },
    biggerCard: {
      type: Boolean,
      default() {
        return false
      },
    },
    openInModal: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  mixins: [mixin],
  data() {
    return {
      showDialog: false,
    }
  },
  computed: {
    mainImage() {
      if (this.product.attributes.media) {
        return this.product.attributes.media[0].url_large
      }
      return null
    },
    priceText() {
      if (this.product.attributes.buy_button_label) {
        return this.product.attributes.buy_button_label.split("-")[0].trim()
      } else {
        return (
          this.$options.filters.monetize(
            this.product.attributes.pricing.amount
          ) + " HT"
        )
      }
    },
    accessLabel() {
      if (this.biggerAddButton) {
        return this.$i18n.t("store.product.access")
      } else {
        return ""
      }
    },
    buttonMinWidth() {
      return this.biggerAddButton ? 50 : 40
    },
    showButtonAtBottom() {
      return (
        (this.$vuetify.breakpoint.name == "xs" ||
          this.$vuetify.breakpoint.name == "sm") &&
        this.accessLabel != ""
      )
    },
    filteredMedias() {
      if (this.product.attributes.media) {
        return this.product.attributes.media.filter(
          (media) => media.type == "screenshot"
        )
      } else {
        return []
      }
    },
    modalIsFullScreen() {
      return (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      )
    },
  },
  methods: {
    showTheDialog() {
      this.showDialog = true
    },
    selectProduct() {
      this.$gtmLogic.clickProductRecommendation(this.product, null)
      this.$router.push({
        name: "store_product",
        params: { productId: this.getProductId(this.product) },
      })
    },
  },
}
</script>

<template>
  <div class="tip-video-thumbnail d-flex">
    <div
      class="video-background-thumbnail"
      :style="{
        background: computedThumbnailUrl,
      }"
    ></div>

    <a
      class="tip-video-play"
      v-if="href != ''"
      :href="href"
      target="_blank"
      :style="{ width: buttonSize + 'px', height: buttonSize + 'px' }"
    >
      <img :src="playImage" />
    </a>

    <div v-else class="ma-auto d-flex align-center justify-center">
      <img :src="thumbnail" />
      <div class="mx-8 grey custom-divider"></div>
      <div class="dark-indigo--text body-1 font-weight-bold coming-soon-text">
        DISPONIBLE PROCHAINEMENT
      </div>
    </div>
  </div>
</template>

<script>
import PlayImage from "../../assets/play.png"
import Thumbnail from "../../assets/oppens-logo-player.jpg"

export default {
  name: "op-video-thumbnail",
  data() {
    return {
      playImage: PlayImage,
      thumbnail: Thumbnail,
    }
  },
  props: {
    href: {
      type: String,
      required: true,
    },
    buttonSize: {
      type: Number,
      required: false,
      default() {
        return 86
      },
    },
    thumbnailUrl: {
      type: String,
    },
  },
  computed: {
    computedThumbnailUrl() {
      return this.thumbnailUrl
        ? "url(" + this.thumbnailUrl + ") center / contain no-repeat"
        : "white"
    },
  },
}
</script>

<style lang="scss">
.custom-divider {
  width: 2px;
  height: 20px;
}
.coming-soon-text {
  opacity: 0.4;
}
.tip-video-thumbnail {
  position: relative;
  background-color: white;
  border-radius: 6px;

  .video-background-thumbnail {
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  .tip-video-play {
    background-color: $topaz;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 30%;
      height: 29%;
      filter: invert(1);
      transform: translateX(3px);
    }
  }
}
</style>

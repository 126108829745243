import {
  interpolateRainbow,
  interpolateReds,
  interpolateYlOrBr,
} from "d3-scale-chromatic"

export default {
  data() {
    return {
      chartColors: {},
      chartData: {
        labels: [],
        datasets: [],
      },
    }
  },
  created() {
    this.EMAIL_SENT_MESSAGE = "Email Sent"
    this.CLICKED_LINK_MESSAGE = "Clicked Link"
    this.SUBMITTED_DATA_MESSAGE = "Submitted Data"
    this.EMAIL_OPENED = "Email Opened"
    this.NB_SESSIONS = "nbSessions"
    this.chartColors[this.EMAIL_SENT_MESSAGE] = "#009BA4"
    this.chartColors[this.CLICKED_LINK_MESSAGE] = this.chartColors[
      this.NB_SESSIONS
    ] = "#FACE32"
    this.chartColors[this.SUBMITTED_DATA_MESSAGE] = "#B50013"
    this.chartColors[this.EMAIL_OPENED] = "#1AC8AA"
  },
  methods: {
    getChartColors(count, colorScale = interpolateRainbow, reverse = false) {
      let colorStart = 0.2
      let colorEnd = 0.8
      let colorRange = colorEnd - colorStart
      let intervalSize = colorRange / count
      let i, colorPoint
      let colorArray = []

      for (i = 0; i < count; i++) {
        colorPoint = reverse
          ? colorEnd - i * intervalSize
          : colorStart + i * intervalSize
        colorArray.push(colorScale(colorPoint))
      }

      return colorArray
    },
    getEventChartData(mappingNames, statMaps) {
      const eventNames = [
        { field: "clicked", colors: interpolateYlOrBr },
        { field: "submitted", colors: interpolateReds },
      ]
      let comparisonChartData = {}
      for (const key in statMaps) {
        // key will be entity, job, city, country or department
        comparisonChartData[key] = {
          clicked: {
            labels: [],
            datasets: [{ backgroundColor: [], data: [] }],
          },
          submitted: {
            labels: [],
            datasets: [{ backgroundColor: [], data: [] }],
          },
        }
        if (mappingNames.includes(key)) {
          const mainElement = statMaps[key]
          let nameCount = 0
          for (const mainName in mainElement) {
            // mainName will be a value for entity, job, city, country or department
            if (Object.hasOwnProperty.call(mainElement, mainName)) {
              const stats = mainElement[mainName]
              nameCount++
              for (
                let eventIndex = 0;
                eventIndex < eventNames.length;
                eventIndex++
              ) {
                const eventName = eventNames[eventIndex]
                comparisonChartData[key][eventName.field].labels.push(
                  mainName === "none"
                    ? this.$t("campaigns.charts.undefined")
                    : mainName
                )
                comparisonChartData[key][eventName.field].datasets[0].data.push(
                  stats[eventName.field]
                )
              }
            }
          }
          for (
            let eventIndex = 0;
            eventIndex < eventNames.length;
            eventIndex++
          ) {
            const eventName = eventNames[eventIndex]
            comparisonChartData[key][
              eventName.field
            ].datasets[0].backgroundColor = this.getChartColors(
              nameCount,
              eventName.colors
            )
          }
        }
      }
      return comparisonChartData
    },
  },
}

<template>
  <div class="tip-box text-h3 dark-sea-green--text font-weight-light">
    <img :src="lightBulbImage" />

    {{ $t("store.product.tipsDescription") }}
  </div>
</template>

<script>
import LightBulbImage from "../../assets/lightbulb.svg"
import PlayImage from "../../assets/play.png"

export default {
  name: "op-tip-card",
  data() {
    return {
      lightBulbImage: LightBulbImage,
      playImage: PlayImage,
    }
  },
  props: {
    showButton: {
      type: Boolean,
      required: false,
      default() {
        return true
      },
    },
  },
}
</script>

<style lang="scss">
.tip-box {
  border-radius: 4px;
  background-color: rgba(26, 200, 170, 0.08);
  padding: 1em 0.75em 1em 1.563em;
  margin-top: 1.786em;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  position: relative;

  > img {
    margin-right: 1.571em;
    width: 1.786em;
  }

  .tip-box-button {
    margin-left: 2.214em;
  }
}
</style>

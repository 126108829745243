import Request from "../services/Request"

export default class ProductRepository {
  /* All */

  /**
   * @returns {Promise}
   */
  static getAll(params) {
    return Request.make("GET", "/products", { params })
  }

  /**
   * @returns {Promise}
   */
  static getByCategoryId(categoryId, params) {
    return Request.make("GET", `/categories/${categoryId}/products`, { params })
  }

  /**
   * @returns {Promise}
   */
  static getByPackId(packId, params) {
    return Request.make("GET", `/packs/${packId}/products`, { params })
  }

  /**
   * @returns {Promise}
   */
  static getOne(uuid, params = {}) {
    return Request.make("GET", `/products/${uuid}`, { params })
  }

  /**
   * @returns {Promise}
   */
  static createOne(uuid, body = {}) {
    return Request.make("POST", `/products`, body)
  }

  /**
   * @returns {Promise}
   */
  static updateOne(uuid, body = {}) {
    return Request.make("PUT", `/products/${uuid}`, body)
  }

  static getPricing(body) {
    return Request.make("POST", `/pricing`, body)
  }
}

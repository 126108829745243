<template>
  <ValidationProvider
    :mode="mode"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
    ref="myinput"
  >
    <v-text-field
      v-bind="$attrs"
      v-on="$listeners"
      v-model="model"
      ref="textField"
      :error-messages="errors"
      :color="$attrs.hasOwnProperty('color') ? $attrs.color : 'blue-dark'"
      :outlined="$attrs.hasOwnProperty('outlined') ? $attrs.outlined : true"
      :floating="$attrs.hasOwnProperty('floating') ? $attrs.floating : true"
      :dense="$attrs.hasOwnProperty('dense') ? $attrs.dense : true"
    >
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

      <!-- Pass on all scoped slots -->
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate"

export default {
  name: "op-text-field",

  components: {
    ValidationProvider,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: "aggressive",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      //type: [String, Number],
      required: true,
    },
  },
  methods: {
    focus() {
      this.$refs.textField.focus()
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", {
          value: value,
          name: this.name,
        })
      },
    },
  },
}
</script>

<template>
  <div class="pack-product-card d-flex flex-column">
    <div class="pack-product-header">
      <div class="icon">
        <img :src="product.attributes.logo" />
      </div>

      <h3 class="text-h3 font-weight-bold dark-indigo--text">
        {{ product.attributes.name }}
      </h3>
    </div>

    <p class="pack-product-description steel--text text-body-1">
      {{ product.attributes.description }}
    </p>

    <div class="pack-product-category text-h3 font-weight-bold steel--text">
      {{ categoryName }}
    </div>

    <div class="text-h4 steel--text">
      {{
        $t("store.pack.seed_up_to_level", {
          productName: product.attributes.name,
          nextLevel: product.attributes.main_score,
        })
      }}
    </div>

    <op-level-group :level="product.attributes.main_score"></op-level-group>

    <op-video-thumbnail
      v-if="instructionalVideo && instructionalVideo.exist"
      :href="instructionalVideo.url_large"
      :thumbnailUrl="instructionalVideo.url_thumbnail"
      :buttonSize="56"
    ></op-video-thumbnail>

    <div
      v-if="instructionalVideo && instructionalVideo.exist"
      class="pack-product-functions dark-indigo--text text-h3 font-weight-medium"
    >
      {{ instructionalVideo.title }}
    </div>

    <router-link
      class="pack-product-know-more topaz--text text-body-1 font-weight-bold"
      :to="{
        name: 'store_product',
        params: { productId: getProductId(product) },
      }"
      >{{
        $t("store.pack.knowMore", { productName: product.attributes.name })
      }}</router-link
    >
  </div>
</template>

<script>
import mixin from "@/mixins/Products.mixins.js"
import CategoryLogic from "../../logics/CategoryLogic"

export default {
  name: "op-pack-product-card",
  data() {
    return {
      instructionalVideo: null,
      categoryName: null,
    }
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  mixins: [mixin],
  methods: {
    setProductCategoryName: async function () {
      let model = await CategoryLogic.getOne(
        this.product.relationships.categories.data[0].id
      )
      this.categoryName = model.attributes.name
    },
  },
  mounted() {
    this.instructionalVideo = this.instructionalVideoMedia(this.product)
    this.setProductCategoryName()
  },
}
</script>

<style lang="scss">
.pack-product-card {
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 4, 10, 0.09);
  background-color: white;
  padding: 1.429em 0.807em 1.786em 1.429em;
  width: 100%;
  height: 100%;

  .level-group {
    margin-top: 1.714em;
  }

  .pack-product-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      width: 72px;
      margin-right: 1.786em;

      img {
        width: inherit;
        height: auto;
      }
    }
  }

  .pack-product-description {
    margin-top: 1.786em;
  }

  .pack-product-category {
    margin-top: 1.286em;
  }

  .pack-product-functions {
    margin: 1.286em 0 1.5em;
  }

  .pack-product-know-more {
    text-decoration: underline;
  }

  .tip-video-thumbnail {
    width: 100%;
    height: 172px;
    margin-top: 2.143em;
  }
}
</style>

import GoodScore from "@/assets/courses/score-good.svg"
import BadScore from "@/assets/courses/score-bad.svg"
import VeryBadScore from "@/assets/courses/score-very-bad.svg"
import NoScore from "@/assets/courses/not-started.svg"
import LightBulb from "@/assets/courses/lightbulb.svg"
import Session from "@/assets/courses/session.svg"
import CourseLogic from "@/logics/CourseLogic"
import ChartsMixins from "@/mixins/Charts.mixins.js"
import { DateTime } from "luxon"

const GOOD_SCORE_THRESHOLD = 80
const BAD_SCORE_THRESHOLD = 50

export default {
  mixins: [ChartsMixins],
  data: () => ({
    courses: [],
    courseIsLoading: false,
    courseSummary: null,
    allCoursesSummary: null,
    weeklySessionChartData: {
      labels: [],
      datasets: [],
    },
  }),
  computed: {
    lightBulb() {
      return LightBulb
    },
    session() {
      return Session
    },
  },
  methods: {
    getCourseStatus(item) {
      if (item) {
        if (item.is_active === true) {
          return this.$t("courses.activeStatus")
        } else {
          if (item.is_stopped === true) {
            return this.$t("courses.inactiveStatus")
          } else {
            return this.$t("courses.createdStatus")
          }
        }
      } else {
        return ""
      }
    },
    getEmployeeBestScoreImage(summary) {
      if (!summary || summary.number_of_sessions === 0) {
        return NoScore
      } else if (summary.best_score >= GOOD_SCORE_THRESHOLD) {
        return GoodScore
      } else {
        return BadScore
      }
    },
    getCourseSuccessImage(summary) {
      if (summary.finished === 0) {
        return null
      } else if (summary.success_percentage >= GOOD_SCORE_THRESHOLD) {
        return GoodScore
      } else if (summary.success_percentage >= BAD_SCORE_THRESHOLD) {
        return BadScore
      } else {
        return VeryBadScore
      }
    },
    getCurrentCourse(courses, courseId) {
      return courses[courseId.toString()]
    },
    async getAllCourses(companyUuid) {
      if (companyUuid) {
        this.courseIsLoading = true
        const courseResults = await CourseLogic.getAll(companyUuid, {
          with_employees: 0,
          with_results: 0,
          with_summary: 1,
        })
        this.courses = courseResults.company_courses
        this.courseSummary = courseResults?.summary
        this.courseIsLoading = false
      }
    },
    async getCourseStats(companyUuid) {
      if (companyUuid) {
        const courseResults = await CourseLogic.getStats(companyUuid)
        this.allCoursesSummary = courseResults?.summary
        if (courseResults?.timelines?.weekly) {
          this.weeklySessionChartData.datasets.push({
            label: this.$t("courses.charts.sessionLabel"),
            data: [],
            borderColor: this.chartColors[this.NB_SESSIONS],
            backgroundColor: this.chartColors[this.NB_SESSIONS],
            tension: 0.1,
            fill: "origin",
          })
          let data = []
          let labels = []
          let dataCount = 0
          let firstDate
          for (const week in courseResults.timelines.weekly) {
            if (dataCount === 0) {
              firstDate = DateTime.fromISO(week)
            }
            dataCount++
            const weekData = courseResults.timelines.weekly[week]
            data.push(weekData.nbSessions)
            /* labels.push(
              this.$t("courses.charts.weekNumber", {
                week: DateTime.fromISO(week).toFormat("WW"),
              })
            ) */
            labels.push(DateTime.fromISO(week).toFormat("WW"))
          }
          const dataCountMissing = 52 - dataCount
          if (dataCount > 0) {
            for (let weekIndex = 0; weekIndex < dataCountMissing; weekIndex++) {
              data.unshift(0)
              labels.unshift(
                firstDate.minus({ weeks: weekIndex + 1 }).toFormat("WW")
              )
            }
          }
          this.weeklySessionChartData.datasets[0].data = data
          this.weeklySessionChartData.labels = labels
        }
      }
    },
  },
}

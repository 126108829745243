<template>
  <v-btn
    v-bind="options"
    x-large
    :block="block"
    :color="color"
    class="text-none body-1 font-weight-bold"
    :height="buttonHeight"
    @click="buyProduct"
    :href="externalHref"
    :target="linkTarget"
    elevation="8"
  >
    {{ buyText }}
  </v-btn>
</template>

<script>
import mixin from "@/mixins/Products.mixins.js"

export default {
  name: "op-buy-button",
  props: {
    product: {
      type: Object,
      required: true,
    },
    displayMode: {
      type: String,
      required: false,
      default() {
        return "normal"
      },
    },
    behavior: {
      type: String,
      required: false,
      default() {
        return "showProduct"
      },
    },
    color: {
      type: String,
      required: false,
      default() {
        return "primary"
      },
    },
    theme: {
      type: String,
      required: false,
      default() {
        return "dark"
      },
    },
    isPack: {
      type: Boolean,
      required: false,
      default() {
        return false
      },
    },
    block: {
      type: Boolean,
      required: false,
      default() {
        return false
      },
    },
  },
  mixins: [mixin],
  computed: {
    productRoute() {
      return this.behavior == "showProduct"
        ? {
            name: "store_product",
            params: {
              productId: this.getProductId(this.product),
            },
          }
        : this.product.attributes.buy_url == ""
        ? {
            name: "store_checkout",
            params: {
              products: this.products,
              packId: this.packId,
            },
          }
        : null
    },
    linkTarget() {
      return this.behavior == "showProduct" ||
        this.product.attributes.buy_url == ""
        ? "_self"
        : "_blank"
    },
    externalHref() {
      return this.behavior == "showProduct"
        ? null
        : this.product.attributes.buy_url
    },
    buttonHeight() {
      return this.displayMode == "normal" ? 49 : 40
    },
    options() {
      return { [this.theme]: "" }
    },
    buyText() {
      return this.product.attributes.pricing &&
        this.product.attributes.buy_button_label
        ? this.product.attributes.buy_button_label
        : this.$i18n.t("store.buy_button.view")
    },
    products() {
      if (this.isPack) {
        let products = this.product.relationships.products.list
        return products
      } else {
        return [this.product]
      }
    },
    packId() {
      return this.isPack ? this.product.id : null
    },
  },
  methods: {
    buyProduct() {
      if (!this.productRoute) {
        // case where you are rediredted to an external page
        this.$gtmLogic.productBuyOrConsult(this.product)
        return
      } else if ("store_product" === this.productRoute.name) {
        //in this case we do nothing
      } else if ("store_checkout" === this.productRoute.name) {
        this.$gtmLogic.productBuyOrConsult(this.product) //clickProductCTABuy
      }

      this.$router.push(this.productRoute)
    },
  },
}
</script>

<template>
  <v-btn-toggle
    :value="toggleValue"
    rounded
    mandatory
    background-color="#FCFCFE"
    color="primary"
    dense
    @change="changeValue"
  >
    <v-btn
      small
      outlined
      :value="button.value"
      :key="index"
      :disabled="disabled"
      v-for="(button, index) in buttons"
      >{{ button.label }}</v-btn
    >
  </v-btn-toggle>
</template>
<script>
export default {
  name: "op-toggle-buttons",
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  methods: {
    changeValue(newValue) {
      this.toggleValue = newValue
    },
  },
  computed: {
    toggleValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
</script>

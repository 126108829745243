import Request from "../services/Request"

export default class DoubleAuthRepository {
  /**
   *
   * @returns {Promise}
   */
  static activate() {
    return Request.make("POST", "/user/two-factor-authentication")
  }

  /**
   *
   * @returns {Promise}
   */
  static deactivate() {
    return Request.make("DELETE", "/user/two-factor-authentication")
  }

  /**
   *
   * @returns {Promise}
   */
  static getQrCode() {
    return Request.make("GET", "/user/two-factor-qr-code")
  }

  /**
   *
   * @returns {Promise}
   */
  static getRecoveryCodes() {
    return Request.make("GET", "/user/two-factor-recovery-codes")
  }

  /**
   *
   * @returns {Promise}
   */
  static generateRecoveryCodes() {
    return Request.make("POST", "/user/two-factor-recovery-codes")
  }

  /**
   *
   * @param code
   * @returns {Promise}
   */
  static confirmActivation(code) {
    return Request.make(
      "POST",
      `/user/confirmed-two-factor-authentication?code=${code}`
    )
  }

  /**
   *
   * @param code
   * @returns {Promise}
   */
  static postChallenge(code) {
    return Request.make("POST", `/two-factor-challenge?code=${code}`)
  }

  /**
   *
   * @param code
   * @returns {Promise}
   */
  static postRecoveryCode(code) {
    return Request.make("POST", `/two-factor-challenge?recovery_code=${code}`)
  }
}

<template>
  <v-data-table
    :items="files"
    :headers="headers.concat(additionalHeaders.concat(lastHeaders))"
    dense
    fixed-header
    :loading="fileListLoading"
    class="my-2"
  >
    <template v-slot:[`item.date`]="{ item }">
      {{ getLocalDateTime(item?.date) }}
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      {{ item.amount | monetize }}
    </template>
    <template v-slot:[`item.type`]="{ item }">
      <v-icon>{{ getFileIcon(item.type) }}</v-icon>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        class="primary--text"
        elevation="0"
        small
        icon
        @click="downloadFile(item)"
        ><v-icon>mdi-download</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import FileLogic from "@/logics/FileLogic"
import DateTimeMixins from "@/mixins/DateTime.mixins.js"
export default {
  name: "op-files-table",
  mixins: [DateTimeMixins],
  props: {
    files: {
      type: Array,
      default() {
        return []
      },
    },
    fileListLoading: {
      type: Boolean,
      default() {
        return false
      },
    },
    /* Headers to be put in between common columns and action columns */
    additionalHeaders: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      /* Common columns */
      headers: [
        {
          text: "",
          value: "type",
          sortable: false,
          cellClass: "text-body-1",
          class: "text-caption grey lighten-4",
          align: "center",
        },
        {
          text: this.$t("documents.headers.date"),
          value: "date",
          sortable: true,
          cellClass: "text-body-1",
          class: "text-caption grey lighten-4",
          align: "center",
        },
        {
          text: this.$t("documents.headers.name"),
          value: "name",
          sortable: true,
          cellClass: "text-body-1",
          class: "text-caption grey lighten-4",
        },
      ],
      /* Action columns */
      lastHeaders: [
        {
          text: "",
          value: "actions",
          sortable: false,
          cellClass: "text-body-1",
          class: "text-caption grey lighten-4",
        },
      ],
    }
  },
  methods: {
    downloadFile(file) {
      this.$emit("download", file)
    },
    getFileIcon(fileType) {
      let icon = ""
      if (!fileType || fileType === "application/pdf") {
        icon = "mdi-file-pdf-outline"
      } else if (/^image\/.*$/.test(fileType)) {
        icon = "mdi-image-outline"
      } else if (fileType === "application/zip") {
        icon = "mdi-zip-box"
      } else {
        icon = "mdi-file-outline"
      }

      return icon
    },
  },
}
</script>

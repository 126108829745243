<template>
  <LineChart
    v-if="isDataAvailable"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :height="height"
  />
  <div
    class="text-body-1 d-flex justify-center align-center fill-height"
    v-else
  >
    {{ $t("courses.charts.noData") }}
  </div>
</template>
<script>
import { Line as LineChart } from "vue-chartjs/legacy"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler,
} from "chart.js"
import "chartjs-adapter-luxon"
import lodash from "lodash"
import { titleOptions } from "../ChartOptions"

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler
)

export default {
  name: "op-course-session-evolution-line",
  components: { LineChart },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default() {
        return 400
      },
    },
  },
  computed: {
    isDataAvailable() {
      return this.chartData.datasets.some(
        (set) => !lodash.isEmpty(set.data) && set.data.some((d) => d !== 0)
      )
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        pointLabelFontSize: 5,
        scales: {
          x: {
            type: "time",
            time: {
              // Luxon format string
              tooltipFormat: "DD",
              unit: "day",
            },
            grid: {
              display: false,
              borderColor: "transparent",
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 1,
            },
            min: 0,
          },
        },
        plugins: {
          filler: {
            propagate: false,
          },
          legend: {
            display: true,
            title: {
              display: true,
              text: this.$t("courses.charts.sessionTitle"),
              ...titleOptions,
            },
            labels: {
              generateLabels: function (chart) {
                return ""
              },
              textAlign: "right",
              usePointStyle: true,
              pointStyleWidth: 10,
              boxHeight: 7.5,
            },
          },
          tooltip: {
            callbacks: {
              label: (context) =>
                `${context.dataset.label}: ${context.parsed.y}`,
              title: () => null,
              beforeBody: () => null,
              afterBody: () => null,
            },
          },
        },
      },
    }
  },
}
</script>

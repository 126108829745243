<template>
  <section v-if="reasons">
    <v-container fluid class="my-6 pa-0">
      <v-row>
        <v-col cols="12">
          <h2 :class="titleClass">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="item in reasons" :key="item.id" cols="12" md="4">
          <div class="product-reason-box">
            <h3 class="text-h3 black--text">{{ item.title }}</h3>
            <div
              class="product-reason-description steel--text"
              v-html="item.body"
            ></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "op-reason-cards",
  props: {
    title: {
      type: String,
      required: true,
    },
    reasons: {
      type: Array,
      required: true,
    },
    titleClass: {
      type: Object,
      required: false,
    },
  },
}
</script>

<style lang="scss">
.product-reason-box {
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 4, 10, 0.09);
  background-color: white;
  padding: 1.071em 1.429em 1.214em;
  border-left: 2px solid #00a1be;
  height: 100%;

  h3 {
    margin-bottom: 10px;
  }
  .product-reason-description {
    line-height: 150%;
  }
}
</style>

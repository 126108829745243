<template>
  <div
    class="slider-container"
    :style="{
      'margin-left': containerLeftMargin,
      'margin-right': containerRightMargin,
    }"
  >
    <ul
      class="slider-list"
      ref="container"
      :style="{
        left: listLeftPosition,
        'justify-content': items.length == 1 ? 'center' : 'initial',
      }"
    >
      <li
        :class="{ 'slider-element': true, hidden: listItemHidden }"
        v-for="item in slides"
        :key="item.id"
        :style="{
          flex: '0 0 ' + listItemWidth,
          height: 'auto',
          padding:
            itemPaddingTop +
            'px ' +
            itemPaddingRight +
            'px ' +
            itemPaddingBottom +
            'px ' +
            itemPaddingLeft +
            'px',
          margin: '0 ' + gutter / 2 + 'px',
        }"
      >
        <slot :item="item" />
      </li>
    </ul>

    <transition name="slide-fade">
      <div
        class="slider-navigation navigation-prev"
        v-if="currentIndex > 0"
        @click="prev"
        :style="{
          transform:
            'translate(' +
            (previewWidth == 0
              ? '0'
              : 'calc(' +
                (currentIndex == items.length - 1
                  ? previewWidth * 2
                  : previewWidth) +
                'px - 50%)') +
            ', -50%)',
        }"
      >
        <op-navigation-arrow
          direction="left"
          :size="navigationSize"
        ></op-navigation-arrow>
      </div>
    </transition>

    <transition name="slide-fade">
      <div
        class="slider-navigation navigation-next"
        v-if="currentIndex < items.length / numOfItems - 1"
        :style="{
          transform:
            'translate(' +
            (previewWidth == 0
              ? '0'
              : 'calc(' +
                (slideWidth - previewWidth * (currentIndex == 0 ? 2 : 1)) +
                'px - 50%)') +
            ', -50%)',
        }"
        @click="next"
      >
        <op-navigation-arrow
          direction="right"
          :size="navigationSize"
        ></op-navigation-arrow>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "op-slider-layout",
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemPaddingTop: {
      type: Number,
      required: false,
      default() {
        return 0
      },
    },
    itemPaddingBottom: {
      type: Number,
      required: false,
      default() {
        return 0
      },
    },
    itemPaddingLeft: {
      type: Number,
      required: false,
      default() {
        return 0
      },
    },
    itemPaddingRight: {
      type: Number,
      required: false,
      default() {
        return 0
      },
    },
    numOfItems: {
      type: Number,
      required: false,
      default() {
        return 1
      },
    },
    gutter: {
      type: Number,
      required: false,
      default() {
        return 0
      },
    },
    previewWidth: {
      type: Number,
      required: false,
      default() {
        return 0
      },
    },
    navigationSize: {
      type: Number,
      required: false,
      default() {
        return 40
      },
    },
  },
  computed: {
    containerLeftMargin() {
      return this.previewWidth == 0 || this.currentIndex == 0
        ? this.gutter / -2 + "px"
        : 0
    },
    containerRightMargin() {
      return this.previewWidth == 0 ||
        this.currentIndex == this.items.length - 1
        ? this.gutter / -2 + "px"
        : 0
    },
    listItemWidth() {
      return this.items.length == 1
        ? this.slideWidth - this.gutter * 2 - this.previewWidth + "px"
        : (this.slideWidth -
            (this.currentIndex == 0 && this.items.length == this.numOfItems
              ? this.gutter * (this.items.length + 1)
              : this.previewWidth * 2)) /
            this.numOfItems -
            this.gutter +
            "px"
    },
    listLeftPosition() {
      return (
        -this.currentIndex * (this.slideWidth - this.previewWidth * 2) +
        (this.currentIndex > 0 &&
        (this.currentIndex + 1) * this.numOfItems >= this.items.length &&
        this.items.length % this.numOfItems != 0
          ? ((this.currentIndex + 1) * this.numOfItems - this.items.length) *
              (this.slideWidth / this.numOfItems) -
            this.previewWidth
          : 0) +
        this.previewWidth *
          (this.currentIndex == 0
            ? 0
            : this.currentIndex == this.items.length - 1
            ? 2
            : 1) +
        "px"
      )
    },
  },
  data() {
    return {
      slideWidth: 0,
      currentIndex: 0,
      listItemHidden: true,
      slides: [],
    }
  },
  watch: {
    items: function(newItems) {
      if (newItems) {
        this.initItems()
      }
    },
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.initItems)

      this.initItems()
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initItems)
  },
  methods: {
    initItems: function() {
      this.slideWidth = this.$refs.container.clientWidth
      this.slides = this.items
      this.listItemHidden = false
    },
    next: function() {
      if (this.currentIndex < this.items.length / this.numOfItems - 1) {
        this.currentIndex += 1
      }
    },
    prev: function() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1
      }
    },
  },
}
</script>

<style lang="scss">
.slider-container {
  position: relative;
  overflow: hidden;
  transition: all 1s ease;
  height: 100%;

  .slider-navigation {
    position: absolute;
    top: 50%;
    cursor: pointer;
    transition: transform 1s ease;

    &.navigation-prev {
      left: 0;
    }

    &.navigation-next {
      left: 0;
    }
  }

  .slider-list {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    transition: left 1s ease;
    list-style: none;
    padding: 0;

    li {
      opacity: 1;
      transition: opacity 1s;

      &.hidden {
        opacity: 0;
      }
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    opacity: 0;
  }
}
</style>

<template>
  <v-menu rounded="lg" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="text-none rounded-lg"
        dark
        elevation="0"
        small
        :disabled="disabled"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t("export") }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in options"
        :key="index"
        @click="item.action"
      >
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "op-csv-export-button",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
}
</script>

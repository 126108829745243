var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-improvement"},[_c('v-col',{staticClass:"level-title",attrs:{"cols":"12","md":"6"}},[_c('div',{domProps:{"innerHTML":_vm._s(
        _vm.$t('store.levels.levelTitle', {
          productName: _vm.productName,
          level: _vm.level,
          categoryName: _vm.categoryName,
        })
      )}})]),_c('div',{staticClass:"level-progression-line"},[_c('op-level-item',{staticClass:"level-item",attrs:{"size":_vm.size(1),"backgroundColor":_vm.backgroundColor(1),"enableShadow":_vm.enableShadow(1),"textContent":"1","strokeWidth":_vm.strokeWidth(1)}}),_c('div',{class:[
        'improvement-line',
        'first-part',
        _vm.lineColourClass(2),
        { 'with-arrows': _vm.displayArrows(2) },
      ]}),_c('div',{class:[
        'improvement-line',
        'second-part',
        _vm.lineColourClass(2),
        { 'with-arrows': _vm.displayArrows(2) },
      ]}),_c('op-level-item',{staticClass:"level-item",attrs:{"size":_vm.size(2),"backgroundColor":_vm.backgroundColor(2),"enableShadow":_vm.enableShadow(2),"textContent":"2","strokeWidth":_vm.strokeWidth(2)}}),_c('div',{class:[
        'improvement-line',
        'first-part',
        _vm.lineColourClass(3),
        { 'with-arrows': _vm.displayArrows(3) },
      ]}),_c('div',{class:[
        'improvement-line',
        'second-part',
        _vm.lineColourClass(3),
        { 'with-arrows': _vm.displayArrows(3) },
      ]}),_c('op-level-item',{staticClass:"level-item",attrs:{"size":_vm.size(3),"backgroundColor":_vm.backgroundColor(3),"enableShadow":_vm.enableShadow(3),"textContent":"3","strokeWidth":_vm.strokeWidth(3)}}),_c('div',{class:[
        'improvement-line',
        'first-part',
        _vm.lineColourClass(4),
        { 'with-arrows': _vm.displayArrows(4) },
      ]}),_c('div',{class:[
        'improvement-line',
        'second-part',
        _vm.lineColourClass(4),
        { 'with-arrows': _vm.displayArrows(4) },
      ]}),_c('op-level-item',{staticClass:"level-item",attrs:{"size":_vm.size(4),"backgroundColor":_vm.backgroundColor(4),"enableShadow":_vm.enableShadow(4),"textContent":"4","strokeWidth":_vm.strokeWidth(4)}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import CustomizationRepository from "@/repositories/CustomizationRepository"
import CustomizationStore from "@/stores/CustomizationStore"

export default class CustomizationLogic {
  static async getCustomization() {
    const url = window.location.origin
    //const url = "http://cyber1.aeropassion.net"
    //const url = "https://staging-msp.oppens.fr"
    const response = await CustomizationRepository.getCustomization(url)
    const customization = response.dataObject()
    CustomizationStore.setCustomization(customization)
    return customization
  }
}

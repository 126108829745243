import Request from "../services/Request"

export default class DashboardRepository {
  /**
   * @returns {Promise}
   */
  static getOne(params) {
    return Request.make("GET", "/dashboard", { params })
  }
}

<template>

  <v-card v-bind="$attrs" v-on="$listeners">

    <v-app-bar dense flat dark :color="toolbarColor">

      <v-toolbar-title>
         {{ toolbarTitle }}
        <span v-if="showDataCount">({{ totalItems }})</span>

      </v-toolbar-title>

    </v-app-bar>

    <v-data-table
      :options="pagination"
      @update:options="$emit('update:pagination', $event)"
      :server-items-length="totalItems"
      :footer-props="{
        'items-per-page-options': [20, 50, 100, 150],
      }"
      :headers="headers"
      :loading="isLoading"
      :items="items"
    >

      <template v-slot:[`item.name`]="{ item }">

        <router-link
          class="default--text"
          :to="{ name: userEditRouteName, params: { uuid: item.id } }"
        >
           {{ item.name }}
        </router-link>

      </template>

      <template v-slot:[`item.created_at`]="{ item }">
         {{ Helpers.parseDate(item.created_at) }}
      </template>

    </v-data-table>

  </v-card>

</template>

<script>
import Helpers from "../../services/Helpers"

export default {
  name: "op-user-table",

  props: {
    value: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      default: () => [
        {
          text: "Nom",
          sortable: true,
          value: "name",
        },
        {
          text: "Email",
          sortable: true,
          value: "email",
        },
        // {
        //   text: 'Nom',
        //   sortable: true,
        //   value: 'last_name'
        // },
        // {
        //   text: 'Prénom',
        //   sortable: true,
        //   value: 'first_name'
        // },
        {
          text: "Inscrit le",
          sortable: true,
          value: "created_at",
        },
      ],
    },
    showDataCount: Boolean,
    totalItems: Number,

    toolbarColor: String,
    toolbarTitle: String,

    userEditRouteName: {
      type: String,
    },
  },

  data: () => ({
    Helpers,
    isLoading: false,
  }),

  mounted() {},

  computed: {
    items: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },

  watch: {},

  methods: {},
}
</script>


var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider-container",style:({
    'margin-left': _vm.containerLeftMargin,
    'margin-right': _vm.containerRightMargin,
  })},[_c('ul',{ref:"container",staticClass:"slider-list",style:({
      left: _vm.listLeftPosition,
      'justify-content': _vm.items.length == 1 ? 'center' : 'initial',
    })},_vm._l((_vm.slides),function(item){return _c('li',{key:item.id,class:{ 'slider-element': true, hidden: _vm.listItemHidden },style:({
        flex: '0 0 ' + _vm.listItemWidth,
        height: 'auto',
        padding:
          _vm.itemPaddingTop +
          'px ' +
          _vm.itemPaddingRight +
          'px ' +
          _vm.itemPaddingBottom +
          'px ' +
          _vm.itemPaddingLeft +
          'px',
        margin: '0 ' + _vm.gutter / 2 + 'px',
      })},[_vm._t("default",null,{"item":item})],2)}),0),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.currentIndex > 0)?_c('div',{staticClass:"slider-navigation navigation-prev",style:({
        transform:
          'translate(' +
          (_vm.previewWidth == 0
            ? '0'
            : 'calc(' +
              (_vm.currentIndex == _vm.items.length - 1
                ? _vm.previewWidth * 2
                : _vm.previewWidth) +
              'px - 50%)') +
          ', -50%)',
      }),on:{"click":_vm.prev}},[_c('op-navigation-arrow',{attrs:{"direction":"left","size":_vm.navigationSize}})],1):_vm._e()]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.currentIndex < _vm.items.length / _vm.numOfItems - 1)?_c('div',{staticClass:"slider-navigation navigation-next",style:({
        transform:
          'translate(' +
          (_vm.previewWidth == 0
            ? '0'
            : 'calc(' +
              (_vm.slideWidth - _vm.previewWidth * (_vm.currentIndex == 0 ? 2 : 1)) +
              'px - 50%)') +
          ', -50%)',
      }),on:{"click":_vm.next}},[_c('op-navigation-arrow',{attrs:{"direction":"right","size":_vm.navigationSize}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
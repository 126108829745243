import { isFinite } from "lodash"
import { DateTime, Duration } from "luxon"

export default {
  methods: {
    getLocalDateTime(dateOrMilliseconds) {
      if (isFinite(dateOrMilliseconds)) {
        return DateTime.fromMillis(dateOrMilliseconds).toLocaleString(
          DateTime.DATETIME_SHORT
        )
      } else if (dateOrMilliseconds) {
        let dt = DateTime.fromSQL(dateOrMilliseconds).toLocaleString(
          DateTime.DATETIME_SHORT
        )
        if (dt.toString() === "Invalid DateTime") {
          dt = DateTime.fromISO(dateOrMilliseconds).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        }
        return dt
      } else {
        return ""
      }
    },
    getLocalDate(date) {
      if (date) {
        let dt = DateTime.fromSQL(date).toLocaleString(DateTime.DATE_SHORT)
        if (dt.toString() === "Invalid DateTime") {
          dt = DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
        }
        return dt
      } else {
        return ""
      }
    },
    displayTimeFromSeconds(seconds) {
      if (seconds) {
        return Duration.fromMillis(seconds * 1000).toFormat("hh:mm:ss")
      } else {
        return "00:00:00"
      }
    },
  },
}

import CompanyLogic from "./CompanyLogic"
import DashboardStore from "../stores/DashboardStore"
import AuthStore from "../stores/AuthStore"
import Model from "../models/Model"
import DashboardRepository from "../repositories/DashboardRepository"
import ZapAPILogic from "@/logics/ZapAPILogic"

export default class DashboardLogic {
  static async getDashboardObject() {
    let user = AuthStore.me
    if (!user) {
      return null
    }

    let obj = DashboardStore.state.obj

    const uuid = user.uuid

    if (obj && obj.uuid != uuid) {
      obj = null
    }

    if (!obj) {
      let obj = await this.computeDashboardObject()
      DashboardStore.setObj(obj)
    }

    return DashboardStore.state.obj
  }

  static async updateDashboardObject() {
    //console.log("*** updateDashboardObject ***")

    let user = AuthStore.me

    if (!user) {
      DashboardStore.setObj(null)
      return null
    }

    if (user.is_anonymous) {
      DashboardStore.setObj(null)
      return null
    }

    let obj = await this.computeDashboardObject()
    DashboardStore.setObj(obj)
  }

  static async computeDashboardObject() {
    //console.log("*** compute dashboard object ***")

    const response = await DashboardRepository.getOne()
    const model = new Model(response.dataObject())

    //console.log("-- DashboardLogic model --> ", model)x

    let user = model.getRelation("users")[0]
    user = user.getAttributes()
    let topics = model.getRelation("topics")
    let products = model.getRelation("products")
    let company = model.getRelation("companies")[0]

    const allTopics = topics.map((t) => t.getAttributes())
    allTopics.forEach((t) => {
      if ("not_started" == t.status) {
        t.score = 0
      }
    })

    const unstartedTopics = topics
      .map((t) => t.getAttributes())
      .filter((t) => {
        return t.status == "not_started"
      })

    const startedTopics = topics
      .map((t) => t.getAttributes())
      .filter((t) => {
        return t.status == "started"
      })

    const completedTopics = topics
      .map((t) => t.getAttributes())
      .filter((t) => {
        return t.status == "completed"
      })

    const uncompletedTopics = topics
      .map((t) => t.getAttributes())
      .filter((t) => {
        return t.status != "completed"
      })

    const obj = {
      uuid: user.uuid,
      user: user,
      company: company,
      feedback: "",
      score: null,
      profile: null,
      unstartedTopics: [],
      products: [],
      levels: null,
      lastScan: null,
    }

    obj.startedTopics = startedTopics
    obj.unstartedTopics = unstartedTopics
    obj.completedTopics = completedTopics
    obj.uncompletedTopics = uncompletedTopics
    obj.profile = this.computeProfile(user, company)
    obj.score = this.computeScore(user, company, completedTopics)
    obj.allTopics = allTopics
    obj.products = products
    obj.lastScan = await ZapAPILogic.scanResult()

    return obj
  }

  static computeScore(user, company, topics) {
    let obj = {
      value: 0,
      description: "",
      link: "",
    }

    // compute score based on topics average
    let score = 0
    let nbTopics = 0
    if (topics && topics.length > 0) {
      topics.forEach((t) => {
        score += t.score
        nbTopics++
      })
      score = Math.floor((score / nbTopics) * 100) / 100
    }

    // compute description
    let description = "Votre entreprise"
    if (score > 3) {
      description += " est très bien protégée"
    } else if (score > 2.5) {
      description += " est bien protégée"
    } else if (score > 2) {
      description += " est moyennement protégée"
    } else {
      description += " est peu protégée"
    }
    obj.value = score.toString()
    obj.description = description

    return obj
  }

  static computeProfile(user, company) {
    let profile = {
      completed: false,
      completionRate: 0,
    }
    profile.completionRate = CompanyLogic.getCompletionRate(company)
    if (100 == profile.completionRate) {
      profile.completed = true
    }
    return profile
  }
}

<template>
  <div
    v-if="product.attributes"
    class="large-product-container pa-7 d-flex flex-column flex-md-row"
  >
    <div class="d-flex flex-column">
      <div class="d-flex">
        <v-img
          :src="product.attributes.logo"
          :width="logoWidth"
          :max-width="logoWidth"
          alt="Logo"
          class="mr-5"
          contain
          position="top"
        ></v-img>
        <div class="d-flex flex-column">
          <h2>{{ product.attributes.name }}</h2>
          <div class="mb-4 body-2 product-editor">
            {{ product.attributes.developer }}
          </div>
        </div>
        <v-rating
          v-model="rating"
          readonly
          background-color="primary"
          dense
          size="16"
          class="ml-3 mt-2"
        ></v-rating>
      </div>
      <div class="d-flex">
        <div class="mr-5 description-spacer d-none d-md-block"></div>
        <div class="product-subtitle">{{ product.attributes.subtitle }}</div>
        <div class="product-description body-2">
          {{ product.attributes.description }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column ml-md-auto">
      <op-buy-button :product="product"></op-buy-button>
      <op-product-tags
        class="product-tags mt-2 mb-3 mb-md-0 mt-md-12 order-first order-md-last"
        :tags="product.attributes.tags"
        small
      ></op-product-tags>
    </div>
  </div>
</template>

<script>
export default {
  name: "op-large-product-card",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "60px"
        case "sm":
          return "60px"
        case "md":
          return "76px"
        case "lg":
          return "76px"
        case "xl":
          return "76px"
        default:
          return "76px"
      }
    },
    rating() {
      let calculatedRating = 0
      if (this.product.comments) {
        this.product.comments.forEach(function (comment) {
          calculatedRating += comment.rating
        })
        return calculatedRating / this.product.comments.length
      }
      return 0
    },
  },
}
</script>

<style lang="scss">
.large-product-container {
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 4, 10, 0.09);
  background-color: white;

  .description-spacer {
    width: 76px;
  }

  .product-editor {
    line-height: 1.43;
    color: $steel;
  }
  .product-subtitle {
    font-weight: 600;
    color: black;
    line-height: 1.57;
    margin-bottom: 16px;
  }
  .product-description {
    line-height: 1.43;
    color: $steel;
  }
}
</style>

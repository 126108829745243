import { render, staticRenderFns } from "./OpCyberscoreByTheme.vue?vue&type=template&id=499f95d4&scoped=true&"
import script from "./OpCyberscoreByTheme.vue?vue&type=script&lang=js&"
export * from "./OpCyberscoreByTheme.vue?vue&type=script&lang=js&"
import style0 from "./OpCyberscoreByTheme.vue?vue&type=style&index=0&id=499f95d4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "499f95d4",
  null
  
)

export default component.exports
import axios from "axios"

//export const EventBus = new Vue()

export default class ZapAPIRepository {
  constructor() {}

  /** @description récupération du résultat du dernier scan sur l'API Zap
    information renvoyées :

    {"id":"2","url":"https://www.oppens.fr"}
  */
  static async scanResult(url, uid) {
    //@todo use .env
    const baseURL = process.env.VUE_APP_OPPENS_ZAP_API
    const path = baseURL + `/scan`

    let client = axios.create({
      baseURL: path,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    let response = await client.get("", {
      params: {
        url: url,
        uid: uid,
      },
    })
    return response.data
  }

  /**
   * @description send data to create a scan request
   */
  static async requestScan(uid, userEmail, url, emails) {
    const baseURL = process.env.VUE_APP_OPPENS_ZAP_API
    const path = baseURL + `/scan`

    let response = await axios.post(path, {
      uid: uid,
      userEmail: userEmail,
      url: url,
      emails: emails,
    })
    return response
  }

  static async getScanHistory(uid) {
    const baseURL = process.env.VUE_APP_OPPENS_ZAP_API
    const path = baseURL + `/scanhistory`

    let client = axios.create({
      baseURL: path,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    let response = await client.get("", {
      params: {
        uid: uid,
      },
    })

    console.log("get scan history response: ", response)

    return response.data
  }

  static async getScan(scanID) {
    const baseURL = process.env.VUE_APP_OPPENS_ZAP_API
    const path = baseURL + `/scanhistory`

    let client = axios.create({
      baseURL: path,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    let response = await client.get("", {
      params: {
        scanID: scanID,
      },
    })

    console.log("get scan response: ", response)

    return response.data
  }

  static async getNotifications(uid) {
    const baseURL = process.env.VUE_APP_OPPENS_ZAP_API
    const path = baseURL + `/notification`

    let client = axios.create({
      baseURL: path,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    let response = await client.get("", {
      params: {
        uid: uid,
      },
    })
    return response.data
  }

  static async deleteNotification(id) {
    const baseURL = process.env.VUE_APP_OPPENS_ZAP_API
    const path = baseURL + `/notification`

    let response = await axios.post(path, {
      id: id,
    })

    return response
  }
}

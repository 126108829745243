<template>
  <v-card tile flat color="transparent">
    <v-card-title class="pl-0 pt-10 title d-flex font-weight-bold">
      {{ title }}
      <op-diagnostic-timeline-card
        v-if="showTitleLevel"
        class="ml-2 align-self-start"
        :color="levelColor"
        :small="$vuetify.breakpoint.smAndDown"
        :value="level"
      >
      </op-diagnostic-timeline-card>
    </v-card-title>

    <v-row class="fill-height" align="center">
      <v-col cols="12" sm="8" md="9" class="py-0">
        <v-list-item
          v-for="(item, key) in items"
          :key="key"
          two-line
          class="pl-0"
        >
          <v-list-item-avatar
            color=""
            style="margin-right: 0px; margin-left: -16px"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-avatar>

          <v-list-item-content
            :class="
              item.active ? activeBgColor + ' border-radius pa-6 my-1' : null
            "
          >
            <v-list-item-title
              :class="item.active ? activeTextColor : null"
              class="text-wrap"
            >
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="4" md="3" class="py-0" v-if="box">
        <v-card class="grey--bg" flat>
          <v-card-title class="headline text-break">
            {{ box.title | titlecase }}
          </v-card-title>

          <v-card-text> {{ box.text }} </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-12" v-if="showSolutionTitle">
      <v-col>
        <v-card-title class="title pa-0">
          {{ $t("diagnostic.solutions") }}
          <op-diagnostic-timeline-small
            v-if="showTimelineSmall"
            class="ml-3"
            :from="level"
            :to="level + 1"
          >
          </op-diagnostic-timeline-small>
        </v-card-title>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="product in products" :key="product.id" cols="12" md="6">
        <op-product-card
          :product="product"
          displayPrice
          displayEditor
          openInModal
          :biggerAddButton="biggerAddButton"
        >
        </op-product-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "op-diagnostic-reco",

  computed: {
    biggerAddButton() {
      const width = window.innerWidth
      if (width < 500) {
        return true
      }
      return false
    },
  },

  props: {
    activeBgColor: {
      type: String,
      default: "primary--bg",
    },
    activeTextColor: {
      type: String,
      default: "primary--text text--darken-1",
    },
    box: {
      type: Object,
      default: () => null,
      validator: (object) => {
        const missingProperties = ["title", "text"].filter(
          (property) => !Object.prototype.hasOwnProperty.call(object, property)
        )

        return missingProperties.length === 0
      },
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    products: {
      type: Array,
      required: true,
      default: () => [],
    },
    level: {
      type: Number,
      validator: (value) => value > 0 && value < 1000,
    },
    nextLevel: {
      type: Number,
      validator: (value) => value > 0 && value < 1000,
    },
    showTitleLevel: {
      type: Boolean,
    },
    showTimelineSmall: {
      type: Boolean,
    },
    levelColor: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    showSolutionTitle: {
      type: Boolean,
      default: () => true,
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialogOpened" width="42rem">
    <v-card class="pa-6" color="whitesmoke">
      <op-alert ref="opAlert" color="red" />
      <v-skeleton-loader
        v-if="isLoading"
        type="card-avatar, list-item-three-line"
      />
      <template v-else>
        <v-container fluid class="pa-0">
          <v-row
            align="center"
            class="px-6 py-4 white mx-n6"
            no-gutters
            justify="space-around"
          >
            <v-col cols="auto">
              <img :src="accountIcon" class="account-img" />
            </v-col>
            <v-col cols="auto" class="mr-auto ml-6">
              <div
                class="text-h6 font-weight-medium steel-blue--text text-uppercase"
              >
                {{ employee?.first_name }}
              </div>
              <div
                class="text-h6 font-weight-medium steel-blue--text text-uppercase"
              >
                {{ employee?.last_name }}
              </div>
            </v-col>
            <v-spacer />
            <v-col md="auto" cols="12" class="mt-6 mt-md-0">
              <div class="grey--text text--darken-3">
                {{ $t("employees.cyberscore") }}
              </div>
              <div class="d-flex align-center mt-n2">
                <div class="d-flex align-end mr-2">
                  <div class="text-h5 font-weight-bold primary--text mr-2">
                    {{ formattedCyberscore }}
                  </div>
                  <div class="primary--text font-weight-bold text-subtitle-2">
                    / 100
                  </div>
                </div>
                <op-cyberscore-gauge
                  size="small"
                  :score="employee?.gamification_profile?.cyber_score || 0"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid class="mt-6">
          <v-row no-gutters>
            <v-col
              cols="12"
              class="text-h6 font-weight-regular steel-blue--text mb-3"
            >
              Badges
            </v-col>
            <v-col cols="12">
              <v-card elevation="0" rounded="lg" class="d-flex flex-wrap">
                <op-badge
                  v-for="badge in employee?.gamification_profile?.badges"
                  :key="badge.name"
                  :type="badge.name"
                  :score="badge.score"
                /> </v-card
            ></v-col>
          </v-row> </v-container
      ></template>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none rounded-lg"
          outlined
          elevation="0"
          @click="close"
        >
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AlertMixin from "@/mixins/Alert.mixins.js"
import CompanyMixin from "@/mixins/Company.mixins.js"
import EmployeeLogic from "@/logics/EmployeeLogic"
import AccountIcon from "@/assets/account.png"
export default {
  name: "op-employee-cyberscore-dialog",
  mixins: [AlertMixin, CompanyMixin],
  props: {
    employeeId: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: false,
      employee: null,
    }
  },
  watch: {
    employeeId(newValue) {
      if (newValue) {
        this.loadEmployee()
      }
    },
  },
  computed: {
    accountIcon() {
      return AccountIcon
    },
    dialogOpened: {
      get() {
        return this.employeeId
      },
      set(value) {},
    },
    formattedCyberscore() {
      return this.employee?.gamification_profile?.cyber_score
        ? Math.floor(this.employee.gamification_profile.cyber_score)
        : "--"
    },
  },
  methods: {
    async loadEmployee() {
      this.isLoading = true
      try {
        this.employee = await EmployeeLogic.getOne(
          this.companyUuid,
          this.employeeId
        )
      } catch (error) {
        this.displayAlert(error?.response?.data?.error?.message)
      } finally {
        this.isLoading = false
      }
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>
<style scoped>
.account-img {
  height: 45px;
  vertical-align: middle;
}
</style>

<template>
  <div class="mx-n1">
    <v-chip
      :color="color"
      :text-color="textColor"
      v-bind="$attrs"
      :ripple="false"
      class="text-no-wrap text-caption text-uppercase py-2 mx-1 mb-2"
      v-for="tag in tags"
      :key="tag"
    >
      {{ tag }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "op-product-tags",
  props: {
    tags: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default() {
        return "#f5f5f5"
      },
    },
    textColor: {
      type: String,
      required: false,
      default() {
        return "#40454e"
      },
    },
  },
}
</script>

<style lang="scss">
.product-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -4px;

  .product-tag {
    margin: 0 4px 0.5em;
    padding: 0 16px;
    white-space: nowrap;
  }
}
</style>

<template>
  <section>
    <h2 class="mb-4 text-h2" :class="titleClass">
      {{ $t("store.product.configurationTitle") }}
    </h2>

    <v-container class="pa-0">
      <v-row>
        <v-col
          v-for="item in processedRequirements"
          :key="item.id"
          cols="12"
          md="3"
        >
          <h3 class="text-body-2 mb-1 steel--text">
            {{ $t("store.product." + item.category) }}
          </h3>
          <div class="text-body-2 black--text" v-html="item.description"></div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import capitalize from "lodash/capitalize"

export default {
  name: "op-requirements-card",
  props: {
    requirements: {
      type: Array,
      required: true,
    },
    titleClass: {
      type: Object,
      required: false,
    },
  },
  methods: {
    getOSName: function (os) {
      switch (os) {
        case "mac-os":
          return "macOS"
        case "ios":
          return "iOS"
        default:
          return capitalize(os)
      }
    },
  },
  computed: {
    processedRequirements() {
      let processed = []
      let oses = []
      let mobileOses = []
      this.requirements.forEach(function (requirement) {
        if (requirement.category == "operating_system") {
          oses.push(
            this.getOSName(requirement.description) + " " + requirement.version
          )
        } else if (requirement.category == "mobile_operating_system") {
          mobileOses.push(
            this.getOSName(requirement.description) + " " + requirement.version
          )
        } else {
          processed.push(requirement)
        }
      }, this)
      if (oses.length > 0) {
        processed.unshift({
          category: "operating_system",
          description: oses.join(", "),
        })
      }
      if (mobileOses.length > 0) {
        processed.unshift({
          category: "mobile_operating_system",
          description: mobileOses.join(", "),
        })
      }
      return processed
    },
  },
}
</script>

import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class CustomizationRepository {
  /**
   *
   * @returns {Promise}
   */
  static getCustomization(url) {
    return Request.make("GET", `/${API_VERSION}/customization?url=${url}`)
  }
}

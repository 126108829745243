<template>
  <div :style="divStyle" style="">
    <svg
      viewBox="0 0 63 63"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="blocks/timeline/huge" transform="translate(3.000000, -44.000000)">
        <path
          d="M28,47 C39.2727477,47 48.7043522,49.102099 51.0831428,51.9165181 C53.897901,54.2956478 56,63.7272523 56,75 C56,86.2727477 53.897901,95.7043522 51.0834819,98.0831428 C48.7043522,100.897901 39.2727477,103 28,103 C16.7272523,103 7.29564775,100.897901 4.91685717,98.0834819 C2.10209898,95.7043522 -3.6095571e-12,86.2727477 -3.6095571e-12,75 C-3.61108941e-12,63.7272523 2.10209898,54.2956478 4.91651807,51.9168572 C7.29564775,49.102099 16.7272523,47 28,47 Z"
          id="Combined-Shape"
          stroke="#FFFFFF"
          stroke-width="5"
          :fill="color"
        ></path>
        <g
          v-if="checked"
          transform="translate(38.000000, 85.000000)"
          fill-rule="nonzero"
          stroke-width="2"
        >
          <circle
            id="Oval"
            stroke="#F5F8FB"
            :fill="color"
            cx="10"
            cy="10"
            r="10"
          ></circle>
          <polyline
            id="Path-2"
            stroke="#F0F3F7"
            stroke-linecap="round"
            points="6 10.0833333 9 13 14.344 8"
          ></polyline>
        </g>
        <text id="1" font-size="29" fill="#FFFFFF">
          <tspan v-if="value < 10" x="20" y="84">
            {{ value }}
          </tspan>
          <tspan v-else-if="value >= 10 && value < 100" x="10" y="84">
            {{ value }}
          </tspan>
          <tspan v-else-if="value >= 100" font-size="19" x="10" y="80">
            {{ value }}
          </tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import colors from "../../plugins/colors"

export default {
  name: "op-diagnostic-timeline-card",

  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: colors.blue.base,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      validator: value => value > 0 && value < 1000,
    },
    sizeLarge: {
      type: String,
      default: "70px",
    },
    size: {
      type: String,
      default: "50px",
    },
    sizeSmall: {
      type: String,
      default: "40px",
    },
    text: {
      type: String,
    },
  },

  computed: {
    divStyle() {
      let size = this.size

      if (this.large) {
        size = this.sizeLarge
      } else if (this.small) {
        size = this.sizeSmall
      }

      return {
        height: size,
        width: size,
      }
    },
  },
}
</script>

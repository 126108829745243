<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15.999"
    viewBox="0 0 16 15.999"
    class="home-menu-icon"
  >
    <path
      id="Op_component_1"
      data-name="Op component 1"
      d="M405.6,3285.6v4h4v-4Zm-.8-1.6h5.6a.8.8,0,0,1,.8.8v5.6a.8.8,0,0,1-.8.8h-5.6a.8.8,0,0,1-.8-.8v-5.6A.8.8,0,0,1,404.8,3284Z"
      transform="translate(-404 -3283.999)"
    />
    <path
      id="Op_component_2"
      data-name="Op component 2"
      d="M416.6,3285.6v4h4v-4Zm-.8-1.6h5.6a.8.8,0,0,1,.8.8v5.6a.8.8,0,0,1-.8.8h-5.6a.8.8,0,0,1-.8-.8v-5.6A.8.8,0,0,1,415.8,3284Z"
      transform="translate(-406.2 -3283.999)"
    />
    <path
      id="Op_component_3"
      data-name="Op component 3"
      d="M416.6,3296.6v4h4v-4Zm-.8-1.6h5.6a.8.8,0,0,1,.8.8v5.6a.8.8,0,0,1-.8.8h-5.6a.8.8,0,0,1-.8-.8v-5.6A.8.8,0,0,1,415.8,3295Z"
      transform="translate(-406.2 -3286.199)"
    />
    <path
      id="Op_component_4"
      data-name="Op component 4"
      d="M405.6,3296.6v4h4v-4Zm-.8-1.6h5.6a.8.8,0,0,1,.8.8v5.6a.8.8,0,0,1-.8.8h-5.6a.8.8,0,0,1-.8-.8v-5.6A.8.8,0,0,1,404.8,3295Z"
      transform="translate(-404 -3286.199)"
    />
  </svg>
</template>
<script>
export default {
  name: "op-home-menu-icon",
}
</script>
<style lang="scss" scoped>
.home-menu-icon {
  fill: currentColor;
}
</style>

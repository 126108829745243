<template>
  <v-card
    width="100%"
    class="py-8"
    color="light-grey"
    rounded="lg"
    elevation="0"
    :disabled="!summary"
  >
    <v-container class="px-0">
      <v-row>
        <v-col v-for="summary in summaries" :key="summary.id" class="px-10">
          <div class="d-flex align-center">
            <img :src="summary.icon" />
            <div class="text-h2 font-weight-bold ml-4">
              {{ `${summary.rate} %` }}
            </div>
          </div>
          <div class="text-h3 mt-4 mb-2">
            {{ summary.label }}
          </div>
          <v-progress-linear
            rounded
            height="14"
            background-color="#dbdbdb"
            :value="!summary ? 100 : summary.rate"
            :color="$colors[summary.color[0]][summary.color[1]]"
          />
          <div
            :style="{ color: $colors[summary.color[0]][summary.color[1]] }"
            class="body-1 font-weight-medium my-2"
          >
            {{
              `${summary.value.toLocaleString()} / ${summary.total.toLocaleString()}`
            }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import lodash from "lodash"
import Enrolled from "@/assets/courses/enrolled.svg"
import Completed from "@/assets/courses/completed.svg"
import SuccessRate from "@/assets/courses/success.svg"

export default {
  name: "op-course-summary",
  props: {
    summary: {
      type: Object,
    },
  },
  data() {
    return {
      summaries: [],
      stats: [
        {
          label: this.$t("courses.enrolledCount"),
          icon: Enrolled,
          color: ["teal", "base"],
          attribute: "employees_count",
          substractMissing: false,
        },
        {
          label: this.$t("courses.completed"),
          icon: Completed,
          color: ["yellow", "base"],
          attribute: "finished",
          substractMissing: true,
        },
        {
          label: this.$t("courses.successRate"),
          icon: SuccessRate,
          color: ["green", "base"],
          attribute: "success",
          substractMissing: true,
        },
      ],
    }
  },
  mounted() {
    this.createSummary(this.summary)
  },
  methods: {
    createSummary(courseSummary) {
      this.summaries = lodash.clone(this.stats, true)
      for (
        let summaryIndex = 0;
        summaryIndex < this.summaries.length;
        summaryIndex++
      ) {
        let summary = this.summaries[summaryIndex]
        if (!this.summary || isNaN(courseSummary[summary.attribute])) {
          summary.value = "-"
          summary.total = "-"
          summary.rate = "-"
        } else {
          summary.value = courseSummary[summary.attribute]
          summary.total =
            courseSummary.employees_count -
            (summary.substractMissing === true ? courseSummary.missing : 0)
          summary.rate =
            courseSummary.employees_count === 0
              ? 0
              : Math.floor((100 * summary.value) / summary.total)
        }
      }
    },
  },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.284"
    height="11.94"
    viewBox="0 0 16.284 11.94"
    class="course-menu-icon"
  >
    <path
      id="Form_35"
      data-name="Form 35"
      d="M636.142,447a.541.541,0,0,0-.216.044l-7.6,3.256a.543.543,0,0,0,0,1l2.385,1.022v3.906a1.756,1.756,0,0,0,.569,1.23,4.037,4.037,0,0,0,1.256.8,9.873,9.873,0,0,0,7.2,0,4.041,4.041,0,0,0,1.256-.8,1.755,1.755,0,0,0,.569-1.23V452.32l.543-.232v.882a.543.543,0,1,0,1.085.015v-1.363l.757-.324a.543.543,0,0,0,0-1l-7.6-3.256a.542.542,0,0,0-.212-.044Zm0,1.133,6.219,2.666-6.219,2.666-6.219-2.666Zm-4.342,4.652,4.128,1.769a.542.542,0,0,0,.428,0l4.128-1.769v3.441a.667.667,0,0,1-.249.46,3.093,3.093,0,0,1-.923.577,8.788,8.788,0,0,1-6.339,0,3.1,3.1,0,0,1-.923-.577.667.667,0,0,1-.249-.46v-3.441Z"
      transform="translate(-627.997 -447)"
    />
  </svg>
</template>
<script>
export default {
  name: "op-course-menu-icon",
}
</script>
<style lang="scss" scoped>
.course-menu-icon {
  fill: currentColor;
}
</style>

import lodash from "lodash"
import SentEmails from "@/assets/phishing/sent-emails.svg"
import ClickedLinks from "@/assets/phishing/clicked-links.svg"
import EnteredData from "@/assets/phishing/entered-data.svg"
import Trained from "@/assets/phishing/trained-employees.svg"
import Report from "@/assets/phishing/reported-emails.svg"

export default {
  data() {
    return {
      employees: [],
      totalEmployees: 0,
      employeeTableIsLoading: false,
      selectedEmployees: [],
      sortOptions: {},
      currentPage: 1,
      attributesToFilter: ["job", "entity", "lang"],
      employeeFilter: undefined,
      headerFilters: {},
      selectedHeaders: {
        position: [],
        job: [],
        entity: [],
        lang: [],
        active: [],
      },
      debouncedSearchUpdate: lodash.debounce(this.searchUpdate, 500, {
        leading: false,
        trailing: true,
        maxWait: 1000,
      }),
      filteringOptions: {},
      selectedEmployeeId: null,
    }
  },
  watch: {
    employeeFilter() {
      this.searchUpdate()
    },
  },
  computed: {
    selectedEmployeesStatus() {
      let status = undefined
      if (
        this.selectedEmployees.every(
          (employee) => employee.informations.active === true
        )
      ) {
        status = true
      } else if (
        this.selectedEmployees.every(
          (employee) => employee.informations.active === false
        )
      ) {
        status = false
      }
      return status
    },
    selectedEmployeeCount() {
      return this.selectedEmployees.length
    },
  },
  methods: {
    emptySelected() {
      this.selectedEmployees = []
    },
    getRemainingAttributes(attributes) {
      return attributes
        .slice(1)
        .map((attribute) => lodash.capitalize(attribute))
        .join(", ")
    },
    searchUpdate() {
      this.refreshEmployees(1)
    },
    changeListOptions(options) {
      this.sortOptions = {}
      this.currentPage = options.page
      this.itemsPerPage = options.itemsPerPage
      for (let sortIndex = 0; sortIndex < options.sortBy.length; sortIndex++) {
        let attributes = options.sortBy[sortIndex].split(".")
        const cleanedAttribute = attributes.pop()
        const sortName =
          attributes[0] === "informations"
            ? `sorts[${cleanedAttribute}]`
            : `sort_${attributes[0]}[${cleanedAttribute}]`
        this.sortOptions[sortName] = options.sortDesc[sortIndex]
          ? "desc"
          : "asc"
      }
      this.refreshEmployees()
    },
    updateFilters(filterParams) {
      this.selectedHeaders = filterParams.selectedHeaders
      this.employeeFilter = filterParams.employeeFilter
      this.refreshEmployees(1)
    },
    refreshEmployees(pageNumber) {},
    getSummaryImage(summary) {
      if (!summary) {
        return null
      } else if (summary.submitted_data > 0) {
        return EnteredData
      } else if (summary.clicked > 0) {
        return ClickedLinks
      } else if (summary.sent > 0) {
        return SentEmails
      } else {
        return null
      }
    },
    getTrainedImage(summary) {
      if (summary?.trained > 0) {
        return Trained
      } else {
        return null
      }
    },
    getEmailReportedImage(summary) {
      if (summary?.email_reported > 0) {
        return Report
      } else {
        return null
      }
    },
    getParams(page_number = this.currentPage) {
      let params = {
        page_size: this.itemsPerPage,
        page_number,
      }
      if (this.employeeFilter) {
        params.filter_by_results = this.employeeFilter
      }
      if (this.search !== "") {
        params.email_or_name = this.search
      }
      if (this.selectedHeaders.lang.length > 0) {
        params["filters[lang]"] = this.selectedHeaders.lang.join(",")
      }
      if (this.selectedHeaders.entity.length > 0) {
        params["filters[entity]"] = this.selectedHeaders.entity.join(",")
      }
      if (this.selectedHeaders.job.length > 0) {
        params["filters[job]"] = this.selectedHeaders.job.join(",")
      }
      if (this.selectedHeaders.position.length > 0) {
        params["filters[position]"] = this.selectedHeaders.position.join(",")
      }
      if (this.selectedHeaders.active.length > 0) {
        params["filters[active]"] = this.selectedHeaders.active.join(",")
      }
      return params
    },
    setHeaderFilters(filteringOptions) {
      if (filteringOptions) {
        this.headerFilters = {
          position: [],
          job: filteringOptions
            ? filteringOptions.jobs.map((j) => {
                return { value: j, text: lodash.capitalize(j) }
              })
            : [],
          entity: filteringOptions
            ? filteringOptions?.entities.map((e) => {
                return { value: e, text: e }
              })
            : [],
          lang: filteringOptions
            ? filteringOptions.lang.map((l) => {
                return { value: l, text: lodash.capitalize(l) }
              })
            : [],
        }
      }
    },
  },
}

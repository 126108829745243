<template>
  <v-card v-bind="$attrs" style="position: relative">
    <div
      v-if="$vuetify.breakpoint[mobileBreakpoint]"
      :style="{ width: '100vw', height: '20vh' }"
    >
      <v-img :src="article.img" :lazy-src="article.img_lazy"> </v-img>
    </div>

    <v-container class="py-0">
      <div
        v-if="!$vuetify.breakpoint[mobileBreakpoint]"
        id="floating-image"
        :style="{
          width: '50vw',
          height: 'calc(70vh - ' + toolbarHeight + 'px)',
        }"
      >
        <v-img height="100%" :src="article.img" :lazy-src="article.img_lazy">
        </v-img>
      </div>

      <v-row
        :align="!$vuetify.breakpoint[mobileBreakpoint] ? 'center' : 'start'"
        :style="{
          height: !$vuetify.breakpoint[mobileBreakpoint]
            ? 'calc(70vh - ' + toolbarHeight + 'px)'
            : null,
        }"
      >
        <v-col cols="12" sm="8" md="6">
          <v-card flat tile color="transparent">
            <v-card-actions>
              <v-chip v-for="tag in tags" :key="tag.id" filter>
                {{ tag.text }}
              </v-chip>
            </v-card-actions>

            <v-card-actions class="headline font-weight-bold">
              {{ article.title }}
            </v-card-actions>

            <v-card-actions class="headline font-weight-bold">
              <v-chip filter :x-large="!$vuetify.breakpoint[mobileBreakpoint]">
                <v-list-item class="px-0">
                  <v-list-item-action class="py-0">
                    <v-avatar>
                      <v-img
                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                      ></v-img>
                    </v-avatar>
                  </v-list-item-action>

                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      {{ author.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ article.created_at }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-chip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <br />
      <br />

      <v-row>
        <v-col md="7" class="article-p">
          <div v-if="article.content" v-html="article.content"></div>

          <slot v-else name="content"> </slot>
        </v-col>

        <v-col
          cols="12"
          md="5"
          justify="center"
          v-if="!$vuetify.breakpoint[mobileBreakpoint]"
          :order="$vuetify.breakpoint[mobileBreakpoint] ? 'first' : 'last'"
        >
          <op-diagnostic-card
            :to="routeCta"
            submitBtnClass="white--text"
            title="Évaluez le niveau de cyber-sécurité de votre entreprise en 1 minute"
            description="Obtenez des recommandations gratuites immédiatement, sans inscription."
            submitBtnText="Commencer mon diagnoctic"
            background-image="rocket"
          >
          </op-diagnostic-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style lang="scss" scoped>
.v-chip {
  max-width: 75%;

  .v-avatar {
    height: 45px !important;
    min-width: 45px !important;
    width: 45px !important;
  }
}

.article-p {
  p {
    padding-bottom: 30px;
  }
}

#floating-image {
  position: absolute;
  right: 0;
}
</style>

<script>
import Helpers from "../../services/Helpers"

export default {
  name: "op-article",

  data: () => ({
    email: "",
    user: {
      avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      first_name: "Jérome",
      last_name: "Pénichon",
    },

    mobileBreakpoint: "smAndDown",
  }),

  props: {
    toolbarHeight: {
      type: Number,
      default: 0,
    },
    loading: Boolean,
    value: Object,
    routeCta: Object,
    tags: Array,
    author: {
      type: Object,
      validator: object => Helpers.propertiesRequired(object, ["name"]),
    },
  },

  computed: {
    article: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },

  methods: {
    setAnswer(key, $event) {
      this.$set(this.answers, key, $event)
    },

    submit() {
      this.$emit("submit", this.answers)
    },
  },
}
</script>

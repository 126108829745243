<template>
  <div
    v-if="systems.length > 0"
    :class="{
      'os-list': true,
      small: size == 'small',
      medium: size == 'medium',
      large: size == 'large',
      'with-background': enableBackground,
    }"
  >
    <img
      v-for="system in systems"
      :key="system"
      :src="logoImages[system]"
      :title="system"
    />
  </div>
</template>

<script>
import AppleLogo from "../../assets/apple-logo.svg"
import LinuxLogo from "../../assets/linux-logo.svg"
import WindowsLogo from "../../assets/windows-logo.svg"

export default {
  name: "op-operating-system-list",
  data() {
    return {
      logoImages: {
        "mac-os": AppleLogo,
        linux: LinuxLogo,
        windows: WindowsLogo,
      },
    }
  },
  props: {
    size: {
      type: String,
      required: false,
      default() {
        return "medium"
      },
    },
    enableBackground: {
      type: Boolean,
      required: false,
      default() {
        return true
      },
    },
    requirements: {
      type: Array,
      required: true,
    },
  },
  computed: {
    systems() {
      let processedSystems = { windows: false, "mac-os": false, linux: false }
      if (!this.requirements) {
        return []
      }
      this.requirements.forEach(function(requirement) {
        if (requirement.category == "operating_system") {
          processedSystems[requirement.description] = true
        }
      })
      return ["windows", "mac-os", "linux"].filter(
        os => processedSystems[os] == true
      )
    },
  },
}
</script>

<style lang="scss">
.os-list {
  display: flex;
  flex-direction: row;
  padding: 9px 10px;

  &.with-background {
    background-color: white;
    border: solid 1px #f0f0f0;
  }

  &.small {
    > img {
      margin: 0 5px;
      height: 0.857em;
    }
  }

  &.medium {
    > img {
      margin: 0 8px;
      height: 1em;
    }
  }

  &.large {
    > img {
      margin: 0 8px;
      height: 1.143em;
    }
  }
}
</style>

<template>
  <v-card rounded="lg" outlined width="100%" height="100%">
    <div class="pt-5 pb-4 px-7 d-flex align-center" :style="titleStyle">
      <img :src="icons[icon]" class="mr-4" />
      <h3 class="text-h3 font-weight-regular">
        {{ title }}
      </h3>
    </div>
    <v-card-text class="d-flex align-start pt-7 pr-3 pb-6 pl-8"
      ><div class="text-h4" v-html="description"></div>
      <v-btn
        color="primary"
        class="text-none rounded-lg font-weight-bold ml-3"
        dark
        small
        elevation="0"
        :to="route"
        :target="target"
        :href="href"
        >{{ buttonLabel }}
      </v-btn></v-card-text
    >
  </v-card>
</template>
<script>
import SurveillanceIcon from "@/assets/card/surveillance.svg"
import RGPDIcon from "@/assets/card/rgpd.svg"

export default {
  name: "op-dashboard-card",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    titleBackgroundColor: {
      type: String,
      default: function () {
        return "none"
      },
    },
    titleColor: {
      type: String,
      default: function () {
        return "inherit"
      },
    },
    icon: {
      type: String,
      required: false,
    },
    route: {
      type: Object,
      required: false,
    },
    target: {
      type: String,
      default: function () {
        return "_self"
      },
    },
    href: {
      type: String,
    },
  },
  data() {
    return {
      icons: { surveillance: SurveillanceIcon, rgpd: RGPDIcon },
    }
  },
  computed: {
    titleStyle() {
      return {
        "background-color": this.titleBackgroundColor,
        color: this.titleColor,
      }
    },
  },
}
</script>

<template>
  <LineChart
    v-if="isDataAvailable"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :height="height"
  />
  <div class="text-body-1" v-else>
    {{ $t("phishingSimulations.charts.noEvolutionData") }}
  </div>
</template>
<script>
import { Line as LineChart } from "vue-chartjs/legacy"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler,
} from "chart.js"
import "chartjs-adapter-luxon"
import lodash from "lodash"
import { titleOptions } from "../ChartOptions"

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler
)

export default {
  name: "op-phishing-evolution-line",
  components: { LineChart },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default() {
        return 400
      },
    },
  },
  computed: {
    isDataAvailable() {
      return this.chartData.datasets.some((set) => !lodash.isEmpty(set.data))
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        pointLabelFontSize: 5,
        scales: {
          xMainAxis: {
            grid: {
              display: true,
              borderColor: "transparent",
            },
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              callback: function (val) {
                const title = this.getLabelForValue(val).split("|")[0]
                return lodash.truncate(title, {
                  length: 25,
                  separator: " ",
                })
              },
            },
          },
          xSubAxis: {
            grid: {
              display: false,
              borderColor: "transparent",
            },
            gridLines: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            ticks: {
              callback: function (val) {
                const date = this.getLabelForValue(val).split("|")[1]
                return date
              },
              padding: 0,
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 1,
            },
            min: 0,
          },
        },
        plugins: {
          filler: {
            propagate: false,
          },
          legend: {
            display: true,
            title: {
              display: true,
              text: this.$t("phishingSimulations.charts.evolutionTitle"),
              ...titleOptions,
            },
            labels: {
              textAlign: "right",
              usePointStyle: true,
              pointStyleWidth: 10,
              boxHeight: 7.5,
            },
          },
        },
      },
    }
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.systems.length > 0)?_c('div',{class:{
    'os-list': true,
    small: _vm.size == 'small',
    medium: _vm.size == 'medium',
    large: _vm.size == 'large',
    'with-background': _vm.enableBackground,
  }},_vm._l((_vm.systems),function(system){return _c('img',{key:system,attrs:{"src":_vm.logoImages[system],"title":system}})}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
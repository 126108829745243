<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M7.716 4.526L21.304 18 7.716 31.474a1 1 0 0 0 0 1.42l2.428 2.408a1 1 0 0 0 1.408 0L28.284 18.71a1 1 0 0 0 0-1.42L11.552.698a1 1 0 0 0-1.408 0L7.716 3.106a1 1 0 0 0 0 1.42z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "op-store-breadcrumb-arrow",
}
</script>

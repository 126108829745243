<template>
  <div>
    <v-card-title class="text-h3">Chiffres-clés</v-card-title>
    <v-container>
      <v-row v-for="line in lines" :key="line.id">
        <v-col cols="10" class="marine-blue--text text-body-1">
          <v-progress-linear
            :value="getPercentageValue(line.attribute)"
            :height="30"
            background-color="grey"
            background-opacity="0.3"
            :color="$colors[line.color[0]][line.color[1]]"
            class="course-progress"
            :indeterminate="!summary"
          >
            <template v-slot:default>
              <span
                v-if="summary"
                :class="`${line.color[0]}--text text--darken-3`"
                >{{
                  $tc(
                    `courses.${line.attribute}Count`,
                    getValue(line.attribute)
                  )
                }}</span
              >
            </template>
          </v-progress-linear></v-col
        >
        <v-col
          v-if="summary"
          cols="2"
          class="grey-blue--text text-body-1 d-flex justify-end align-center"
          >{{ `${getPercentageValue(line.attribute)}%` }}</v-col
        >
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "op-course-success-key-numbers",
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lines: [
        {
          attribute: "success",
          color: ["teal", "lighten-5"],
        },
        {
          attribute: "failed",
          color: ["yellow", "lighten-5"],
        },
        {
          attribute: "notStarted",
          color: ["red", "lighten-5"],
        },
      ],
    }
  },
  methods: {
    getValue(attribute) {
      let value
      switch (attribute) {
        case "success":
          value = this.summary.success
          break
        case "failed":
          value = this.summary.finished - this.summary.success
          break
        case "notStarted":
          value =
            this.summary.employees_count -
            this.summary.finished -
            this.summary.missing
          break
        default:
          value = ""
          break
      }
      return value
    },
    getPercentageValue(attribute) {
      const totalEmployees = this.summary.employees_count - this.summary.missing
      return totalEmployees === 0
        ? 0
        : Math.round((100 * this.getValue(attribute)) / totalEmployees)
    },
  },
}
</script>
<style lang="scss">
.course-progress .v-progress-linear__content {
  left: 1rem;
  justify-content: left;
}
</style>

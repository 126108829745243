import Model from "./Model"

class ModelList {
  constructor(data) {
    this.list = []
    this.replace(data)
  }

  replace(data) {
    let included = data["included"]

    if (data instanceof Array) {
      this.list = []

      for (let key in data) {
        if (key !== "included") {
          let includedFiltered = this.getAllIncluded(data[key], included)

          this.list.push(
            new Model({
              included: includedFiltered,
              ...data[key],
            })
          )
        }
      }
    }
  }

  getAllIncluded(data = {}, included = []) {
    let includedFiltered = []

    if (Object.prototype.hasOwnProperty.call(data, "relationships")) {
      Object.entries(data.relationships).forEach(([key, relationship]) => {
        let item = null

        if (Array.isArray(relationship.data)) {
          relationship.data.map(value => {
            item = included.find(
              item => item.type === key && item.id === value.id
            )
          })
        } else {
          item = included.find(
            item => item.type === key && item.id === relationship.id
          )
        }

        if (item) {
          includedFiltered.push(item)
        }
      })
    }

    return includedFiltered
  }

  getData() {
    return this.list
  }
}

export default ModelList

export default Object.freeze({
  "green-dark": "#31655A",
  "green-blue": "#009BA4",
  "dark-sea-green": "#0f8e41",

  primary: {
    base: "#1AC8AA",
    darken1: "#0C994A",
    darken2: "#314839",
  },
  green: {
    base: "#1AC8AA",
    darken1: "#0C994A",
    darken2: "#314839",
  },
  "blue-dark": "#003766",
  blue: {
    base: "#02549C",
    lighten1: "#0383B4",
    lighten2: "#05B1CC",
    neon: "#06DCE3",
  },
  creamy: {
    base: "#fafafa",
    darken1: "#F2F2F2",
  },
  yellow: { base: "#face32", "lighten-5": "#f2eabd", "darken-3": "#ca8a21" },
  red: { base: "#b50013", "lighten-5": "#fbd5da" },
  teal: {
    base: "#009BA4",
    "lighten-5": "#e0f2f1",
  },

  grey: { base: "#DEDEDE" },
  "very-light-grey": "#92929d",
  "light-grey": "#f7f8f8",
  "charcoal-grey": "#40454e",
  "grey-dark": "#798190",
  "black-light": "#424242",
  "black-dark": "#303030",
  "oxford-blue": "#313949",
  "quite-dark": "#44444F",
  "grey-blue": "#809FB8",
  background: "#FFFFFF",
  whitesmoke: "#F5F5F5",
  "ice-blue": "#eef2f6",
  "marine-blue": "#00305b",
  "dark-indigo": "#081124",
  steel: "#798190",
  "very-light-pink": "#fbfafa",
  "steel-blue": "#0D2646",
  topaz: "#1ac8aa",

  // Examples

  // primary: '#ee44aa',
  // secondary: '#424242',
  // accent: '#82B1FF',
  // error: '#FF5252',
  // info: '#2196F3',
  // success: '#4CAF50',
  // warning: '#FFC107'
})

<template>
  <v-card v-bind="$attrs">
    <div style="width: 200px; margin: 0 auto; margin-bottom: 5%; padding: 5px">
      <a :href="$links.oppens_site">
        <v-img :alt="`${companyName} logo`" :src="logo" width="99%"> </v-img>
      </a>
    </div>

    <template v-if="index >= 2">
      <v-card-text class="text-center">
        {{ $t("register.finished") }}
      </v-card-text>
      <v-card-text class="mt-2 text-center">
        <v-btn text color="primary" :to="{ name: 'login' }" class="text-none">
          {{ $t("label.connexionScreen") }}
        </v-btn>
      </v-card-text>
    </template>
    <template v-else>
      <v-card-title class="title font-weight-bold" v-if="false == displayLogo">
        {{ $t("register.title") }}
      </v-card-title>

      <v-card flat class="grey--bg mx-3">
        <v-row class="mx-0">
          <v-col
            class="pa-0"
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            align-self="center"
            cols="6"
          >
            <v-card
              :flat="tabIndex !== index"
              :tile="tabIndex !== index"
              :class="tabIndex === index ? 'primary--border' : 'transparent'"
            >
              <v-list-item @click="itemClick(tabIndex)">
                <v-list-item-avatar>
                  <v-avatar
                    :color="tabIndex === index ? 'primary' : 'grey-dark'"
                    size="30"
                  >
                    <div class="white--text">{{ tabIndex + 1 }}</div>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-card-text
                    class="pa-0"
                    :class="
                      tabIndex === index
                        ? 'primary--text'
                        : 'grey-dark--text text--darken-1'
                    "
                  >
                    {{ $t(tab.text) }}
                  </v-card-text>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-window :value="index" @input="$emit('update:index', $event)">
        <v-window-item>
          <op-form ref="emailForm">
            <v-card-text>
              {{ $t("register.description") }}
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                color="blue-dark"
                background-color="white"
                v-model="email"
                name="email"
                rules="required|email"
                :label="$t('label.email')"
                outlined
                floating
                dense
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0 text-center">
              <v-btn
                block
                large
                color="primary"
                dark
                class="text-none"
                @click="validateEmail"
              >
                {{ $t("label.validate") }}
              </v-btn>
            </v-card-text>
          </op-form>
        </v-window-item>
        <v-window-item>
          <op-form ref="passwordForm">
            <v-card-text>
              {{ $t("register.password_title") }}
            </v-card-text>

            <v-card-text class="py-2 pb-3 px-3">
              <op-text-field
                color="blue-dark"
                background-color="white"
                v-model="password"
                name="password"
                rule="required|strong_password"
                :label="$t('label.password')"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                hide-details
                outlined
                floating
                dense
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3">
              <v-text-field
                color="blue-dark"
                background-color="white"
                v-model="passwordConfirmation"
                :label="$t('label.password_confirmation')"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                hide-details
                outlined
                floating
                dense
              >
              </v-text-field>
            </v-card-text>

            <!--v-card-actions-->
            <v-card-text class="px-3 pa-0 text-center validations">
              <v-checkbox v-model="cgu" color="primary">
                <template v-slot:label>
                  <div class="links">
                    J'accepte les
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://www.oppens.fr/conditions-generales-utilisation"
                          @click.stop
                          v-on="on"
                        >
                          Conditions Générales d'Utilisations
                        </a>
                      </template>
                      {{ $t("register.linkTooltip") }}
                    </v-tooltip>
                    (CGU) et la

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://www.oppens.fr/politique-confidentialite"
                          @click.stop
                          v-on="on"
                        >
                          Politique de Confidentialité
                        </a>
                      </template>
                      {{ $t("register.linkTooltip") }}
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox v-model="newsletter" color="primary">
                <template v-slot:label>
                  <div class="links">
                    {{ $t("label.newsletterSubscription") }}
                  </div>
                </template>
              </v-checkbox>
            </v-card-text>

            <!--v-card-actions-->
            <v-card-text class="px-3 pa-0 text-center">
              <v-btn
                block
                large
                color="primary"
                dark
                class="text-none"
                @click="submit"
                :loading="loading"
              >
                {{ $t("label.validate") }}
              </v-btn>
            </v-card-text>
            <!--/v-card-actions-->
          </op-form>
        </v-window-item>
        <v-window-item>
          <v-card-title class="title justify-center font-weight-bold">
            {{ $t("register.welcome_title") }}
          </v-card-title>

          <v-card-text class="text-center">
            {{ $t("register.welcome_description") }}
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <v-btn
              block
              large
              dark
              color="primary"
              class="text-none"
              :to="redirectionRoute"
            >
              {{ $t("register.redirection_btn") }}
            </v-btn>
          </v-card-text>
        </v-window-item>
      </v-window>

      <v-card-text class="px-3 pa-0 mt-2 text-center">
        <v-btn text color="primary" :to="{ name: 'login' }" class="text-none">
          {{ $t("label.already_have_account") }}
        </v-btn>
      </v-card-text></template
    >
  </v-card>
</template>
<style lang="scss" scoped>
.validations {
  margin-top: 20px;

  .links {
    font-size: 0.875rem;
    //font-size: 0.75rem !important;
    font-weight: 400;
  }

  .v-input--selection-controls {
    margin-top: 0px;
  }

  .v-input__slot {
    margin-bottom: 0px;
  }
}
</style>

<script>
import UserLogic from "@/logics/UserLogic"

export default {
  name: "op-register-card",
  data: () => ({
    cgu: null,
    newsletter: null,
    email: null,
    password: null,
    passwordConfirmation: null,
    showPassword: false,
    tabs: [{ text: "label.email" }, { text: "label.password" }],
    warning: null,
    score: null,
  }),
  props: {
    loading: Boolean,
    displayLogo: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    redirectionRoute: {
      type: Object,
      default: () => ({
        name: "member-dashboard",
      }),
    },
  },
  computed: {
    emailForm() {
      return this.$refs.emailForm
    },
    passwordForm() {
      return this.$refs.passwordForm
    },
    logo() {
      return this.$customizationStore.getLogos()?.default
    },
    companyName() {
      return this.$customizationStore.getCompany()?.name
    },
  },
  methods: {
    async validateEmail() {
      const isValid = await this.emailForm.validate()

      if (isValid) {
        this.$emit("update:index", this.index + 1)
      }
    },
    submit() {
      if (!this.cgu) {
        this.passwordForm.displayError(
          "Veuillez accepter les CGU et la politique de confidentialité"
        )
        return false
      }
      if (this.newsletter) {
        UserLogic.registerToNewsletter(this.email)
          .then(() => {
            //console.log("successfully added user to newsletter")
          })
          .catch(() => {
            //console.error("Failed to add user to newsletter")
          })
      }
      this.$emit("submit", {
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      })
    },
    displayError(response) {
      this.passwordForm.displayError(response)
    },
    itemClick(index) {
      this.$emit("update:index", index)
    },
  },
}
</script>

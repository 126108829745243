<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData" :height="height" />
</template>
<script>
import { Bar } from "vue-chartjs/legacy"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
} from "chart.js"
import "chartjs-adapter-luxon"
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement
)

export default {
  name: "op-phishing-event-summary-bar",
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default() {
        return 400
      },
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            stacked: true,
            time: {
              // Luxon format string
              tooltipFormat: "DD",
              unit: "day",
            },
            grid: {
              display: false,
              borderColor: "transparent",
            },
          },
          y2: {
            stacked: true,
            stack: "actions",
            reverse: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            stack: "actions",
            grid: {
              display: false,
            },
          },
        },
      },
    }
  },
}
</script>

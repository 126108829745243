import lodash from "lodash"
import Active from "@/assets/active.svg"
import Inactive from "@/assets/inactive.svg"

export default {
  data() {
    return {
      itemsPerPageOptions: [10, 25, 50, 100, 250, 500],
      search: "",
      currentPage: 1,
      itemsPerPage: 25,
      headers: [],
      hideableColumns: [],
      headerClass: "text-body-2 font-weight-bold",
    }
  },
  methods: {
    updateColumnSelection(columns) {
      this.headers = lodash.clone(this.STATIC_HEADERS)
      for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
        const element = columns[columnIndex]
        this.headers = this.headers.concat(this[element.value])
      }
      this.headers = this.headers.concat(this.ACTION_HEADERS)
    },
    getActiveIcon(active) {
      if (active) {
        return Active
      } else {
        return Inactive
      }
    },
  },
}

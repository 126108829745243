import ProductRepository from "../repositories/ProductRepository"
import ProductPackRepository from "../repositories/ProductPackRepository"
import ModelList from "../models/ModelList"
import Model from "../models/Model"

export default class ProductLogic {
  static async getAll() {
    const response = await ProductRepository.getAll(/*params*/)
    return new ModelList(response.dataObject())
  }

  static async getByCategoryId(categoryId) {
    const response = await ProductRepository.getByCategoryId(categoryId)
    return new ModelList(response.dataObject())
  }

  /**
   * TODO should be possible with only one request
   * @param uuids
   * @param params
   * @returns {Promise}
   */
  static async getSome(uuids, params = {}) {
    let responses = []
    for (const uuid of uuids) {
      const response = await ProductRepository.getOne(uuid, params)
      responses.push(new Model(response.dataObject()))
    }

    return responses
  }

  /**
   *
   * @param uuid
   * @param params
   * @returns {Promise}
   */
  static async getOne(uuid, params = {}, includedRelationships = []) {
    const response = await ProductRepository.getOne(uuid, params)
    let model = new Model(response.dataObject())
    includedRelationships.forEach(async relationshipName => {
      switch (relationshipName) {
        case "packs": {
          const packs = await ProductPackRepository.getByProductId(
            model.id,
            params
          )
          model.relationships.packs = new ModelList(packs.dataObject())
          break
        }
      }
    })
    return model
  }

  static getEmptyObject(params) {
    return Object.assign(
      {
        id: null,
        name: null,
        slug: null,
        developer: null,
        pricing: null,
        description: null,
        tags: null,
        version: null,
        arguments: null,
        buy_url: null,
        comments: null,
        little_extra: null,
        logo: null,
        media: null,
        requirements: null,
        threats: null,
        main_score: null,
      },
      params
    )
  }

  /**
   * @param products
   * @returns {Promise}
   */
  static async getPricing(products, packId) {
    let body = {
      products: [],
    }

    for (const product of products) {
      body.products.push({
        id: product.id,
        quantity: parseInt(
          product.attributes.checkout_quantity ||
            product.attributes.checkout_config.quantity_min
        ),
      })
    }

    if (packId) {
      body.packId = packId
    }

    console.log("requesting pricing with parameters: ", body)

    const response = await ProductRepository.getPricing(body)

    if (response && response.response.data) {
      return response.response.data.pricing
    }

    return null
  }
}

import _ from "lodash"
import Helpers from "../services/Helpers"
import UserRepository from "../repositories/UserRepository"
import ModelList from "../models/ModelList"
import User from "../models/User"
import Model from "../models/Model"
import AuthStore from "../stores/AuthStore"

export default class UserLogic {
  static async getMe() {
    let me = AuthStore.state.me
    if (me) {
      return me
    }
    return null
  }

  static async updateUser(user) {
    if (user && user.uuid) {
      const uuid = user.uuid
      await UserRepository.patchOne(uuid, user)
    }
  }

  static async getAll(pagination) {
    let params = Object.assign(Helpers.parsePagination(pagination))
    const response = await UserRepository.getAll(params)
    let userList = new ModelList(User)
    userList.replace(response.dataObject())

    return {
      data: userList.list.map((userModel) => ({
        id: userModel.id,
        ...userModel.getAttributes(),
      })),
      pagination: Helpers.syncPagination(pagination, response.getPagination()),
    }
  }

  /**
   * @returns {Promise}
   */
  static async createOne(body = {}) {
    const response = await UserRepository.createOne(body)
    return new Model(response.dataObject())
  }

  static getEmptyObject(params) {
    return Object.assign(
      {
        id: null,
        uuid: null,
        last_name: "",
        first_name: "",
        username: null,
        email: null,
        mobile_number: null,
        birthday: null,
        gender: null,
        is_enabled: true,
      },
      params
    )
  }

  static async registerToNewsletter(email) {
    if (email) {
      await UserRepository.registerToNewsletter(email)
    }
  }

  static async getPermissions() {
    const response = await UserRepository.getPermissions()
    if (response.status_code === 200) {
      return response.dataObject()
    }
  }

  static async getNotifications(unread = true) {
    const response = await UserRepository.getNotifications(unread)
    return response.responseObject().data
  }

  static async markNotificationAsRead(notificationId) {
    const response = await UserRepository.markNotificationAsRead(notificationId)
    return response.responseObject()
  }

  static async markAllAsRead() {
    const response = await UserRepository.markAllAsRead()
    return response.responseObject()
  }
}

import DefaultLayout from "../views/DefaultLayout"
import LazyLoad from "../services/LazyLoad"
import AdminLayout from "../views/AdminLayout"
import ViewStorePaymentResult from "../views/member/store/ViewStorePaymentResult"
import NestedRouteLayout from "../components/NestedRouteLayout"

import AuthLogic from "@/logics/AuthLogic"
import PageStore from "@/stores/PageStore"
import CustomizationStore from "@/stores/CustomizationStore"
import Request from "@/services/Request";

const defaultTopicSlug = "identite-mot-de-passe"

const routes = [
  {
    path: "/",
    name: "root",
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      if (to.name === "root") {
        next({ name: "member-dashboard" })
      } else if (CustomizationStore.getDisabledRoutes().includes(to.name)) {
        next({
          name: "access-denied",
        })
      } else {
        next()
      }
    },
    children: [
      {
        path: "logout",
        name: "logout",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          const logoutUrl= CustomizationStore?.getLogoutUrl();
          AuthLogic.logout().then(() => {
            const storeRoutes = [
              "store_main_home",
              "store_product",
              "store_category",
              "store_pack",
              "store_checkout",
            ]
            const routeName = from.name
            if (routeName && storeRoutes.includes(routeName)) {
              next({
                name: "store_main_home",
              })
            } else {
              window.location.href = logoutUrl //oppens_site
            }
          })
        },
      },
      {
        path: "dashboard",
        name: "member-dashboard",
        component: LazyLoad.localView("member/dashboard/ViewDashboard"),
        meta: { requiresAuth: true },
      },
      {
        path: "phishing",
        name: "member-phishing",
        component: LazyLoad.localView("member/phishing/ViewPhishingOverview"),
        meta: { requiresAuth: true },
      },
      {
        path: "phishing-history",
        name: "member-phishing-history",
        component: LazyLoad.localView("member/phishing/ViewPhishingHistory"),
        meta: { requiresAuth: true },
      },
      {
        path: "phishing-simulation/:simulationId",
        name: "member-phishing-simulation",
        props: true,
        component: LazyLoad.localView("member/phishing/ViewPhishingDetail"),
        meta: { requiresAuth: true },
      },
      {
        path: "course-history",
        name: "member-course-history",
        meta: { requiresAuth: true },
        component: LazyLoad.localView("member/courses/ViewCourseHistory"),
      },
      {
        path: "course-overview",
        name: "member-course-overview",
        meta: { requiresAuth: true },
        component: LazyLoad.localView("member/courses/ViewCourseOverview"),
      },
      {
        path: "course/:courseId",
        name: "member-course",
        props: true,
        meta: { requiresAuth: true },
        component: LazyLoad.localView("member/courses/ViewCourseDetail"),
      },
      {
        path: "diagnostic",
        name: "diagnostic",
        meta: { requiresAuth: false },
        beforeEnter: (to, from, next) => {
          PageStore.showToolbar = false
          PageStore.showDrawer = false

          if (
            from.name === "member-diagnostic-survey-standalone" ||
            from.name === "member-diagnostic-result-standalone"
          ) {
            next({
              name: "member-diagnostic-survey-standalone",
              params: { topicSlug: defaultTopicSlug },
            })
          } else {
            next({
              name: "member-diagnostic-survey-standalone",
              params: { topicSlug: defaultTopicSlug },
            })
          }
        },
      },
      {
        path: "/analysis/:uuid/:topicId",
        name: "analysis",
        meta: { requiresAuth: false },
        component: LazyLoad.localView("member/analysis/ViewAnalysis"),
      },
      {
        path: "/oxibox",
        name: "oxibox",
        meta: { requiresAuth: false, showToolbar: true },
        component: LazyLoad.localView("member/partners/ViewOxibox"),
      },
      {
        path: "/eset-antimalware-cloud",
        name: "eset-antimalware-cloud",
        meta: { requiresAuth: false, showToolbar: true },
        component: LazyLoad.localView("member/partners/ViewEset"),
      },
      {
        path: "assessment",
        name: "member-assessment",
        meta: { requiresAuth: true },
        component: LazyLoad.localView("member/diagnostic/ViewDiagnostic"),
      },
      {
        path: "rgpd-scan",
        name: "member-rgpd-scan",
        meta: { requiresAuth: true },
        component: LazyLoad.localView("member/rgpd-scan/ViewRgpdScan"),
      },
      {
        path: "diagnostic/:topicSlug",
        component: LazyLoad.localView("member/diagnostic/ViewDiagnosticLayout"),
        children: [
          {
            path: "survey",
            name: "member-diagnostic-survey",
            component: LazyLoad.localView(
              "member/diagnostic/ViewDiagnosticSurvey"
            ),
          },
          {
            path: "standalone/survey",
            name: "member-diagnostic-survey-standalone",
            component: LazyLoad.localView(
              "member/diagnostic/ViewDiagnosticSurvey"
            ),
          },
          {
            path: "result",
            name: "member-diagnostic-result",
            component: LazyLoad.localView(
              "member/diagnostic/ViewDiagnosticResult"
            ),
          },
          {
            path: "standalone/result",
            name: "member-diagnostic-result-standalone",
            component: LazyLoad.localView(
              "member/diagnostic/ViewDiagnosticResult"
            ),
          },
          {
            path: "standalone/email",
            name: "member-diagnostic-result-standalone-email",
            component: LazyLoad.localView(
              "member/diagnostic/ViewDiagnosticEndAction"
            ),
          },
        ],
      },
      {
        path: "tools",
        name: "member-tools",
        meta: { requiresAuth: true },
        component: LazyLoad.localView("member/tool/ViewTool"),
      },
      {
        path: "collaborators",
        name: "member-collaborators",
        meta: { requiresAuth: true },
        component: LazyLoad.localView("member/collaborators/ViewCollaborators"),
      },
      {
        path: "access-rights",
        name: "member-access-rights",
        meta: { requiresAuth: true },
        component: LazyLoad.localView(
          "member/collaborators/ViewAccessManagement"
        ),
      },
      {
        path: "store",
        meta: {
          requiresAuth: false,
          showToolbar: true,
        },
        component: NestedRouteLayout,
        children: [
          {
            path: "pack/:packId",
            name: "store_pack",
            component: LazyLoad.localView("member/store/ViewStorePack"),
          },
          {
            path: "checkout",
            name: "store_checkout",
            props: true,
            component: LazyLoad.localView(
              "member/store/checkout/ViewStoreCheckout"
            ),
          },
          {
            path: "",
            component: LazyLoad.localView("member/store/ViewStoreTabs"),
            children: [
              {
                path: "category/:categoryId",
                name: "store_category",
                component: LazyLoad.localView("member/store/ViewStoreCategory"),
              },
              {
                path: "product/:productId",
                name: "store_product",
                props: true,
                component: LazyLoad.localView("member/store/ViewStoreProduct"),
              },
              {
                path: "",
                name: "store_main_home",
                component: LazyLoad.localView("member/store/ViewStoreHome"),
              },
              {
                path: "*",
                redirect: { name: "store_main_home" },
              },
            ],
          },
        ],
      },
      {
        path: "company-profile",
        name: "company-profile",
        meta: { requiresAuth: true },
        component: LazyLoad.localView(
          "member/company-profile/ViewCompanyProfile"
        ),
      },
      {
        path: "member-profile",
        name: "member-profile",
        meta: { requiresAuth: true },
        component: LazyLoad.localView(
          "member/member-profile/ViewMemberProfile"
        ),
      },
      {
        path: "cybercheck",
        name: "cybercheck",
        meta: { requiresAuth: true },
        component: LazyLoad.localView("member/cyber-check/ViewCyberCheck"),
      },
      {
        path: "my-documents",
        name: "my_documents",
        meta: { requiresAuth: true },
        component: LazyLoad.localView("member/files/ViewDocuments"),
      },
    ],
  },
  {
    path: "/payment-result",
    name: "store_payment_result",
    props: true,
    component: ViewStorePaymentResult,
  },
  {
    path: "/admin",
    name: "admin-layout",
    meta: { requiresAuth: true },
    component: AdminLayout,
    beforeEnter: (to, from, next) => {
      if (to.name === "admin-layout") {
        next({ name: "admin-dashboard" })
      } else {
        next()
      }
    },
    children: [
      {
        path: "dashboard",
        name: "admin-dashboard",
        component: LazyLoad.localView("admin/ViewAdminHome"),
      },
      {
        path: "users",
        name: "admin-users",
        component: LazyLoad.localView("admin/user/ViewAdminUserList"),
      },
      {
        path: "categories",
        name: "admin-categories-list",
        component: LazyLoad.localView("admin/category/ViewAdminCategoryList"),
      },
    ],
  },
  {
    path: "/article",
    name: "article",
    meta: { requiresAuth: false },
    component: LazyLoad.localView("public/ViewArticle"),
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false },
    component: LazyLoad.localView("auth/ViewLogin"),
  },
  {
    path: "/login-directly-sso",
    name: "login-directly-sso",
    meta: {requiresAuth: false},
    async component() {
      const response = await Request.make(
          "GET",
          `/login-directly-sso?type=azure-active-directory&url=${window.location.origin}`
      )

      if ('url' in response.response.data) {
        window.location = response.response.data.url
      } else {
        window.location = '/login'
      }
    },
  },
  {
    path: "/register",
    name: "register",
    meta: { requiresAuth: false },
    component: LazyLoad.localView("auth/ViewRegister"),
  },
  {
    path: "/connection",
    name: "sso-connection",
    component: LazyLoad.localView("auth/ViewConnection"),
    meta: { requiresAuth: false },
  },
  {
    // not found handler
    name: "access-denied",
    path: "/403",
    meta: { requiresAuth: false },
    component: LazyLoad.localView("ViewDeny"),
  },
  {
    path: "*",
    meta: { requiresAuth: false },
    component: LazyLoad.localView("ViewNotFound"),
  },
]

export default routes

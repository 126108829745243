export default {
  computed: {
    opAlert() {
      return this.$refs.opAlert
    },
  },
  methods: {
    displayAlert(message = this.$t("error.default")) {
      this.opAlert.displayAlert(message)
    },
  },
}

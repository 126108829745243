<template>
  <div>
    <v-card
      width="100%"
      class="pa-1"
      color="light-grey"
      rounded="lg"
      elevation="0"
      ><v-container>
        <v-row align="center" class="very-light-grey--text text-h4">
          <v-col cols="2" class="d-flex"><img width="36" :src="icon" /></v-col>
          <v-col cols="7">{{ title }}</v-col>
          <v-col cols="3" class="d-flex justify-center">{{
            numberTitle
          }}</v-col>
        </v-row>
      </v-container></v-card
    >
    <v-container>
      <template v-if="isRelevantData">
        <v-row
          v-for="(row, index) in data.slice(0, count)"
          :key="index"
          class="quite-dark--text text-h4"
        >
          <v-col cols="2"></v-col>
          <v-col cols="7" class="font-weight-medium">{{ row.email }}</v-col>
          <v-col cols="3" class="d-flex justify-center">{{
            formatFunction(row[fieldName])
          }}</v-col>
        </v-row></template
      ><template v-else
        ><div class="text-body-1">
          {{ emptyDataText }}
        </div></template
      ></v-container
    >
  </div>
</template>
<script>
export default {
  name: "op-top-chart",
  props: {
    title: {
      type: String,
      required: true,
    },
    numberTitle: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    formatFunction: {
      type: Function,
      default: (value) => value,
    },
    emptyDataText: {
      type: String,
      required: true,
    },
  },
  computed: {
    isRelevantData() {
      return this.data.length > 0
    },
  },
}
</script>

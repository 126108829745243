import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class EmployeeRepository {
  static ADD_MODE_FLUX = "flux"
  static ADD_MODE_STOCK = "stock"
  /* All */
  /**
   * @param companyUuid
   * @returns {Promise}
   */
  static getAll(companyUuid, params) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/employees`,
      {
        params,
      }
    )
  }

  /**
   * @param companyUuid
   * @param employeeId
   * @returns {Promise}
   */
  static getOne(companyUuid, employeeId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/employees/${employeeId}`
    )
  }

  /**
   * @param companyUuid
   * @param body
   * @returns {Promise}
   */
  static createOne(
    companyUuid,
    body = {},
    mode = EmployeeRepository.ADD_MODE_FLUX
  ) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees`,
      {
        employees: [body],
        mode,
      }
    )
  }

  /**
   * @param companyUuid
   * @param list
   * @param mode
   * @returns {Promise}
   */
  static createList(
    companyUuid,
    list = [],
    mode = EmployeeRepository.ADD_MODE_FLUX
  ) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees`,
      {
        employees: list,
        mode,
      }
    )
  }

  /**
   * @param companyUuid
   * @param body
   * @returns {Promise}
   */
  static activateList(companyUuid, list = []) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees/activate`,
      {
        employees: list,
      }
    )
  }

  /**
   * @param companyUuid
   * @param body
   * @returns {Promise}
   */
  static deactivateList(companyUuid, list = []) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees/deactivate`,
      {
        employees: list,
      }
    )
  }

  /**
   * @returns {Promise}
   */
  static updateOne(
    companyUuid,
    body = {},
    mode = EmployeeRepository.ADD_MODE_FLUX
  ) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees`,
      {
        employees: [body],
        mode,
      }
    )
  }

  /**
   *
   * @param companyUuid
   * @param userId
   * @returns {Promise}
   */
  static deleteOne(companyUuid, userId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/employees/${userId}`
    )
  }
}

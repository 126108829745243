import { saveAs } from "file-saver"
import FileRepository from "../repositories/FileRepository"
import UserLogic from "./UserLogic"

export default class FileLogic {
  static async getUserFiles() {
    const user = await UserLogic.getMe()
    let res = await FileRepository.getUserFiles(user.uuid)
    if (res.response && res.response.data && res.response.data.files) {
      return res.response.data.files
    }
    return []
  }

  static async downloadUserFile(file) {
    const user = await UserLogic.getMe()
    let res = await FileRepository.downloadUserFile(user.uuid, {
      file: file.name,
    })
    saveAs(res.data, file.name)
  }

  static async getInvoices() {
    let res = await FileRepository.getInvoices()
    if (res.response && res.response.data && res.response.data.invoices) {
      return res.response.data.invoices
    }
    return []
  }

  static async downloadInvoice(invoice) {
    let res = await FileRepository.downloadInvoice({ id: invoice.id })
    saveAs(res.data, `${invoice.name}.pdf`)
  }

  static async getCompanyFiles() {
    const user = await UserLogic.getMe()
    let res = await FileRepository.getCompanyFiles(user.company.uuid)
    if (res.response && res.response.data && res.response.data.files) {
      return res.response.data.files
    }
    return []
  }

  static async downloadCompanyFile(file) {
    const user = await UserLogic.getMe()
    let res = await FileRepository.downloadCompanyFile(user.company.uuid, {
      file: file.name,
    })
    saveAs(res.data, file.name)
  }
}

class CustomizationStore {
  constructor() {
    this.customization = null
  }

  setCustomization(customization = null) {
    this.customization = customization
  }

  getColors() {
    return this.customization?.colors
  }

  getDisabledRoutes() {
    return this.customization?.disabled_routes
  }

  getLogos() {
    return this.customization?.logos
  }

  getCompany() {
    return this.customization?.company
  }

  getEmails() {
    return this.customization?.emails
  }

  getPhoneNumber() {
    return this.customization?.phone_number
  }

  isReseller() {
    return this.customization?.is_reseller === true
  }

  getLogoutUrl() {
    return this.customization?.url ?? "https://www.oppens.fr/"
  }

  reset() {
    this.customization = null
  }
}

export default new CustomizationStore()

const READ = "read"
const WRITE = "write"

class AuthorizationStore {
  constructor() {
    this.permissions = null
  }

  setPermissions(permissions = null) {
    this.permissions = permissions
  }

  reset() {
    this.permissions = null
  }

  canSeePhishingSimulations() {
    return [READ, WRITE].includes(this.permissions?.system?.phishing)
  }

  canSeeLearning() {
    return [READ, WRITE].includes(this.permissions?.system?.learning)
  }
}

export default new AuthorizationStore()

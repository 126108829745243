<template>
  <v-data-table
    :items="testResults"
    :headers="headers"
    dense
    fixed-header
    :loading="isLoading"
    hide-default-footer
    class="my-2"
    :items-per-page="-1"
    :no-data-text="$t('phishingSimulations.testLogsDialog.noData')"
  >
    <template v-slot:[`item.time`]="{ item }">
      {{ getLocalDateTime(item?.time) }}
    </template>
    <template v-slot:[`item.message`]="{ item }">
      <span :class="messageClass(item)">{{ item.message }}</span>
    </template>
  </v-data-table>
</template>
<script>
import DateTimeMixins from "@/mixins/DateTime.mixins.js"
export default {
  name: "op-phishing-test-results",
  mixins: [DateTimeMixins],
  props: {
    testResults: {
      type: Array,
      default() {
        return []
      },
    },
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("phishingSimulations.tests.headers.event"),
          value: "message",
          sortable: false,
          cellClass: "text-body-1",
          class: "text-caption grey lighten-4",
          align: "center",
        },
        {
          text: this.$t("phishingSimulations.tests.headers.time"),
          value: "time",
          sortable: false,
          cellClass: "text-body-1",
          class: "text-caption grey lighten-4",
        },
        {
          text: this.$t("phishingSimulations.tests.headers.email"),
          value: "email",
          sortable: false,
          cellClass: "text-body-1",
          class: "text-caption grey lighten-4",
        },
      ],
    }
  },
  methods: {
    messageClass(item) {
      if (item.message === "Clicked Link") {
        return "primary--text font-weight-bold"
      } else {
        return ""
      }
    },
  },
}
</script>

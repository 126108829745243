<template>

  <ValidationProvider
    :mode="$attrs.mode ? $attrs.mode : 'eager'"
    :name="$attrs.name"
    :rules="rules"
    v-slot="{ errors }"
  >

    <v-select
      v-bind="$attrs"
      v-on="$listeners"
      :error-messages="errors"
      :color="$attrs.hasOwnProperty('color') ? $attrs.color : 'blue-dark'"
      :outlined="$attrs.hasOwnProperty('outlined') ? $attrs.outlined : true"
      :floating="$attrs.hasOwnProperty('floating') ? $attrs.floating : true"
      :dense="$attrs.hasOwnProperty('dense') ? $attrs.dense : true"
    >

      <!-- Pass on all named slots -->

      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

      <!-- Pass on all scoped slots -->

      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >

        <slot :name="slot" v-bind="scope" />

      </template>

    </v-select>

  </ValidationProvider>

</template>

<script>
import { ValidationProvider } from "vee-validate"

export default {
  name: "op-select",

  components: {
    ValidationProvider,
  },

  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
  },
}
</script>


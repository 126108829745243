import _ from "lodash"
import { DateTime } from "luxon"

export default class Helpers {
  /**
   * https://stackoverflow.com/a/7228322
   * @param min
   * @param max
   * @returns {number}
   */
  static randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  /**
   * https://antenna.io/blog/2017/12/custom-validator-function-that-checks-if-object-properties-exist-in-vue-js/
   * @param object
   * @param properties
   * @returns {boolean}
   */
  static propertiesRequired(object = {}, properties = []) {
    const missingProperties = properties.filter(
      property => !Object.prototype.hasOwnProperty.call(object, property)
    )

    return missingProperties.length === 0
  }

  /**
   * https://stackoverflow.com/a/2117523
   * @returns {string}
   */
  static uuidv4(length = 16) {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      const r = (Math.random() * length) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8
      return v.toString(length)
    })
  }

  /**
   * @param itemsPerPage
   * @param page
   * @param sortBy
   * @param descending
   * @returns {{itemsPerPage: *, sortDesc: [*], sortBy: [*], page: *}}
   */
  static initPagination(
    itemsPerPage = 20,
    page = 1,
    sortBy = null,
    descending = null
  ) {
    return {
      itemsPerPage,
      page,
      sortBy: [sortBy],
      sortDesc: [descending],
    }
  }

  /**
   * @param pagination
   * @returns {{page, limit: *|number}}
   */
  static parsePagination(pagination) {
    return {
      page: pagination.page,
      limit: pagination.itemsPerPage,
      sortBy: pagination.sortBy.length ? pagination.sortBy[0] : null,
      descending: pagination.sortDesc.length ? +pagination.sortDesc[0] : null,
    }
  }

  /**
   * @param currentPagination
   * @param newPagination
   */
  static syncPagination(currentPagination, newPagination) {
    let pagination = Object.assign({}, currentPagination)

    pagination.page = newPagination.current_page
    pagination.itemsPerPage = newPagination.per_page

    return pagination
  }

  /**
   * @param filters
   */
  static parseFilters(filters) {
    return _.pick(filters, filter => {
      return filter !== ""
    })
  }

  /**
   * @param date
   * @param format
   * @returns {string}
   */
  static parseDate(date, format = DateTime.DATE_SHORT) {
    return DateTime.fromISO(date).toLocaleString(format)
  }

  /**
   * @param ms
   * @returns Promise
   */
  static sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}

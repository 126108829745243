<template>
  <svg
    id="Groupe_40"
    data-name="Groupe 40"
    xmlns="http://www.w3.org/2000/svg"
    width="13.899"
    height="14.376"
    viewBox="0 0 13.899 14.376"
    class="consulting-menu-icon"
  >
    <g id="Groupe_33" data-name="Groupe 33" transform="translate(0)">
      <g id="Groupe_21" data-name="Groupe 21" transform="translate(4.456)">
        <path
          id="Tracé_41"
          data-name="Tracé 41"
          d="M270.66,773.774h-3.889a.561.561,0,0,1-.561-.561h0a.561.561,0,0,1,.561-.561h3.889a.562.562,0,0,1,.561.561h0A.562.562,0,0,1,270.66,773.774Z"
          transform="translate(-266.21 -772.651)"
        />
      </g>
      <g id="Groupe_32" data-name="Groupe 32" transform="translate(0 0.477)">
        <g id="Groupe_22" data-name="Groupe 22">
          <path
            id="Tracé_42"
            data-name="Tracé 42"
            d="M256.878,788.238a6.947,6.947,0,0,1-5.786-10.094.56.56,0,0,1,.8-.213h0a.56.56,0,0,1,.2.73,5.834,5.834,0,1,0,1.29-1.685.563.563,0,0,1-.761,0h0a.561.561,0,0,1,0-.826,6.949,6.949,0,1,1,4.26,12.086Z"
            transform="translate(-250.336 -774.351)"
          />
        </g>
        <g
          id="Groupe_23"
          data-name="Groupe 23"
          transform="translate(3.158 3.134)"
        >
          <path
            id="Tracé_43"
            data-name="Tracé 43"
            d="M262.205,793.146a.62.62,0,0,1-.59-.808l1.527-4.809a.816.816,0,0,1,.538-.534l4.692-1.449a.62.62,0,0,1,.778.768l-1.412,4.75a.815.815,0,0,1-.539.547l-4.806,1.507A.623.623,0,0,1,262.205,793.146Zm-.126-1.027h0Zm2.023-4.161-1.243,3.916,3.91-1.226,1.15-3.87Zm2.783,2.654h0Zm-2.746-2.769h0Z"
            transform="translate(-261.586 -785.518)"
          />
        </g>
        <g
          id="Groupe_24"
          data-name="Groupe 24"
          transform="translate(6.119 6.118)"
        >
          <ellipse
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="0.831"
            cy="0.831"
            rx="0.831"
            ry="0.831"
          />
        </g>
        <g
          id="Groupe_31"
          data-name="Groupe 31"
          transform="translate(1.634 1.634)"
        >
          <g id="Groupe_27" data-name="Groupe 27" transform="translate(4.969)">
            <g id="Groupe_25" data-name="Groupe 25">
              <path
                id="Tracé_44"
                data-name="Tracé 44"
                d="M274.556,780.518a.346.346,0,1,1-.346-.346A.346.346,0,0,1,274.556,780.518Z"
                transform="translate(-273.864 -780.172)"
                fill="#1ac8aa"
              />
            </g>
            <g
              id="Groupe_26"
              data-name="Groupe 26"
              transform="translate(0 9.939)"
            >
              <path
                id="Tracé_45"
                data-name="Tracé 45"
                d="M274.556,815.929a.346.346,0,1,1-.346-.346A.346.346,0,0,1,274.556,815.929Z"
                transform="translate(-273.864 -815.583)"
              />
            </g>
          </g>
          <g
            id="Groupe_30"
            data-name="Groupe 30"
            transform="translate(0 4.969)"
          >
            <g id="Groupe_28" data-name="Groupe 28">
              <path
                id="Tracé_46"
                data-name="Tracé 46"
                d="M256.5,797.877a.346.346,0,1,1-.346.346A.346.346,0,0,1,256.5,797.877Z"
                transform="translate(-256.159 -797.877)"
              />
            </g>
            <g
              id="Groupe_29"
              data-name="Groupe 29"
              transform="translate(9.939)"
            >
              <path
                id="Tracé_47"
                data-name="Tracé 47"
                d="M291.916,797.877a.346.346,0,1,1-.346.346A.346.346,0,0,1,291.916,797.877Z"
                transform="translate(-291.57 -797.877)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <path
      id="Tracé_48"
      data-name="Tracé 48"
      d="M270.66,773.774h-3.889a.561.561,0,0,1-.561-.561h0a.561.561,0,0,1,.561-.561h3.889a.562.562,0,0,1,.561.561h0A.562.562,0,0,1,270.66,773.774Z"
      transform="translate(-261.754 -772.651)"
    />
    <path
      id="Tracé_49"
      data-name="Tracé 49"
      d="M256.878,788.238a6.947,6.947,0,0,1-5.786-10.094.56.56,0,0,1,.8-.213h0a.56.56,0,0,1,.2.73,5.834,5.834,0,1,0,1.29-1.685.563.563,0,0,1-.761,0h0a.561.561,0,0,1,0-.826,6.949,6.949,0,1,1,4.26,12.086Z"
      transform="translate(-250.336 -773.874)"
    />
    <path
      id="Tracé_50"
      data-name="Tracé 50"
      d="M262.205,793.146a.62.62,0,0,1-.59-.808l1.527-4.809a.816.816,0,0,1,.538-.534l4.692-1.449a.62.62,0,0,1,.778.768l-1.412,4.75a.815.815,0,0,1-.539.547l-4.806,1.507A.623.623,0,0,1,262.205,793.146Zm-.126-1.027h0Zm2.023-4.161-1.243,3.916,3.91-1.226,1.15-3.87Zm2.783,2.654h0Zm-2.746-2.769h0Z"
      transform="translate(-258.428 -781.906)"
    />
    <ellipse
      id="Ellipse_2"
      data-name="Ellipse 2"
      cx="0.831"
      cy="0.831"
      rx="0.831"
      ry="0.831"
      transform="translate(6.119 6.595)"
    />
    <g id="Groupe_36" data-name="Groupe 36" transform="translate(6.604 2.111)">
      <g id="Groupe_34" data-name="Groupe 34">
        <path
          id="Tracé_51"
          data-name="Tracé 51"
          d="M274.556,780.518a.346.346,0,1,1-.346-.346A.346.346,0,0,1,274.556,780.518Z"
          transform="translate(-273.864 -780.172)"
        />
      </g>
      <g id="Groupe_35" data-name="Groupe 35" transform="translate(0 9.939)">
        <path
          id="Tracé_52"
          data-name="Tracé 52"
          d="M274.556,815.929a.346.346,0,1,1-.346-.346A.346.346,0,0,1,274.556,815.929Z"
          transform="translate(-273.864 -815.583)"
        />
      </g>
    </g>
    <g id="Groupe_39" data-name="Groupe 39" transform="translate(1.634 7.08)">
      <g id="Groupe_37" data-name="Groupe 37">
        <path
          id="Tracé_53"
          data-name="Tracé 53"
          d="M256.5,797.877a.346.346,0,1,1-.346.346A.346.346,0,0,1,256.5,797.877Z"
          transform="translate(-256.159 -797.877)"
        />
      </g>
      <g id="Groupe_38" data-name="Groupe 38" transform="translate(9.939)">
        <path
          id="Tracé_54"
          data-name="Tracé 54"
          d="M291.916,797.877a.346.346,0,1,1-.346.346A.346.346,0,0,1,291.916,797.877Z"
          transform="translate(-291.57 -797.877)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "op-consulting-menu-icon",
}
</script>
<style lang="scss" scoped>
.consulting-menu-icon {
  fill: currentColor;
}
</style>

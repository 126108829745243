<template>
  <ValidationProvider
    :mode="mode"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
  >
    <v-select
      v-bind="$attrs"
      v-on="$listeners"
      v-model="model"
      :items="countries"
      :error-messages="errors"
      :color="$attrs.hasOwnProperty('color') ? $attrs.color : 'blue-dark'"
      :outlined="$attrs.hasOwnProperty('outlined') ? $attrs.outlined : true"
      :floating="$attrs.hasOwnProperty('floating') ? $attrs.floating : true"
      :dense="$attrs.hasOwnProperty('dense') ? $attrs.dense : true"
    >
      <!-- Pass on all named slots -->

      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

      <!-- Pass on all scoped slots -->

      <template slot="selection" slot-scope="data">
        {{ $t("countries." + data.item.toUpperCase()) }}
      </template>

      <template slot="item" slot-scope="data">
        {{ $t("countries." + data.item.toUpperCase()) }}
      </template>
    </v-select>
  </ValidationProvider>
</template>

<script>
export default {
  name: "op-country-select",

  props: {
    name: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: "eager",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      //type: [String, Number],
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
</script>

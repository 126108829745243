<template>
  <div
    class="white border-radius-6 pt-5 pr-5 pb-6 pl-5 mb-5 d-flex align-center"
  >
    <v-img
      class="mr-3 d-none d-md-block"
      :src="product.product.logo"
      :width="logoWidth"
      :max-width="logoWidth"
      contain
    >
    </v-img>

    <div
      class="d-flex product-description"
      :class="[
        { 'flex-row': dense, 'flex-column': !dense, 'align-baseline': dense },
      ]"
    >
      <div
        class="text-body-1 marine-blue--text product-name font-weight-medium"
        :class="[{ dense: dense, 'mr-3': dense }]"
      >
        {{ product.product.name }}
      </div>

      <div class="black--text text-body-2" :class="[{ 'd-none': hideEditor }]">
        {{ product.product.developer }}
      </div>

      <div class="grey-dark--text text-body-2">
        {{
          $tc(
            "store.checkout.licence_count.product" + product.product.id,
            product.quantity ? product.quantity : 0
          )
        }}
      </div>
    </div>

    <div
      v-if="product.quantity"
      class="ml-auto text-body-1 font-weight-bold dark-indigo--text"
    >
      {{ product.total.taxExcluded | monetize }}
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/Products.mixins.js"
export default {
  name: "op-store-checkout-item",

  props: {
    product: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  mixins: [mixin],
  created() {
    let addedTranslations = { store: { checkout: { licence_count: {} } } }
    addedTranslations.store.checkout.licence_count[
      "product" + this.product.product.id
    ] = this.product.unit
      ? this.product.unit
      : this.$i18n.t("store.checkout.default_licence_count")
    this.$i18n.mergeLocaleMessage(this.$i18n.locale, addedTranslations)
  },
  computed: {
    logoWidth() {
      return this.dense ? "22px" : "62px"
    },
    hideEditor() {
      return this.dense ? true : false
    },
    quantity() {
      return this.productQuantity(this.product)
    },
  },
}
</script>

<style lang="scss" scoped>
.product-description {
  overflow: hidden;

  .product-name {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.dense {
      max-width: 90px;
    }
  }
}
</style>

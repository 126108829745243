<template>
  <v-dialog v-model="dialogOpened" fullscreen eager>
    <v-card class="pa-12">
      <v-card-title>{{ title }}</v-card-title>
      <op-alert ref="opAlert" color="red" />
      <v-container fluid class="px-12">
        <v-row>
          <template v-if="preview">
            <v-col>
              <div class="preview-container">
                <v-tabs color="primary" v-model="selectedTab">
                  <v-tab>
                    {{ $t("phishingSimulations.preview.emailTab") }}
                  </v-tab>
                  <v-tab>
                    {{ $t("phishingSimulations.preview.pageTab") }}
                  </v-tab>
                  <v-tabs-items v-model="selectedTab">
                    <v-tab-item eager>
                      <div class="text-h6 my-4">
                        {{ $t("phishingSimulations.preview.title") }}
                      </div>
                      <v-card-text class="font-weight-bold frame">{{
                        $t("phishingSimulations.preview.emailObject", {
                          object: preview.email.subject,
                        })
                      }}</v-card-text>
                      <iframe
                        name="emailIframe"
                        :srcdoc="emailHtmlDoc"
                        class="frame with-subject email-iframe full-size"
                      ></iframe>
                    </v-tab-item>
                    <v-tab-item eager>
                      <div class="text-h6 my-4">
                        {{ $t("phishingSimulations.preview.title") }}
                      </div>
                      <iframe
                        name="landingPageIframe"
                        :srcdoc="landingPageHtmlDoc"
                        class="frame landingPage-iframe full-size"
                      ></iframe> </v-tab-item
                  ></v-tabs-items>
                </v-tabs></div
            ></v-col>
            <v-col sm="12" md="3" v-if="mode === 'edit'">
              <op-form ref="form">
                <v-window v-model="selectedTab">
                  <v-window-item>
                    <div class="text-h6 mt-16 mb-4">
                      {{ $t("phishingSimulations.preview.tagTitle") }}
                    </div>
                    <template v-if="templateTags?.email?.tags">
                      <template
                        v-for="(value, key, index) in templateTags.email.tags"
                      >
                        <op-color-input
                          v-if="
                            templateTags.email.tags[key].type === 'colorcode'
                          "
                          :key="index"
                          :name="`emailIframe-${templateTags.email.tags[key].name}`"
                          :label="key"
                          v-model="templateTags.email.tags[key].value"
                          @input="onInputChange"
                          class="my-4"
                        ></op-color-input>
                        <op-text-field
                          v-else-if="
                            templateTags.email.tags[key].type === 'img'
                          "
                          :key="index"
                          :name="`emailIframe-${templateTags.email.tags[key].name}`"
                          :id="`emailIframe-${templateTags.email.tags[key].name}`"
                          color="primary"
                          class="my-4"
                          rounded
                          outlined
                          hide-details="auto"
                          :label="key"
                          v-model="templateTags.email.tags[key].value"
                          @input="onInputChange" />
                        <v-textarea
                          v-else
                          :key="index"
                          :name="`emailIframe-${templateTags.email.tags[key].name}`"
                          :id="`emailIframe-${templateTags.email.tags[key].name}`"
                          color="primary"
                          class="my-4"
                          rounded
                          outlined
                          :rows="
                            templateTags.email.tags[key].type === 'html' ? 3 : 1
                          "
                          auto-grow
                          hide-details="auto"
                          :label="key"
                          v-model="templateTags.email.tags[key].value"
                          @input="onInputChange"
                      /></template>
                    </template>
                    <v-card-text v-else-if="!isLoading">{{
                      $t("phishingSimulations.preview.noTags")
                    }}</v-card-text>
                  </v-window-item>
                  <v-window-item>
                    <div class="text-h6 mt-16 mb-4">
                      {{ $t("phishingSimulations.preview.tagTitle") }}
                    </div>
                    <template v-if="templateTags?.landingPage?.tags">
                      <template
                        v-for="(value, key, index) in templateTags.landingPage
                          .tags"
                      >
                        <op-color-input
                          v-if="
                            templateTags.landingPage.tags[key].type ===
                            'colorcode'
                          "
                          :key="index"
                          :name="`landingPageIframe-${templateTags.landingPage.tags[key].name}`"
                          :label="key"
                          v-model="templateTags.landingPage.tags[key].value"
                          @input="onInputChange"
                          class="my-4"
                        ></op-color-input>
                        <op-text-field
                          v-else-if="
                            templateTags.landingPage.tags[key].type === 'img'
                          "
                          :key="index"
                          :name="`landingPageIframe-${templateTags.landingPage.tags[key].name}`"
                          :id="`landingPageIframe-${templateTags.landingPage.tags[key].name}`"
                          color="primary"
                          class="my-4"
                          rounded
                          outlined
                          hide-details="auto"
                          :label="key"
                          v-model="templateTags.landingPage.tags[key].value"
                          @input="onInputChange" />
                        <v-textarea
                          v-else
                          :key="index"
                          :name="`landingPageIframe-${templateTags.landingPage.tags[key].name}`"
                          :id="`landingPageIframe-${templateTags.landingPage.tags[key].name}`"
                          color="primary"
                          class="my-4"
                          rounded
                          outlined
                          :rows="
                            templateTags.landingPage.tags[key].type === 'html'
                              ? 3
                              : 1
                          "
                          auto-grow
                          hide-details="auto"
                          :label="key"
                          v-model="templateTags.landingPage.tags[key].value"
                          @input="onInputChange" /></template
                    ></template>
                    <v-card-text v-else-if="!isLoading">{{
                      $t("phishingSimulations.preview.noTags")
                    }}</v-card-text>
                  </v-window-item></v-window
                >
              </op-form>
            </v-col></template
          >
          <template v-else-if="isLoading">
            <v-progress-circular
              indeterminate
              :size="70"
              :width="7"
              color="primary"
              class="ma-auto"
            ></v-progress-circular
          ></template>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-alert
          v-model="showSuccessAlert"
          dense
          dismissible
          outlined
          type="success"
          transition="slide-x-transition"
          class="mb-0 mr-6"
          >{{ $t("phishingSimulations.preview.tagsSaved") }}</v-alert
        >
        <v-dialog
          v-model="confirmationDialog"
          width="500"
          v-if="
            mode === 'edit' &&
            (templateTags?.email?.tags ||
            templateTags?.landingPage?.tags)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-none rounded-lg"
              outlined
              icon
              elevation="0"
              :disabled="isLoading"
              v-bind="attrs"
              v-on="on"
              title="Reset"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-6">
            <v-card-text>
              {{
                $t("phishingSimulations.preview.revertConfirmationDescription")
              }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-none rounded-lg"
                outlined
                elevation="0"
                @click="closeConfirmationDialog"
                >{{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                class="text-none rounded-lg"
                outlined
                elevation="0"
                @click="revertChanges"
                >{{ $t("continue") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          v-if="
            mode === 'edit' &&
            (templateTags?.email?.tags || templateTags?.landingPage?.tags)
          "
          color="primary"
          class="text-none rounded-lg"
          outlined
          elevation="0"
          @click="save"
          >{{ $t("save") }}
        </v-btn>
        <v-btn
          class="text-none rounded-lg"
          outlined
          elevation="0"
          @click="close"
        >
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { cloneDeep, debounce } from "lodash"
import PhishingSimulationLogic from "@/logics/PhishingSimulationLogic"
import AlertMixin from "@/mixins/Alert.mixins.js"
function focusInput(event, frameName) {
  const tagName = event.target.getAttribute("data-oppens-tag-name")
  if (tagName) {
    const input = document.querySelector(`#${frameName}-${tagName}`)
    input.focus()
  }
}
export default {
  name: "op-phishing-exercice-preview-dialog",
  mixins: [AlertMixin],
  props: {
    simulation: {
      type: Object,
    },
    companyUuid: {
      type: String,
    },
    mode: {
      type: String,
      default: "preview",
    },
  },
  created() {
    this.parser = new DOMParser()
  },
  watch: {
    companyUuid() {
      this.loadPreview()
    },
    simulation() {
      this.loadPreview()
    },
  },
  computed: {
    dialogOpened: {
      get() {
        return this.simulation && this.companyUuid
      },
      set(value) {},
    },
    title() {
      return this.mode === "preview"
        ? this.$t("phishingSimulations.preview.title")
        : this.$t("phishingSimulations.preview.editTitle")
    },
  },
  data() {
    return {
      preview: null,
      templateTags: null,
      originalTags: null,
      selectedTab: "email",
      isLoading: false,
      confirmationDialog: false,
      emailHtmlDoc: "",
      landingPageHtmlDoc: "",
      showSuccessAlert: false,
    }
  },
  methods: {
    onInputChange() {
      this.refreshTemplate()
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false
    },
    revertChanges() {
      this.templateTags = cloneDeep(this.originalTags)
      this.closeConfirmationDialog()
      this.refreshTemplate()
    },
    refreshTemplate: debounce(async function () {
      await this.compileTemplate()
    }, 300),
    async loadPreview() {
      if (this.companyUuid && this.simulation && !this.isLoading) {
        this.isLoading = true
        try {
          this.templateTags = JSON.parse(this.simulation.template_tags)
          if (this.templateTags) {
            await this.compileTemplate()
          } else {
            this.preview =
              await PhishingSimulationLogic.getExerciseCompiledTemplates(
                this.companyUuid,
                this.simulation.phishing_exercise.id
              )
            let htmlDoc = this.parser.parseFromString(
              this.preview.email.html,
              "text/html"
            )
            this.emailHtmlDoc = htmlDoc.documentElement.innerHTML
            htmlDoc = this.parser.parseFromString(
              this.preview.landingPage.html,
              "text/html"
            )
            this.landingPageHtmlDoc = htmlDoc.documentElement.innerHTML
          }
          if (this.mode === "edit") {
            this.originalTags = cloneDeep(this.templateTags)
          }
        } catch (error) {
          this.displayAlert(error?.response?.data?.error?.message)
        } finally {
          this.isLoading = false
        }
      }
    },
    parseTags(html, htmlDocSrc, containerClass, frameName) {
      if (html) {
        const htmlDoc = this.parser.parseFromString(html, "text/html")
        if (this.mode === "edit") {
          const css =
            "span[data-oppens-tag-name]:not(:hover) { " +
            "color: #000 !important; " +
            "background-color: #ffc900 !important; " +
            "}" +
            "img[data-oppens-tag-name]:not(:hover) { " +
            "border: 3px solid #ffc900 !important; " +
            "}" +
            "img[data-oppens-tag-name]:hover { " +
            "border: 0px solid #ffc900 !important; " +
            "}" +
            "[data-oppens-tag-name] { " +
            "cursor: pointer;" +
            "transition: background-color 0.2s ease-in, border 0.2s ease-in " +
            "}"

          const style = htmlDoc.createElement("style")
          style.textContent = css

          htmlDoc.head.appendChild(style)
        }
        this[htmlDocSrc] = htmlDoc.documentElement.innerHTML
      }
    },
    setListeners(containerClass, frameName) {
      this.$nextTick(() => {
        const htmlContainer = document.querySelector(containerClass)
        htmlContainer.onload = () => {
          window.frames[frameName].addEventListener("click", (event) =>
            focusInput(event, frameName)
          )
        }
      })
    },
    removeListeners(frameName) {
      if (window.frames[frameName]) {
        window.frames[frameName].removeEventListener("click", (event) =>
          focusInput(event, frameName)
        )
      }
    },
    async compileTemplate() {
      this.isLoading = true
      try {
        this.removeListeners("emailIframe")
        this.removeListeners("landingPageIframe")
        this.preview =
          await PhishingSimulationLogic.getExerciseCompiledWithTemplateTags(
            this.companyUuid,
            this.simulation.phishing_exercise.id,
            this.templateTags
          )
        this.parseTags(
          this.preview?.email?.html,
          "emailHtmlDoc",
          ".email-iframe",
          "emailIframe"
        )
        this.parseTags(
          this.preview?.landingPage?.html,
          "landingPageHtmlDoc",
          ".landingPage-iframe",
          "landingPageIframe"
        )
        this.setListeners(".email-iframe", "emailIframe")
        this.setListeners(".landingPage-iframe", "landingPageIframe")
      } catch (error) {
        this.displayAlert(error?.response?.data?.error?.message)
      } finally {
        this.isLoading = false
      }
    },
    async save() {
      this.isLoading = true
      this.showSuccessAlert = false
      try {
        await PhishingSimulationLogic.saveSimulation(this.companyUuid, {
          id: this.simulation.id,
          template_tags: this.templateTags,
          /*
          // For now we only update the template tags
          campaign_id: this.simulation.campaign_id,
          employee_group_ids: this.simulation.employee_groups.map(
            (eg) => eg.id
          ),
          automatic_duration_days: this.simulation.automatic_duration_days,

          has_automatic_launch: this.simulation.has_automatic_launch,
          has_automatic_stop: this.simulation.has_automatic_stop,
          launch_date: this.simulation.launch_date,
          name: this.simulation.name,
          phishing_exercise_id: this.simulation.phishing_exercise_id,
          send_by_date: this.simulation.send_by_date,
          send_by_time: this.simulation.send_by_time,
          is_test: this.simulation.is_test,*/
        })
        this.showSuccessAlert = true
        this.$emit("refresh")
      } catch (error) {
        this.displayAlert(error?.response?.data?.error?.message)
      } finally {
        this.isLoading = false
      }
    },
    close() {
      this.preview = null
      this.templateTags = null
      this.selectedTab = "email"
      this.removeListeners("emailIframe")
      this.removeListeners("landingPageIframe")
      this.$emit("close")
    },
  },
}
</script>
<style lang="scss">
.frame {
  border-style: dotted;
  background-color: #fff;

  &.with-subject {
    height: calc(100% - 60px - 64px);
  }
}
.full-size {
  width: 100%;
  height: calc(100% - 64px);
  overflow: auto;
}

.preview-container {
  height: calc(100vh - 70px - 52px - 12px);
  display: flex;

  .v-window.v-item-group {
    height: calc(100% - 48px);

    .v-window__container {
      height: 100%;

      .v-window-item {
        height: 100%;
      }
    }
  }
}
</style>

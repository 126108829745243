import Request from "@/services/Request"
import { API_VERSION } from "./ApiVersion"

export default class CourseRepository {
  /* All */
  /**
   * @param uuid
   * @returns {Promise}
   */
  static getAll(uuid, params) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${uuid}/company-courses`,
      {
        params,
      }
    )
  }
  /**
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static getEmployees(companyUuid, params = {}) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/company-courses/employees`,
      { params }
    )
  }
  /**
   * @param companyUuid
   * @returns {Promise}
   */
  static getStats(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/company-courses/stats`
    )
  }

  /**
   * @param courseId
   * @returns {Promise}
   */
  static exportResults(companyUuid, courseId) {
    return Request.blob(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/company-courses/${courseId}/exports/results`
    )
  }

  /**
   * @param courseId
   * @returns {Promise}
   */
  static exportCompletedSessions(companyUuid, courseId) {
    return Request.blob(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/company-courses/${courseId}/exports/completed-sessions`
    )
  }

  /**
   * @param companyUuid
   * @param body
   * @returns {Promise}
   */
  static setMissingList(companyUuid, courseId, list = []) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/company-courses/${courseId}/employees/activate-missing`,
      {
        employees: list,
      }
    )
  }

  /**
   * @param companyUuid
   * @param body
   * @returns {Promise}
   */
  static setPresentList(companyUuid, courseId, list = []) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/company-courses/${courseId}/employees/deactivate-missing`,
      {
        employees: list,
      }
    )
  }
}

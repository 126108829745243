<template>
  <v-card width="100%" height="100%" outlined rounded="lg" class="pa-3">
    <v-card-title :class="`text-h3`">{{ title }}</v-card-title>
    <v-container fluid class="d-flex align-center flex-column">
      <v-progress-circular
        :rotate="-90"
        :size="size"
        :width="width"
        rounded
        :value="progressValue"
        color="primary"
        :class="`text-${textSize}`"
        >{{ displayTimeFromSeconds(time) }}</v-progress-circular
      >
    </v-container>
  </v-card>
</template>
<script>
import DateTimeMixin from "@/mixins/DateTime.mixins.js"
export default {
  name: "op-course-session-time",
  mixins: [DateTimeMixin],
  props: {
    title: {
      type: String,
      default() {
        return ""
      },
    },
    time: {
      type: Number,
      required: true,
    },
    target: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default() {
        return 110
      },
    },
    width: {
      type: Number,
      default() {
        return 12
      },
    },
    textSize: {
      type: String,
      default() {
        return "h3"
      },
    },
  },
  computed: {
    progressValue() {
      return this.target === 0 ? 0 : 100 * (this.time / this.target)
    },
  },
}
</script>

<template>
  <LineChart
    :chart-options="chartOptions"
    :chart-data="chartData"
    :height="height"
  />
</template>
<script>
import { Line as LineChart } from "vue-chartjs/legacy"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler,
} from "chart.js"
import "chartjs-adapter-luxon"
import { titleOptions } from "../ChartOptions"
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler
)

export default {
  name: "op-phishing-event-summary-line",
  components: { LineChart },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default() {
        return 400
      },
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              // Luxon format string
              tooltipFormat: "DD",
              unit: "day",
            },
            grid: {
              display: false,
              borderColor: "transparent",
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 1,
            },
            min: 0,
          },
        },
        plugins: {
          filler: {
            propagate: false,
          },
          legend: {
            display: true,
            title: {
              display: true,
              text: this.$t("phishingSimulations.charts.eventSummaryTitle"),
              ...titleOptions,
            },
            labels: {
              textAlign: "right",
              usePointStyle: true,
              pointStyleWidth: 10,
              boxHeight: 7.5,
            },
          },
        },
      },
    }
  },
}
</script>

<template>
  <div class="layout-col improve-with-product-card d-flex flex-column">
    <div class="improvement-header">
      <h3>{{ categoryName }}</h3>

      <div class="improvement-levels">
        <op-level-item
          :size="44"
          backgroundColor="#a0b4c9"
          :enableShadow="false"
          :textContent="initialLevel.toString()"
          :strokeWidth="3"
          strokeColor="#eef2f6"
          class="level-item"
        ></op-level-item>
        <div class="improvement-line first-part green-line"></div>
        <op-level-item
          :size="44"
          backgroundColor="#0383b4"
          :enableShadow="false"
          :textContent="product.attributes.main_score.toString()"
          :strokeWidth="0"
          class="level-item"
        ></op-level-item>
      </div>
    </div>

    <op-product-card
      :product="product"
      :displayPrice="true"
      :displayEditor="true"
      :biggerAddButton="biggerAddButton"
      :biggerCard="true"
    ></op-product-card>
  </div>
</template>

<script>
import CategoryStore from "../../stores/CategoryStore"
export default {
  name: "op-improve-with-product-card",
  data() {
    return {
      categoryName: "",
    }
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.product.relationships.categories.data[0]) {
      this.categoryName = CategoryStore.getCategoryById(
        this.product.relationships.categories.data[0].id
      ).attributes.name
    }
  },
  computed: {
    biggerAddButton() {
      const width = window.innerWidth
      if (width < 500) {
        return true
      }
      return false
    },
    initialLevel() {
      return Math.max(1, this.product.attributes.main_score - 1)
    },
  },
}
</script>

<style lang="scss">
.improve-with-product-card {
  width: 100%;
  height: 100%;

  .improvement-header {
    margin-bottom: 2.071em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 1.286em;
      font-weight: bold;
      line-height: 1.56;
      color: $dark-indigo;
    }

    .improvement-levels {
      display: flex;
      flex-direction: row;
      align-items: center;

      .level-item {
        z-index: 1;
      }

      .improvement-line {
        width: 50px;
        height: 8px;
        margin-left: -2px;
        margin-top: -4px;

        &.first-part.green-line {
          background-image: linear-gradient(263deg, #00cbd1, #02539b);

          &:before,
          &:after {
            content: "";
            width: 7px;
            height: 2px;
            display: flex;
            background: $ice-blue;
            position: absolute;
          }
          &:before {
            transform: translate(25px, 1px) rotate(45deg);
          }
          &:after {
            transform: translate(25px, 4px) rotate(-45deg);
          }
        }
      }
    }
  }
}
</style>

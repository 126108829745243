const storageKey = process.env.VUE_APP_LOCAL_STORAGE_KEY || "op"

export default class SessionStorage {
  /**
   * return the custom key
   *
   * @param key
   * @returns {string}
   */
  static getKey(key) {
    return `${storageKey}-${key}`
  }

  /**
   * Store a value.
   *
   * @param {string} key
   * @param {*} value
   *
   * @returns {SessionStorage}
   */
  static set(key, value) {
    sessionStorage.setItem(this.getKey(key), JSON.stringify(value))

    return this
  }

  /**
   * Get value from selected item.
   *
   * @param {string} key
   */
  static get(key) {
    return JSON.parse(sessionStorage.getItem(this.getKey(key)))
  }

  /**
   * Remove selected item.
   *
   * @param {string} key
   * @returns {SessionStorage}
   */
  static remove(key) {
    if (sessionStorage.getItem(this.getKey(key)) !== null) {
      sessionStorage.removeItem(this.getKey(key))
    }

    return this
  }
}

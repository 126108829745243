class PageStore {
  constructor() {
    this.title = ""
    this.breadcrumbs = []
    // permet de savoir si le drawer est activable ou pas
    this.drawer = true
    // permet de savoir si le drawer est ouvert ou pas
    this.showDrawer = true
    this.showToolbar = true
    this.dark = false
    this.storeBreadcrumbs = {
      root: "store.breadcrumb.store",
      categoryId: 0,
      productName: null,
    }

    this.previousUrl = null
    this.previousExternalUrl = null
  }

  setPageTitle(title = "") {
    this.title = title
  }

  setBreadcrumb(breadcrumbs = []) {
    this.breadcrumbs = breadcrumbs
  }

  setStoreBreadcrumbsCategoryId(categoryId) {
    this.storeBreadcrumbs.categoryId = categoryId
  }

  setStoreBreadcrumbsProductName(productName) {
    this.storeBreadcrumbs.productName = productName
  }

  resetStore() {
    this.title = ""
    this.breadcrumbs = []
    this.showDrawer = true
    this.showToolbar = true
    this.drawer = true
    this.dark = false
    this.storeBreadcrumbs = {
      root: "store.breadcrumb.store",
      categoryId: 0,
      productName: null,
    }
  }
}

export default new PageStore()

<template>
  <v-img v-if="source_url" :src="source_url" height="200px"> </v-img>
</template>

<script>
import BlogRepository from "../../repositories/BlogRepository"

export default {
  name: "op-async-img",
  computed: {},
  props: ["post"],
  data() {
    return {
      source_url: null,
    }
  },
  created: async function () {
    if (this.post.imgUrl) {
      this.source_url = this.post.imgUrl
      return
    }
    const media = await BlogRepository.getMedia(this.post.featured_media)
    if (media && media.source_url) {
      this.source_url = media.source_url
    }
  },
}
</script>

import Request from "../services/Request"

export default class CategoryRepository {
  /* All */

  /**
   * @returns {Promise}
   */
  static getAll(params) {
    return Request.make("GET", "/categories", { params })
  }

  /**
   * @returns {Promise}
   */
  static getOne(uuid, params = {}) {
    return Request.make("GET", `/categories/${uuid}`, { params })
  }

  /**
   * @returns {Promise}
   */
  static createOne(uuid, body = {}) {
    return Request.make("POST", `/categories`, body)
  }

  /**
   * @returns {Promise}
   */
  static updateOne(uuid, body = {}) {
    return Request.make("PUT", `/categories/${uuid}`, body)
  }
}

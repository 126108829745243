<template>
  <div>
    <v-skeleton-loader
      class="mx-auto"
      v-if="!dataLoaded"
      type="card"
    ></v-skeleton-loader>

    <div
      :class="{ 'store-product': true, 'is-modal': isInModal }"
      class="pa-5 pa-sm-12"
      v-else
    >
      <transition name="enter">
        <div
          v-if="showSticky && product.attributes"
          class="product-fixed-header"
          :style="fixedHeaderStyle"
        >
          <img class="product-header-logo" :src="product.attributes.logo" />

          <div class="product-header-title text-h1">
            {{ product.attributes.name }} {{ product.attributes.version }}
          </div>

          <op-operating-system-list
            :requirements="product.attributes.requirements"
            size="small"
          ></op-operating-system-list>

          <op-buy-button
            class="product-header-buy"
            :product="product"
            behavior="buy"
            displayMode="compact"
          ></op-buy-button>
        </div>
      </transition>

      <div class="store-product-info" v-if="product.attributes">
        <div>
          <div class="product-main-info mb-10" ref="mainInfo">
            <div class="icon">
              <img :src="product.attributes.logo" />
            </div>

            <div class="product-overview">
              <div class="product-title-line">
                <h1
                  :class="[
                    $vuetify.breakpoint.smAndUp ? 'text-h1' : 'headline',
                    'dark-indigo--text',
                  ]"
                >
                  {{ product.attributes.name }} {{ product.attributes.version }}
                </h1>

                <op-operating-system-list
                  :requirements="product.attributes.requirements"
                  size="small"
                ></op-operating-system-list>
              </div>

              <div class="charcoal-grey--text mb-5 text-h4 font-weight-medium">
                {{ product.attributes.developer }}
              </div>

              <op-product-tags
                :tags="product.attributes.tags"
                small
              ></op-product-tags>
            </div>

            <div class="product-actions d-none d-sm-flex">
              <op-buy-button
                :price="product.attributes.pricing"
                :product="product"
                behavior="buy"
                :buyUrl="product.attributes.buy_url"
              ></op-buy-button>

              <div
                class="product-buy-info text-body-1 font-weight-bold steel--text"
              >
                {{ product.attributes.pricing.description }}
              </div>
            </div>
          </div>

          <div class="product-actions d-sm-none mb-10">
            <op-buy-button
              :price="product.attributes.pricing"
              :product="product"
              behavior="buy"
              :buyUrl="product.attributes.buy_url"
              :block="true"
            ></op-buy-button>

            <div class="caption text-center mt-3 steel--text">
              {{ product.attributes.pricing.description }}
            </div>
          </div>

          <div
            class="mb-11 text-body-1 steel--text"
            style="max-width: 685px"
            v-html="product.attributes.description_long"
          ></div>

          <div
            class="product-slider mb-10"
            :class="{ 'one-slide': filteredMedias.length == 1 }"
            v-if="filteredMedias && filteredMedias.length > 0"
          >
            <op-slider-layout
              :items="filteredMedias"
              :navigationSize="60"
              :itemPaddingLeft="26"
              :itemPaddingRight="26"
              :itemPaddingTop="26"
              :numOfItems="numOfslides"
              :gutter="20"
              :previewWidth="40"
            >
              <op-product-media-slide
                slot-scope="{ item }"
                :item="item"
                imgMaxHeight="240px"
              ></op-product-media-slide>
            </op-slider-layout>
          </div>

          <v-container fluid class="mb-12">
            <v-row>
              <v-col cols="12">
                <h2
                  class="text-h2"
                  v-html="
                    $t('store.product.risksTitle', {
                      productName: product.attributes.name,
                      version: product.attributes.version,
                    })
                  "
                ></h2>
              </v-col>
            </v-row>
            <v-row v-if="product.attributes.threats">
              <v-col
                v-for="item in product.attributes.threats"
                :key="item.id"
                cols="12"
                md="6"
              >
                <div class="product-info-box">
                  <h3 class="text-h3">{{ item.title }}</h3>
                  <div class="text-body-1 steel--text" v-html="item.body"></div>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <op-improvement-levels
            :productName="product.attributes.name"
            :level="product.attributes.main_score"
            :categoryName="productCategoryName"
            class="mb-12"
          ></op-improvement-levels>

          <op-reason-cards
            v-if="product.attributes.arguments"
            :title="
              $t('store.product.reasonTitle', {
                productName: product.attributes.name,
              })
            "
            :reasons="product.attributes.arguments"
            titleClass="text-h2"
          ></op-reason-cards>
        </div>

        <section
          class="product-additional-info mx-n12 mx-md-0"
          :style="productPlusStyle"
        >
          <h2 class="text-h2 font-weight-bold dark-indigo--text">
            {{ $t("store.product.plusTitle") }}
          </h2>

          <div class="text-h2 font-weight-light dark-indigo--text">
            {{ product.attributes.little_extra.title }}
          </div>

          <div
            class="additional-info-description text-body-1 mt-3"
            v-html="product.attributes.little_extra.description"
          ></div>
        </section>
      </div>

      <div class="store-product-info" v-if="product.attributes">
        <section class="padded-top-bottom tip-container">
          <div class="tip-background"></div>

          <h2 class="text-h2">
            {{
              $t("store.product.tipsTitle", {
                productName: product.attributes.name,
              })
            }}
          </h2>

          <op-tip-card :showButton="false"></op-tip-card>

          <div class="tip-tutorials d-flex flex-column flex-sm-row">
            <op-video-thumbnail
              class="mr-sm-5 mb-5 mb-sm-0"
              :style="videoThumbnailStyle"
              :href="instructionalVideo.url_large"
              :thumbnailUrl="instructionalVideo.url_thumbnail"
            ></op-video-thumbnail>

            <div class="tip-tutorials-info">
              <h3 class="text-h3 dark-indigo--text font-weight-medium">
                {{ instructionalVideo.title }}
              </h3>

              <div
                class="tip-tutorials-info-description steel--text text-body-1"
                v-html="instructionalVideo.description"
              ></div>
            </div>
          </div>
        </section>
      </div>

      <div
        class="store-product-info"
        v-if="
          product.attributes &&
          !(
            !product.attributes.comments ||
            product.attributes.comments.length == 0
          )
        "
      >
        <section class="padded-top-bottom reviews">
          <h2 class="text-h2">{{ $t("store.product.reviewTitle") }}</h2>

          <!--div  //DEPRECATED no longer displayed
            class="slider-element no-reviews"
            v-if="
              !product.attributes.comments ||
                product.attributes.comments.length == 0
            "
          >
            <p>{{ $t("store.product.noReviews") }}</p>
          </div>

           <op-slider-layout
             v-else
          
          -->

          <op-slider-layout
            class="review-slider"
            :items="product.attributes.comments"
            :itemPaddingLeft="21"
            :itemPaddingRight="20"
            :itemPaddingTop="28"
            :itemPaddingBottom="27"
            :numOfItems="3"
            :gutter="20"
            :previewWidth="42"
            :navigationSize="52"
          >
            <div slot-scope="{ item }">
              <h3 class="text-h3">{{ item.title }}</h3>

              <v-rating
                v-model="item.rating"
                readonly
                background-color="primary"
                dense
                size="18"
                class="mb-6"
              ></v-rating>

              <div class="review-slider-description">{{ item.body }}</div>
            </div>
          </op-slider-layout>
        </section>
      </div>

      <div
        class="store-product-info pt-7 px-6 pb-6 pa-sm-0"
        :class="{
          'border-radius-12': $vuetify.breakpoint.xsOnly,
          'elevation-3': $vuetify.breakpoint.xsOnly,
        }"
        v-if="product.attributes"
      >
        <op-requirements-card
          :class="{ configuration: !isInModal }"
          :requirements="product.attributes.requirements"
        ></op-requirements-card>
      </div>
    </div>

    <section class="ice-blue px-12 pt-12 pb-2" v-if="showProductBox">
      <div
        class="d-flex align-center white py-6 px-8 border-radius-10 elevation-3"
      >
        <v-img
          height="80px"
          max-width="100px"
          contain
          :src="product.attributes.logo"
        />
        <div class="d-flex flex-column ml-7">
          <div class="headline marine-blue--text">
            {{ product.attributes.name }} {{ product.attributes.version }}
          </div>
          <div class="steel--text text-body-2">
            {{ product.attributes.developer }}
          </div>
        </div>

        <div class="d-flex flex-column align-center ml-auto">
          <op-buy-button
            :price="product.attributes.pricing"
            :product="product"
            behavior="buy"
            :buyUrl="product.attributes.buy_url"
          ></op-buy-button>

          <div class="text-caption steel--text mt-3">
            {{ product.attributes.pricing.description }}
          </div>
        </div>
      </div>
    </section>

    <section
      class="associated-packs py-12 pa-5 pa-sm-12"
      :class="{ 'ice-blue': isInModal, 'px-12': isInModal }"
    >
      <h2 :class="{ 'px-3': !isInModal, 'text-h2': true }">
        {{ $t("store.product.associatedPacksTitle") }}
      </h2>

      <div
        v-if="
          product.relationships &&
          product.relationships.packs &&
          product.relationships.packs.list &&
          product.relationships.packs.list.length > 0
        "
      >
        <div
          class="associated-packs-description steel--text text-body-1 mt-3"
          :class="{ 'px-3': !isInModal }"
        >
          {{
            $t("store.product.associatedPacksDescription", {
              productName: product.attributes.name,
              version: product.attributes.version,
            })
          }}
        </div>

        <v-container>
          <v-row v-if="product.relationships.packs">
            <v-col
              v-for="item in product.relationships.packs.list"
              :key="item.id"
              cols="12"
              md="6"
            >
              <op-pack-overview-card :pack="item"></op-pack-overview-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div v-else>
        <div
          class="associated-packs-description steel--text text-body-1 mt-3"
          :class="{ 'px-3': !isInModal }"
        >
          {{ $t("store.product.noAssociatedPacksDescription") }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProductLogic from "@/logics/ProductLogic"
import CategoryLogic from "@/logics/CategoryLogic"
import ProductPlusImage from "@/assets/product-plus.png"
import PlayImage from "@/assets/play.png"
import PageStore from "@/stores/PageStore"
import mixin from "@/mixins/Products.mixins.js"

export default {
  name: "view-store-product",

  props: {
    productId: {
      required: true,
    },
    isInModal: {
      type: Boolean,
      required: false,
      default() {
        return false
      },
    },
  },
  mixins: [mixin],
  data() {
    return {
      productPlusImage: ProductPlusImage,
      playImage: PlayImage,
      dataLoaded: false,
      showSticky: false,
      product: ProductLogic.getEmptyObject(),
      productCategoryName: "",
      instructionalVideo: null,
      mustReloadData: true,
    }
  },
  created() {
    if (!this.isInModal) {
      window.addEventListener("scroll", this.handleScroll)
    }
    this.initAll(this.productId)
  },
  beforeRouteUpdate(to, from, next) {
    this.showSticky = false
    this.dataLoaded = false
    this.initAll(to.params.productId)
    next()
  },
  destroyed() {
    if (!this.isInModal) {
      window.removeEventListener("scroll", this.handleScroll)
    }
  },
  methods: {
    initAll: async function (productId) {
      if (!this.mustReloadData) {
        return
      }
      try {
        this.product = await ProductLogic.getOne(productId, {}, ["packs"])
        this.updateCategory(this.product)
        PageStore.setStoreBreadcrumbsProductName(this.product.attributes.name)
        if (
          !this.isInModal &&
          this.product.attributes.slug &&
          this.product.attributes.slug != productId
        ) {
          this.mustReloadData = false
          this.$router.replace({
            name: "store_product",
            params: { productId: this.product.attributes.slug },
          })
        } else {
          this.mustReloadData = true
        }
        this.dataLoaded = true
        this.instructionalVideo = this.instructionalVideoMedia(this.product)
        this.$gtmLogic.productDisplay(this.product)
      } catch {
        this.$router.replace({
          name: "store_main_home",
        })
      }
    },
    handleScroll: function () {
      if (this.$refs.mainInfo) {
        this.showSticky =
          this.$refs.mainInfo.getBoundingClientRect().top < 0 &&
          this.$vuetify.breakpoint.name != "xs"
      }
    },
    updateCategory: async function (originalProduct) {
      if (
        PageStore.storeBreadcrumbs.categoryId == 0 ||
        originalProduct.relationships.categories.data.every(
          (category) => category.id != PageStore.storeBreadcrumbs.categoryId
        )
      ) {
        PageStore.setStoreBreadcrumbsCategoryId(
          originalProduct.relationships.categories.data[0].id
        )
      }

      const category = await CategoryLogic.getOne(
        PageStore.storeBreadcrumbs.categoryId
      )

      if (category) {
        this.productCategoryName = category.attributes.name
      }
    },
  },
  computed: {
    productPlusStyle() {
      return {
        "background-image": "url(" + this.productPlusImage + ")",
      }
    },
    videoThumbnailStyle() {
      return {
        width: this.$vuetify.breakpoint.name == "xs" ? "100%" : "60%",
        height: ["xs", "sm"].includes(this.$vuetify.breakpoint.name)
          ? "190px"
          : "325px",
      }
    },
    filteredMedias() {
      if (this.product.attributes.media) {
        return this.product.attributes.media.filter(
          (media) => media.type == "screenshot"
        )
      } else {
        return []
      }
    },
    fixedHeaderStyle() {
      return {
        width: !this.$authStore.isSignedIn()
          ? "100%"
          : PageStore.drawer
          ? "calc(100% - 300px)"
          : ["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name)
          ? "100%"
          : "calc(100% - 56px)",
      }
    },
    numOfslides() {
      return this.$vuetify.breakpoint.name == "xs" ? 1 : 2
    },
    showProductBox() {
      return (
        this.isInModal &&
        this.product.attributes &&
        this.$vuetify.breakpoint.name != "xs"
      )
    },
  },

  metaInfo() {
    const productName = this.product.attributes
      ? this.product.attributes.name
      : undefined
    const productDescription = this.product.attributes
      ? this.product.attributes.description
      : undefined
    return {
      title: productName,
      meta: [
        {
          name: "description",
          content: productDescription,
        },
      ],
    }
  },
}
</script>

<style lang="scss">
$horizontal-padding: 45px;
$risk-box-padding: 37px;
$box-margin: 10px;
$store-content-horizontal-padding: 4.286em;
$store-content-vertical-padding: 3.5em;

@media only screen and (max-width: 600px) {
  .store-product {
    .store-product-info {
      .tip-container {
        .tip-tutorials-info {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) {
  .store-product {
    .store-product-info {
      .tip-container {
        .tip-tutorials-info {
          width: calc(40% - 20px);
        }
      }
    }
  }
}

.store-product {
  background-color: white;
  overflow: hidden;

  &:not(.is-modal) {
    border-radius: 10px;
    box-shadow: 0 3px 8px 0 rgba(0, 4, 10, 0.09);
  }

  .product-fixed-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    box-shadow: 0 1px 5px 0 rgba(21, 27, 38, 0.14);
    position: fixed;
    z-index: 10;
    right: 0;
    top: 0px;
    transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 20px 4rem;

    .product-header-logo {
      width: auto;
      height: 40px;
      margin-right: 10px;
    }

    .product-header-title {
      color: $dark-indigo;
      margin-right: 16px;
    }

    .product-header-buy {
      margin-left: auto;
    }
  }

  .enter-enter-active,
  .enter-leave-active {
    transition: top 0.5s ease-out;
  }
  .enter-enter,
  .enter-leave-to {
    top: -90px;
  }

  h2 {
    margin: 0 0 30px;
  }

  .padded-top-bottom {
    padding-top: 54px;
    padding-bottom: 60px;
  }

  .associated-packs {
    margin-top: 3.643em;

    h2 {
      margin-bottom: 0.571em;
    }

    .associated-packs-description {
      max-width: 523px;
      margin-bottom: 1.417rem;
      line-height: 1.5;
    }
  }

  .product-info-box {
    border-radius: 4px;
    background-color: rgba(0, 161, 190, 0.1);
    padding: 32px $risk-box-padding;
    height: 100%;

    h3 {
      color: #070f28;
      margin-bottom: 8px;
    }
  }

  .store-product-info {
    .configuration {
      h2 {
        margin-top: 0;
      }
    }

    .reviews {
      margin-right: -4.286em;

      .slider-element {
        border-radius: 4px;
        background-color: #e5f5f8;

        &.no-reviews {
          width: 295px;
          padding: 6em 1em 6.714em 1.5em;
          text-align: center;
        }
      }
      h3 {
        font-weight: 500;
        color: $dark-indigo;
        margin-bottom: 0.5em;
      }

      .review-slider-description {
        line-height: 1.71;
        color: $charcoal-grey;
      }

      .star-rating {
        margin-bottom: 1.5em;
      }
    }

    .level-improvement {
      margin-top: 3.214em;
    }

    .product-slider {
      margin-right: -$store-content-horizontal-padding;
      height: auto;

      &.one-slide {
        margin-right: 0;
      }

      .slider-element {
        border-radius: 4px;
        background-color: $light-pink;
      }

      .product-slider-description {
        font-size: 1em;
        line-height: 1.43;
        text-align: center;
        color: $steel;
        margin-bottom: 1.429em;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .tip-container {
      position: relative;
      margin: 7em 0 3em;
      padding-top: 6.429em;
      padding-bottom: 7em;

      .tip-video-thumbnail {
        width: 60%;
        height: 350px;
      }

      .tip-background {
        position: absolute;
        width: calc(100% + #{$store-content-horizontal-padding} * 2);
        height: 100%;
        top: 0;
        left: -$store-content-horizontal-padding;
        background-color: #f2f2f2;
        transform: skew(0, -3deg);
      }

      h2 {
        z-index: 1;
        position: relative;
      }

      .tip-tutorials {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 1;
        position: relative;
        margin-top: 3.357em;

        .tip-tutorials-info {
          .tip-tutorials-info-description {
            line-height: 150%;
            margin: 0.571em 0 1.286em;
          }
        }
      }
    }

    .product-main-info {
      display: flex;
      flex-direction: row;

      .icon {
        width: 104px;
        margin-right: 29px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .product-overview {
        .product-title-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 0.357em;

          h1 {
            margin-bottom: 0;
            margin-right: 0.714em;
          }
        }
      }

      .product-actions {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .product-buy-info {
          text-align: center;
          margin-top: 0.917em;
        }
      }
    }

    .product-additional-info {
      border-radius: 6px;
      background-color: rgba(0, 161, 190, 0.1);
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 401px 225px;
      padding: 8px $horizontal-padding 56px;
      margin-top: 70px;

      .posth2 {
        margin-bottom: 27px;
        margin-top: 0;
      }

      h2 {
        margin-top: 56px;
        margin-bottom: 0;
      }

      .additional-info-description {
        line-height: 200%;
        color: rgba(8, 17, 36, 0.6);
        max-width: 506px;
      }
    }
  }
}
</style>

import CategoryLogic from "@/logics/CategoryLogic"
import CompanyLogic from "@/logics/CompanyLogic"

class GTMLogic {
  //keep an instance of the VueJS app
  vue
  userState = null
  previousUserState = null
  onGoingCheckout

  /** @description init class with vueJS instance
   */
  init(vue) {
    this.vue = vue
    this.resetCheckout()
  }

  resetCheckout() {
    this.onGoingCheckout = {
      productId: null,
      step: null,
      quantity: null,
    }
  }

  improveRouteName(path, name) {
    let pathArr = path.split("/")

    //  Improve name for diagnostic page : diagnostic/2/survey
    if (
      "member-diagnostic-survey" == name &&
      "diagnostic" == pathArr[1] &&
      pathArr[2]
    ) {
      let themeID = pathArr[2]
      let themeName = this.getCyberSecurityCategory(themeID)
      if (themeName) {
        name = "diagnostic - " + themeName
      }
    }

    //  Improve name for store page  : /store/category/1
    else if (
      "store_category" == name &&
      "store" == pathArr[1] &&
      "category" == pathArr[2] &&
      pathArr[3]
    ) {
      let nb = pathArr[3]
      switch (nb) {
        case "1":
          name = "store - mot de passe"
          break
        case "2":
          name = "store - protection des données (data)"
          break
        case "3":
          name = "store - sensibilisation"
          break
        case "4":
          name = "store - sécuriser les appareils"
          break
        case "5":
          name = "store - web (site internet)"
          break
        case "6":
          name = "store - nomadisme"
          break
        case "7":
          name = "store - incidents & crises"
          break
        case "8":
          name = "store - conseil et audits"
          break
      }
    }

    return name
  }

  /** @description Au chargement d'un contenu statique ou dynamique / A l'affichage d'une pop-in
   *  @param strName override the router page name
   */
  pushVirtualPageView(strName) {
    /* let path = this.vue.$route.path
    let name = strName || this.vue.$route.name
    name = this.improveRouteName(path, name)

    this.vue.$gtm.trackEvent({
      event: "virtualPageView",
      virtualPagePath: path,
      virtualPageTitle: name,
    })

    this.updateUserStatusAndPush() */
  }

  /*****************************************************************
   *                            User Events
   *****************************************************************/

  updateCompanyData(company) {
    if (company) {
      this.userState.employeeNumber = parseInt(company.headcount)
    } else {
      this.userState.employeeNumber = 0
    }
  }

  resetUserState() {
    this.userState = {
      userId: null,
      loginStatus: null,
      userType: null,
      userActivityArea: null,
      employeeNumber: null,
      newsletterSubscriber: null,
    }
  }

  /** @description build/updateuser status object and check if update is needed
   */
  async updateUserStatusAndPush() {
    /* if (!this.userState) {
      this.resetUserState()
    }
    if (this.vue.$authStore.me) {
      const user = this.vue.$authStore.me.getAttributes()

      if (user && user.is_anonymous) {
        this.resetUserState()
        this.userState.userId = user.uuid || user.id
        this.userState.loginStatus = false
        this.userState.userType = "visitor"
        this.userState.newsletterSubscriber =
          user.is_newsletter_subscriber || false
      } else if (user) {
        this.userState.userId = user.uuid || user.id
        this.userState.loginStatus = true
        this.userState.userType = "member"
        this.userState.newsletterSubscriber =
          user.is_newsletter_subscriber || false
      }

      // check employer number
      if (!this.userState.employeeNumber) {
        await CompanyLogic.getMyCompany().then((data) => {
          this.updateCompanyData(data)
        })
      }
    }

    this.vue.$gtm.trackEvent(this.userState) */
  }

  /** @description Toutes les pages sur lequelles il est possible de se connecter - Lorsque l'utilisateur est connecté
   */
  login() {
    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "user",
      action: "user logged in",
    }) */
  }

  /** @description Toutes les pages sur lequelles il est possible de créer un compte - Lorsque le compte est créé
   */
  register() {
    //let labelOrigin = "création de compte depuis page " + this.vue.$route.path
    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "user",
      action: "account created", //"création de compte depuis page registation",
    }) */
  }

  /** @description Lorsque le profil est complété à 100% (profil d'entreprise)
   */
  profileCompletion() {
    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "user",
      action: "profile completed",
    }) */
  }

  /*****************************************************************
   *                            NewsLetter
   *****************************************************************/
  /** @description Lorsque l'utilisateur s'inscrit à la newsletter
   */
  newsletterSubscription() {
    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "newsletter",
      action: "newsletter signed up",
    }) */
  }

  /*****************************************************************
   *                            Diagnostics
   *****************************************************************/
  /** @description !!only work for diagnostic pages (store theme IDs can be different)
   */
  getCyberSecurityCategory(diagID) {
    let id = parseInt(diagID)
    let name = null
    switch (id) {
      case 1:
        name = "mot de passe"
        break
      case 2:
        name = "data"
        break
      case 3:
        name = "sensibilisation"
        break
      case 7:
        name = "sécuriser les appareils"
        break
      case 4:
        name = "web"
        break
    }
    return name
  }

  /** @description Lorsque le visiteur répond à une première question d'un diagnostic
   *  NB : à envoyer au passage à la question 2
   */
  startDiagnostic(diagID) {
    let label = this.getCyberSecurityCategory(diagID)

    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "diagnosis",
      action: "section started",
      label: label, //%cyberSecurityCategory
    }) */
  }

  /** @description Au clic sur le CTA  "améliorer mon score"
   */
  improveScoreClick(diagID) {
    let label = this.getCyberSecurityCategory(diagID)

    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "diagnosis",
      action: "improve score clicked",
      label: label,
    }) */
  }

  /** @description Au clic sur le CTA  "Faire le diagnostic (X min)"
   */
  beginDiagnostic(diagID) {
    let label = this.getCyberSecurityCategory(diagID)
    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "diagnosis",
      action: "begin diagnosis clicked",
      label: label,
    }) */
  }

  /** @description Lorsqu'une section du diagnostic a été complétée (ex : l'ensemble des questions de la catégorie Mots de passe & authentification ont été validées)
   */
  completedDiagnostic(diagID) {
    let label = this.getCyberSecurityCategory(diagID)

    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "diagnosis",
      action: "section completed",
      label: label,
    }) */
  }

  /** @description Lorsqu'une section du diagnostic a été complétée
   */
  savedDiagnostic(diagID) {
    let label = this.getCyberSecurityCategory(diagID)
    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "diagnosis",
      action: "diagnosis saved",
      label: label,
    }) */
  }

  /** @description au click CTA "je ne suis pas concerné"
   */
  notConcerned(diagID) {
    let label = this.getCyberSecurityCategory(diagID)
    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "diagnosis",
      action: "not concerned clicked",
      label: label,
    }) */
  }

  /*****************************************************************
   *                            PRODUCTS
   *****************************************************************/

  getProductPricing(product) {
    if (
      product.attributes &&
      product.attributes.pricing &&
      product.attributes.pricing.amount
    ) {
      let price = product.attributes.pricing.amount
      return price / 100
    }
    return 0
  }

  /** @description return product tags joined together in a single string
   *  @todo  voir avec jérôme comment récupérer ces infos
   *  @description ex  ["Software"]
   */
  computeProductTypeArr(product) {
    let type = []
    if (product.attributes.tags) {
      type = product.attributes.tags.join(" + ")
    }
    return type
  }

  /** @description returns the name of the product category the product belongs to
   */
  async getProductCategory(product) {
    let name = null
    let category = null

    if (
      product.relationships &&
      product.relationships.categories &&
      product.relationships.categories.data &&
      product.relationships.categories.data[0].id
    ) {
      category = await CategoryLogic.getOne(
        product.relationships.categories.data[0].id
      )
    }

    if (category) {
      name = category.attributes.name
    }

    return name
  }

  /**   @deprecated
   *    @description Toutes les pages sur lesquelles un produit est poussé dans les recommandations - Au click sur le produit
   */
  clickProductRecommendation(product, activeTheme) {
    let cyberSecurityCategory = null
    if (activeTheme) {
      cyberSecurityCategory = activeTheme.attributes.title
    }
    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "recommendation",
      action: "click " + product.attributes.name,
      label: cyberSecurityCategory, //%cyberSecurityCategory
    }) */
  }

  /** @description A l'affichage d'une page produit
   */
  async productDisplay(product) {
    const p = product.attributes
    let category = await this.getProductCategory(product)

    /* this.vue.$gtm.trackEvent({
      event: "productDetail",
      productNameArr: [p.name],
      productId: [product.id],
      productProviderArr: [p.developer],
      productPriceArr: [this.getProductPricing(product)],
      productCategoryArr: [category],
      productTypeArr: this.computeProductTypeArr(product),
      _clear: true,
    }) */
  }

  /** @description  Au clic sur le CTA "acheter" ou "consulter"
   */
  async productBuyOrConsult(product) {
    const p = product.attributes
    let category = await this.getProductCategory(product)
    const priceLabel = p.checkout_config ? p.checkout_config.price_label : null

    /* this.vue.$gtm.trackEvent({
      event: "addToCart",
      productNameArr: [p.name],
      productId: [product.id],
      productProviderArr: [p.developer],
      productQuantityUnit: [priceLabel],
      productPriceArr: [this.getProductPricing(product)],
      productCategoryArr: [category],
      productTypeArr: this.computeProductTypeArr(product),
      _clear: true,
    }) */
  }

  /** @description A chaque étape du tunnel de paiement
   *  TODO renvoyer le step 1 du checkout si on y repasse et qu'il y a eu modification des quantités
   *  TODO productQuantityUnit
   */
  async productCheckoutStep(product, step, quantity) {
    const p = product.attributes
    let category = await this.getProductCategory(product)
    const priceLabel = p.checkout_config ? p.checkout_config.price_label : null

    if (
      null != this.onGoingCheckout.productId &&
      null != this.onGoingCheckout.step &&
      this.onGoingCheckout.productId == product.id &&
      this.onGoingCheckout.step >= step &&
      this.onGoingCheckout.quantity == quantity
    ) {
      // Already been through that step
      return false
    }

    this.onGoingCheckout.step = step
    this.onGoingCheckout.quantity = quantity
    this.onGoingCheckout.productId = product.id

    quantity = parseInt(quantity)
    //TODO  vérifier les numéros des steps avec jérôme
    // Valeur attendues : Panier --> 1  | Identité --> 2 | Facturation --> 3 | Paiement --> 4
    /* this.vue.$gtm.trackEvent({
      event: "checkout",
      checkoutStep: step, // entier (1,2,3, etc.)
      productNameArr: [p.name],
      productId: [product.id],
      productQuantityUnit: [priceLabel],
      productQuantity: [quantity],
      productProviderArr: [p.developer],
      productPriceArr: [this.getProductPricing(product)],
      productCategoryArr: [category],
      productTypeArr: this.computeProductTypeArr(product),
      _clear: true,
    }) */
  }

  /** @description Lorsque la transaction est validée
   *  TODO productQuantityUnit
   */
  async productPurchase(product, quantity) {
    const p = product.attributes
    let category = await this.getProductCategory(product)
    const priceLabel = p.checkout_config ? p.checkout_config.price_label : null

    // @todo: crypter le transactionRevenue
    let transactionId = "ID" + Date.now()
    let price = this.getProductPricing(product)
    let transactionTTCRevenue = "" // montant total payé par l'utilisateur (TTC).
    let transactionTax = "" //montant réel des taxes par transaction.
    let transactionMargin = ""

    if (price & quantity) {
      quantity = parseInt(quantity)
      transactionTTCRevenue = 1.2 * price * quantity
      transactionTax = 0.2 * price * quantity
      transactionTTCRevenue = transactionTTCRevenue.toString()
      transactionTax = transactionTax.toString()
    }

    /* this.vue.$gtm.trackEvent({
      event: "purchase",
      transactionMargin: transactionMargin, //string
      transactionId: transactionId,
      transactionTax: transactionTax,
      transactionRevenue: transactionTTCRevenue, //string - valeur obligatoire
      productNameArr: [p.name],
      productId: [product.id],
      productQuantityUnit: [priceLabel],
      productProviderArr: [p.developer],
      productPriceArr: [price],
      productCategoryArr: [category],
      productTypeArr: this.computeProductTypeArr(product),
      _clear: true,
    }) */
  }

  /** @description Lorsque la transaction est validée
   */
  productDownloadStarted(product) {
    const p = product.attributes
    /* this.vue.$gtm.trackEvent({
      event: "eventGA",
      category: "solution",
      action: "download started",
      label: p.name,
    }) */
  }
}

export default new GTMLogic()

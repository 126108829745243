<template>
  <LineChart
    :chart-options="chartOptions"
    :chart-data="chartData"
    :height="height"
  />
</template>
<script>
import { Line as LineChart } from "vue-chartjs/legacy"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from "chart.js"
import "chartjs-adapter-luxon"
import { titleOptions } from "../ChartOptions"

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
)

export default {
  name: "op-course-session-by-date-line",
  components: { LineChart },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default() {
        return 400
      },
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        pointLabelFontSize: 5,
        scales: {
          x: {
            grid: {
              display: false,
              borderColor: "transparent",
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 1,
            },
            min: 0,
          },
        },
        plugins: {
          filler: {
            propagate: false,
          },
          legend: {
            display: true,
            title: {
              display: true,
              text: this.$t("courses.charts.sessionHistoryTitle"),
              ...titleOptions,
            },
            labels: {
              generateLabels: function (chart) {
                return ""
              },
              textAlign: "right",
              usePointStyle: true,
              pointStyleWidth: 10,
              boxHeight: 7.5,
            },
          },
          tooltip: {
            callbacks: {
              label: (context) =>
                this.$t("courses.charts.weeklySessionsTooltip", {
                  label: context.dataset.label,
                  week: context.label,
                  value: context.parsed.y,
                }),
              title: () => null,
              beforeBody: () => null,
              afterBody: () => null,
            },
          },
        },
      },
    }
  },
}
</script>

import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import "roboto-fontface/css/roboto/roboto-fontface.css"
import "@mdi/font/css/materialdesignicons.css"

// vuetify
import Vuetify from "vuetify/lib"
import colors from "./plugins/colors"

//import { vuetify } from "./plugins/oppens"
import { i18n } from "./plugins/i18n"
import fr from "vuetify/es5/locale/fr"

import "./plugins/vee-validate"
import browserDetect from "vue-browser-detect-plugin"
import lodash from "lodash"

import VuePapaParse from "vue-papa-parse"

import AuthStore from "@/stores/AuthStore"
import DashboardStore from "@/stores/DashboardStore"
import PageStore from "@/stores/PageStore"
import AuthLogic from "@/logics/AuthLogic"
import CustomizationLogic from "@/logics/CustomizationLogic"
import VueMatomo from "vue-matomo"
import GTMLogic from "@/logics/GTMLogic"
import VueMeta from "vue-meta"
import NotifStore from "@/stores/NotifStore"
import CustomizationStore from "@/stores/CustomizationStore"
import OpHomeMenuIcon from "@/components/icons/OpHomeMenuIcon"
import OpPhishingMenuIcon from "@/components/icons/OpPhishingMenuIcon"
import OpConsultingMenuIcon from "@/components/icons/OpConsultingMenuIcon"
import OpCourseMenuIcon from "@/components/icons/OpCourseMenuIcon"

import "@/globalComponents"

import "@/sass/global.scss"
import AuthorizationStore from "./stores/AuthorizationStore"

const customization = CustomizationLogic.getCustomization()
  .then((customization) => {
    const newColors = { ...colors, primary: customization.colors.primary }

    const vuetify = new Vuetify({
      theme: {
        options: {
          customProperties: true,
        },
        dark: false,
        themes: {
          light: newColors,
          dark: newColors,
        },
      },
      lang: {
        locales: { fr },
        current: "fr",
      },
      icons: {
        values: {
          homeMenuIcon: { component: OpHomeMenuIcon },
          phishingMenuIcon: { component: OpPhishingMenuIcon },
          consultingMenuIcon: { component: OpConsultingMenuIcon },
          courseMenuIcon: { component: OpCourseMenuIcon },
        },
      },
    })

    Vue.use(Vuetify)
    Vue.use(VuePapaParse)

    Vue.prototype.$colors = colors
    Vue.prototype.$authStore = AuthStore
    Vue.prototype.$dashboardStore = DashboardStore
    Vue.prototype.$authorizationStore = AuthorizationStore
    Vue.prototype.$authLogic = AuthLogic
    Vue.prototype.$pageStore = PageStore
    Vue.prototype.$gtmLogic = GTMLogic
    Vue.prototype.$notifStore = NotifStore
    Vue.prototype.$customizationStore = CustomizationStore

    Vue.prototype.$links = {
      oppens_site: "https://www.oppens.fr/",
      oppens_blog: "https://www.oppens.fr/blog-cybersecurite/",
      oppens_contact: "https://www.oppens.fr/contact/",
      logout_to_url: CustomizationStore?.getLogoutUrl() ?? "https://www.oppens.fr/",
    }
    
    Vue.use(VueMatomo, {
      // Configure your matomo server and site by providing
      host: "//mat.jipitou.com/",
      siteId: 1,

      // Changes the default .js and .php endpoint's filename
      // Default: 'matomo'
      // trackerFileName: 'matomo',

      // Overrides the autogenerated tracker endpoint entirely
      // Default: undefined
      trackerUrl: "//mat.jipitou.com/matomo.php",

      // Overrides the autogenerated tracker script path entirely
      // Default: undefined
      // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

      // Enables automatically registering pageviews on the router
      router: router,
    })

    Vue.use(browserDetect)
    Vue.use(VueMeta)

    Vue.config.productionTip = false

    Vue.filter("uppercase", function (value) {
      if (!value) return ""
      value = value.toString()
      return value.toUpperCase()
    })

    Vue.filter("titlecase", function (value) {
      if (!value) return ""
      value = value.toString()
      return lodash.capitalize(value)
    })

    Vue.filter("monetize", function (value) {
      let monetizedValue = ""
      if (value != null) {
        if (value.toString() == "0") {
          monetizedValue = i18n.t("store.product.free")
        } else {
          monetizedValue = i18n.n(value / 100, "currency")
        }
      }
      return monetizedValue
    })

    Vue.filter("date", function (value) {
      return new Date(value).toLocaleString(i18n.locale, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
    })

    Vue.filter("datetime", function (value) {
      return new Date(value).toLocaleString(i18n.locale, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
    })

    Vue.filter("formatedRate", function (rate) {
      return isNaN(rate) ? "- %" : `${rate} %`
    })

    Vue.filter("secondsToMinutes", function (seconds) {
      return isNaN(seconds) ? "" : Math.round(seconds / 60)
    })

    new Vue({
      router,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app")
  })
  .catch((error) => console.error(error))



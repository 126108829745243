<template>
  <div v-if="employee" class="main-bloc">
    <div class="info-line">
      <div class="primary-infos">
        <span class="value name">
          {{ employee.informations.first_name }}
          {{ employee.informations.last_name }}
        </span>
        <span class="value">
          {{ employee.informations.email }}
        </span>
      </div>

      <div class="secondary-infos" v-if="!partial">
        <span v-if="employee.informations.telephone">
          <span class="desc"> {{ $t("employees.row.telephone") }} </span>
          <span class="value"> {{ employee.informations.telephone }} </span>
        </span>

        <span v-if="employee.informations.lang">
          <span class="desc"> {{ $t("employees.row.lang") }} </span>
          <span class="value"> {{ employee.informations.lang }} </span>
        </span>

        <span v-if="employee.informations.job">
          <span class="desc"> {{ $t("employees.row.job") }} </span>
          <span class="value"> {{ employee.informations.job }} </span>
        </span>

        <span v-if="employee.informations.position">
          <span class="desc"> {{ $t("employees.row.position") }} </span>
          <span class="value"> {{ employee.informations.position }} </span>
        </span>

        <span v-if="employee.informations.entity">
          <span class="desc"> {{ $t("employees.row.entity") }} </span>
          <span class="value"> {{ employee.informations.entity }} </span>
        </span>

        <span v-if="employee.informations.department">
          <span class="desc"> {{ $t("employees.row.department") }} </span>
          <span class="value"> {{ employee.informations.department }} </span>
        </span>

        <span v-if="employee.city">
          <span class="desc"> {{ $t("employees.row.city") }} </span>
          <span class="value"> {{ employee.informations.city }} </span>
        </span>

        <span v-if="employee.informations.country">
          <span class="desc"> {{ $t("employees.row.country") }} </span>
          <span class="value"> {{ employee.informations.country }} </span>
        </span>

        <span v-if="employee.informations.manager_email">
          <span class="desc"> {{ $t("employees.row.manager_email") }} </span>
          <span class="value"> {{ employee.informations.manager_email }} </span>
        </span>
      </div>

      <div class="actions" v-if="addButton || deleteButton">
        <v-btn v-if="deleteButton" @click="suppress()" icon>
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-btn v-if="addButton" @click="add()" icon color="primary">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "oppens-employee",
  props: {
    employee: {
      default: null,
    },
    partial: {
      default: false,
    },
    addButton: {
      default: false,
    },
    deleteButton: {
      default: false,
    },
  },
  methods: {
    suppress() {
      this.$emit("suppress", this.employee)
    },
    add() {
      this.$emit("add", this.employee)
    },
  },
}
</script>
<style scoped lang="scss">
.main-bloc {
  border: 1px solid #d9d9d9;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;

  .value {
    margin-right: 5px;
    display: inline-block;
    color: grey;
  }

  .name {
    min-width: 200px;
  }

  .desc {
    color: #000;
    font-size: 0.8rem;
  }

  .info-line {
    font-weight: normal;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .primary-infos {
      min-width: 400px;
      display: inline-block;
    }

    .secondary-infos {
      font-weight: normal;
      font-size: 0.9rem;
    }
  }
}
</style>

<template>
  <div>
    <router-view />
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      <p style="font-size: 15px; font-family: Montserrat;">{{ snackbarText }}</p>
    </v-snackbar>
  </div>
</template>

<script>
import { debounce, isEmpty } from "lodash"
import AuthLogic from "@/logics/AuthLogic"
// import { EventBus } from "@/services/Request"
import { EventBus, ACTIONS } from "./bus"

export default {
  name: "App",
  async beforeMount() {
    EventBus.$on("401-unauthorized", () => {
      this.redirectToLogin()
    })

    EventBus.$on(ACTIONS.SNACKBAR, ({ message, color }) => {
      this.snackbarText = message
      this.snackbarColor = color || "#FF5252"
      this.snackbar = true
    })
    if (
      isEmpty(process.env.VUE_APP_CLIENT_ID) ||
      isEmpty(process.env.VUE_APP_CLIENT_SECRET)
    ) {
      console.warn("Client secrets are not properly configured")
    }

    const accessToken = AuthLogic.getTokens()

    if (
      accessToken &&
      Object.prototype.hasOwnProperty.call(accessToken, "access_token")
    ) {
      await this.$authStore.sync()
    }

    const checkNotifDelay = process.env.VUE_APP_CHECK_NOTIFICATIONS_DELAY_MS

    this.$notifStore.sync()
    if (!isNaN(checkNotifDelay) && checkNotifDelay > 0) {
      this.notifListener = setInterval(() => {
        this.$notifStore.sync()
      }, checkNotifDelay)
    }
  },

  beforeDestroy() {
    clearInterval(this.notifListener)
  },

  data: () => ({
    snackbar: false,
    snackbarText: null,
    snackbarColor: "",
    fpOptions: {
      excludeAdBlock: true,
    },
    notifListener: null,
  }),

  methods: {
    redirectToLogin: debounce(function () {
      if (this.$route.name !== "login") {
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.fullPath },
        })
      }
    }, 300),
  },

  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Le coach en sécurité informatique",
      // all titles will be injected into this template
      titleTemplate: this.$customizationStore.isReseller()
        ? "%s"
        : "%s | Oppens",
      meta: [
        {
          name: "description",
          content:
            "Oppens est la 1ère solution cybersécurité des TPE-PME. Un diagnostic rapide et des préconisations sûr-mesures gratuites, vous permettront d&#039;identifier les actions prioritaires pour faire un 1er pas vers une meilleure sécurité de votre entreprise. Oppens propose un catalogue de produits et de services sélectionnés.",
        },
      ],
      link: [
        {
          rel: "icon",
          href: this.$customizationStore.getLogos()?.thumbnail,
        },
      ],
    }
  },
}
</script>

<template>
  <v-row>
    <v-col :cols="firstRowCols">
      <h3 v-if="title" class="text-h3 font-weight-light">
        {{ title }}
      </h3>
      <op-toggle-buttons
        v-else
        v-model="selectedAction"
        :buttons="[
          {
            value: 'clicked',
            label: $t('phishingSimulations.charts.clicked'),
          },
          {
            value: 'submitted',
            label: $t('phishingSimulations.charts.submitted'),
          },
        ]"
      ></op-toggle-buttons>
    </v-col>
    <v-col :cols="firstRowCols" class="d-flex justify-end">
      <op-toggle-buttons
        v-model="selectedMapping"
        :buttons="mappingButtons"
      ></op-toggle-buttons>
    </v-col>
    <v-col cols="12">
      <Doughnut
        v-if="hasData"
        :chart-options="chartOptions"
        :chart-data="chartData[selectedMapping][selectedAction]"
        :height="height"
      />
      <div v-else class="text-body-1">
        {{ $t(`phishingSimulations.charts.noDataFor_${selectedMapping}`) }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { Doughnut } from "vue-chartjs/legacy"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js"
import "chartjs-adapter-luxon"
import lodash from "lodash"

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: "op-comparison-donut",
  components: { Doughnut },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default() {
        return 400
      },
    },
    title: {
      type: String,
      default() {
        return null
      },
    },
    mappingButtons: {
      type: Array,
      required: true,
    },
    defaultAction: {
      type: String,
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      },
      selectedMapping: "job",
      selectedAction: "clicked",
    }
  },
  created() {
    if (this.defaultAction) {
      this.selectedAction = this.defaultAction
    }
  },
  computed: {
    firstRowCols() {
      if (this.title) {
        return 12
      } else {
        return 6
      }
    },
    hasData() {
      return (
        this.chartData[this.selectedMapping] &&
        this.chartData[this.selectedMapping][this.selectedAction] &&
        !lodash.isEmpty(
          this.chartData[this.selectedMapping][this.selectedAction].labels
        ) &&
        this.chartData[this.selectedMapping][
          this.selectedAction
        ].datasets[0].data.some((value) => value !== 0)
      )
    },
  },
}
</script>

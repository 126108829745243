<template>
  <v-container class="px-0">
    <v-progress-circular
      indeterminate
      color="primary"
      class="ma-auto d-flex"
      v-if="isLoading"
    ></v-progress-circular>
    <v-row v-else>
      <v-col cols="4">
        <v-card
          outlined
          width="100%"
          height="100%"
          class="pa-4 fill-height"
          rounded="lg"
        >
          <div class="text-h3 mt-1 mb-3">
            {{ $t("humanCyberscore.overallTitle") }}
          </div>
          <div class="d-flex align-center justify-space-around mt-10">
            <div class="teal--text">
              <span class="text-h1 font-weight-bold">{{
                `${globalScore}  `
              }}</span>
              <span class="text-body-1 font-weight-bold">/ 100</span>
            </div>
            <op-cyberscore-gauge :score="globalScore" />
          </div>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card outlined width="100%" height="100%" class="pa-4" rounded="lg">
          <div class="text-h3 mt-1 mb-3">
            {{ $t("humanCyberscore.themesTitle") }}
          </div>
          <v-row v-if="company?.gamification">
            <v-col
              cols="6"
              v-for="theme in themes"
              :key="theme.code"
              class="py-2"
            >
              <op-cyberscore-by-theme
                :theme="theme.code"
                :score="company.gamification.thematic_scores[theme.code]"
                :icons="theme.icons"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CompanyLogic from "@/logics/CompanyLogic"
import PhishingIcon300 from "@/assets/learning/badges/badge-phishing-300px.png"
import PhishingIcon100 from "@/assets/learning/badges/badge-phishing-100px.png"
import DataProtectionIcon300 from "@/assets/learning/badges/badge-data-protection-300px.png"
import DataProtectionIcon100 from "@/assets/learning/badges/badge-data-protection-100px.png"
import WorkstationIcon300 from "@/assets/learning/badges/badge-workstation-300px.png"
import WorkstationIcon100 from "@/assets/learning/badges/badge-workstation-100px.png"
import OutOfOfficeIcon300 from "@/assets/learning/badges/badge-out-of-office-300px.png"
import OutOfOfficeIcon100 from "@/assets/learning/badges/badge-out-of-office-100px.png"
import CommunicationToolsIcon300 from "@/assets/learning/badges/badge-communication-tools-300px.png"
import CommunicationToolsIcon100 from "@/assets/learning/badges/badge-communication-tools-100px.png"
import SocialEngineeringIcon300 from "@/assets/learning/badges/badge-social-engineering-300px.png"
import SocialEngineeringIcon100 from "@/assets/learning/badges/badge-social-engineering-100px.png"
export default {
  name: "op-cyberscore-summary",
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      company: null,
      themes: [
        {
          code: "phishing",
          icons: { px100: PhishingIcon100, px300: PhishingIcon300 },
        },
        {
          code: "out-of-office",
          icons: { px100: OutOfOfficeIcon100, px300: OutOfOfficeIcon300 },
        },
        {
          code: "data-protection",
          icons: { px100: DataProtectionIcon100, px300: DataProtectionIcon300 },
        },
        {
          code: "communication-tools",
          icons: {
            px100: CommunicationToolsIcon100,
            px300: CommunicationToolsIcon300,
          },
        },
        {
          code: "workstation",
          icons: { px100: WorkstationIcon100, px300: WorkstationIcon300 },
        },
        {
          code: "social-engineering",
          icons: {
            px100: SocialEngineeringIcon100,
            px300: SocialEngineeringIcon300,
          },
        },
      ],
    }
  },
  async mounted() {
    this.company = await CompanyLogic.getMyCompany()
  },
  computed: {
    globalScore() {
      return this.company?.gamification?.global_cyber_score
        ? this.company.gamification.global_cyber_score
        : 0
    },
  },
}
</script>

import Vue from "vue"
import VueRouter from "vue-router"

import AuthLogic from "@/logics/AuthLogic"
import PageStore from "@/stores/PageStore"
import AuthStore from "@/stores/AuthStore"

import routes from "./routes"

Vue.use(VueRouter)

// maintain a list of routes names where being connected event as anonymous is NOT necessary
const noUserNeededRoutesNames = ["login", "store_main_home", "store_category"]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

/** @description Let the router manage the calls GTM Logic with regards to page views
 *                 Except for some cases (when we need the inner page details) where
 *                 we let the component handle the calls
 */
router.afterEach((to) => {
  //console.log("route after : ", to.path)

  if ("/store/checkout" === to.path) {
    return
  }
  router.app.$gtmLogic.pushVirtualPageView()
})

router.beforeEach(async (to, from, next) => {
  // fix #144
  const basePath = to.path.split("/")

  // After being redirected from SSO login
  if (to.path.includes("connection")) {
    if (to.query?.message === "Authenticated user") {
      AuthLogic.setTokens({
        expires_in: to.query?.expires_in,
        access_token: to.query?.token,
        token_type: to.query?.token_type,
        refresh_token: to.query?.refresh_token,
      })
      next({
        path: "/dashboard",
      })
    }
  }

  //console.log("route before : ", from.path)

  if ("diagnostic" == basePath[0] || "diagnostic" == basePath[1]) {
    HideDrawer()
    HideToolbar()
  }

  const accessToken = AuthLogic.getTokens()
  const needs2FA = AuthLogic.get2FAIsNeeded()

  if (accessToken && !needs2FA) {
    //console.log("I have a token : ", from.path, to.path)
    if (!AuthStore.me) {
      await AuthLogic.loadMe()
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!accessToken || needs2FA || AuthStore.isAnonymous()) {
      //console.log("No token....")
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      })
    } else {
      ShowDrawer()
      ShowToolbar()
    }
    next()
  } else {
    if (noUserNeededRoutesNames.includes(to.name)) {
      // No need to be authenticated here
      if (AuthStore.me && !AuthStore.isAnonymous()) {
        ShowDrawer()
        ShowToolbar()
      } else {
        HideDrawer()
        HideToolbar(to)
      }

      next()
    } else if (!accessToken) {
      const created = await AuthLogic.createAnonymousUserAndLogin()
      if (!created) {
        next({
          path: "/login",
        })
      } else {
        HideDrawer()
        HideToolbar(to)
        next()
      }
    } else if (AuthStore.isAnonymous()) {
      HideDrawer()
      HideToolbar(to)
      next()
    } else {
      ShowDrawer()
      ShowToolbar()
      next()
    }
  }
})

function ShowDrawer() {
  PageStore.showDrawer = true
}
function ShowToolbar() {
  PageStore.showToolbar = true
}
function HideDrawer() {
  //PageStore.showDrawer = false
  PageStore.showDrawer = window.innerWidth <= 500 ? true : false
}
function HideToolbar(to) {
  if (to && to.matched.some((record) => record.meta.showToolbar)) {
    PageStore.showToolbar = true
  } else {
    PageStore.showToolbar = false
  }
}

export default router

import Vue from "vue"
import ZapAPILogic from "../logics/ZapAPILogic"

class NotifStore {
  constructor() {
    this.state = Vue.observable({
      notifs: [],
    })
  }

  async sync() {
    this.state.notifs = await ZapAPILogic.getNotifications()
  }

  getNotifications() {
    return this.state.notifs
  }
}

export default new NotifStore()

export default class LocaleLogic {
  static getBrowserLanguage() {
    return (navigator.language || navigator.userLanguage).split("-")[0]
  }

  static availableLocales() {
    return ["en", "fr"]
  }

  static getDefaultLanguage() {
    const availableLocales = LocaleLogic.availableLocales()
    const browserLanguage = LocaleLogic.getBrowserLanguage()
    let defaultLanguage = "en"
    for (
      let languageIndex = 0;
      languageIndex < availableLocales.length;
      languageIndex++
    ) {
      const language = availableLocales[languageIndex]
      const reg = new RegExp(`^${language}\\b`)
      if (reg.test(browserLanguage)) {
        defaultLanguage = language
        break
      }
    }
    return defaultLanguage
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pack-overview-container"},[_c('div',{staticClass:"pack-background"}),_c('div',{staticClass:"pack-details"},[_c('h3',{staticClass:"text-h3 font-weight-medium marine-blue--text"},[_vm._v(" "+_vm._s(_vm.pack.attributes.name)+" ")]),_c('div',{staticClass:"pack-price steel--text"},[_vm._v(" "+_vm._s(_vm.pack.attributes.pricing.description)+" ")]),_c('div',{staticClass:"pack-tags"},[_c('div',{staticClass:"pack-tag text-caption marine-blue--text tag-title"},[_vm._v(" PACK ")]),_vm._l((_vm.pack.attributes.tags),function(tag){return _c('div',{key:tag,staticClass:"pack-tag text-caption charcoal-grey--text"},[_vm._v(" "+_vm._s(tag)+" ")])})],2)]),_c('div',{staticClass:"pack-actions"},[_c('v-btn',{class:[
        'text-none',
        'font-weight-bold',
        'text-body-1',
        'ml-auto',
        'icon-btn',
      ],attrs:{"color":"primary","dark":"","small":true,"min-width":40,"min-height":40,"to":{ name: 'store_pack', params: { packId: _vm.getProductId(_vm.pack) } },"elevation":"8"}},[_c('v-icon',{attrs:{"right":"","size":"26"}},[_vm._v("mdi-plus")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
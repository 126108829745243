import Helpers from "../services/Helpers"
import CategoryRepository from "../repositories/CategoryRepository"
import ModelList from "../models/ModelList"
import CategoryStore from "../stores/CategoryStore"

let isLoadingAll = false

export default class CategoryLogic {
  /**
   *
   * @returns {Promise}
   */
  static async getAll(/*pagination, filters = {}*/) {
    // let params = Object.assign(
    //   Helpers.parsePagination(pagination),
    //   Helpers.parseFilters(filters)
    // );
    if (!isLoadingAll) {
      isLoadingAll = true
      const response = await CategoryRepository.getAll(/*params*/)

      let categoryList = new ModelList(response.dataObject())
      CategoryStore.setList(categoryList.list)
      isLoadingAll = false
      return {
        data: categoryList.getData(),
        // pagination: Helpers.syncPagination(pagination, response.getPagination())
      }
    }
  }

  /**
   *
   * @param uuid
   * @param params
   * @returns {Promise}
   */
  static async getOne(uuid) {
    while (isLoadingAll) {
      await Helpers.sleep(50)
    }
    const categoryInStore = CategoryStore.getCategoryById(uuid)
    if (categoryInStore) {
      return categoryInStore
    } else {
      await CategoryLogic.getAll()
      return CategoryStore.getCategoryById(uuid)
    }
  }

  static convertListToTabs(data) {
    return data.map((value) => ({
      name: value.getAttribute("name"),
      id: value.id,
      slug: value.getAttribute("slug"),
      route: {
        name: "store_category",
        params: {
          categoryId: value.getAttribute("slug")
            ? value.getAttribute("slug")
            : value.id,
        },
      },
    }))
  }

  static getEmptyObject(params) {
    return Object.assign(
      {
        id: null,
        name: null,
        slug: null,
        description: null,
        created_at: null,
      },
      params
    )
  }
}

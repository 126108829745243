<template>
  <div>
    <!-- Warning -->
    <v-card class="warning-card" v-if="differentUsers">
      <h3>{{ $t("diagnostic.warning.different-users.title") }}</h3>

      <span v-if="isAnonymous">
        {{ $t("diagnostic.warning.different-users.anonymous-description") }}
      </span>
      <span v-else>
        {{ $t("diagnostic.warning.different-users.connected-description") }}
      </span>
    </v-card>

    <!-- Header -->
    <v-img
      height="50vh"
      :src="require('../../assets/diagnostic-illustration.jpg')"
      :lazy-src="require('../../assets/diagnostic-illustration-lazy.jpg')"
    >
      <v-row justify="center" align="end" class="fill-height pa-0">
        <!--  max-width-1050 -->
        <v-col cols="12" md="10" lg="8" class="pb-0 px-0">
          <!-- 
min-height="300px"
            height="40vh"
            -->
          <v-card flat dark class="diagnostic-header-card">
            <v-row align="center" class="fill-height pa-0">
              <v-col>
                <v-row align="center" class="pa-0">
                  <v-col cols="3" md="3" class="text-center pl-5">
                    <op-category-level-card :level="diagnostic.level">
                    </op-category-level-card>
                  </v-col>
                  <v-col cols="9" md="9" class="py-0">
                    <div class="boxedinfos">
                      <div class="uppertitle">
                        {{ diagnostic.title }}
                      </div>

                      <div class="title1 font-weight-bold">
                        {{
                          $t("diagnostic.level-title", {
                            level: diagnostic.level,
                          })
                        }}
                      </div>

                      <div class="title2 pb-4">
                        {{ diagnostic.description_short }}
                      </div>

                      <op-diagnostic-btn
                        :redoTopicLink="redoTopicLink"
                        v-if="!showSaveAndAuthBtn"
                        flat
                        tile
                        color="transparent"
                      >
                      </op-diagnostic-btn>

                      <op-diagnostic-btn
                        @coach="consultCoach(true)"
                        :nextTopicLink="nextTopicLink"
                        v-if="$vuetify.breakpoint.smAndUp && showSaveAndAuthBtn"
                        flat
                        tile
                        color="transparent"
                      >
                      </op-diagnostic-btn>
                    </div>
                  </v-col>
                  <v-col class="pt-0 justify-self-center">
                    <op-diagnostic-btn
                      @coach="consultCoach(true)"
                      :nextTopicLink="nextTopicLink"
                      v-if="!$vuetify.breakpoint.smAndUp && showSaveAndAuthBtn"
                      flat
                      tile
                      color="transparent align-center"
                    >
                    </op-diagnostic-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-img>

    <op-divider height="70px">
      <template v-slot:default>
        <v-row justify="center" align="start" class="fill-height py-0">
          <v-col
            cols="12"
            md="10"
            lg="8"
            class="pt-0 pl-0 max-width-1059"
            style="padding-right: 6px"
          >
            <v-progress-linear
              color="green-blue"
              class="diagnostic-header-card-progress"
              background-color="diagnostic-header-card-progress-slider"
              height="8"
              :value="0"
            >
            </v-progress-linear>
          </v-col>
        </v-row>
      </template>
    </op-divider>

    <v-container class="py-0 max-width-1050">
      <op-diagnostic-timeline class="mb-6" :value="scorePercentage">
      </op-diagnostic-timeline>

      <op-diagnostic-reco
        v-if="diagnostic.recommendation"
        :showTimelineSmall="diagnostic.level < 4"
        :level="diagnostic.level"
        :nextLevel="diagnostic.next_level"
        :products="diagnostic.recommendation.products"
        :title="diagnostic.recommendation.title"
        :items="diagnostic.recommendation.recommendations"
        :box="diagnostic.recommendation.additional"
      >
      </op-diagnostic-reco>
    </v-container>

    <op-divider-basic height="30px" contain bgColor="white"> </op-divider-basic>

    <div class="white pt-5" v-if="diagnostic.next_level < 5">
      <v-container class="max-width-1050 pt-0 pb-8">
        <!-- 
            :items="diagnostic.next_recommendations" 
           :box="diagnostic.next_recommendation_additional"
        -->
        <op-diagnostic-reco
          v-if="diagnostic.next_recommendation"
          showTitleLevel
          :level="diagnostic.next_level"
          :levelColor="$colors.blue.lighten2"
          :products="diagnostic.next_recommendation.products"
          :title="diagnostic.next_recommendation.title"
          :showSolutionTitle="false"
        >
        </op-diagnostic-reco>
      </v-container>
    </div>

    <v-footer padless>
      <op-diagnostic-btn
        @coach="consultCoach(true)"
        flat
        tile
        :nextTopicLink="nextTopicLink"
        dark
        class="py-4"
        v-if="showSaveAndAuthBtn"
        :title="diagnostic.title"
      >
      </op-diagnostic-btn>
    </v-footer>

    <v-dialog :value="dialogSave" @input="consultCoach($event)" width="550">
      <v-card class="pa-6 pt-3">
        <v-window
          :value="dialogIndex"
          @input="$emit('update:dialogIndex', $event)"
        >
          <v-window-item>
            <v-card-title class="title font-weight-bold justify-center">
              {{ $t("diagnostic.consult-coach") }}
            </v-card-title>

            <v-card-text class="text-center">
              {{ $t("diagnostic.save-description") }}
            </v-card-text>

            <v-card-actions>
              <v-btn
                block
                large
                color="primary"
                dark
                class="text-none"
                @click="register(dialogIndex)"
              >
                {{ $t("register.title") }}
              </v-btn>
            </v-card-actions>

            <v-card-actions>
              <v-btn block large text class="text-none" @click="login()">
                {{ $t("register.have_account") }}
              </v-btn>
            </v-card-actions>
          </v-window-item>

          <v-window-item>
            <op-register-card
              ref="opRegisterCard"
              :index="registerIndex"
              @update:index="$emit('update:registerIndex', $event)"
              @submit="registerUser"
              :loading="registerLoading"
              flat
            >
            </op-register-card>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
$xsBreakpoint: 700px;

.uppertitle {
  text-decoration: underline;
  text-underline-position: under;
  text-transform: uppercase;
  font-size: 1.5rem;

  @media (max-width: $xsBreakpoint) {
    font-size: 1rem;
    font-size: 12px;
  }
}

.title1 {
  padding-top: 20px;
  @media (max-width: $xsBreakpoint) {
    padding-top: 0px;
  }
}

.title1 {
  font-size: 2.125rem;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em;
  font-family: Montserrat, sans-serif;
  font-weight: 700;

  @media (max-width: $xsBreakpoint) {
    font-size: 18px;

    font-size: 18px;
    line-height: 1rem;
    line-height: 28px;
  }
}
.title2 {
  font-size: 22px;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
  font-family: Montserrat, sans-serif;

  @media (max-width: $xsBreakpoint) {
    font-size: 18px;

    line-height: 1rem;
    line-height: 28px;
    font-size: 18px;
  }
}

.boxedinfos {
  max-width: 95%;
}

.warning-card {
  padding: 10px;
  margin: 10px;
  font-size: 14px;
  color: #798190 !important;
  //background-color: rgba(255, 153, 0, 0.44) !important;

  h3 {
    font-size: 16px;
    color: black;
  }
}

.max-width-1059 {
  max-width: 1059px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.diagnostic-header-card-progress {
  transform: translate(0, 0);

  .diagnostic-header-card-progress-slider {
    background: rgb(156, 206, 165);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: linear-gradient(
      90deg,
      rgba(0, 155, 164, 0.9094012605042017) 0%,
      rgba(156, 206, 165, 1) 100%
    );
  }
}

.diagnostic-header-card,
.diagnostic-footer-card {
  &.v-sheet {
    background-color: rgba(1, 1, 1, 0.7) !important;
  }
}
</style>

<script>
export default {
  name: "op-diagnostic-result",
  props: {
    value: {
      type: Object,
    },
    dialogSave: Boolean,
    registerLoading: Boolean,
    loginUrl: Object,
    dialogIndex: Number,
    nextTopicLink: Object,
    redoTopicLink: Object,
    showSaveAndAuthBtn: Boolean,

    registerIndex: Number,
    tabs: {
      type: Array,
    },
    differentUsers: {
      type: Boolean,
      default: false,
    },
    isAnonymous: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    diagnostic: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    scorePercentage() {
      return ((this.diagnostic.score - 1) * 100) / 3
    },
    opRegisterCard() {
      return this.$refs.opRegisterCard
    },
  },

  methods: {
    register(dialogIndex) {
      this.$authStore.setAnonymousData(true)
      this.$emit("update:dialogIndex", dialogIndex + 1)
    },
    login() {
      this.$authStore.setAnonymousData(true)
      this.$router.push({
        name: "login",
      })
    },
    displayError(response) {
      this.opRegisterCard.displayError(response)
    },
    consultCoach(value) {
      this.$emit("update:dialogSave", value)
      if (value) {
        this.$gtmLogic.savedDiagnostic(this.diagnostic.id)
      }
    },

    registerUser(body) {
      this.$emit("registerUser", body)
    },
  },
}
</script>

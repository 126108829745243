<template>
  <div ref="sliderSlide">
    <div class="product-slider-description">{{ item.title }}</div>
    <v-img
      :src="item.url_thumbnail"
      aspect-ratio="1.333"
      :max-height="imgMaxHeight"
      contain
    ></v-img>
  </div>
</template>

<script>
export default {
  name: "op-product-media-slide",
  props: {
    item: {
      type: Object,
      required: true,
    },
    imgMaxHeight: {
      type: String,
      required: false,
      default() {
        return "100%"
      },
    },
  },
}
</script>

<style lang="scss"></style>

<template>
  <div class="level-improvement">
    <v-col cols="12" md="6" class="level-title"
      ><div
        v-html="
          $t('store.levels.levelTitle', {
            productName: productName,
            level: level,
            categoryName: categoryName,
          })
        "
      ></div
    ></v-col>

    <div class="level-progression-line">
      <op-level-item
        :size="size(1)"
        :backgroundColor="backgroundColor(1)"
        :enableShadow="enableShadow(1)"
        textContent="1"
        :strokeWidth="strokeWidth(1)"
        class="level-item"
      ></op-level-item>

      <div
        :class="[
          'improvement-line',
          'first-part',
          lineColourClass(2),
          { 'with-arrows': displayArrows(2) },
        ]"
      ></div>

      <div
        :class="[
          'improvement-line',
          'second-part',
          lineColourClass(2),
          { 'with-arrows': displayArrows(2) },
        ]"
      ></div>

      <op-level-item
        :size="size(2)"
        :backgroundColor="backgroundColor(2)"
        :enableShadow="enableShadow(2)"
        textContent="2"
        :strokeWidth="strokeWidth(2)"
        class="level-item"
      ></op-level-item>

      <div
        :class="[
          'improvement-line',
          'first-part',
          lineColourClass(3),
          { 'with-arrows': displayArrows(3) },
        ]"
      ></div>

      <div
        :class="[
          'improvement-line',
          'second-part',
          lineColourClass(3),
          { 'with-arrows': displayArrows(3) },
        ]"
      ></div>

      <op-level-item
        :size="size(3)"
        :backgroundColor="backgroundColor(3)"
        :enableShadow="enableShadow(3)"
        textContent="3"
        :strokeWidth="strokeWidth(3)"
        class="level-item"
      ></op-level-item>

      <div
        :class="[
          'improvement-line',
          'first-part',
          lineColourClass(4),
          { 'with-arrows': displayArrows(4) },
        ]"
      ></div>

      <div
        :class="[
          'improvement-line',
          'second-part',
          lineColourClass(4),
          { 'with-arrows': displayArrows(4) },
        ]"
      ></div>

      <op-level-item
        :size="size(4)"
        :backgroundColor="backgroundColor(4)"
        :enableShadow="enableShadow(4)"
        textContent="4"
        :strokeWidth="strokeWidth(4)"
        class="level-item"
      ></op-level-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "op-improvement-levels",
  data() {
    return {}
  },
  props: {
    productName: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
  },
  methods: {
    strokeWidth: function (itemLevel) {
      return itemLevel == this.level ? 3 : 5
    },
    enableShadow: function (itemLevel) {
      return itemLevel == this.level
    },
    backgroundColor: function (itemLevel) {
      const diff = this.level - itemLevel
      if (diff == 0) {
        return "#05b1cc"
      } else if (diff < 0) {
        return "#06dce3"
      } else if (diff == 1) {
        return "#0383b4"
      } else {
        return "#02549c"
      }
    },
    size: function (itemLevel) {
      return itemLevel == this.level ? 100 : 56
    },
    displayArrows: function (itemLevel) {
      return itemLevel == this.level
    },
    lineColourClass: function (itemLevel) {
      if (itemLevel > this.level) {
        return "grey-line"
      } else if (itemLevel == this.level) {
        return "green-line"
      } else {
        return "blue-line"
      }
    },
  },
}
</script>

<style lang="scss">
.level-improvement {
  .level-title,
  .level-title b {
    font-size: 1.286em;
    line-height: 1.39;
    text-align: center;
    margin: 0 auto;
    color: $charcoal-grey;
  }

  .level-progression-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2.143em 0 3.571em;

    .level-item {
      z-index: 1;
    }

    .improvement-line {
      width: 20%;
      height: 6px;
      margin-left: -15px;
      margin-right: -15px;
      margin-top: -4px;

      &.first-part {
        margin-right: 0;
        width: 10%;

        &.with-arrows {
          &:before,
          &:after {
            content: "";
            width: 5px;
            height: 2px;
            display: flex;
            background: $ice-blue;
            position: absolute;
          }
          &:before {
            transform: translate(65px, 1px) rotate(45deg);
          }
          &:after {
            transform: translate(65px, 3px) rotate(-45deg);
          }
        }
      }

      &.second-part {
        margin-left: 0;
        width: 10%;

        &.with-arrows {
          &:before,
          &:after {
            content: "";
            width: 5px;
            height: 2px;
            display: flex;
            background: $ice-blue;
            position: absolute;
          }
          &:before {
            transform: translate(25px, 1px) rotate(45deg);
          }
          &:after {
            transform: translate(25px, 3px) rotate(-45deg);
          }
        }
      }

      &.green-line {
        &.first-part {
          background-image: linear-gradient(to left, #049ac0, #0483b4);
        }
        &.second-part {
          background-image: linear-gradient(to left, #05b1cc, #049ac0);
        }
      }

      &.blue-line {
        &.first-part {
          background-image: linear-gradient(to left, #036ba8, #02549c);
        }
        &.second-part {
          background-image: linear-gradient(to left, #0483b4, #036ba8);
        }
      }

      &.grey-line {
        background-color: #dedede;
        margin-left: -22px;
      }
    }

    .improvement-arrow {
      width: 50px;
      height: 8px;
      background-image: linear-gradient(252deg, #2400cd, #232ccc 37%, #004be8);
    }
  }
}
</style>

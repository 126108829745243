<template>
  <v-footer
    class="footer"
    padless
    :class="isConnected || isInDiagnostic ? 'light-footer' : 'blue-footer'"
  >
    <div class="upper-section" v-if="!(isConnected || isInDiagnostic)">
      <div class="max-width fullHeight">
        <v-row justify="space-between" align="top">
          <v-col class="bloc1">
            <div class="ftitle">Restez Informés</div>
            <div class="desc">
              Recevez notre newsletter mensuelle pour une actualité adaptée aux
              TPE-PME, et soyez le premier informé des nouveautés d'Oppens.
            </div>
            <div class="email">
              <input v-model="email" placeholder="Adresse email" />
              <div class="button" @click="submit()">
                <v-icon class="icon" color="white">mdi-plus</v-icon>
              </div>
            </div>
          </v-col>
          <v-col class="bloc2">
            <div class="ftitle">Besoin d'aide ?</div>
            <div class="desc">Support Oppens</div>

            <v-row justify="space-between" align="center">
              <div class="subbloc">
                <v-icon class="ml-1" color="white">mdi-phone</v-icon>
                <div class="desc">Support client</div>
                <div class="contact-info">0805 65 99 97</div>
              </div>

              <div class="subbloc">
                <v-icon class="ml-1" color="white">mdi-email</v-icon>
                <div class="desc">Support client</div>
                <div class="contact-info">support[at]oppens.fr</div>
              </div>
            </v-row>
          </v-col>
          <v-col class="bloc3">
            <div class="links">
              <a v-for="link in secondarylinks" :key="link.id" :href="link.url">
                {{ link.name }}
              </a>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      class="base-section"
      :class="{
        bigMargin: needBigMargin,
        smallMargin: needSmallMargin,
      }"
    >
      <div class="max-width fullHeight">
        <v-row
          justify="space-between"
          align="center"
          no-gutters
          class="fullHeight"
        >
          <v-col class="logobloc">
            <v-row justify="start" align="center" no-gutters>
              <div class="logo">
                <a :href="$links.oppens_site">
                  <v-img
                    :alt="`${companyName} logo`"
                    :src="logo"
                    height="37px"
                    width="37px"
                  >
                  </v-img>
                </a>
              </div>
              <div class="copyright">
                {{ `Copyright @${new Date().getFullYear()} ${companyName}` }}
              </div>
            </v-row>
          </v-col>
          <div class="links">
            <a v-for="link in mainlinks" :key="link.id" :href="link.url">
              {{ link.name }}
            </a>
          </div>
        </v-row>
      </div>
    </div>

    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-footer>
</template>
<style scoped lang="scss">
.max-width {
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.fullHeight {
  height: 100%;
}

.footer {
  border: 1px solid rgba(64, 69, 78, 0.08) !important;
  font-size: 14px;
  line-height: 20px;
}

/* main themes */
.light-footer {
  background-color: white !important;
  color: #40454e !important;

  a {
    color: #40454e !important;
  }
}

.blue-footer {
  background-color: #00305b !important;
  color: white !important;

  .base-section {
    background-color: #002649 !important;
  }
  a {
    color: white !important;
  }
}

$height: 40px;

/* upper section (disconnected mode) */
.upper-section {
  margin: 2% 2% 2% 2%;
  width: 100%;

  @media (max-width: 850px) {
    display: none;
  }

  .links {
    font-size: 1.1em;
    margin-top: 30px;
    margin-left: 0px;
    a {
      display: block;
      opacity: 1 !important;
      margin-bottom: 10px;
    }
    .contact-info {
      opacity: 0.7;
    }
    .email {
      margin-top: 30px;
    }
  }

  .bloc1 {
    margin-right: 50px;
  }

  .bloc2 {
    max-width: 300px;
  }
  .bloc3 {
    max-width: 250px;
  }

  .email {
    margin-top: 20px;
    border: 1px solid white;
    width: 85%;
    border-radius: 6px;
    padding: 2px;
    height: 50px;

    ::placeholder {
      color: white;
      opacity: 0.7;
    }

    .button {
      float: right;
      background-color: #becbd7;
      border-radius: 6px;
      height: 100%;
      width: 25%;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    input {
      color: white;
      //background-color: antiquewhite;
      height: 100%;
      width: 73%;
      padding: 2px;
      padding-left: 10px;
    }
  }
}

.subbloc {
  margin: 2%;
}

.ftitle {
  font-weight: 600;
  font-size: 1.4em;
  font-size: 20px;
  line-height: 28px;
}
.desc {
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.4;
}

/* base section (connected & disconnected mode) */
.base-section {
  height: 65px;
  width: 100%;
  font-size: 12px;
}

.logobloc {
  @media (max-width: 850px) {
    display: none;
  }

  .logo {
    height: 20px;
    margin-top: -15px;
    filter: grayscale(100%);
  }

  .copyright {
    margin-left: 10px;
    opacity: 0.4;

    @media (max-width: 950px) {
      display: none;
    }
  }
}

.links {
  min-height: 20px;
  margin-right: 2%;
  //border: 1px solid red;

  @media (max-width: 850px) {
    width: 100%;
  }

  a {
    margin-left: 20px;
    text-decoration: none;
    height: 20px;
    opacity: 0.6;
    //opacity: 0.8;
    font-weight: 500;
    line-height: 20px;

    @media (max-width: 850px) {
      margin-left: 10px;
      width: 46%;
      display: inline-block;
      text-align: center;
      //border: 1px solid blue;
    }
  }
}

/* connected mode with toolbar */
.smallMargin {
  padding-left: 62px;
  @media (max-width: 850px) {
    padding-left: 0px;
  }
}
.bigMargin {
  padding-left: 304px;
}
</style>
<script>
import PageStore from "@/stores/PageStore"
import UserLogic from "@/logics/UserLogic"

export default {
  name: "op-footer",
  computed: {
    logo() {
      return this.$customizationStore.getLogos()?.thumbnail
    },
    companyName() {
      return this.$customizationStore.getCompany()?.name
    },
    isInDiagnostic() {
      const diagnosticRoutes = [
        "analysis",
        "member-diagnostic-survey",
        "member-diagnostic-survey",
        "member-diagnostic-survey-standalone",
        "member-diagnostic-result",
        "member-diagnostic-result-standalone",
        "store_checkout",
      ]

      const routeName = this.$router.currentRoute.name
      if (routeName && diagnosticRoutes.includes(routeName)) {
        return true
      }
      return false
    },
    isConnected() {
      if (
        this.$authStore.state.me &&
        false == this.$authStore.state.me.is_anonymous
      ) {
        return true
      }
      return false
    },
    needBigMargin() {
      if (PageStore.drawer && this.isConnected) {
        return true
      }
      return false
    },
    needSmallMargin() {
      if (!PageStore.drawer && this.isConnected) {
        return true
      }
      return false
    },
  },
  data: () => ({
    PageStore,
    email: null,
    mainlinks: [
      {
        id: 1,
        name: "Mentions légales",
        url: "https://www.oppens.fr/mentions-legales-securite-informatique/",
      },
      {
        id: 2,
        name: "Politique de confidentialité",
        url: "https://www.oppens.fr/politique-confidentialite/",
      },
      {
        id: 3,
        name: "Gestion des cookies",
        url: "https://www.oppens.fr/politique-cookies/",
      },
      {
        id: 4,
        name: "Conditions générales",
        url: "https://www.oppens.fr/conditions-generales/",
      },
    ],
    secondarylinks: [
      {
        id: 1,
        name: "Dernières actualités",
        url: "https://www.oppens.fr/blog-cybersecurite/",
      },
      {
        id: 2,
        name: "Nos Solutions",
        url: "https://app.oppens.fr/store/",
      },
      {
        id: 3,
        name: "Contactez-nous",
        url: "https://www.oppens.fr/contact/",
      },
      /*{
        id: 4,
        name: "F.A.Q",
        url: "#",
      },*/
    ],
    snackbar: {
      show: false,
      color: "success",
      snackbar: false,
      text: "",
      timeout: 3000,
    },
  }),
  methods: {
    submit() {
      if (!this.validEmailFormat()) {
        this.displayError("Format d'email invalide")
        return
      }

      this.displaySuccess()

      UserLogic.registerToNewsletter(this.email)
        .then(() => {
          this.email = null
        })
        .catch(() => {
          this.displayError()
          this.email = null
        })
    },
    validEmailFormat() {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(this.email)
    },
    displaySuccess() {
      this.snackbar.text = "Merci, votre email à bien été enregistré"
      this.snackbar.color = "success"
      this.snackbar.show = true
      this.$gtmLogic.newsletterSubscription()
    },
    displayError(msg) {
      this.snackbar.text = "Désolé une erreur a eu lieu"
      this.snackbar.color = "error"
      this.snackbar.show = true
      if (msg) {
        this.snackbar.text = msg
      }
    },
  },
}
</script>

import UserLogic from "@/logics/UserLogic"

export default {
  data() {
    return {
      companyUuid: "",
      companyRoles: [],
    }
  },
  mounted() {
    this.setCompany()
  },
  methods: {
    async setCompany() {
      const user = await UserLogic.getMe()
      this.companyUuid = user.company?.uuid
      this.companyRoles = user.company_roles
    },
  },
  computed: {
    canExport() {
      return this.companyRoles && this.companyRoles.includes("company-user")
    },
  },
}

import ZapAPIRepository from "../repositories/ZapAPIRepository"
import UserLogic from "./UserLogic"

export default class ZapAPILogic {
  static async requestScan(url, emails) {
    console.log("- start scan request -")
    let user = await UserLogic.getMe()
    let result = {
      success: null,
      message: null,
    }

    try {
      let ret = await ZapAPIRepository.requestScan(
        user.uuid,
        user.email,
        url,
        emails
      )
      console.log("requestScan ret = ", ret)

      if (ret && ret.status == 200) {
        result = {
          success: true,
          message: "",
        }
      }
    } catch (e) {
      console.error("failed to request scan ", e)
      console.error("error ", e.response)

      result = {
        success: false,
        message: e.response.data.msg || e.response.data.message,
      }
    }

    return result
  }

  /** @description
   */
  static async scanResult(url) {
    let user = await UserLogic.getMe()

    try {
      let ret = await ZapAPIRepository.scanResult(url, user.uuid)
      console.log("scan results ret = ", ret)
      if (ret && ret.scan) {
        return ret.scan
      }
    } catch (e) {
      console.error("failed to reach Zap API ")
    }

    return null
  }

  static async getScanHistory() {
    let user = await UserLogic.getMe()
    try {
      let ret = await ZapAPIRepository.getScanHistory(user.uuid)
      console.log("got scan history ret = ", ret)
      if (ret && ret.history) {
        return ret.history
      }
    } catch (e) {
      console.error("failed to reach Zap API ")
    }
    return null
  }

  static async getScan(scanID) {
    try {
      let ret = await ZapAPIRepository.getScan(scanID)
      if (ret && ret.scan) {
        return ret.scan
      }
    } catch (e) {
      console.error("failed to reach Zap API ")
    }
    return null
  }

  static async getNotifications() {
    let user = await UserLogic.getMe()

    let notifications = []

    try {
      let ret = await ZapAPIRepository.getNotifications(user.uuid)
      if (ret && ret.notifications) {
        notifications = ret.notifications
      }
    } catch (e) {
      console.error("failed to reach Zap API ")
    }

    return notifications
  }

  static async deleteNotification(notif) {
    let result = {
      success: null,
      message: null,
    }

    if (!notif._id) {
      result = {
        success: false,
        message: "missing notification id",
      }

      return result
    }

    try {
      let ret = await ZapAPIRepository.deleteNotification(notif._id)
      console.log("deleteNotification ret = ", ret)

      if (ret && ret.status == 200) {
        result = {
          success: true,
          message: "",
        }
      }
    } catch (e) {
      console.error("failed to request scan ", e)
      console.error("error ", e.response)

      result = {
        success: false,
        message: e.response.data.msg,
      }
    }

    return result
  }
}

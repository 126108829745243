<template>
  <v-row
    id="op-diagnostic-timeline"
    op-diagnostic-timeline
    slider-height="8px"
    align="center"
    style="position:relative;height:100px;"
  >
    <div style="position: absolute;height:140px;width: 100%;z-index:5;">
      <div :style="getStyleObj(value, 1)">
        <op-diagnostic-timeline-card
          :value="1"
          :checked="value >= 0.1"
          :sizeLarge="sizeLarge"
          :size="sizeSmall"
          :large="value >= 0.1 && value < 30"
        >
        </op-diagnostic-timeline-card>
      </div>
      <div :style="getStyleObj(value, 2)">
        <op-diagnostic-timeline-card
          :value="2"
          :checked="value >= 30"
          :sizeLarge="sizeLarge"
          :size="sizeSmall"
          :large="value >= 30 && value < 60"
          :color="$colors.blue.lighten1"
        >
        </op-diagnostic-timeline-card>
      </div>
      <div :style="getStyleObj(value, 3)">
        <op-diagnostic-timeline-card
          :value="3"
          :checked="value >= 60"
          :large="value >= 60 && value < 90"
          :sizeLarge="sizeLarge"
          :size="sizeSmall"
          :color="$colors.blue.lighten2"
        >
        </op-diagnostic-timeline-card>
      </div>
      <div :style="getStyleObj(value, 4)">
        <op-diagnostic-timeline-card
          :value="4"
          :checked="value >= 90"
          :large="value >= 90"
          :sizeLarge="sizeLarge"
          :size="sizeSmall"
          :color="$colors.blue.neon"
        >
        </op-diagnostic-timeline-card>
      </div>
    </div>

    <v-col cols="12">
      <v-slider
        ref="diagnostic-timeline-slider"
        color="diagnostic-timeline-gradient-1"
        thumb-color="black"
        track-color="grey"
        hide-details
        readonly
        :thumb-size="24"
        :height="10"
        :value="sliderValue"
        @input="$emit('input', $event)"
      >
      </v-slider>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.row[op-diagnostic-timeline] {
  .diagnostic-timeline-gradient-1 {
    background: rgb(2, 84, 156);
    background: linear-gradient(
      90deg,
      rgba(2, 84, 156, 1) 0%,
      rgba(3, 131, 180, 1) 100%
    );
  }

  .v-slider__track-container {
    height: 8px;
  }

  .v-slider__thumb-container {
    background: white;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    position: inherit;
    z-index: 10;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  }

  .v-slider__thumb {
    height: 10px;
    width: 10px;
    z-index: 10;
    position: absolute;
    top: 12.5px;
    left: 7.7px;
  }
}
</style>

<script>
export default {
  name: "op-diagnostic-timeline",
  data: () => ({
    small: 70,
    large: 110,
  }),
  methods: {
    computeStep(value) {
      const steps = [0, 30, 60, 90]
      let step = null
      steps.forEach((val, index) => {
        if (
          value >= steps[index] &&
          (index <= 3 || value <= steps[index + 1])
        ) {
          step = index + 1
        }
      })
      return step
    },
    getStyleObj(value, position) {
      let style = {
        bottom: null,
        left: 0,
        position: "absolute",
      }

      let width = window.innerWidth
      const step = this.computeStep(value)

      //compute bottom position
      style.bottom = step == position ? "9%" : "24%"
      if (width < 500) {
        style.bottom = step == position ? "25%" : "34%"
      }

      if (2 == position) {
        style.left =
          value > 30 && value < 60
            ? `calc(33.333% - ${this.halfSizeLarge})`
            : `calc(33.333% - ${this.halfSizeSmall})`
      }
      if (3 == position) {
        style.left =
          value > 60 && value < 90
            ? `calc(66.6666% - ${this.halfSizeLarge})`
            : `calc(66.6666% - ${this.halfSizeSmall})`
      }
      if (4 == position) {
        style.left =
          value > 90
            ? `calc(100% - ${this.sizeLarge})`
            : `calc(100% - ${this.sizeSmall})`
      }

      return style
    },

    leftPosition() {},
  },
  computed: {
    sliderValue() {
      let val = this.value
      let width = window.innerWidth

      if (this.value < 4) {
        val = 9
      }
      if (this.value >= 33 && this.value <= 35) {
        val = 36
      }
      if (this.value >= 66 && this.value <= 70) {
        val = 71
      }
      if (this.value >= 98) {
        val = width <= 500 ? 85 : 90
      }

      return val
    },
    sizeLargeValue() {
      let width = window.innerWidth
      return width < 500 ? this.large / 1.5 : this.large
    },
    sizeSmallValue() {
      let width = window.innerWidth
      return width < 500 ? this.small / 1.5 : this.small
    },
    sizeLarge() {
      return this.sizeLargeValue + "px"
    },
    sizeSmall() {
      return this.sizeSmallValue + "px"
    },
    halfSizeLarge() {
      return this.sizeLargeValue / 2 + "px"
    },
    halfSizeSmall() {
      return this.sizeSmallValue / 2 + "px"
    },
  },
  props: {
    value: {
      type: Number,
      default: 0,
      validator: value => value >= 0 && value <= 100,
    },
  },
}
</script>

<template>
  <v-card width="100%" height="100%" outlined rounded="lg" class="pa-3">
    <v-card-title class="text-h3">{{
      $t("courses.successRateTitle")
    }}</v-card-title>
    <v-container class="mb-2">
      <template v-if="isRelevantData">
        <v-row v-for="course in sortedCourses" :key="course.id">
          <v-col cols="8" class="marine-blue--text text-body-1"
            ><div class="mb-3">{{ course?.name }}</div>
            <v-progress-linear
              :value="course?.summary?.success_percentage"
              rounded
              :height="9"
              background-color="grey"
              color="primary"
            ></v-progress-linear
          ></v-col>
          <v-col
            cols="2"
            class="grey-blue--text text-body-1 d-flex align-end"
            >{{ `${course?.summary?.success_percentage}%` }}</v-col
          >
          <v-col cols="2" class="d-flex justify-end"
            ><v-btn
              class="primary--text"
              fab
              small
              elevation="0"
              :to="{
                name: 'member-course',
                params: { courseId: course.id },
              }"
              ><v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row></template
      >
      <template v-else
        ><div class="text-body-1">
          {{ $t("courses.noStatisticsAvailableAtAll") }}
        </div></template
      >
    </v-container>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        class="text-none rounded-lg"
        elevation="0"
        small
        :to="{
          name: 'member-course-history',
        }"
        >{{ $t("courses.seeAll") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { cloneDeep, slice } from "lodash"
import { DateTime } from "luxon"
export default {
  name: "op-course-success-rate",
  props: {
    courses: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    isRelevantData() {
      return this.sortedCourses.length > 0
    },
    sortedCourses() {
      let sorted = cloneDeep(this.courses)
      sorted.sort((a, b) => {
        if (a.is_active === true) {
          return -1
        } else if (a.status === "stopped") {
          if (b.is_active === true) {
            return 1
          } else if (b.status === "stopped") {
            return 0
          } else {
            return -1
          }
        } else {
          if (b.status === "created") {
            return 0
          } else {
            return 1
          }
        }
      })
      sorted = slice(sorted, 0, this.limit)
      sorted.sort((a, b) => {
        const aDate = DateTime.fromISO(a.start_date)
        const bDate = DateTime.fromISO(b.start_date)
        if (aDate > bDate) {
          return -1
        } else if (aDate < bDate) {
          return 1
        } else {
          return 0
        }
      })
      return sorted
    },
  },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.532"
    height="14.852"
    viewBox="0 0 15.532 14.852"
    class="phishing-menu-icon"
  >
    <path
      class="menu-icon"
      data-name="Tracé 3944"
      d="M17.36,2.7A1.461,1.461,0,0,0,15.9,2.238L3.947,5.287a2.328,2.328,0,0,0-.618,4.26l3.547,2.106a.309.309,0,0,1,.111.135L8.563,15.6a2.29,2.29,0,0,0,2.154,1.441h0a2.3,2.3,0,0,0,2.154-1.433L17.606,4.226a1.459,1.459,0,0,0-.245-1.52ZM3.393,7.377a1.115,1.115,0,0,1,.847-.942L15.428,3.584,7.368,10.56,3.939,8.525a1.108,1.108,0,0,1-.546-1.148Zm8.385,7.768a1.107,1.107,0,0,1-1.053.7h0a1.107,1.107,0,0,1-1.053-.7l-1.52-3.682,8.061-6.984Z"
      transform="translate(-2.186 -2.194)"
    />
  </svg>
</template>
<script>
export default {
  name: "op-phishing-menu-icon",
}
</script>
<style lang="scss" scoped>
.phishing-menu-icon {
  fill: currentColor;
}
</style>

<template>
  <v-card
    width="100%"
    class="pt-6 pb-3 pr-16 pl-6 card-background"
    color="primary"
    rounded="lg"
    elevation="0"
  >
    <div class="d-flex justify-space-between">
      <div>
        <v-card-title class="text-h2 white--text">{{ title }}</v-card-title>
        <v-container v-if="dataLines.length > 0">
          <div
            class="d-flex justify-start"
            v-for="(line, index) in dataLines"
            :key="index"
          >
            <img :src="line.icon" :alt="line.text" width="30" height="35" />
            <v-card-text class="white--text py-1"
              ><span class="text-h3">{{ `${line.data} ` }}</span
              ><span class="text-h4">{{ line.text }}</span></v-card-text
            >
          </div>
          <v-btn
            class="primary--text mt-5"
            elevation="0"
            rounded
            :to="linkRoute"
            ><v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-container>
        <v-card-text v-else class="text-body-1 white--text">
          {{ noDataText }}
        </v-card-text>
      </div>
      <div class="mt-8 mr-16"><img :src="backgroundIcon" /></div>
    </div>
  </v-card>
</template>

<script>
import lodash from "lodash"
import PhishingMixin from "@/mixins/Phishing.mixins.js"

export default {
  name: "op-campaign-linked-activity-card",
  props: {
    title: {
      type: String,
      default() {
        return ""
      },
    },
    dataLines: {
      type: Array,
      default() {
        return []
      },
    },
    noDataText: {
      type: String,
      default() {
        return ""
      },
    },
    backgroundIcon: {
      type: String,
      required: true,
    },
    linkRoute: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style scoped lang="scss">
.card-background {
  background: radial-gradient(
    circle farthest-corner at 100% 100%,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 10%,
    var(--v-primary-base) 10%,
    var(--v-primary-base)
  );
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="size"
    :height="size"
    viewBox="0 0 40 40"
  >
    <defs>
      <circle id="a" cx="20" cy="20" r="20" />
      <path
        id="c"
        d="M3.827 2.406L9.468 8l-5.64 5.594a1 1 0 0 0 0 1.42l.29.288a1 1 0 0 0 1.408 0l6.647-6.592a1 1 0 0 0 0-1.42L5.526.698a1 1 0 0 0-1.409 0l-.29.288a1 1 0 0 0 0 1.42z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="b" fill="#fff">
        <use xlink:href="#a" />
      </mask>
      <g fill="#00305B" fill-rule="nonzero" mask="url(#b)">
        <path d="M0 0h40v40H0z" />
      </g>
      <g mask="url(#b)" id="arrow" :class="{ left: direction == 'left' }">
        <g transform="translate(12 12)">
          <mask id="d" fill="#fff">
            <use xlink:href="#c" />
          </mask>
          <use fill="#000" fill-rule="nonzero" xlink:href="#c" />
          <g fill-rule="nonzero" mask="url(#d)">
            <path fill="#FFF" d="M0 0h16v16H0z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "op-navigation-arrow",
  props: {
    direction: {
      type: String,
      required: true,
      default() {
        return "right"
      },
    },
    size: {
      type: Number,
      required: false,
      default() {
        return 40
      },
    },
  },
}
</script>

<style lang="scss">
#arrow {
  transform-origin: 20px 20px;

  &.left {
    transform: rotate(180deg);
  }
}
</style>

import CourseRepository from "../repositories/CourseRepository"

export default class CourseLogic {
  /**
   *
   * @param uuid
   * @returns {Promise}
   */
  static async getAll(companyUuid, params = {}) {
    /* try {
      const res = await CourseRepository.getAll(companyUuid, params)
      let courseList = res?.response?.data
      return courseList
    } catch (e) {
      return e.message
    } */
    const response = await CourseRepository.getAll(companyUuid, params)
    return response.dataObject()
  }
  /**
   *
   * @param companyUuid
   * @param params
   * @param courseId
   * @returns {Promise}
   */
  static async getDetails(companyUuid, courseId, params = {}) {
    /* try {
      const res = await CourseRepository.getAll(companyUuid, {
        ...params,
        "company_course_ids[]": courseId,
      })
      let courseList = res?.response?.data
      return courseList
    } catch (e) {
      return e.message
    } */
    const response = await CourseRepository.getAll(companyUuid, {
      ...params,
      "company_course_ids[]": courseId,
    })
    return response.dataObject()
  }
  /**
   *
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static async getEmployees(companyUuid, params = {}) {
    try {
      const res = await CourseRepository.getEmployees(companyUuid, params)
      let employeeList = res?.response?.data
      return employeeList
    } catch (e) {
      return e.message
    }
  }
  /**
   *
   * @param companyUuid
   * @returns {Promise}
   */
  static async getStats(companyUuid) {
    /* try {
      const res = await CourseRepository.getStats(companyUuid)
      let stats = res?.response?.data
      return stats
    } catch (e) {
      return e.message
    } */
    const response = await CourseRepository.getStats(companyUuid)
    return response.dataObject()
  }
  /**
   *
   * @param courseId
   * @returns {Promise}
   */
  static async exportResults(companyUuid, courseId) {
    try {
      const res = await CourseRepository.exportResults(companyUuid, courseId)
      let results = res?.response
      return res
    } catch (e) {
      return e.message
    }
  }

  /**
   *
   * @param courseId
   * @returns {Promise}
   */
  static async exportCompletedSessions(companyUuid, courseId) {
    try {
      const res = await CourseRepository.exportCompletedSessions(companyUuid, courseId)
      let results = res?.response
      return res
    } catch (e) {
      return e.message
    }
  }

  /**
   * @param companyUuid
   * @param list
   * @returns {Promise}
   */
  static async setMissingList(companyUuid, courseId, list = []) {
    const response = await CourseRepository.setMissingList(
      companyUuid,
      courseId,
      list
    )
    return response
  }

  /**
   * @param companyUuid
   * @param list
   * @returns {Promise}
   */
  static async setPresentList(companyUuid, courseId, list = []) {
    const response = await CourseRepository.setPresentList(
      companyUuid,
      courseId,
      list
    )
    return response
  }
}

<template>
  <ValidationObserver ref="form" v-slot="scopedValue">
    <op-alert tile ref="opAlert" />

    <slot v-bind:default="scopedValue"></slot>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate"

export default {
  name: "op-form",

  extends: ValidationObserver,

  props: {},

  computed: {
    opAlert() {
      return this.$refs.opAlert
    },
    form() {
      return this.$refs.form
    },
  },

  methods: {
    displayError(error) {
      if (error.data) {
        let errors = error.data.errors
        let message = error.data.message

        if (errors) {
          this.setErrors(errors)

          if (errors.password) {
            message = errors.password[0]
          }
        }

        if (message) {
          this.displayAlert(message)
        }
      } else {
        this.displayAlert(error)
      }
    },

    displayAlert(...args) {
      this.opAlert.displayAlert(...args)
    },
    displaySuccess(str) {
      this.opAlert.displayAlert(str, "success")
    },
  },
}
</script>

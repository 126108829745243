class CategoryStore {
  constructor() {
    this.list = null
  }

  setList(list = null) {
    this.list = list
  }

  getCategoryById(categoryId) {
    if (this.list) {
      return this.list.find(
        (category) =>
          category.attributes.slug == categoryId ||
          category.id === parseInt(categoryId)
      )
    } else {
      return null
    }
  }

  reset() {
    this.list = null
  }
}

export default new CategoryStore()
